
(function(){
'use strict';

/* Directives */


var module = angular.module('kohapac.directives', []);


/* Use:
    <div>
        <koha-marc-field bib="bib" tagspec='244bhc' join=" " />
        <span class="attribution" ng-show="record.has(100)||record.has(110)">  <!-- etc -->
            by <koha-marc-field tag='100abcdq' join=". " filter-last="." />
        </span>
    </div>
*/
// FIXME: this should be like bvMarcFieldSet,
// should select fields, pass to new directive that keeps the field object on its controller.

// much of this is duplicated in bv-marc-display.
module.directive('kohaMarcField', ["$filter", "$sce", "configService", function($filter, $sce, configService){
    return {
        scope: {
            bib: '=',
            subfieldJoin: '@',
            fieldJoin: '@',
            filterEach: '@',
            filterLast: '@',
            tagspec: '@',
            reorder: '@'
            // anchor-href: '@',
            // anchor-text: '@'
        },
        template: '<span ng-repeat="field in fields" ng-bind-html="field"></span>',
        link: function(scope, el, attrs) {

            scope.tag = attrs.tagspec.substr(0,3);

            var dereg = scope.$watch('bib', function(bib){
                if(!bib) return;

                var record = bib.marc;

                scope.fields = [];

                var tagspec = attrs.tagspec.substr(3);
                var options = { delimiter : attrs.subfieldJoin || ' ',
                        filter : tagspec,
                        reorder: attrs.hasOwnProperty('reorder'),
                        filterEach: []
                      };

                if(attrs.filterEach) {
                    options.filterEach.push( {filter: $filter(attrs.filterEach), target: '*'} );
                }
                else {
                    // if the subfield is mapped to an authval, display the authval description in addition to the authval code.
                    // this logic is overridden when a filterEach function is explicitly delcared in the directive tag.
                    var targetfield = record.field(scope.tag);
                    if(targetfield && targetfield.subfield_data.length){
                        var target_subfields = targetfield.subfields(tagspec);
                        var target_subfields_len = target_subfields.length || 0;
                        for(var i = 0; i < target_subfields_len; i++){
                            var subf = target_subfields[i].code;
                            var targettag = scope.tag+subf;
                            var authvalcat = (configService.marcAuthvals && configService.marcAuthvals.bib) ? configService.marcAuthvals.bib[targettag] : null;
                            if(authvalcat){
                                options.filterEach.push( {filter: $filter('displayName'), target: targettag, type: authvalcat} );
                            }
                        }
                    }
                }

                if(attrs.filterLast) options.filterLast = $filter(attrs.filterLast);

                record.fields(scope.tag).forEach(function(field, i, arr){
                    var field_html = field.html(options);
                    field.linkedFields().forEach(function(linkedField){
console.warn('LINKED FIELD');
console.log(angular.copy(linkedField));
                        field_html +=  linkedField.html(options);
                    });
                    if(attrs.fieldJoin && i<arr.length-1)
                        field_html += '<span class="delim">' + attrs.fieldJoin + '</span>';
                    scope.fields.push($sce.trustAsHtml(field_html));
                });

            });

        }
    };
}])

.directive('kohaSearchResult', ["configService", "bibService", "$timeout", "kohaTagsSvc", "ratingService", function(configService, bibService, $timeout, kohaTagsSvc, ratingService){
    return {
        templateUrl: '/app/static/partials/bib-result.html',
        scope: true,
        // attrs: index,
        //        resultSet (0 or 1 for injected EBSCO results.)

        link: function(scope,el,attrs){
            //FIXME: IF config.altSearch.eds.injectNum > numSearchResults, then this will break.
            // lives in ng-repeat, so we have parent scope's search & federatedSearch

            scope.searchResultNum = Number(scope.search.results.start) + Number(scope.$eval(attrs.index)) + 1;
            var edsOffset = configService.altSearch.eds.on ?
                    (parseInt(configService.altSearch.eds.injectOffset)||0) : 0;

            if(attrs.resultSet=='1'){
                scope.searchResultNum += (parseInt(configService.altSearch.eds.injectNum)||0) + edsOffset;
            }

            if (configService.search.resultsDisplayHoldings)
                scope.includeHoldings = true;

            var bibid = scope.$eval(attrs.bibid);

            var getTags = !!configService.TagsShowOnList;
            bibService.get(bibid, { includeTags: getTags }).then(function (bib) {
                scope.bib = bib;
                if (getTags && !bib.tags) {
                        $timeout(function () {
                            kohaTagsSvc.get(bibid).then(function (tags) {
                                // only add tags to searchresults page. searchResults prop is added in an ng-init in the results view.
                                bib.tags = tags;
                            });
                        }, 100);
                }
                if (scope.includeHoldings) {
                    if (!scope.holdings) bibService.holdings(bibid).then(function (holdings) {
                        scope.holdings = holdings;
                    });
                }

                // Just for the promise
                if (scope.reloadRatings) {     // yes we're testing if the function is defined
                    var dereg;
                    scope.watchRatings = function() {
                        dereg = scope.$watch('patronRatings[bib.id]', function(newVal, oldVal) {
                            if (newVal && (newVal !== oldVal)) {
                                ratingService.submit(newVal, scope.bib.id).then(function(data) {
                                    scope.bib.summary.average_rating = data.average_rating;
                                }, function() {
                                    // Prevent infinite loop, and no reason to continue anyway
                                    dereg();
                                    dereg = null;
                                    scope.ratings[bib.id] = oldVal;
                                });
                            }
                        });
                    };
                    scope.watchRatings();
                    scope.$on('loggedin', function(e, args) {
                        if (dereg) {
                            dereg();
                            dereg = null;
                        }
                        scope.reloadRatings().then(function() {
                            scope.watchRatings();
                        });
                    });
                }
            });

        },
        controller: ["$scope", function($scope){
        }]
    };
}])
.directive('ebscoSearchResult', function(){
    return {
        controller: ["$scope", "$sce", function($scope, $sce){
            $scope.trusted = $sce.trustAsHtml;
        }],
        templateUrl: '/app/static/partials/bib-result-ebsco.html',
        link: function(scope,el,attrs){
        }
    };
});

module.directive('bvTitlePreload', ["bibService", function(bibService){
    // Render title from solr until bib has been fetched.
    return {
        restrict: 'E',
        scope: {
            solrbib: '='
        },
        template: '<div class="preload-title" ng-show="title"><h2 class="bibdata title">'+
            '<a ng-href="{{::href}}">{{::title}}</a></h2></div>',
        link: function(scope,el,attrs){

            var drg = scope.$watch('solrbib', function(solrbib){
                if(!solrbib) return;
                if(solrbib.title){
                    scope.title = solrbib.title;
                    scope.href = bibService.details_url();
                    var bibQ = bibService.get(solrbib.id).then(function(bib){
                        scope.title = null;
                    });
                }
                drg();
            });
        }
    };
}])

.directive('bvMarcDisplay', ["configService", "$q", "$http", "$filter", "bibService", "$timeout", "kohaSearchSvc", "marcBibSpec", function(configService, $q, $http, $filter,
            bibService, $timeout, kohaSearchSvc, marcBibSpec ){

    // Directive builds html for MARC data display.
    // This is built outside of angularjs to simplify customization options.

    function safeClassname(str){ // safe-ish.
        return str.toLowerCase().replace(/[^a-z ]+.*$/,'').replace(/\s+/g,'-');
    }
    function defaultBlockClassname(label){
        if(!label) return '';
        var wordmatch = safeClassname(label);
        if (wordmatch) return wordmatch + '-data';
            else return '';
    }
    function fieldClasses (f){
        // e.g. '760 1# ...' => marcfield marc760 marc7XX marc-i11 marc-i2 marc-note-hidden .
        // FIXME: hidden notes should be removed rather than hidden depending on interface.
        var i1 = (!f.ind1 || f.ind1 === ' ' || f.ind1 === '#') ? '' : f.ind1;
        var i2 = (!f.ind2 || f.ind2 === ' ' || f.ind2 === '#') ? '' : f.ind2;
        return 'marcfield ' + ('marc' + f.tag) +
                ' marc' + f.tag.substring(0,1) + 'XX' +
                ' marc-i1'+i1 + ' marc-i2'+i2 +
                ((marcBibSpec.noteIsHidden(f)) ? ' marc-note-hidden':'') +
                (f.subfield('a') ? '' : 'no-subf-a') ;
    }
    function normalizeQuery(q){
        return '(' + encodeURIComponent( q.replace(/[\(\):]/," ") ) + ')';  //clean for solr query.
    }

    var fieldFilter = {  // returns true for fields that should not render.
        // avoids dangling labels.

        "856": function(field){
            return !!field.subfield('b'); // DLSO
        }
    };

    function pluralize(n,term){ return n + " " + term + ((n!=1) ? 's':'') ;}

    function collapseClicker(showText, hideText, collapseEl){
        var $label = $('<span class="collapse-label">'+showText+'</span>');
        var $clicker = $('<a href class="collapser"> </a>')
                        .append($label).append('<span class="caret"></span>').click(function(e){
                            var hiding = (collapseEl.is(':visible'));
                            if(hiding){
                                collapseEl.hide(300);
                                $label.text( showText );
                                $(this).removeClass('dropup');
                            } else {
                                collapseEl.show(300);
                                $label.text( hideText );
                                $(this).addClass('dropup');
                            }
                            return false;
                        })
        return $clicker;

    }

    function runFieldProcessor(p, fields, el, bib){
        var processor = {
                linkedRecord: function(data,$fieldset,bib,opt){

                    if(!opt) opt = {};
                    // get first matching subfield w or o, and link to catalog record.
                    data.forEach(function(f){
                        var sysid = f.field.subfield('o')||f.field.subfield('w');
                        $q.when( (/^\d+$/.test(sysid)) ? bibService.get(sysid) :
                            $http.get('/api/opac/ctrlnum:"'+sysid+'"',
                                { rows: 1, facet: false, fq:"suppress:0" }).then(function(rsp){
                                    if( ! rsp.data.hits.length ) return null;
                                    return bibService.put(rsp.data.hits[0]._embed); //meh.
                                }, function(nomatch){ return null; })
                        ).then(function(bib){
                            if(!bib) return;
                            // wrap $a or $t with link:
                            var $sfat = f.$el.find('.subfield-a, .subfield-t');
                            var $sfow = f.$el.find('.subfield-o, .subfield-w');
                            var $linkEl;
                            if($sfat.length){
                                $sfow.hide();
                                $linkEl = $sfat.first();
                            } else {
                                $linkEl = $sfow.first().html(bib.marc.title());
                            }
                            $linkEl.wrapInner( $("<a>").attr('href','/app/work/'+bib.id).attr('title', bib.marc.title() ) );
                            if(opt.itemCount){
                                if(bib.summary.item_count)
                                    $linkEl.append('<span class="item-count">[' + pluralize(bib.summary.item_count, 'item') + ']</span>');
                                if(bib.uuids && bib.uuids.length)
                                    $linkEl.append('<span class="dlso-count">[' + pluralize(bib.uuids.length,'document') + ']</span>');
                            }
                        });
                    });

                },
                "marc856": function(data, $fieldset, bib){
                    data.forEach(function(f){
                        var href = f.field.subfield('u');
                        var linktext = (f.field.subfields('y3z')[0]||{}).value ||
                                configService.URLLinkText || "Click to access online. ";
                        f.$el.wrapInner($("<a>"+linktext+"</a>").attr('href',href));
                    });

                },
                detailLink: function(data, $fieldset,bib){
                    data.forEach(function(f){
                        f.$el.wrapInner(
                            $('<a href="'+bibService.details_url(bib.id)+'" class="details-link"></a>'));
                    });
                },
                collapseFields: function(data, $fieldset, bib, opt){

                    // collapsed by default:
                    // subjects, isbn, notes.

                    // adds collapsable with options:
                    //   trigger: N -- min number of fields required to add collapse.
                    //   show: M -- num fields to show uncollapsed.
                    //   hideText: str
                    //   showText: str.

                    if(opt.trigger && data.length < opt.trigger)
                        return;
                    var collapsedCount = data.length - opt.show||0;
                    var showMoreText = opt.showText ||
                            ((opt.show) ? 'Show ' + collapsedCount + ' more' : 'Show');
                    var hideText = opt.hideText || (opt.show) ? 'Show fewer' : 'Hide';

                    var $collapsedFieldset= $('<div class="collapsed-fields"></div>');
                    var $collapsedContainer = $('<div class="collapsible"></div>').append($collapsedFieldset);
                    $collapsedFieldset.hide();

                    var $clicker = collapseClicker(showMoreText, hideText, $collapsedFieldset);

                    if(opt.show){
                        $clicker.appendTo($collapsedContainer);
                    } else {
                        $clicker.prependTo($collapsedContainer);
                    }
                    for (var i = opt.show; i < data.length; i++) {
                        data[i].$el.appendTo($collapsedFieldset);
                    }
                    $fieldset.append($collapsedContainer);

                },
                searchTrigger: function(data, $fieldset, bib, opt){
                    // options:
                    //    opt.subfield: extract query from this subfield (default $a)
                    //    opt.field: solr field to search on if no rcn.
                    data.forEach(function(f){
                        var query;
                        if(f.field.rcn){
                            query = 'linked_rcn:"'+f.field.rcn+'"';
                        } else {
                            var qtxt = f.field.subfield(opt.subfield||'a');
                            if(qtxt) {
                                query = ((opt.field) ? opt.field + ':': '') + normalizeQuery(qtxt);
                            }
                        }
                        if(query)
                            f.$el.wrapInner( $("<a href='/app/search/" + query + "'></a>") )

                    });

                },
                wrapInner: function(data, $fieldset, bib, opt){
                    // wraps a field via jQuery wrapInner.
                    // options:
                    //    spec: jQuery spec. (string)
                    console.warn('wrappingInner');
                    data.forEach(function(f){
                        console.log(f);
                        f.$el.wrapInner('<' + opt.element + '>');
                    });
                }
            };

        if(processor[p.id]){
            try {
                processor[p.id]( fields, el, bib, p.options );
            } catch ( e ) {
                console.warn ( e );
            }
        }
    }

    function renderSectionHtml(cfg, bib, solrbib, $bibEl){
        var $node;
        if(cfg.section){

            var childrenWithContent = [];
            if(cfg.childNodes){
                for (var i = 0; i < cfg.childNodes.length; i++) {
                    var child = renderSectionHtml(cfg.childNodes[i], bib, solrbib, $bibEl);
                    if(child)
                        childrenWithContent.push( child );
                }
            }
            if(childrenWithContent.length){
                $node = $('<div class="bib-section">');
                if(cfg.className) $node.addClass(cfg.className);
                if(cfg.label){
                    $node.append(
                            $('<div class="bib-section-label"></div>').text(cfg.label)
                        );
                }

                $('<div class="bib-section-content">').append(childrenWithContent).appendTo($node);
                $node.append('<div class="bib-section-footer"></div>');

                if(cfg.collapse){
                    var showText = cfg.collapse.showText || cfg.label || 'Show';
                    var hideText = cfg.collapse.hideText || 'Hide';
                    var $collapsedSection = $node.wrapInner( '<div class="bib-section-collapsible"></div>').children().hide();
                    var $clicker = collapseClicker(showText, hideText, $collapsedSection);
                    $node.append( $clicker );
                }

            }
        } else {
            // note we don't require a root section.
            $node = renderFieldsetHtml( cfg, bib , solrbib, $bibEl) ;
            // can be undef.
        }
        return $node;
    }
    function renderFieldsetHtml(cfg, bib, solrbib, $bibEl){

        var $root_el = $('<div></div>');
        var classname = cfg.className || defaultBlockClassname(cfg.label);
        $root_el.addClass( 'bibdata ' + classname);
        var block_has_label = cfg.label;
        if(block_has_label){
            var $blocklabel = $('<span class="biblabel fieldgroup-label">' + cfg.label + '</span>');
            $blocklabel.appendTo($root_el) ;
        }

        var $fieldset = $('<div class="fieldset"></div>');
        var block_has_data;
        var $joinspan = (cfg.join) ?
                $('<span class="field-join">'+cfg.join+'</span>') : null;


        var renderedFields = [];

        if(cfg.data){
            // pull specific data from bib.
            // for now assume prop on bib object, or on solrbib if passed..
            // valid: format, language, score

            var data = bib[cfg.data] || (solrbib||{})[cfg.data];
            if(data){
                if( ! angular.isArray(data) ) data = [data];
                var interpolator = configService.interpolator( 'bv-' + cfg.data );
                var classnames = "non-marc bib-data-" + cfg.data;
                data.forEach( function(datum, i){
                    block_has_data = true;
                    if($joinspan && i) $fieldset.append($joinspan.clone());

                    var $dataEl = $( '<div class="'+classnames+'">' +
                            interpolator.display(datum) +'</div>')
                    $fieldset.append( $dataEl );
                    renderedFields.push(
                        { data: {
                                key: cfg.data,
                                value: datum
                            },
                            $el: $dataEl
                        });
                    if(cfg.data=='format'||cfg.data=='language'){
                        var datumClassName = cfg.data+'-is-'+safeClassname(datum);
                        $bibEl.addClass(datumClassName);
                    }

                });

            } else {
                console.warn('invalid data specifier');
            }

        }

        (cfg.fields||[]).forEach(function(fspec){
            var tag = fspec.tagspec.substr(0,3);
            var sflist = fspec.tagspec.substr(3);

            var fields = bib.marc.fields(tag);
            fields.forEach(function(field, i){
                if(fieldFilter[field.tag] && fieldFilter[field.tag](field))
                        return; // next.
                // var field_has_data;
                var subfields_html = field.subfields(sflist, { reorder :fspec.reorder }).map(function(sf, j, sfArr){
                    var sfid = field.tag+sf.code;
                    var classnames = "subfield subfield-" + sf.code + " marc"+sfid;
                    var text = sf.value;

                    if(cfg.filterLast && j==sfArr.length-1){
                        if(cfg.filterLast=='.') {
                            text = $filter('.')(text);}
                            else {
                             text = $filter('chop')(text);
                        }
                    }

                    var authval = configService.marcAuthvals.bib[sfid];
                    if(authval){
                        text = '<span class="av-desc">' + configService.display(sf.value, authval)+'</span>'+
                                '<span class="av-code">' + sf.value + '</span>';
                    }
                    return '<span class="'+classnames+'">'+text+'</span>';
                });
                if(!subfields_html.length)
                    return;

                block_has_data = true;
                var $field = $('<div class="'+fieldClasses(field)+'"></div>');
                var $fieldData = $('<span class="field-data"></span>').appendTo($field);
                $fieldData.html(subfields_html.join(''));

                var flabel = marcBibSpec.getMarcLabel(field) || fspec.label;
                if(flabel){
                    $field.addClass('labeled-field');
                    if(block_has_label) $field.addClass('marcfield-block');
                        //unused class. marcfield-block...
                        // todo: differentiate between parent fieldgroup label and field labels when both are rendered.
                    $field.prepend('<span class="biblabel">' + flabel + '</span>');
                }

                if($joinspan && i) $fieldset.append($joinspan.clone());
                $fieldset.append($field);

                // prob only necessary if blockspec.postProcess...
                renderedFields.push( { field: field, $el: $field } );

            }); // end fieldset foreach.
        });     // end blockspec.fields foreach

        if(block_has_data){
            if(cfg.postProcess){
                cfg.postProcess.forEach(function(p){
                    runFieldProcessor(p, renderedFields, $fieldset, bib);
                })
            }

            $root_el.append($fieldset);
        }
        if(block_has_data)
            return $root_el;
    }

    var userJsmap = { detail: "bibDetail",  // iface to userJS id.
                      staffDetail: "staffDetail",
                      result: "bibResult" };

return {
    restrict: 'A',
    scope: {
        bib: '=',
        solrbib: '=',
        holdings: '='  // just for userjs.
        // marcTemplate: '@'
        // iface: '@'
    },
    link: function( scope, el, attrs ){

        var tmpl;
        if(attrs.marcTemplate){
            tmpl = scope.$eval(attrs.marcTemplate);
        } else {
            tmpl = configService.marcDisplayTmpl[attrs.iface||'detail'].tmpl ;
        }

        var dereg = scope.$watch('bib', function(bib){
            if(!bib) return;
            bib._configDisplay = configService.display; // yes this really is used in the wild

            var $bibEl = $('<div></div>');

            tmpl.forEach(function(blockspec){
                $bibEl.append( renderSectionHtml( blockspec, bib, scope.solrbib, $bibEl ) );

            });
            el.html($bibEl);

            $timeout(function(){
                if(attrs.iface && configService.userJS[userJsmap[attrs.iface]])
                    configService.userJS[userJsmap[attrs.iface]](el, bib, scope.holdings);
                if(attrs.iface=='result'){
                    scope.$watch(function(){ return configService.OpacHighlightedWords ;},
                        kohaSearchSvc.termHighlighterWithin(el) );
                }
            });
            dereg();
        });
    }
};
}]);

module.directive('bvSearchResultsNav', ["kohaSearchSvc", "SearchQuery", "$location", "$state", function(kohaSearchSvc, SearchQuery, $location, $state){
    return {
        restrict: 'E',
        template: '<div class="results-nav"> <div class="btn-group btn-group-sm kw-btn-grp">' +
          '<button class="btn btn-outline-secondary" ng-click="stepResult(-1)" ng-disabled="isFirst" title="Previous record in result set"><span class="bi bi-arrow-left"></span> <span class="button-text">Previous</span></button>' +
          '<button class="btn btn-outline-secondary" ng-click="backToResults()" title="Return to search results"><span class="bi bi-align-justify"></span> <span class="button-text">List</span></button>' +
          '<button class="btn btn-outline-secondary" ng-click="stepResult(1)" ng-disabled="isLast" title="Next record in result set"><span class="button-text">Next</span> <span class="bi bi-arrow-right"></span></button>' +
          '</div> </div>',
        scope: {
            bib: '='
        },
        link: function(scope,el,attrs){

            var dereg = scope.$watch('bib', function(bib){
                var srch = kohaSearchSvc.currentSearch();
                if(!srch || srch.results.hits < 2){
                    el.hide();
                    dereg();
                    return;
                }

                scope.isFirst = srch.results.isFirst(bib.id);
                scope.isLast = srch.results.isLast(bib.id);

                scope.backToResults = function () {
                    $location.url(srch.asUrl()).hash('kohabib-' + scope.bib.id);
                };

                scope.stepResult = function (dir) {
                    var newSearch;
                    if (srch.results.isFirstOnPage(scope.bib.id) && dir == -1) {
                        if (srch.results.pager.page == 1) {
                            return;
                        } else {
                            srch.page--;
                            scope.big_step = true; // just for loading indicator.
                            newSearch = new SearchQuery(srch);
                            kohaSearchSvc.currentSearch(newSearch);
                            newSearch.fetch().then(function(rs){
                                var bibid = rs.bibs[rs.bibs.length - 1].id;
                                $state.go('.',{ biblionumber: bibid, bibid: bibid });
                            });
                            return;
                        }
                    } else if (srch.results.isLastOnPage(scope.bib.id) && dir == 1) {
                        if (srch.results.pager.page == srch.results.pager.numPages) {
                            return;
                        } else {
                            srch.page++;
                            scope.big_step = true;
                            newSearch = new SearchQuery(srch);
                            kohaSearchSvc.currentSearch(newSearch);
                            newSearch.fetch().then(function(rs){
                                $state.go('.',{ biblionumber: rs.bibs[0].id, bibid: rs.bibs[0].id });
                            });

                            return;
                        }
                    } else {
                        var targetbibid = srch.results.step(scope.bib.id, dir);
                        if (targetbibid) {
                            $state.go('.',{ biblionumber: targetbibid, bibid: targetbibid });
                        }
                    }

                };

                dereg();
            });
        }
    }
}])

module.directive('previewimage',function(){
    return {
        link: function ($scope, $element, $attributes,ngModel) {
            /* CONFIG */
        
            var xOffset = -20;
            var yOffset = 40;
            var theModel = $scope.rec.attributes;
            var dModel = ngModel;
                /* END CONFIG */
            var prevCallback = function(e,instant) {
                if(instant){
                    $("#map_screenshot").remove();
                    var currModel= $scope.rec.attributes;
                    var uuid = null;
                    if(dModel && dModel.records && dModel.records.length > 0)
                        uuid = dModel.records[0].uuid;
                    else if(currModel && currModel.records && currModel.records.length > 0)
                        uuid = currModel.records[0].uuid;
                    else
                        uuid = null;
                     
                     
                    var hreff = ARCHVIEW.getCoverage.ThumbnailURL.format(uuid);
                    $("body").append("<p id='map_screenshot'><img src='"+ hreff +"' alt='Image preview' /></p>");
                }
                 $("#map_screenshot")
                        .css("top",(e.clientY - xOffset) + "px")
                        .css("left",(e.clientX + yOffset) + "px")
                        .fadeIn("slow");
            };
            
            $($element).hover(function(e){
                    
                    prevCallback(e,true);
                },
                function(){
                    this.title = this.t;    
                    $("#map_screenshot").remove();
                }
            )
            .mousemove(prevCallback);        
         

    }
}

});

module.directive('kohaCoins', ["$timeout", function($timeout){
  // add COinS title attribute to this element.
  // assumes bib in scope.
  return {
    restrict: 'A',
    scope: {
        bib: '='
    },
    link: function(scope,el,attrs){
        var dereg = scope.$watch('bib', function(bib){
            if(!bib) return;
            $timeout(function(){
                el.attr('title', scope.bib.marc.coins());
            }, 200);
            dereg();
        });
    }
  };
}]);
// multiselect: pass it an array of objects with 'name' and 'value' keys.
// and an array in which to store the selected values,
// and a type (itemtype, ccode, branch, etc)
module.directive('kohaMultipleSelect', ["configService", function(configService){
  return {
    scope: {
      values: '=ngModel',
      kohaOptions: '='
    },
//    template: '<select ng-model="picker" ng-change="addItem()" ng-options="code as config.display(code, type) for code in kohaOptions">'+
    template: '<div class="row"><div class="col-md-6 col-sm-8 form-inline">' +
              '<select ng-model="picker" ng-change="addItem()" class="form-select">'+
                '<option value="" class="disabled">-- add {{config.advancedSearch[type].displayName}} limit --</option>'+
                // normally you'd use ng-options here, but we're just binding to strings, and we want to avoid sanitization,
                // primarily for the languages.
              '<option ng-repeat="code in kohaOptions" value="{{code}}" ng-bind-html="config.display(code,type)"></option>'+
              '</select> </div><div class="col-md-6 col-sm-4"> <i class="icon icon-circle-arrow-right icon-large multiselect-arrow"></i>'+
              '<div class="well selected-limit-list {{type}}-select"><h5 ng-show="values.length" class="limit-list-head">Limit to:</h5>'+
                '<span class="selected-limit {{item}}-choice" ng-repeat="item in values"><span ng-bind-html="config.display(item,type)"></span>'+
                 '<i class="clickable icon rm-item" ng-class="config.icons.deselect" ng-click="rmItem($index)"></i></span>'+
                 '<span ng-hide="values.length">Any {{config.advancedSearch[type].displayName}} </div></div></div>',
    link: function(scope,el,attrs){
      scope.type = attrs.type;
      scope.picker = ''; //
      scope.config = configService;
      //scope.list = kohaInterpolateSvc.list(scope.type, true);
      el.addClass('multi-select');

      scope.addItem = function(){
        if(scope.picker && $.inArray(scope.picker, scope.values)==-1){
          scope.values.push(scope.picker);
        }
        scope.picker='';
      };
      scope.rmItem = function(i){
        scope.values.splice(i,1);
      };
    }
  };
}]);

module.directive('kohaIcon',["configService", function(configService){
    return function(scope,el,attrs){
        var c= configService.icons[attrs.kohaIcon];
        // convert fontawesome to bi
        if(c){
            if(c.match(/^icon-/)){
              el.addClass('icon ' + c);
            } else if(c.match(/^bi-/)){
              el.addClass('bi ' + c);
            } else if(c.match(/^fa-/)){
              el.addClass('fa ' + c);
            } else {
              el.addClass(c);
            }
        } else {
            el.addClass(attrs.kohaIcon);
        }
    };
}]);

module.directive('kohaMultipleCheckbox',["configService", function(configService){
  return {
    scope: {
      values: '=ngModel',
      kohaOptions: '='
    },
    template: '<div id="limit-by-{{type}}" class="clearfix multi-checkbox"><ul class="{{type}}-select row row-cols-6">'+
              '<li class="{{code}}-choice col-md-{{cols}}" ng-repeat="code in kohaOptions">' +
              '<label class="label-with-input"><input type="checkbox" class="form-check-input" ng-click="toggleItem(code)" ng-checked="selected(code)" />' +
              '<span class="label-right" ng-bind-html="code |displayName:type"></span></label></li></ul></div>',
    link: function(scope,el,attrs){
      scope.type = attrs.type;

       scope.cols = (scope.kohaOptions.length > 19) ? 3 :
                    (scope.kohaOptions.length > 8) ? 4 :
                        (scope.kohaOptions.length > 3) ? 6 : 12;

      scope.config = configService;
      scope.selected = function(code){
        return ($.inArray(code,scope.values)==-1) ? false : true;
      };
      scope.toggleItem = function(code){
        var i = $.inArray(code,scope.values);
        if(i==-1){
          scope.values.push(code);
        } else {
          scope.values.splice(i,1);
        }
      };
    }
  };
}]);
module.directive('kohaSingleSelect', ["configService", function(configService){
  // simple select directive that takes an array model.
  return {
    scope: {
      values: '=ngModel',
      kohaOptions: '='
    },
    template: '<select ng-model="picker" ng-change="updateItem()" class="form-select">'+
                '<option value="">Any {{config.advancedSearch[type].displayName}}</option>'+
                '<option ng-repeat="code in kohaOptions" value="{{code}}" ng-bind-html="code |displayName:type" ng-selected="code==picker"></option>'+
              '</select>',
    link: function(scope,el,attrs){
      scope.type = attrs.type;

      el.addClass('single-select');
      scope.$watch('values', function(v){
        console.warn(v);
        scope.picker = scope.values[0];
      }, true);
      attrs.$observe('ngModel', function(nv){
        console.log(nv);
      });
      scope.picker = (scope.values.length==1) ? scope.values[0] : '';
      scope.config = configService;



      scope.updateItem = function(){
        scope.values = (scope.picker) ? [scope.picker] : [];
      };
    }
  };
}])
.directive('kwClearableInput', ["$compile", "$timeout", function($compile, $timeout){
    return {
        require: 'ngModel',
        scope: true,
        link: function(scope,el,attrs, ngModelCtrl){
            var tmpl = '<span class="searchclear" ng-click="clearMe()">' +
                        '<a ng-show="length>1" class="bi bi-x-circle" aria-label="Clear search input"></a> </span>';
            el.wrap('<span style="position: relative;"></span>');
            scope.length = (el.val()||'').length;
            scope.$watch(function(){ return el.val(); }, function(v){
                if(v) scope.length = v.length;
            });
            scope.clearMe = function(){
                $timeout(function(){el.val('');});
                scope.length = 0;
                el.focus();
            };
            el.parent().append( $compile(tmpl)(scope) );
        }
    };
}])
.directive('kwPubMastheadSearch', ["$timeout", "configService", "userService", "$rootScope", "bvAsyncOnreadySvc", "syncTemplateCache", function($timeout, configService,  userService, $rootScope, bvAsyncOnreadySvc, syncTemplateCache){
    return {
        scope: true,
        restrict: 'E',
        template: syncTemplateCache.get('/app/static/partials/pub-masthead-search.html'),
        link: function(scope, el, attrs){
            // Expects in scope:
            // srchCfg, prefilterSelects, query

            scope.opt = {
                visible: (scope.srchCfg.prefilter.default) ? 'prefilter' : 'simple',
                showFqDropdown: false,
                menuOnFocus: scope.srchCfg.prefilter.dropdownOnFocus,
            };
            scope.showFieldSelect = {
                prefilter: configService.mastheadSearchConfig.prefilter.showFieldSelect,
                simple: configService.mastheadSearchConfig.simpleSearch.showFieldSelect
            };

            scope.toggleView = function(){
                scope.opt.showFqDropdown = false;
                if(scope.opt.visible =='simple'){
                    scope.opt.visible = 'prefilter';
                } else {
                    scope.opt.visible = 'simple';
                }
                // Ew.
                $rootScope.$emit('setSearchMethod', scope.opt.visible);
                if (userService.loggedin) userService.setPref('masthead_search_form',scope.opt.visible);
                scope.query.clear();
                scope.query.field = scope.defaultField();
            };

            scope.showSwitcher = (scope.srchCfg.enableSwitch && scope.orderedSelects.length);
            scope.defaultField = function(){
                return (scope.showFieldSelect[scope.opt.visible]) ? scope.fieldSelect.options[0].field : '';
            };
            scope.query.field = scope.defaultField();
            userService.whenAnyUserDetails().then(function(){
                if((userService.prefs||{}).masthead_search_form){
                    scope.opt.visible = userService.prefs.masthead_search_form;
                    scope.query.field = scope.defaultField();
                }

                // FIXME: Should delay rendering until this resolves.
                scope.$on('loggedin', function(){
                    if((userService.prefs||{}).masthead_search_form){
                        scope.opt.visible = userService.prefs.masthead_search_form;
                        scope.query.field = scope.defaultField();


                    }
                });

                // We probably don't have to wait until now to signal readiness but
                // since whenAnyUserDetails() should always complete, it doesn't 
                // hurt to do so
                bvAsyncOnreadySvc.childReady(attrs.bvaoName, attrs.bvAsyncOnreadyAwait);
            });

            scope.onSimpleSearchFocus = function() {
                $timeout(function() {
                    scope.opt.showFqDropdown = (scope.opt.visible=='simple' && scope.query.q.length > 3);
                }, 0);
            };

            if((scope.srchCfg.simpleSearch.filter||{}).facet){
                scope.$watch('query.q', function(q, oldQ){
                    if (q && oldQ) {
                        scope.opt.showFqDropdown = (scope.opt.visible=='simple' && q.length > 3);
                    }
                });
            }

            var $queryInput = el.find('input.form-control');
            scope.mastheadSearch = function(event){
                scope.opt.showFqDropdown = false;

                $queryInput.blur(); // without this, input retains focus, mobile keyboard doesn't hide.
                // event.preventDefault();  // shouldn't be necessary, but seems to be.
                if(scope.opt.visible=='prefilter'){
                    scope.prefilterSearch();
                } else {
                    scope.simpleSearch();
                }
            };
        }

    };
}])
.directive('kwPrefilterSelect', ["kwApi", function( kwApi){
    return {
        // scope: true,
        link: function(scope, el, attrs){
          // Select input.
          // expects 'filter' on scope.

          scope.group = function(obj){
            return obj.optgroup || undefined;
          };
          scope.loading = false;
            if(scope.filter.parentFilter){
                scope.filter.allOptions = angular.copy(scope.filter.options);

    /// Fixme: should also watch grandparents, though latency could make this ugly.

                scope.$watch("prefilters['"+scope.filter.parentFilter+"'].value", function(nv){
                    if(nv){
                        var parent = scope.prefilters[scope.filter.parentFilter];
                        var fqs = [];
                        var bail = 0;
                        while(parent && bail++ < 6){
                            fqs.push(parent.value);
                            parent = scope.prefilters[parent.parentFilter];
                        }
                        scope.loading = true;
                        el.prop('disabled', true);
                        var newOptions = kwApi.Prefilter.get(
                                {   field: scope.filter.filterDef.facet,
                                    maxEntries: scope.filter.filterDef.maxEntries,
                                    authval: scope.filter.filterDef.authval,
                                    fq: fqs
                                });
                        newOptions.$promise.then(function(rsp){
                            scope.loading = false;
                            el.prop('disabled', false);
                            if(angular.isArray(rsp)){
                                scope.filter.options = rsp;
                                if(scope.filter.filterDef.unlimited){
                                    scope.filter.options.unshift({ label: scope.filter.filterDef.unlimited, fq: "" });
                                }                            
                            }
    
                            if(!scope.filter.options.filter(function(opt){ return opt.fq == scope.filter.value; }))
                                scope.filter.value = scope.filter.default;
                            }, function(fail){
                                scope.loading = false;
                                el.prop('disabled', false);
                                console.warn(fail);
                            });
                    } else {
                        scope.filter.options = scope.filter.allOptions;
                    }


                });
            }

        }
    };
}])
.directive('kwPrefilterDefine', function(){
    return {
        templateUrl: '/app/static/partials/prefilter-define-directive.html',
        link: function(scope,el,attrs){
            // Assumes scope.prefilter .

            scope.optionRow = { fq: '', label: '' };
            scope.clearQF = function(){ scope.optionRow.fq = scope.optionRow.label = ''; };

            scope.wellHtml = function(option){
                var fq = (option.fq) ? "<span class='fq'>" + option.fq + "</span>" : "(none)";
                return "Solr Filter Query: " + fq + "; Displayed as: " + option.label;
            };

        }
    };
})
.directive('kohaArrayInput', ["configService", "$filter", "$sce", function(configService, $filter, $sce){
  // simple text input directive to manage an array, with a well underneath listing array.
  // if display-value-type is specified, display will show interpolated values,
  // and you'll get a select instead of free input.
  return {
    scope: {
      values: '=arrayModel',
      inputClass: '@',
      displayValueType: '@',
      valueClass: '@',         // values in well will have this class.
      transcludedModel: '=',   // model used in transcluded content.
      clearTranscluded: '&',
      displayTranscluded: '&', // should return a string representation of the transcludedModel element to display in the list of selected values.
                                // Note fcn must accept param 'element'.
      validTranscluded: '&',  // accepts param 'element'. should return true if `element` can be added to array.
      reorderable: '@',
      emptyOption: '@'        // string to display in empty option if using `type`.
    },
    transclude: true,  // optional.
    templateUrl: '/app/static/partials/arraybuilder-directive.html',

    link: function(scope,el,attrs){
      if (!scope.values) scope.values = [];
      scope.type = attrs.displayValueType;
      scope.inputClass = attrs.inputClass;
      scope.valueClass = attrs.valueClass;
      scope.placeholder = attrs.placeholder;
      scope.transcludedContent = attrs.transcludedModel;  // i.e. assume we have content if there's a model.
      scope.reorderable = attrs.hasOwnProperty('reorderable');

      scope.emptyOption = attrs.emptyOption;

      scope.kohaOptions = (scope.type) ? configService.listCodes(scope.type) : [];
      scope.tempvar = '';
      scope.config = configService;

      scope.display = function(val){
        // display value for array item.

        if(attrs.displayTranscluded){
          return scope.displayTranscluded({element: val});
        } else if(attrs.displayValueType){
          return $filter('displayName')(val, attrs.displayValueType);
        }
        // Needs $sce.  if displayTranscluded, that's left up to the caller.
        // In this final case, we cache string values (FIXME)
        // This issue is addressed in https://github.com/angular/angular.js/issues/3932
        // fixed in master.  we'll get infinite digests until that patch lands in an RC.
        return $sce.trustAsHtml(val);
      };
      scope.reorder = function(i,dir){
        // dir is 1||-1 (i.e. down or up)
        if((i===0 && dir===-1) || (i===scope.values.length-1 && dir===1)) return;
        scope.values[i+dir] = scope.values.splice(i,1,scope.values[i+dir])[0];
      };
      scope.classname = function(item){
        // ensure we generate a valid css class for this item.
        if(typeof item==='object'){
          return 'unknown';  // FIXME: we could probably sanitize the output of displayTranscluded().
        } else {
          return item;  // FIXME: Actually sanitize this.
        }
      };
      scope.addItem = function(){
        if(scope.transcludedContent){
          if(attrs.validTranscluded && !scope.validTranscluded({element: scope.transcludedModel})) return;

          scope.values.push(angular.copy(scope.transcludedModel));
          scope.clearTranscluded();
        } else if(scope.tempvar && $.inArray(scope.tempvar, scope.values)==-1){
          scope.values.push(scope.tempvar);
          scope.tempvar = '';
        }
      };
      scope.rmItem = function(i){
          scope.values.splice(i,1);
      };
      scope.updateItem = function(){
        if(scope.picker !== '') scope.values = [scope.picker];
      };
      scope.clearTmp = function(){
        if (attrs.clearTranscluded) {
          scope.clearTranscluded();
        } else {
          scope.tempvar = '';
        }
      };

    }

  };
}])

.directive("kwRestrictKeypress", function() {
    return {
        // Ignore keys that don't match characters defined by regex.
        // This will swallow <enter> if not specifically allowed by matching \r or \x0D
        restrict: "A",
        link: function(scope, elem, attrs) {
            var regex = new RegExp(attrs.kwRestrictKeypress);
            angular.element(elem).on("keypress", function(e) {
                if (!regex.test( String.fromCharCode(e.which))) {
                    e.preventDefault();
                    return false;
                }
            });
        }
    };
})

.directive('kwPagesizer', ["configService", function(configService){
    // Shows num results shown, clicking allows altering.
    // assumes parent ng-model will act on changes.
    return {
        scope: {
            shown: '=',  // actual number shown.
            hits: '=',
            onSelect: '&'
        },
        template: '<span uib-dropdown class="page-sizer"> ' +
            '<a href uib-dropdown-toggle>{{pageSize}}</a>' +
            '<ul uib-dropdown-menu role="menu">'+
            '<li role="menuitem" ng-repeat="a in validSizes" ><a href class="dropdown-item" ng-click="changeSize(a)"> ' +
            '<span ng-if="a==pageSize">*</span>{{a}}</a></li></ul></span>',
        link: function(scope,el,attr){
            scope.$watch('shown', function(shown){
                if(shown){

                    var defaultNum = configService.OPACnumSearchResults * 1;
                    scope.pageSize = shown;

                    var sizes = [10,25,50,75,100];
                    if(sizes.indexOf(defaultNum)==-1) sizes.push(defaultNum);
                    if(sizes.indexOf(shown)==-1) sizes.push(shown);
                    sizes.sort(function(a,b){ return a-b ;});
                    scope.validSizes = sizes;

                    scope.changeSize = function(newSize){
                        scope.onSelect({size: newSize});
                    };
                }
            });
        }
    };
}]);

module.directive('kwFacetFieldValues', ["kohaSearchSvc", "SearchQuery", "$state", function(kohaSearchSvc, SearchQuery, $state){
    return {
        scope: {
            facetField: '=kwFacetFieldValues',
            limitTo: '@',
            onSelect: '&', // called with arg: event.
            advancedFacets: '=',
        },
        templateUrl: '/app/static/partials/facet-value-list.html',
        link: function(scope, el, attrs){

            if('limitTo' in attrs)  scope.maxShown = parseInt(attrs.limitTo);
            if(scope.onSelect){
                el.on('click', 'a, button', function(e){
                    scope.onSelect({event: e});
                });
            }
        },
        controller: ["$scope", function($scope){
            $scope.facetField.pending = 0;
            $scope.applyLimit = function(facetOption, operator) {
                if (operator == 'NOT') {
                    if (facetOption.negated) {
                        facetOption.negated = false;
                        $scope.facetField.pending--;
                    }
                    else if (facetOption.checked) {
                        facetOption.negated = true;
                        facetOption.checked = false;
                    }
                    else {
                        facetOption.negated = true;
                        $scope.facetField.pending++;
                    }
                }
                else if (operator == 'AND' || operator == 'OR') {
                    if (facetOption.checked) {
                        facetOption.checked = false;
                        $scope.facetField.pending--;
                    }
                    else if (facetOption.negated) {
                        facetOption.checked = true;
                        facetOption.negated = false;
                    }
                    else {
                        facetOption.checked = true;
                        $scope.facetField.pending++;
                    }
                }
                //console.log($scope.facetField.pending);
            };

            $scope.facetField.applySearch = function() {
                var conj = [], not = [], fq = [];
                var op = ' ' + $scope.facetField.operator + ' ';
                $scope.facet_values.forEach(function(v) {
                    if (v.checked) {
                        conj.push(v.value);
                    }
                    else if (v.negated) {
                        not.push(v.value);
                    }
                });

                if (conj.length) {
                    fq.push($scope.facetField.field + ':(' + conj.join(op) + ')')
                }
                if (not.length) {
                    fq.push('NOT ' + $scope.facetField.field + ':(' + not.join(op) + ')')
                }
                var fqstr = fq.join(op)

                var newParams = (new SearchQuery( kohaSearchSvc.currentSearch() ))
                        .addLimit( 'fq', fqstr )
                        .stateParams();
                $state.go( 'search-results.koha', newParams, { inherit: false }  );
            };

            var refreshValues = function(field_values) {
                $scope.facet_values = ($scope.maxShown) ?
                     angular.copy(field_values).slice(0,$scope.maxShown) :
                     angular.copy(field_values);

                $scope.facet_values.forEach(function(facet_option){
                    facet_option.negated = facet_option.checked = false;
                    facet_option.clickTitle = ( (facet_option.applied) ? 'Remove ' : 'Apply ' )
                        + 'limit ' + $scope.facetField.display + ': ' + facet_option.display_value;
                    facet_option.negateTitle = ( (facet_option.negated) ? 'Remove ' : 'Apply ' ) 
                        + 'exclusion of ' + $scope.facetField.display + ': ' + facet_option.display_value;
                    facet_option.checkTitle = ( (facet_option.checked) ? 'Remove ' : 'Apply ' ) 
                        + $scope.facetField.display + ': ' + facet_option.display_value;

                    var newLimit = {};
                    newLimit[facet_option.field] = facet_option.value;

                    if(facet_option.applied){
                        facet_option.url = kohaSearchSvc.currentSearch().clone()
                                .rmLimit( facet_option.field, facet_option.value ).asUrl();
                    } else {
                        facet_option.url = kohaSearchSvc.currentSearch().clone()
                                .addLimit( newLimit ).asUrl();
                    }
                });
                $scope.facetField.pending = 0;
                if($scope.facetField.field=="pubyear"){
                    $scope.rangeSlider.setLimits();
                    $scope.hasDateLimit = !!(kohaSearchSvc.currentSearch().limitfields.pubyear);
                }
            };


            kohaSearchSvc.onSearchChange($scope, function() {
                kohaSearchSvc.currentSearch().whenResults().then(function(r) {
                    refreshValues($scope.facetField.values);
                });
            });


            $scope.$watch( 'facetField.values', function(nv,ov){
                if(!nv||!ov) return;  // we're watching for a resort, really.
                if(nv!=ov){
                    refreshValues(nv);
                }
            });

            if($scope.facetField.field=='pubyear'){
                $scope.rangeSlider = {
                    minValue : 1800,
                    maxValue : dayjs().year() ,
                    userMax : null,
                    userMin : null,
                    floor :  1800,
                    ceiling : dayjs().year(),
                    setLimits: function(){
                        // note: $scope.facet_values is slice of $scope.facetField.values.
                        var dates = $scope.facetField.values;
                        var minDate = dates[dates.length-1].value.match(/\d\d\d\d/g)[0];
                        var maxDates = dates[0].value.match(/\d\d\d\d/g);

                        this.userMin = this.userMax = null;
                        this.minValue = this.floor = parseInt(minDate);
                        this.maxValue = this.ceiling = parseInt(maxDates[maxDates.length-1]);
                    }
                };

                $scope.syncSliderDate = function(max){
                    if(max){
                        $scope.rangeSlider.maxValue = ($scope.rangeSlider.userMax) ?
                            $scope.rangeSlider.userMax : $scope.rangeSlider.maxValue;
                    } else {
                        $scope.rangeSlider.minValue = ($scope.rangeSlider.userMin) ?
                            $scope.rangeSlider.userMin : $scope.rangeSlider.minValue;
                    }
                    if($scope.rangeSlider.userMin || $scope.rangeSlider.userMax)
                        $scope.rangeSlider.changed = true;
                };

                $scope.$watch(function(){ return ($scope.rangeSlider.minValue||0) + ($scope.rangeSlider.maxValue||0) ; },
                    function(v){ // sync inputs from slider.
                        // FIXME: Can't quite get to the upper/lower bounds when the range is large.
                        if($scope.rangeSlider.minValue != $scope.rangeSlider.floor){
                                $scope.rangeSlider.userMin = $scope.rangeSlider.minValue;
                                $scope.rangeSlider.changed = true;
                            }
                        if($scope.rangeSlider.maxValue != $scope.rangeSlider.ceiling){
                                $scope.rangeSlider.userMax = $scope.rangeSlider.maxValue;
                                $scope.rangeSlider.changed = true;
                            }
                });

                $scope.clearDateSearchLimits = function(){
                    var srch = new SearchQuery( kohaSearchSvc.currentSearch() );
                    srch.rmLimit('pubyear', null);
                    srch.go();
                };
                $scope.triggerDateFacetSearch = function(){
                    if( ! ($scope.rangeSlider.userMax || $scope.rangeSlider.userMin)) return null;
                    var srch = new SearchQuery( kohaSearchSvc.currentSearch() );
                    srch.rmLimit('pubyear', null);
                    srch.addLimit( "pubyear", "[" + ($scope.rangeSlider.userMin||"*") +
                                    " TO " + ($scope.rangeSlider.userMax||"*") + "]" );
                    srch.go();
                };
            }

            refreshValues($scope.facetField.values);
        }]
    };
}]);


/*  <div cover-img koha-bib='bib' />
    Takes a koha Bib object.
    Optional attributes:
      img-size  // 's','m', 'l'.
                // note not all service providers provide all sizes.
      service-link  // whether or not to include a link to the image service provider
      details-link  // if true, wraps the element in a link to the details page.
      dynamic-bib   // by default, watcher on koha-bib is deregistered.
    */

module.directive('coverImg', ["configService", "kwCoverImgSvc", "bibService", "userService", "$q", "$state", "$injector", "kwApi", function(configService, kwCoverImgSvc, bibService, userService, $q, $state, $injector, kwApi){

  return {
    scope: {
      kohaBib: '=',      // resolved bib, promise or bibid.
      imgSize: '@',      // 's','m' or 'l'.
      serviceLink: '@',  // bool
      detailsLink: '@',   // bool
      itemId: '='        // optional item-level data.
    },
    templateUrl: '/app/static/partials/cover-image.html',

    link: function(scope, el, attrs){

        var coverEl = el.find('.cover');
        var overlayText = el.find('.coverimg-text-overlay');

        el.addClass('img-src-' + configService.coverImgSource);
        el.addClass('cover-img-ctn');
        if(attrs.hasOwnProperty('textOverlay')){
            coverEl.hover(function(mousein){
                overlayText.css( 'opacity', 0.8 );
            }, function(mouseout){
                overlayText.css( 'opacity', 0 );
            });
        }
        var loadDefaultImage = function(bib){
            if(bib.default_cover_id){ // infomart branch default cover
                var image = new Image();
                image.src = "/api/branch/" + bib.default_cover_id + "/img";
                $(image).addClass('no-cover');
                coverEl.append(image);
            } else {
                coverEl.addClass('no-cover-img');
                // show text overlay always.
                coverEl.off('mouseenter mouseleave');
                overlayText.css( 'opacity', 1 );
            }
        };

        var updateImage = function(bib){
            var image = new Image();
            $(image).addClass('cover-img');
            // coverEl.empty();  // what's this for ?
            if(bib.format.length) coverEl.addClass( 'format-' + bib.format[0]);
            var title = bib.title;
            var maxTitleLength = (attrs.imgSize=='s') ? 36 : 50;
            if(title.length > maxTitleLength){
                title = title.substr(0,maxTitleLength);
                title = title.replace( /\s+\S*$/,' ...');
            }
            scope.title = title;
            scope.author = bib.author();
            scope.formats = bib.format.map(function(fmt){ return configService.display(fmt,'bv-format');}).join(',');
            scope.dateStr = (bib.marc.subfield('260c')||'').replace(/[\.;:]*$/,'');
            var item = (scope.itemId) ? kwApi.Item.get({id: scope.itemId }).$promise : undefined;
            $q.when(item).then(function(item){
                scope.cn = (item||{}).itemcallnumber;
                if(!scope.cn && bib.marc.has('050a'))
                    scope.cn = bib.marc.subfield('050a') + ' ' + (bib.marc.subfield('050b')||'');
            });

            if(attrs.hasOwnProperty('detailsLink') && !bib.records.length){ // i.e. not dlso.
                var href = (userService.is_staff) ?
                        $state.href('staff.bib.details',{biblionumber: bib.id}) :
                        $state.href('work', {bibid: bib.id});
                coverEl.attr('href', href);
            } else {
                coverEl.addClass('no-link');
            }

            kwCoverImgSvc.get(bib.id).then(function(cover){

                if(attrs.hasOwnProperty('detailsLink') && cover.dlso){
                    coverEl.click(function(){
                        $injector.get('DLSOViewerService').show({uuid: cover.dlso}, bib, true);
                        return false;
                    });
                }
                image.onload = function(){
                    // Syndetics gives us a 1x1 img instead of 404'ing.
                    if(this.naturalWidth==1){
                        loadDefaultImage(bib);
                        return;
                    }
                    coverEl.append(this);
                    // FIXME [RCH]:  If parent element has size defined, we should resize the image here.

                    if( attrs.hasOwnProperty('serviceLink') && (cover.service_link||{}).url){
                      var link_el = '<a class="cover-img-service" href="'+cover.service_link.url+'">' +
                          ((cover.service_link.img) ?
                              '<img src="'+cover.service_link.img+'" title="' + cover.service_link.title + '" />':
                              cover.service_link.title ) + "</a>";
                        el.append(link_el);
                    }
                };
                image.onerror = function(){
                    this.onload = null; // maybe clear out image too.
                    loadDefaultImage(bib);
                };
                var src = cover.src(attrs.imgSize);
                if(src){
                    image.src = src;
                } else {
                    loadDefaultImage(bib);
                }

            }, function(fail){
                // console.warn(fail);
                loadDefaultImage(bib);
            });
        };

        var dereg = scope.$watch('kohaBib', function(bib){
            if(bib){
                if(!angular.isObject(bib)) bib = bibService.get(bib);
                $q.when(bib).then(function(resolvedBib){
                        updateImage(resolvedBib);
                });
                if(!('dynamicBib' in attrs)) dereg();
            }
        });


    }
  };
}]);

module.directive('kohaBibTagger', ["kohaTagsSvc", "userService", "bibService", "configService", "$rootScope", function(kohaTagsSvc, userService, bibService, configService, $rootScope){
  return {
    scope: {
      bibid: '@'
    },
    templateUrl: '/app/static/partials/bib-tagger-directive.html',
    link: function(scope,el,attrs){
      scope.tagsvc = kohaTagsSvc;
      scope.user = userService;
      scope.config = configService;

      var bib_q = bibService.get(scope.bibid);

      scope.$watch('addingTag', function(val){
        if(val) {
            userService.whenAuthenticatedUser().then(function(){
                el.find('input[type="text"]').eq(0).focus();
            })
        }
      });
      scope.addTag = function(tagterm){

        kohaTagsSvc.submit(tagterm, scope.bibid).then(function(){
          if(bib_q){
            bib_q.then(function(bib){
              if(!bib.tags_added) bib.tags_added = [];
              bib.tags_added.push({term: tagterm, weight: 1});
              if(bib.tags) bib.tags.forEach(function(tag){ if(tagterm==tag.term) tag.weight++; });
            });
          }
          scope.tagTerm = '';
          scope.addingTag = false;
        });
        return false;
      };
    }
  };
}]);

module.directive('kohaSavedSearchDisplay', ["$sce", function($sce){
  return {
    template: '<span class="query-terms">{{query}}</span>' +
        '<ul ng-if="limits" class="limits"><li ng-repeat="limit in limits">' +
        '<span class="searchlimit">{{limit}}</span></li></ul>',
    link: function(scope,el,attrs){
      attrs.$observe('kohaSavedSearchDisplay', function(q){
        var query = decodeURIComponent(q).split('?');
        scope.query = $sce.trustAsHtml(query[0]);
        if(query[1]){
          scope.limits = query[1].split('&').map(function(str){ return $sce.trustAsHtml(str);});
        }
      });
    }
  };
}]);

module.directive('sortable', ["$timeout", function($timeout){
  // assumes we're using orderBy filter,
  // and we're using sort specs like '+field'.
  //
  // attr sortable : name of scope variable that holds sort field value.
  //    this MUST be an object like { field: 'fieldname', reverse: false }.
  // attr sort-field : name of field that this column will sort by.

  //  ng-repeat="thing in things|orderBy:order.field:order.reverse"


  return {
    // scope: {
    //   sortable: '&'
    // },
    link: function(scope, el, attrs){
      var asc = true;

      var sortspec = scope.$eval(attrs.sortable);

      if(sortspec.field==attrs.sortField){
        var iconName = (sortspec.reverse)? 'icon-sort-up':'icon-sort-down';
        el.append('<i class="sorter icon '+ iconName + '"></i>').addClass('sortable sorted sorted-down');
      } else {
        el.append('<i class="sorter icon icon-sort"></i>').addClass('sortable');
      }

      el.click(function(){
        var sortspec = scope.$eval(attrs.sortable);

        if(attrs.sortField == sortspec.field){
          sortspec.reverse = !sortspec.reverse;
        }
        $timeout(function(){
          sortspec.field = attrs.sortField;
        },0);

        var whichway = (sortspec.reverse) ? 'icon-sort-up' : 'icon-sort-down';
        var otherway = (sortspec.reverse) ? 'icon-sort-down' : 'icon-sort-up';
        var sortedDir = (sortspec.reverse) ? 'sorted-up' : 'sorted-down';

        el.siblings('[sortable]').removeClass('sorted sorted-down sorted-up').children('i').removeClass('icon-sort-up icon-sort-down').addClass('icon-sort');
        el.removeClass('sorted-up sorted-down').addClass('sorted '+ sortedDir ).children('i').removeClass('icon-sort ' + otherway).addClass( whichway );

      });
    }
  };

}]);

module.directive('kohaToggleAll', function(){
  // accepts a function name in current scope.
  // will be called with true to select all, false to select none.

  // must add param 'bool' to function expression, e.g.
  //  koha-toggle-all="cart.toggleAll(bool)"

  return {
    scope: {
      kohaToggleAll: '&'
    },
    template: '<a href class="toggle-all" ng-click="switchbool()">'+
            '<i class="bi" ng-class="glyphi"></i><span class="toggle-text">{{allNone}}</span></a>',
    link: function(scope, el, attrs){
      scope.allNone = 'All';
      scope.glyphi = 'bi-check';
      scope.bool = false;
      scope.switchbool = function(){
        scope.bool = !scope.bool;
        scope.allNone = (scope.bool) ? 'None': 'All';
        scope.glyphi = (scope.bool) ? 'bi-square' : 'bi-check';
        scope.kohaToggleAll({bool: scope.bool});
      };
    }
  };
});

module.directive('kohaKbEnter', function() {
  // evaluate an expression on enter-key.
    return function(scope, element, attrs) {
        element.bind("keydown keypress", function(event) {
            if(event.which === 13) {
                scope.$apply(function(){
                    scope.$eval(attrs.kohaKbEnter);
                });

                event.preventDefault();
            }
        });
    };
});

module.directive('marcView', function(){
  // looks in current scope for marc object (named marc)
  // attrs:  marc-view (marc data string)
  // marc-type:  if 'text', displays it as <pre>.
  //   If 'html', extracts body and displays that.

  return {
    link: function(scope,el,attrs){
      attrs.$observe('marcView', function(){
        if(attrs.marcType == 'html'){
          var record = jQuery(attrs.marcView);
          el.html(record);
        } else {
          el.html('<pre>'+attrs.marcView+'</pre>');
        }
      });

    }
  };
});

module.directive('maxColspan', ["$timeout", function( $timeout ){
  // adjust a td's colspan to number of previous tr's elements.
  return {
    restrict: 'A',
    link: function(scope,el,attrs){
      var offset = parseInt(attrs.maxColspan); // expected to be negative.
      if(!offset) offset = 0;
      var cnt = 0;
      $timeout(function(){
          el.parent().prev().children('td').each(function(i,td){ cnt += parseInt($(td).prop('colspan')||1); });
          el.attr('colspan', cnt + offset);
      });
    }
  };
}]);

module.directive('clearOnSubmit', function(){
    return {
        require: 'ngModel',
        link: function(scope,el,attrs, ngModelCtrl){

// FIXME [rch] ui-bootstrap handling of tabs can cause multiple dom element creation.

            var form = el.closest('form');
            form.on('submit', function(){
                ngModelCtrl.$setViewValue('');
            })
        }
    };
});

module.directive('clickOnEnter', function(){
  return function(scope,el,attrs){
    el.keypress(function (e) {
      if (e.which == 13) {
        if (attrs.clickOnEnter) {
          document.getElementById(attrs.clickOnEnter).click();
        }
        else {
          el.get(0).click();
        }
        return false;
      }
    });
  };
});

module.directive('hideTab', function(){
  //hide a bootstrap-ui tab.
  return {
    require: 'uibTab',
    link: function(scope,el,attrs){

      // bootstrap-ui tab creates isolate scope;
      // this is fragile, but easiest way to hide tab without patching ui-tabs.
      // No longer true since angular 1.2 -- we're not tied to the tab's isolate scope.
      scope.$watch(function(){
//        return scope.$parent.$eval(attrs.hideTab);
        return scope.$eval(attrs.hideTab);
      }, function(val){
        if(val){
          el.addClass('hide');
        } else {
          el.removeClass('hide');
        }
      });
    }
  };
});

// module.directive('autoFocus', function($timeout){
//   // focus on element selector, or the first '.auto-focus' element.
//   // This can probably be removed now, and just rely on the
//   // browser to handle the `autofocus` attribute.
//   return function(scope,el,attrs){
//     var selector = attrs.autoFocus || '.auto-focus';
//     $timeout(function(){
//         console.log('focus() from auto-focus directive');
//         var target = el.find(selector);
//         if(target.length) target.first().focus();
//     }, 200);
//   };
// });

module.directive('focusOnShow', ["$timeout", function($timeout) {
    return {
        restrict: 'A',
        link: function($scope, $element, $attr) {
            if ($attr.ngShow){
                $scope.$watch($attr.ngShow, function(newValue){
                    if(newValue){
                        $timeout(function(){
                            $element[0].focus();
                        }, 0);
                    }
                })
            }
            if ($attr.ngHide){
                $scope.$watch($attr.ngHide, function(newValue){
                    if(!newValue){
                        $timeout(function(){
                            $element[0].focus();
                        }, 0);
                    }
                })
            }

        }
    };
}]);

module.directive('reorderChildren', ["$timeout", function($timeout){
  // find all child dom elements and reorder them
  // according to value of data-sortorder value.
  //  (we could also use a child directive but our use case is trivial)
  return function(scope,el,attrs){
    // to allow children's orders to be interpolated values, we accept an attribute
    // on reorderChildren and observe it.  You can pass any interpolated value here.
    attrs.$observe('reorderChildren', function(){

      $timeout(function(){
        el.children('[sortorder]').
            sort(function(a,b){ return ( parseInt( $(b).attr('sortorder'), 10) - parseInt( $(a).attr('sortorder'), 10) )}).
            each(function(i, element){ $(element).prependTo(el); });
          }, 10);
    });

  };
}]);

module.directive('draggableModal', ["$timeout", function($timeout){
  // add resizable and draggable to modal.
  return {
    restrict: 'A',
    link : function(scope,elem,attr){

      // wrap in timeout to avoid placement issues.
      $timeout(function(){

// RESIZABLE DOESN'T WORK ANYMORE. [FIXME]

        //default cancel is 'input,textarea,button,select,option'
        elem.closest('div.modal').
              draggable({handle: "div.modal-header",cancel:'input,textarea,button,select,option,em'}).
              resizable().
              on("resize", function(event, ui) {
                  // this is buggy.
               //   ui.element.css("top", "50%");
               //   ui.element.css("left", "50%");
              $(ui.element).find(".modal-body").each(function() {
                $(this).css("max-height", 400 + ui.size.height - ui.originalSize.height);
              });
            });

          var modalPos = elem.closest('div.modal').offset();

          scope.$on("$routeChangeStart", function(){
            modalPos = elem.closest('div.modal').offset();
          });
          scope.$on("$routeChangeSuccess", function(){
            // This is imperfect, but better than nothing.
            // modal tends to get pushed below main-content div.
            if(modalPos) elem.closest('div.modal').offset(modalPos);
          });
      },1);
    }
  };
}]);

module.directive('kohaLoading', function(){
  // set opacity on parent element and display a loading indicator .
  // We target parent element to avoid scope conflicts.
  // <div koha-loading="search._loading"></div>
  // TODO: calculate size of parent div and place the indicator appropriately.
  // TODO: make two sizes.

  return {
    scope: {
      loading: '=kohaLoading'
    },
    link: function(scope,el,attrs){

      var p = el.parent();
      el.addClass('loading-container');
      scope.$watch('loading', function(loading){

        if(loading){
          if(!p.hasClass('loading')){
            p.addClass('loading');
           // TODO: Add animation here.
           // loading indicator should fade in.
            $('<div class="loading-indicator well"><i class="icon-spinner icon-spin icon-2x"></i><span>Loading...</span></div>').insertBefore(p);
          }
        } else {
          p.removeClass('loading');
          p.parent().find('.loading-indicator').remove();
        }
      });
    }
  };
});

module.directive('kohaTriggerEvent', ["$timeout", function($timeout){
  // Triggers a dom event on render.  (Not an angular event).
  // attrs:  koha-trigger-event="eventName"
  return function(scope, el, attrs, controller) {
        $timeout(function(){
          // kohabibloaded event is currently used by librarything.
            var e;
            if (document.createEvent) {
              e = document.createEvent("HTMLEvents");
              e.initEvent(attrs.kohaTriggerEvent, true, true);
              el[0].dispatchEvent(e);
            } else {
              e = document.createEventObject();
              e.eventType = attrs.kohaTriggerEvent;
              el[0].fireEvent("on" + e.eventType, e);
            }
          }, 10);
  };
}]);

module.directive('bvReservesDisplay', function(){
    return {
        restrict: 'E',
        template: '<div class="reserves-bib-detail"><span class="biblabel">Course Reserves</span>'+
            '<a href class="collapser" ng-class="displayReserves ? \'collapser dropup\' : \'collapser\'" ng-click="displayReserves = !displayReserves"><i class="bi bi-option-horizontal"></i> <span class="collapse-txt"> </span><span ng-if="displayReserves">Hide</span><span ng-if="!displayReserves">Show</span><span class="caret"></span></a> '+
            '<div ng-repeat="i in bib.summary.reserves" ng-show="displayReserves" class="animate-show-hide"> '+
            '<a ng-href="{{address}}{{i.course_id}}" title="View Course Reserve">{{i.course_name}}</a> '+
            '<span title="Department">{{i.department}}</span> '+
            '<span title="Course Number">{{i.course_number}}</span> '+
            '<span title="Section">{{i.section}}</span> '+
            '<span title="Term">{{i.term}}</span> '+
            '<span title="Instructors">{{i.instructors}}</span> '+
            '<span title="Staff Notes" ng-if="showStaffNotes">{{i.staff_note}}</span> '+
            '<span title="Public Notes" ng-if="showPublicNotes">{{i.public_note}}</span> '+
            '</div> </div>',
        link: function(scope, el, attrs){
            attrs.$observe('reservesDisplay', function(v) {
                if(v == 'staffView'){
                    scope.showStaffNotes = true;
                    scope.address = '/app/staff/circ/course-reserves?op=view_course_reserves&course_id=';
                }
                else{
                    scope.showPublicNotes = true;
                    scope.address = '/app/course-reserves?course_id=';
                }
            });
        }
    };
});

module.directive('bvSolrRecordStatus', ["kohaDlg", "$http", function( kohaDlg, $http){
    return {
        template: '<div ng-if="index_outdated" class="alert alert-danger alert-sm">' +
                    'Record may not be current in the search index &nbsp;&nbsp;&nbsp;&nbsp;' +
                    '<button class="btn btn-danger btn-xs" ng-click="solrView()">' +
                    '<i class="icon-search"></i> View Metadata</button></div>',

        scope: {
            bibId: '='
        },
        link: function(scope,el,attrs){
            var dereg = scope.$watch('bibId', function(bibid){
                if(!bibid) return;
                $http.get('/api/work/' + bibid + '?view=index_info').then(function (response) {
                    var last_changelog_entry = response.data.last_changelog_entry;
                    var last_solr_index = response.data.last_solr_index;
                    var update_time = new Date(last_changelog_entry);
                    var index_time  = new Date(last_solr_index);

                    if ( (index_time.getTime() - update_time.getTime()) < 0 )
                        scope.index_outdated = true;

                    scope.solrView = function(){
                        kohaDlg.recordView(bibid, 'solr').then(function(new_index_time){
                            console.warn(new_index_time);
                            if ( (index_time.getTime() - update_time.getTime()) > 0 ){
                                scope.index_outdated = false;
                            }
                        });
                    };
                });

            });
        }
    };
}]);

module.directive('bvStaffMastSearch', ["$timeout", '$rootScope', "configService", "$state", "$injector", function($timeout, $rootScope, configService, $state, $injector){
    return function(scope, el){

        scope.auth_types = configService.interpolator('authtype').options();

        scope.toggleMap = function() {
            if(configService.geospatialSearch) $injector.get('mapComptrollerSvc').toggleMap();
        }

        scope.resetTabSearch = function() {
            scope.tabsearch = {
                auth: { idx: 'auth-heading', orderby: 'auth-heading-sort asc'},
                cslip: {},
                checkin: {},
                checkout: {},
                serial: {},
                syspref: {},
                patron: {orderby: 'surname,firstname'},
                creserves : {}
            };
        };
        scope.resetTabSearch();
        $rootScope.$on('loggedout', function() {
            scope.resetTabSearch();
        });

        // note catsearch has its own controller.

        function setTabFocus ( id ) {
            var targetTabEl = el.find( '.mastTab' + id );
            var targetInputEl = targetTabEl.find( 'input.focus-active' );
            if(!targetInputEl.length){
                console.warn( 'Could not find el: ' + id);
                return;
            }
            targetInputEl.first().focus();
        }

        scope.tabSelected = function(id, i){
            if( ! tabdef[id].loaded ){
                console.warn('suppressing tabSelected - not loaded.');
                return;
            }
            $timeout(function(){
                setTabFocus( id );
            }, 50);
        }
        scope.tabDeselected = function(id){
            if(id=='catSearch') return;
            var classSelector = '.mastTab' + id;
            var targetEl = el.find( classSelector + ' input[type="text"]');
            targetEl.val('');
        }

        scope.tabLoaded = function(id){
            tabdef[id].loaded = true;
            if(id==scope.activeTabId){
                if($state.includes('staff') &&
                        !$state.is('staff.patron.checkout') &&
                        !$state.is('staff.circ.checkin')){
                    $timeout(function(){
                        setTabFocus( id );
                    },50);
                }
            }
        }

        var tabdef = {
            checkout : {
                title: "Check Out",
                tmpl: "/app/static/partials/staff/tabs/checkoutTab.html"
            },
            checkin : {
                title: "Check In",
                tmpl: "/app/static/partials/staff/tabs/checkinTab.html"
            },
            patronSearch : {
                title: "Search Patrons",
                tmpl: "/app/static/partials/staff/tabs/patronSearchTab.html"
            },
            catSearch : {
                title: "Search the Catalog",
                tmpl: "/app/static/partials/staff/tabs/catSearchTab.html"
            },
            subSearch : {
                title: "Search Subscriptions",
                tmpl: "/app/static/partials/staff/tabs/subSearchTab.html"
            },
            authSearch : {
                title: "Search Authorities",
                tmpl: "/app/static/partials/staff/tabs/authSearchTab.html"
            },
            sysprefSearch : {
                title: "Search System Preferences",
                tmpl: "/app/static/partials/staff/tabs/sysprefSearchTab.html"
            },
            fillCallslip : {
                title: "Fill Request",
                tmpl: "/app/static/partials/staff/tabs/callslipFillTab.html"
            },
            courseReserves : {
                title: "Course Reserves",
                tmpl : "/app/static/partials/staff/tabs/courseReserves.html"
            }
        };
        angular.forEach(tabdef, function(def,id){
            def.id = id;
        });
        scope.tabs = [ ];

        scope.$on('$stateChangeSuccess', function(e, toState, toParams, fromState) {

            var tabs;
            var activeTabIdx = 0;
            var activeTabId;
            var unhideMasthead = true;

            if (toState.name.match(/^staff\.patron/)) {
                tabs = [ 'patronSearch', 'catSearch', 'checkout', 'checkin'];
                if(toState.name.match(/checkout/)){
                    activeTabId = 'checkout';
                }
            }
            else if (toState.name.match(/^staff\.serials/)) {
                tabs = [ 'subSearch', 'catSearch' ];
            }
            else if (toState.name.match(/^staff\.authorities/)) {
                tabs = [ 'authSearch', 'catSearch' ];
            }
            else if (toState.name.match(/^staff\.admin/)) {
                tabs = [ 'sysprefSearch', 'catSearch' ];
            }
            else if (toState.name.match(/^staff\.circ\.course-reserves/)) {
                tabs = [ 'catSearch', 'courseReserves', 'checkout', 'checkin'];
                activeTabId = 'courseReserves';
            }
            else if (toState.name.match(/^staff\.circ\.callslips/)) {
                tabs = [ 'catSearch', 'fillCallslip', 'checkin'];
                activeTabId = 'fillCallslip';
            }
            else if (toState.name.match(/^staff\.circ/)) {
                tabs = [ 'catSearch', 'checkout', 'checkin'];
                activeTabId = ( toState.name.match(/checkin/) ) ? 'checkin' : 'checkout';
            }
            else {
                tabs = [ 'catSearch', 'checkout', 'checkin'];
                unhideMasthead = false;
            }

            if(unhideMasthead)
                scope.masthead.docked = false;

            scope.tabs = tabs.map(function(tabid, i){
                tabdef.loaded = false;
                return tabdef[tabid]; });

            if(!activeTabId) activeTabId = scope.tabs[0].id;

            // uib-tabset  active prop is two-way bound; when we add/remove tabs, it updates,
            // potentially clobbering our activeTabId.
            $timeout(function(){
                // console.log('SETTING activeTabId: ' + activeTabId);
                scope.activeTabId = activeTabId;
            });

        });

    };
}]);

module.directive('bvHoldingsTable', ["configService", function(configService){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/components/holdings/holdings-table.html',
        scope: {
            holdings: '=',
            bib: '=',
            iface: '@',
            openEditModal: '=',
        },

        link: function(scope, el, attrs){

            if(!scope.iface) scope.iface = 'public';    // results, public, staff, edit

            scope.showMFHD = (scope.iface == 'results') ? false :
                (scope.iface == 'public') ? configService.search.showMFHD : true;
            //console.dir(scope.holdings);

            scope.collapsed = {};
            scope.config = configService;

        },
        controller: ["$scope", "kwApi", "$q", "userService", "kohaSearchSvc", function($scope, kwApi, $q, userService, kohaSearchSvc){
            var self = this;

            $scope.user = userService;
            var itemCheckinNotesQ = $q.defer();
            this.itemCheckinNotes = itemCheckinNotesQ.promise;
            $scope.$watch('holdings', function(holdings){
                if(!holdings)return;
                $scope.defaultSort =
                    configService.search.sortOwnBranchFirst ? 'homebranch' :
                    holdings.bib.isSerial ? 'enumchron' : 'dateaccessioned';
                if($scope.iface!='public'){
                    kwApi.ItemCheckinNote.getForBib({bibid: holdings.bib.id},
                        function(notesArr){
                            var note = {};
                            notesArr.forEach(function(cnote){
                                note[cnote.item_id] = cnote;
                            });
                            itemCheckinNotesQ.resolve( note );
                    }, function(e){ itemCheckinNotesQ.reject(e); });
                }

                if(configService.facetItemFilter){
                    var currentSearch = kohaSearchSvc.currentSearch();
                    var itemFacetConstraints;
                    if(currentSearch)
                        itemFacetConstraints = currentSearch.itemFacetConstraints();
                    if(itemFacetConstraints)
                        holdings.applyItemFacetConstraints(itemFacetConstraints);
                }
            });
        }]
    };
}]);

module.directive('bvNoAnimate', ["$animate", function($animate){
    // This doesn't work for many uib animations, which use $animate directly.
    return function(scope,el){
        $animate.enabled(el, false);
    };
}]);

module.directive('kohaItemAvailability', ["$filter", "$http", "$sce", "bvItemSvc", "kwApi", "configService", "userService", function($filter, $http, $sce, bvItemSvc, kwApi, configService, userService){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/item-availability.html',
        scope: {
            item: '=',  // $resource object.
            iface: '='
            // watch: '@'
        },
        link: function(scope, el, attrs){

            var itemfieldDefs = bvItemSvc.fieldDef;

            var capitalize = function (s) { return s && s[0].toUpperCase() + s.slice(1); };
            var display = function (field, item) {
                if (itemfieldDefs[field].authval) {
                    return capitalize(configService.display(item[field], itemfieldDefs[field].type));
                } else {
                    return item[field];
                }
            };
            var availabilityFlags = {
                "damaged": {
                    label: itemfieldDefs.damaged.label,
                },
                "wthdrawn": {
                    label: itemfieldDefs.wthdrawn.label,
                },
                "notforloan": {
                    label: itemfieldDefs.notforloan.label,
                },
                "itemlost": {
                    label: itemfieldDefs.itemlost.label,
                },
                "onloan": {
                    label: itemfieldDefs.onloan.label,
                },
                "on_hold": {
                    label: "On " + configService.wording.hold
                },
                "on_holdshelf": {
                    label: "Waiting on shelf"
                },
                "in_transit": {
                    label: "In transit"
                },
                "is_recalled": {
                    label: "Recalled"
                },
                "shelving": {
                    label: "On shelving cart"
                }
            };
            var onloan = null;
            scope.flags = {};
            var dereg = scope.$watch( 'item.timestamp', function(ts){  // won't get onloan status.
                var item = scope.item;
                if(item){
                    el.addClass( (item.$is_available ? 'available' : 'unavailable' ) );

                    ['damaged','wthdrawn','notforloan','itemlost'].forEach(function(statusName){
                        if(item[statusName]) availabilityFlags[statusName].desc = display(statusName, item);
                    });
                    scope.flags = {};
                    angular.forEach(availabilityFlags, function(data, code){
                        if(item[code] && item[code] !="0"){
                            scope.flags[code] = data;
                        }
                    });
                    if( scope.flags.onloan
                        && ( scope.iface != 'public' || configService.OPACShowCheckoutName )
                        && userService.can({borrowers: { borrowers_details: 'name'}})
                        && ! onloan ){

                            scope.flags.onloan.details = [ $sce.trustAsHtml("<span class='duedate'>(due back " + $filter('kohaDate')(item.onloan) + ")</span>") ]

                            kwApi.Issue.itemIssues({id: item.id, current: true}, function(issues){
                                if(!issues.length) return;
                                var borrowernumber = issues[0].borrowernumber;
                                scope.item.recalled = issues[0].recall_policy_id ? true : false;
                                $http.get('/api/patron/'+borrowernumber+'/name', { cache: true }).then(function (response) {
                                    var firstname = response.data.firstname;
                                    var surname = response.data.surname;
                                    var owner = borrowernumber + " " + surname + ", " + firstname;
                                    if(scope.iface != 'public' ){
                                        owner = '<a href="/app/staff/patron/' + borrowernumber + '/details">' + owner + '</a>';
                                    }
                                    scope.flags.onloan.details.unshift(
                                            $sce.trustAsHtml(' <span class="issueowner"> to ' + owner + '</span> ')
                                        );
                                });
                            } );

                    } else {
                        onloan = null;
                    }
                    if( scope.flags.in_transit && scope.iface != 'public' ){
                        scope.flags.in_transit.details = [ $sce.trustAsHtml(
                            ' to ' + $filter('displayName')(item.in_transit.to, 'branch') + ' since '
                                + $filter('kohaDate')(item.in_transit.since)
                            ) ];
                    }
                    if( scope.flags.on_holdshelf && scope.iface != 'public' ){
                        scope.flags.on_holdshelf.details = [ $sce.trustAsHtml(
                            ' at ' + $filter('displayName')(item.on_holdshelf.at, 'branch') + ' since '
                                + $filter('kohaDate')(item.on_holdshelf.since)
                            ) ];
                    }
                    scope.statuses = item.statuses;
                    scope.config = configService;
                    if( !('watch' in attrs) ) dereg();
                }
            });
        }
    };
}])

.directive('bvItemGroup', ["configService", "bvItemSvc", "Pager", "userService", "$timeout", function( configService, bvItemSvc, Pager, userService, $timeout) {
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/components/holdings/item-group.html',
        scope: true,

        link: function(scope, el, attrs){

            var pageOption = {
                maxItems : 29,
                size: 20
            };

            scope.sortOrder = {
                field: configService.DefaultItemSort || scope.defaultSort,
                reverse: false,
            };
            scope.showHiddenItems = false;

            function setVisibleItems () {
                var sortOrder = scope.sortOrder;

                // item visibility filters:
                // 1: item._facet_hidden .
                // 2: item.default_hidden && !user.is_staff
                // 3: item.suppressed && !user.is_staff
                // 4: paging.

                var sortedItems = scope.parent.items.filter(function(item){
                    if(item._facet_hidden || (scope.iface=='public' && item.suppressed))
                        return false;
                    if(!scope.showHiddenItems){
                        return userService.is_staff || !(item.default_hidden && item.default_hidden !== "0");
                    }
                    return true;
                });
                bvItemSvc.sort(sortedItems, sortOrder.field, sortOrder.reverse);

                scope.visibleItems = scope.pager ?
                    sortedItems.slice(scope.pager.offset(),scope.pager.rangeEnd()) : sortedItems;

            }
            var pageElHt = 0;
            scope.pageChanged = function() {
                var tableEl = el.find('.item-group-fixed-ht').eq(0);
                var tableHeight = tableEl.height();
                if(tableHeight > pageElHt){
                    pageElHt = tableHeight;
                    tableEl.css('min-height',pageElHt);
                }
                setVisibleItems();
            }

            scope.$watchCollection('sortOrder',
                function(sortOrder, oldSortOrder){
                    if(!scope.parent) return;

                    if(scope.pager)
                        scope.pager.page = 1;
                    setVisibleItems(sortOrder);
                }
            );

            scope.expandRow = {};

            if(scope.iface=='edit'){

                scope.$watch( 'holdings.lastMod' , function(ts, oldts){

                    if(ts==oldts)
                        return;

                    if(scope.pager){
                        // in case of adds/deletes.
                        if(scope.pager.count != scope.parent.items.length)
                            scope.pager.count = scope.parent.items.length;
                    }

                    scope.visibleItems.length = 0;
                    $timeout(function(){
                        // force ng-repeat to pick up changes.
                        updateColumnVis();
                        setVisibleItems();
                    });

                } )
            }

            function updateColumnVis (){
                var columns = scope.parent.visibleItemCols[scope.iface];
                scope.primaryColumns = [];
                scope.secondaryColumns = [];
                columns.forEach(function(col) {
                    if ( (scope.iface=='public' && bvItemSvc.fieldDef[col].secondary)
                        || (scope.iface=='staff' && bvItemSvc.fieldDef[col].secondary=='staff') ) {
                        scope.secondaryColumns.push(col);
                    }
                    else {
                        scope.primaryColumns.push(col);
                    }
                });
            }

            var dereg = scope.$watch(attrs.parent, function(parentObj) {
                if (!parentObj) return;

                scope.visibleItems = [];
                if(parentObj.items.length > pageOption.maxItems){
                    scope.pager = new Pager({
                        count: parentObj.items.length,
                        pagelength: pageOption.size });
                }

                // parent may be holdings obj or mfhd.
                scope.parent = parentObj;

                setVisibleItems();

                if (!scope.iface) scope.iface = 'public';

                scope.user = userService;
                var columns = parentObj.visibleItemCols[scope.iface];
                if( columns.indexOf('guide')>=0 )
                    scope.showGuide = true;

                // console.log( 'simple view?: ' + parentObj === scope.holdings);  // we are in simple (non-grouped) View.

                var defaultHidden = {};
                if(!userService.is_staff){
                    scope.defaultHiddenCnt = parentObj.items.filter(function(item){
                                return (item.default_hidden || item.default_hidden == "0");
                            }).length;
                }

                scope.toggleHiddenItems = function() {
                    console.log("Toggle");
                    scope.showHiddenItems = !scope.showHiddenItems;
                    setVisibleItems();
                };

                scope.canSelect = configService.AcqLists;
                updateColumnVis();

                scope.selectedItems = {};

                // Deselect items that are not compatible with selected items (Infomart; may be deprecated)
                // Note, Infomart does not use mfhds so all items are in the same group
                scope.filterItemSelection = function(id) {
                    if (!scope.selectedItems[id]) return;

                    var item;
                    parentObj.items.forEach(function(i) {
                        if (i.id == id) item=i;
                    });
                    var selectionProgram = item.order_type + ' ' + item.program;
                    parentObj.items.forEach(function(i) {
                        if (scope.selectedItems[i.id]) {
                            var selectedSelectionProgram = i.order_type + ' ' + i.program;
                            if (selectionProgram !== selectedSelectionProgram) {
                                scope.selectedItems[i.id] = false;
                            }
                        }
                    });
                };

                // if (scope.iface != 'edit')
                    dereg();
            });
        }
    };
}])

.directive('bvMfhdGroup', ["userService", function( userService) {
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/components/holdings/mfhd-group.html',
        scope: true,

        link: function(scope, el, attrs){

            // holdings.mfhds is updated when holdings obj is refreshed.
            var dereg = scope.$watch('holdings.mfhds', function(newVal) {
                if (!newVal) return;
                if (!scope.iface) scope.iface = 'public';

                scope.user = userService;
                scope.mfhds = scope.holdings.mfhds;
                scope.columns = scope.holdings.visibleMfhdCols;
                scope.realMfhdCount = 0;
                scope.showTextualHoldings = false;
                scope.mfhds.forEach(function(mfhd) {

                    if (!mfhd._dummy) {
                        scope.realMfhdCount++;
                    }
                    if (mfhd._textual_holdings.has) {
                        scope.showTextualHoldings = true;
                        if(!mfhd.items.length)
                            mfhd._textual_holdings.expand = true;
                    }
                });

                if (scope.iface != 'edit') dereg();
            });
        }
    };
}])

.directive('bvItemCell', ["$uibModal", "bvItemSvc", "userService", function( $uibModal, bvItemSvc, userService){
    return {
        restrict: 'E',
        require: '^bvHoldingsTable',
        templateUrl: '/app/static/partials/components/holdings/item-cell.html',
        scope: false,

        link: function(scope, el, attrs, tableCtrl){
            var dereg = scope.$watch('item', function(newVal) {
                if (!newVal) return;
                var itemfieldDefs = bvItemSvc.fieldDef;

                scope.displayValue = scope.item._display[scope.itemCol];
                el.closest('td').addClass('item-data-' + scope.itemCol);

                // Special column/interface semantics
                if (scope.itemCol == '_availability') {
                    scope.columnClass = 'availability';
                }
                else if (scope.itemCol == 'barcode' && scope.iface == 'staff') {
                    scope.columnClass = 'staff-barcode';
                    scope.showCheckinNotes = userService.can({circulate: {item_checkin_notes: 'view'}});
                    if (scope.showCheckinNotes) {
                        tableCtrl.itemCheckinNotes.then(function(noteHash){
                            scope.checkin_note = noteHash[scope.item.id];
                        });
                    }
                }
                else if (bvItemSvc.fieldDef[scope.itemCol].type == 'uri') {
                    scope.uri = scope.item[scope.itemCol];
                    if (scope.uri === undefined || scope.uri === null)
                        scope.uri = '';

                    var domainMatch = /^(?:http)?s?\:?\/\/([^/]+)\/?/.exec(scope.uri);
                    if (domainMatch) {
                        scope.columnClass = 'uri';
                        scope.linkText = (scope.uri.length < 32) ? scope.uri : "[" + domainMatch[1] + "]";
                    }
                    else {
                        scope.columnClass = 'default';
                    }
                }
                else {
                    scope.columnClass = 'default';
                }

                scope.editCheckinNote = function(){
                    $uibModal.open({
                        templateUrl: '/app/static/partials/staff/circ/item-checkin-note-modal.html',
                        animation: false,
                        // keyboard: false,
                        size: 'sm',
                        windowClass: 'item-checkin-note',
                        backdrop: false,
                        controller: 'ItemCheckinNoteCtrl',
                        resolve: {
                            checkin_note: function(){ return scope.checkin_note; },
                            item: function(){ return scope.item; },
                            checkin: function(){ return false; }
                        }
                    }).result.then(function(cnote){
                        // in case of 404, we have a new note obj. update if it's been saved.
                        if(cnote && cnote != scope.checkin_note ){
                            scope.checkin_note = cnote;
                        }
                    });
                };

                dereg();
            });
        }
    };
}]);

module.directive('kohaItemDataInput', ["kwApi", "configService", "alertService", "userService", function( kwApi, configService, alertService, userService){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/staff/item-data-input.html',
        scope: true,
        // require: 'ngModel',
        link: function(scope, el, attrs ){
            // Assume we have a field in scope, including props from configService.ItemFields.
            // And an item, which is a $resource obj.

            scope.forceAllowInput = attrs.forceAllowInput;

            var updateField = function() {
                scope.inputType = 'string';
                if(scope.field.authval){
                    if(scope.field.code=='itemlost'){
                        // lost item handling happens in circ-status view.
                        scope.field.editor.readonly = true;
                    }
                    scope.inputType = 'authval';
                    scope.options = configService.interpolator(scope.field.type).options();
                    if (scope.field.type == 'bv-branch') {
                        scope.options = scope.options.filter(function(b) {
                            return userService.canInBranch({editcatalogue: {item: 'update'}}, b.code);
                        });
                    }
                    var falsies = scope.options.find(function(o){ return o.label==='';});

                    // option ng-if creates an undesirable child scope
                    if (!scope.field.editor.required && !falsies) {
                        scope.options.push({label: '', value: ''});
                    }


                    // These fields should probably never have allowed NULL in the first place
                    if (scope.field.code=='wthdrawn' || scope.field.code=='restricted' || scope.field.code=='notforloan') {
                        if (scope.item[scope.field] === null || scope.item[scope.field] === '' || scope.item[scope.field] === undefined)
                            scope.item[scope.field] = 0;
                    }
                } else if(scope.field.type == 'date'){
                    scope.inputType = 'date';
                } else if (scope.field.type == 'codabar') {
                    scope.inputType = 'codabar';
                } else if(scope.field.type == 'decimal'){
                    var n = scope.item[scope.field.code];
                    if (n === null || n === undefined || n === '') {
                        scope.item[scope.field.code] = '';
                    }
                    else {
                        scope.item[scope.field.code] = parseFloat(scope.item[scope.field.code]);
                        if (scope.item[scope.field.code] == Math.floor(scope.item[scope.field.code]))
                            scope.item[scope.field.code] = "" + scope.item[scope.field.code] + ".00";
                        else
                            scope.item[scope.field.code] = "" + scope.item[scope.field.code];
                    }
                    scope.inputType = 'string'; // Angular cannot handle .00 numbers
                }

                if(!scope.forceAllowInput && "OFF"!=KOHA.config.autoBarcode && scope.field.code=='barcode'){
                    scope.field._widget = {
                        do : function(){
                            this.doing = true;
                            var self = this;
                            var branch = scope.item.homebranch || userService.login_branch;
                            var dummy = new kwApi.Item();
                            dummy.$nextBarcode( { branch: branch })
                                .then(function(bcObj){
                                    var bc = (bcObj||{}).barcode;
                                    scope.item.barcode = bc;
                                    self.doing = false;
                                }, function(e){
                                    alertService.add( {
                                        type: "danger",
                                        msg: "Cannot calculate next barcode.  " + e.replace(/^\d\d\d /, '')
                                    } );
                                    self.doing = false;
                                });
                        },
                        doing : false
                    };
                    if(!scope.item.id) scope.field._widget.do();
                }
            };

            if (attrs.watchField) {
                if (attrs.watchField == 'init')
                    updateField();
                scope.$watch('field', function(newVal, oldVal) {
                    if (newVal && (newVal !== oldVal)) {
                        updateField();
                    }
                }, true);
            }
            else {
                updateField();
            }
    // ADD WIDGETS .

        }
    };
}])


module.directive('kohaItemDataValue', ["configService", "userService", function( configService, userService){
    return {
        restrict: 'E',
        template: '<span class="item-data-input">{{displayValue}}</span>',
        scope: true,
        // require: 'ngModel',
        link: function(scope, el, attrs ){
            var updateField = function() {
                scope.inputType = 'string';
                scope.displayValue = scope.item[scope.field.code];
                if(scope.field.authval){
                    scope.options = configService.interpolator(scope.field.type).options();
                    if (scope.field.type == 'bv-branch') {
                        scope.options = scope.options.filter(function(b) {
                            return userService.canInBranch({editcatalogue: {item: 'update'}}, b.code);
                        });
                    }
                    for (var i=0; i<scope.options.length; i++) {
                        var o = scope.options[i];
                        if (o.code === scope.displayValue) {
                            scope.displayValue = o.label + '(' + scope.displayValue + ')';
                            break;
                        }
                    }
                }
                if (scope.field.code=='wthdrawn' || scope.field.code=='restricted' || scope.field.code=='notforloan') {
                    if (scope.item[scope.field] === null) {
                        scope.displayValue = 0;
                    }
                } else if(scope.field.type == 'decimal'){
                    var n = scope.item[scope.field.code];
                    if (n === null || n === undefined || n === '') {
                        scope.displayValue = '';
                    }
                    else {
                        scope.displayValue = parseFloat(scope.item[scope.field.code]);
                        if (scope.displayValue == Math.floor(scope.item[scope.field.code]))
                            scope.displayValue = "" + scope.displayValue + ".00";
                        else
                            scope.displayValue = "" + scope.displayValue;
                    }
                    scope.inputType = 'string'; // Angular cannot handle .00 numbers
                }
            };

            if (attrs.watchField) {
                if (attrs.watchField == 'init')
                    updateField();
                scope.$watch('field', function(newVal, oldVal) {
                    if (newVal && (newVal !== oldVal)) {
                        updateField();
                    }
                }, true);
            }
            else {
                updateField();
            }
    // ADD WIDGETS .

        }
    };
}])

.directive('kwItemEditMfhdAction', ["alertService", "userService", "kohaDlg", function(alertService, userService, kohaDlg){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/staff/item-edit-mfhd-action.html',
        scope: true,
        link: function(scope, el, attrs){
            scope.vis = { mfhds : false, dropdown : false };

            scope.canMutate = function(i, f) {
                return userService.can(
                    { editcatalogue:{item: f} }, 'branch='+i.homebranch
                )
                || (
                    userService.can(
                        { editcatalogue:{item: f} }, 'branch=my_own_branch'
                    )
                    && userService.details_data.branchcode === i.homebranch
                )
                || 
                    userService.canInBranch({editcatalogue: {item: 'update'}}, i.homebranch);
            };

            scope.selectItemsForDelete = function(){
                console.dir(scope.mfhd);
                scope.mfhd.items.forEach(function(item){
                    if (scope.canMutate(item, 'delete')) {
                        item._saveStatus = 'deleting';
                        scope.itemGroups.toDelete[item.id] = true;
                    }
                });
                scope.vis.dropdown=false;
            };
            scope.otherRealMfhds = scope.holdings.mfhds.filter(function(m){
                return m.id && m.id != scope.mfhd.id; // i.e. !_dummy & !self.
            });

            scope.deleteMfhd = function(){
                if(scope.mfhd._dummy || scope.mfhd.items.length) return;
                scope.vis.dropdown=false;
                el.parents('tr').addClass("deleting");
                var mfhd_id = scope.mfhd.id;
                kohaDlg.dialog({
                    heading: 'Are you sure?',
                    message: "This action cannot be undone.",
                }).result.then(function(rv) {
                    scope.mfhd.$delete().then(function(rsp){
                        alertService.add({ type: 'success', msg: 'MFHD successfully deleted.'});
                        scope.holdings.rmMfhd(mfhd_id);
                    }, function(e){
                        el.parents('tr').addClass("delete-failed").removeClass("deleting");
                        alertService.addApiError(e,'MFHD cannot be deleted');
                    });
                });
            };
        }
    };
}])
.directive('kwItemEditItemAction', ["userService", function( userService){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/staff/item-edit-item-action.html',
        scope: true,
        link: function(scope, el, attrs){

            scope.vis = { mfhds : false, dropdown : false };

            // FIXME - this should respect circ control item owner
            scope.canMutate = function(i, f) {
                return userService.can(
                    { editcatalogue:{item: f} }, 'branch='+i.homebranch
                )
                || (
                    userService.can(
                        { editcatalogue:{item: f} }, 'branch=my_own_branch'
                    )
                    && userService.details_data.branchcode === i.homebranch
                )
                || 
                    userService.canInBranch({editcatalogue: {item: 'update'}}, i.homebranch);
            };

            scope.toggleItemForDelete = function(){
                scope.item._saveStatus = 'deleting';
                scope.vis.dropdown=false;
                if(scope.itemGroups.toDelete[scope.item.id]){
                    delete scope.itemGroups.toDelete[scope.item.id];
                } else {
                    scope.itemGroups.toDelete[scope.item.id] = true;
                }
            };

            scope.deleteItem = function(item){
                item._saveStatus = 'deleting';
                scope.vis.dropdown=false;
                scope.deleteItems( [ item.id ] );
            };
        }
    };
}]);
module.directive('bvLinkUnless', ["userService", function(userService){
    // Conceal the <a> behavior of link if:
    //   * bv-link-unless interpolated value evaluates to true.
    //   * `permission` attribute evaluates to false for user.
    function interceptClick (e){ e.stopPropagation(); return false; }
    return function(scope, el, attrs){
        var a = el.closest('a');

        function modLink (ok){
            if(ok){
                a.removeClass('no-link').prop('disabled', false);
                a.off('click', interceptClick);
            } else {
                a.addClass('no-link').prop('disabled', true);
                a.click(interceptClick);
            }
        }
        if(attrs.bvLinkUnless.length)
            attrs.$observe('bvLinkUnless', modLink);
        if('permission' in attrs){
            attrs.$observe('permission', function(p){
                modLink(userService.can(p));
            });
        }

    };
}]);
module.directive('kwStaffToolbarCat', ["$window", "userService", function($window, userService){
    return {
        restrict: 'E',
        scope: {
            bib: '='
        },
        templateUrl: '/app/static/partials/staff/staff-toolbar-cat.html',
        link: function(scope, el, attrs){
            scope.$watch('bib', function(bib){
                if(!bib) return;
                userService.whenAnyUserDetails().then( function() {
                    scope.itemedit = userService.can({editcatalogue: {item: '*'}});
                    scope.bibedit = userService.can({editcatalogue: {bib: 'update'}}, 'catsource='+bib.marc.subfield('942x'));
                });
            });
        },
        controller: ["$scope", "userService", "configService", "kohaDlg", "bibService", "$state", function($scope, userService, configService, kohaDlg, bibService, $state){
            $scope.user = userService;
            $scope.config = configService;
            $scope.bibedit = 0;
            $scope.itemedit = 0;
            $scope.dlformats = {
                mods : "MODS (XML)",
                dcq : "Dublin Core (XML)",
                dc : "Dublin Core (Simple XML)",
                marcxml : "MARCXML",
                marc : "MARC (UTF-8)",
            };
            $scope.download = function(fmt){
                $window.open("/api/work/" + $scope.bib.id + "/exports/" + fmt + "?download=true");
            };
            $scope.doDelete = function( ){
                kohaDlg.delBibs( $scope.bib.id ).then(function(){
                    bibService.clearCache( $scope.bib.id );
                    $state.reload();
                })
            };
            $scope.canHold = function(){ return ($scope.bib && $scope.bib.summary.item_count) ? true : false; };
            $scope.addToList = function(){
                kohaDlg.addToList( [ $scope.bib.id ] );
            }
            $scope.printme= function(){ window.print(); };
        }]
    };
}]);

module.directive('validCodabar', function() {
    var cbarvalid = function(barcode, is_patron) {
        if (barcode.length !== 14) {
            return false;
        }

        if (is_patron && barcode.charAt(0) !== '2') {
            return false;
        }

        if(! /^\d+$/.test(barcode)) {
            return false;
        }

        var check_digit = barcode.charAt(13);
        var sum = 0;
        for (var i=0; i < 13; i++) {
            var this_digit = Number(barcode.charAt(i));
            if (i %2) {
                sum += this_digit;
            } else {
                var product = 2*this_digit;
                if (product > 9) {
                    product -= 9;
                }
                sum += product;
            }
        }
        var remainder = sum %10;
        if (remainder != 0) {
            remainder = 10 - remainder;
        }
        if (remainder != check_digit) {
            return false;
        }

        return true;
    };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ctrl) {
            ctrl.$validators.codabar = function(modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue)) {
                    return true;
                }
                return cbarvalid(viewValue, (attrs.validCodabar === 'patron'));
            };
        }
    }
});

module.directive('uniqueColumn', function(){
    // Assumes table data in an ng-repeat, and inputs.
    // Should probably be made more efficient for larger data sets.
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, el, attrs, ngModelCtrl){

            var tdIndex = el.parents('td').index() + 1;

            var inputs = el.parents('table').find("tr td:nth-child(" + tdIndex + ") input");
            scope.$watch(function(){
                return el.parents('table').find("tr").length;
            }, function(n,o){
                if(n && n!=o){
                    inputs = el.parents("table").find("tr td:nth-child(" + tdIndex + ") input");
                    if (n < o){
                        //  FIXME: check validity again if a row was deleted.
                        var values = {};
                        inputs.each(function(i,input){
                            var val = $(input).val();
                            if(values[val]){
                                values[val].push($(input).controller('ngModel'));
                            } else {
                                values[val] = [ $(input).controller('ngModel') ];
                            }
                        });
                        for(var v in values){
                            values[v].forEach(function(ngModelCtrl){
                                ngModelCtrl.$setValidity('unique', values[v].length == 1);
                            });
                        }
                    }
                }
            });
            ngModelCtrl.$parsers.push(function(viewValue) {
                var valid = true;

                // var inputs = el.parents('table').find("tr td:nth-child(" + tdIndex + ") input");
                var duplicates = [];
                var fixed = [];
                inputs.each(function(i,input){
                    if( ngModelCtrl === otherNgModelCtrl) return true;
                    var otherNgModelCtrl = $(input).controller('ngModel');

                    if( ngModelCtrl.$modelValue == otherNgModelCtrl.$modelValue && viewValue != otherNgModelCtrl.$modelValue ){
                        fixed.push(otherNgModelCtrl);
                    }
                    if( viewValue == otherNgModelCtrl.$modelValue){
                        duplicates.push(otherNgModelCtrl);
                        valid = false;
                    }
                });
                fixed.forEach(function(otherNgModelCtrl){
                    otherNgModelCtrl.$setValidity('unique', true);
                });
                duplicates.forEach(function(otherNgModelCtrl){
                    otherNgModelCtrl.$setValidity('unique', false);
                });
                ngModelCtrl.$setValidity('unique', valid);

                return viewValue;
            });

        }
    };
});

module.directive('polybox', ['$parse', function ($parse) {
    return {
        restrict: 'E',
        scope: {},

        template: '<div ng-click="cycle()" ng-class="state.class" title="{{state.title}}">' +
                      '<i ng-class="state.icon"></i>{{state.display}}' +
                  '</div>',
        replace: true,
        link: function(scope, elem, attrs) {

            var model = $parse(attrs.ngModel);
            var states = scope.$parent.$eval(attrs.states);

            scope.cycle = function(isInit) {
                var isFirst = (scope.idx == -1);

                if (attrs.iterator)
                    scope.idx = scope.$parent.$eval(attrs.iterator)(scope.idx);
                else
                    scope.idx = (scope.idx + 1) % states.length;
                scope.state = states[scope.idx];
                model.assign(scope.$parent, scope.state.value);

                if (scope.state.style) {
                    elem.css(scope.state.style);
                }
                if (!isFirst && attrs.ngChange) {
                    scope.$parent.$eval(attrs.ngChange);
                }
                if (!isFirst && !isInit && attrs.pbClick) {
                    scope.$parent.$eval(attrs.pbClick);
                }
            };
            scope.idx = -1;
            //scope.cycle();

            scope.sync = function() {
                var init = model(scope.$parent);
                for (var i=0; i<states.length; i++) {
                    if (states[i].value == init) {
                        scope.idx = i;
                        scope.state = states[scope.idx];
                        if (scope.state.style) {
                            elem.css(scope.state.style);
                        }
                        return;
                    }
                }
                scope.idx = -1;
                if (attrs.iterator)
                    scope.idx = scope.$parent.$eval(attrs.iterator)(scope.idx);
                else
                    scope.idx = (scope.idx + 1) % states.length;
                scope.state = states[scope.idx];
                if (scope.state.style) {
                    elem.css(scope.state.style);
                }
            };
            //scope.sync();
            var dereg = scope.$parent.$watch(attrs.ngModel, function(val) {
                if (typeof(val) !== 'undefined') {
                    scope.sync();
                    dereg();
                }
            });
        }
    }
}]);

module.directive('bsAffix', ["$window", function($window) {

    var affixed;
    var unpin = null;

    var checkPosition = function(scope, iElement, minimum) {

                var scrollPos = $(document).scrollTop();

                if(minimum && scrollPos < minimum)
                    scrollPos = minimum;
                else
                    scrollPos += 10;

                $(iElement).offset({
                    top: scrollPos
                });

    };

    return {
      restrict: 'EAC',
      link: function postLink(scope, iElement, iAttrs) {

        angular.element($window).bind('scroll', function() {
            var minOff = $(iElement).attr("data-offset-top");
            minOff = minOff == null ? null : minOff * 1;
            checkPosition(scope, iElement, minOff);
        });

        angular.element($window).bind('click', function() {
          setTimeout(function() {
           var minOff = $(iElement).attr("data-offset-top");
            minOff = minOff == null ? null : minOff * 1;
            checkPosition(scope, iElement, minOff);
          }, 1);
        });

      }
    };

}]);

module.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.ngEnter);
                });

                event.preventDefault();
            }
        });
    };
});

module.directive('kohaPrintModalBody', function(){
    return function(scope, el, attrs ){
        el.click(function(){
            var modal = el.parents('.modal-content');
            var content = modal.find('.modal-body').clone();
            var body = content.wrap('<div class="print-modal"></div>').parent().addClass(modal.children().attr('class'));
            if('withHeader' in attrs){
                var header = modal.find('.modal-header').clone();
                body.prepend(header);
            }
            var printWindow = window.open("", "popup","width=1000,height=600,scrollbars=yes,resizable=yes,toolbar=no,directories=no,location=no,menubar=no,status=no,left=0,top=0");
            var doc = $(printWindow.document).contents();
            var cssroot = window.location.origin + '/app/static/css';
            doc.find('head').append('<link rel="stylesheet" type="text/css" href="'+cssroot+'/koha-wack.css">')
                .append('<link rel="stylesheet" type="text/css" media="print" href="'+cssroot+'/opac-print.css">')
                .append('<style>.ng-hide{display:none !important;}</style>');
            doc.find('body').append(body);
            printWindow.print();
            printWindow.close();
        });

    };
});

// This the iframe like a black box, and just sizes it to whatever is left in the window, leaving the 
// iframe with a scroll bar but the outer window without one. It's not necessarily as elegant as polling but
// it avoids the feedback problem that I've never been able to get around.
// 09232015: Updated to size the iframe to what's left of the document height, so long as the Bootstrap 
// responsive classes haven't kicked in once the window is resized to mobile-width.

module.directive('heightTracksHeader', ["$timeout", function( $timeout) {
    return function(scope, elem, attrs) {
        var bottomMargin = 20;
        if(attrs.src.lastIndexOf('/marced') === 0 ){
          bottomMargin = 0;
          elem.addClass('marced');
        } else {
          elem.removeClass('marced');
        }

        var setSize = function() {
            var headerElem = $("#header");
            var footerElem = $("#footer");
            var headerHeight = (headerElem.length ? headerElem.height() : 200);
            var footerHeight = (footerElem.length ? footerElem.height() : 0);

            var size;
            var topbarElem = $("#topbar");
            if (topbarElem && topbarElem.is(":visible")) {
              var documentHeight = $(document).height();
              size = documentHeight - headerHeight - bottomMargin;
              //console.log("Setsize doc=" + documentHeight + "- header=" + headerHeight + " - footer=" + footerHeight + " -bottommargin=" + bottomMargin + " => " + size);
            }
            else {              
              size = window.innerHeight - headerHeight - bottomMargin;
              //console.log("Setsize win=" + window.innerHeight + "- header=" + headerHeight + " - footer=" + footerHeight + " -bottommargin=" + bottomMargin + " => " + size);
            }
            
            elem.height(size);
        };

        var init = false;
        var setSizeAfterDigest = function(newVal, oldVal) {
            if (!init || !angular.equals(newVal, oldVal)) {
                $timeout(setSize, 50);
                init = true;
            }
        };
        scope.$watch('masthead', setSizeAfterDigest, true);

        $(window).on('resize', setSize);

        scope.$on('$destroy', function() {
            $(window).off('resize', setSize);
        });
    };
}]);


module.directive('bvSpacefillContainer', ["$timeout", function( $timeout) {
    return function(scope, elem, attrs) {
        var excludeHeight = 0;
        var excludeElements = [];
        if (attrs.bvscExcludeHeight) 
            excludeHeight = attrs.bvscExcludeHeight;

        if (attrs.bvscExcludeElements)
            excludeElements = attrs.bvscExcludeElements.split(',');

        var setSize = function() {
            var totalExclude = 1*excludeHeight;
            var headerElem = $("#header");
            var footerElem = $("#footer");

            totalExclude += (headerElem.length ? headerElem.height() : 200);
            //console.log("Exclude header: " + headerElem.height());

            excludeElements.forEach(function(elementSelector) {
                var el = $(elementSelector);
                if (el.length) {
                    //console.log("Exclude " + elementSelector + ": " + el.height());
                    totalExclude += el.height();
                }
            });
            
            var size = window.innerHeight - totalExclude;
            //console.log("" + size + " = " + window.innerHeight + " - " + totalExclude);
            elem.height(window.innerHeight - totalExclude);
        };

        var init = false;
        var setSizeAfterDigest = function(newVal, oldVal) {
            if (!init || !angular.equals(newVal, oldVal)) {
                $timeout(setSize, 50);
                init = true;
            }
        };
        var dereg = scope.$watch('masthead', setSizeAfterDigest, true);

        $(window).on('resize', setSize);

        scope.$on('$destroy', function() {
            dereg();
            $(window).off('resize', setSize);
        });
        
        if (attrs.bvscListen) {
            scope.$on(attrs.bvscListen, setSize);
        }
    };
}]);

/**
 * Adds a 'ui-scrollpoint' class to the element when the page scrolls past it's position.
 * @param [offset] {int} optional Y-offset to override the detected offset.
 *   Takes 300 (absolute) or -300 or +300 (relative to detected)
  * from : [] https://github.com/angular-ui/ui-scrollpoint ]
 */
module.directive('uiScrollpoint', ['$window', function($window) {

        function getWindowScrollTop() {
            if (angular.isDefined($window.pageYOffset)) {
                return $window.pageYOffset;
            } else {
                var iebody = (document.compatMode && document.compatMode !== 'BackCompat') ? document.documentElement : document.body;
                return iebody.scrollTop;
            }
        }
        return {
            require: '^?uiScrollpointTarget',
            link: function(scope, elm, attrs, uiScrollpointTarget) {
                var absolute = true,
                        shift = 0,
                        fixLimit,
                        $target = uiScrollpointTarget && uiScrollpointTarget.$element || angular.element($window);

                if (!attrs.uiScrollpoint) {
                    absolute = false;
                } else if (typeof (attrs.uiScrollpoint) === 'string') {
                    // charAt is generally faster than indexOf: http://jsperf.com/indexof-vs-charat
                    if (attrs.uiScrollpoint.charAt(0) === '-') {
                        absolute = false;
                        shift = -parseFloat(attrs.uiScrollpoint.substr(1));
                    } else if (attrs.uiScrollpoint.charAt(0) === '+') {
                        absolute = false;
                        shift = parseFloat(attrs.uiScrollpoint.substr(1));
                    }
                }

                fixLimit = absolute ? attrs.uiScrollpoint : elm[0].offsetTop + shift;

                function onScroll() {

                    var limit = absolute ? attrs.uiScrollpoint : elm[0].offsetTop + shift;

                    // if pageYOffset is defined use it, otherwise use other crap for IE
                    var offset = uiScrollpointTarget ? $target[0].scrollTop : getWindowScrollTop();
                    if (!elm.hasClass('ui-scrollpoint') && offset > limit) {
                        elm.addClass('ui-scrollpoint');
                        fixLimit = limit;
                    } else if (elm.hasClass('ui-scrollpoint') && offset < fixLimit) {
                        elm.removeClass('ui-scrollpoint');
                    }
                }

                $target.on('scroll', onScroll);
                onScroll(); // sets the initial state

                // Unbind scroll event handler when directive is removed
                scope.$on('$destroy', function() {
                    $target.off('scroll', onScroll);
                });
            }
        };
    }]).directive('uiScrollpointTarget', [function() {
        return {
            controller: ['$element', function($element) {
                    this.$element = $element;
                }]
        };
    }]);


module.directive('resizable', function() {
    return {
        restrict: 'A',
        link: function(scope, elem, attrs) {
            if (attrs.resizable) {
                var opts = angular.fromJson(attrs.resizable);
                elem.resizable(opts);
            }
            else {
                elem.resizable();
            }
        }
    };
});

module.directive('kwTabout', ["$parse", function($parse) {
    return {
        restrict: 'A',
        compile: function($element, attr) {
            var fn = $parse(attr.kwTabout, null, true);
            return function ngEventHandler(scope, element) {
                element.on('keydown', function(event) {
                    if (event.keyCode === 9 && event.shiftKey === false && event.ctrlKey === false) {
                        var callback = function() {
                            fn(scope, {});
                        };
                        scope.$apply(callback);
                    }
                });
            };
        }
    };
}]);

// FIXME - this should be more general in presentation
module.directive('kwTablePaste', ["modalForm", function(modalForm) {
    return {
        restrict: 'E',
        scope: true,
        template: '<button ng-class="class" ng-click="paste()">Paste Table</button>',
        link: function(scope, elem, attr) {
            scope.class = attr.class || 'btn btn-sm btn-outline-secondary';
            var parentScope = scope.$parent;
            scope.paste = function() {
                modalForm.open({
                    formdata: {},
                    title: 'Paste Table',
                    saveText: 'OK',
                    template: 'Highlight the rows and columns of your spreadsheet or table and use Ctrl-C / Cmd-C '
                            + 'to copy, then paste in the space below and click OK. Do not include any header rows '
                            + 'or columns, just the data cells. Do not edit the text after you paste it. This will ' 
                            + 'replace any existing data in the form.'
                            + '<textarea class="form-control" ng-model="formdata.input"></textarea>'
                }).then(function(data) {
                    var newVal = [];
                    angular.forEach(data.input.split(/[\r\n]/), function(row) {
                        if (row)
                            newVal.push(row.split('\t'));
                    });
                    if (attr.model) {
                        var array = parentScope.$eval(attr.model);
                        array.replaceWith(newVal);
                    }
                    else if (attr.apply) {
                        var applyFunc = parentScope.$eval(attr.apply);
                        applyFunc(newVal);
                    }
                });
            };
        }
    };
}]);

module.directive('kwDynamicForm', function() {
    return {
        restrict: 'E',
        scope: {
            parameters: '=kwdfParameters',
        },
        templateUrl: '/app/static/partials/components/kw-dynamic-form.html',
        link: function(scope, elem, attrs) {
            angular.forEach(scope.parameters, function(p) {
                if (p.type == 'matrix') {
                    if (!p.value) p.value = [];
                }
            });
        }
    };
});

module.directive('kwDynamicFormConfig', function() {
    return {
        restrict: 'E',
        scope: {
            parameters: '=kwdfcParameters',
        },
        link: function(scope, elem, attr) {
            scope.types = [
                {display: "Text input", value: "text"},
                {display: "Select (dropdown)", value: "select"},
                {display: "Large text (textarea)", value: "textarea"},
                {display: "Fixed-width matrix", value: "matrix"}
            ];
            scope.newParameter = function() {
                scope.parameters.push({type: 'text', values: [], columnHeader: [], columns: 1 });
            };
        },
        templateUrl: '/app/static/partials/components/kw-dynamic-form-config.html',
    };
});

module.directive('kwMatrixInput', function() {
    return {
        restrict: 'E',
        scope: {
            matrix: '=kwmiModel',
            columnHeader: '=kwmiColumnHeader',
            columns: '=kwmiColumns',
        },
        templateUrl: '/app/static/partials/components/kw-matrix-input.html',
        link: function(scope, elem, attr) {
            scope.columnTemplate = [];
            scope.columnTemplate.length = scope.columns;
            scope.newRow = function() {
                var row = [];
                row.length = scope.columns;
                scope.matrix.push(row);
            };

        }
    };
});

module.directive('bvArrayInput', function() {
    return {
        restrict: 'E',
        scope: {
            array: '=model',
        },
        templateUrl: '/app/static/partials/components/bv-array-input.html',
        link: function(scope, elem, attr) {
            scope.newCol = function() {
                scope.array.push('');
            };
        }
    };
});

module
.directive('kwCheckinMsg', ["$compile", function($compile){

    var msgs = {
        'checkin_fail' : { alert: 'danger',
                short: 'Item NOT checked in.'},
        'not_found' : { alert: 'danger',
                short: 'Barcode not found.'},
        'not_issued' : { alert: 'danger',
                short: 'Not checked out.'},
        'withdrawn' : { alert: 'danger',
                long: 'Item is WITHDRAWN.  Not checked in.',
                short: 'Item WITHDRAWN.'},
        'was_lost' : { alert: 'warning',
                long: "Item was marked lost [{{old_lost}}], now found.",
                short: 'Item was LOST.'},
        'hold_requeued' : { alert: 'danger',
                short: 'Trapped hold requeued.'},
        'unhandled_hold' : { alert: 'warning',
                short: 'Trapped hold ignored.'},
        'unhandled_lost' : { alert: 'warning',
                short: 'Lost Item record retained on patron account.'},
        'was_on_order' : { alert: 'warning',
                short: 'Item was On Order.',
                long: "Item was On Order [{{old_notforloan|displayName:'notforloan'}}], now Available."},
        'unprocessed_callslip' : { alert: 'warning',
                short: 'Requested by callslip.',
                long: 'Item requested by callslip, but not yet processed. '},
        'cancelled_callslip' : { alert: 'warning',
                short: 'Cancelled callslip.',
                long: 'Item routed by cancelled callslip.'},
        'item_status' : {
                short: 'Custom Item Status: {{name}}',
                long: 'Item Status : <em>{{name}}</em> ({{date|kohaDate}}) ' +
                        '<div class="item-attribute" ng-if="desc">{{desc}}</div>' +
                        '<div class="item-attribute">Please follow local procedures for this attribute. </div>'
            },
        'transfer_received' : {
                short: 'Transfer received.'},
        'local_use' : {
                short: 'Item logged for local use statistics.'},
    };

  return {
    restrict: 'A',
    scope: {
        msg: '=kwCheckinMsg',
    },
    link: function(scope,el,attrs){

        if(! msgs[scope.msg.code]) return;

        var alertClass = msgs[scope.msg.code].alert || 'info';
        if(el.hasClass('alert')) alertClass = 'alert-' + alertClass;
        el.addClass(alertClass);

        for ( var p in scope.msg.param ){
            scope[p] = scope.msg.param[p];
        }
        var tmpl = msgs[scope.msg.code].short;
        if( 'verbose' in attrs && msgs[scope.msg.code].long ){
            tmpl = msgs[scope.msg.code].long;
        }
        tmpl = '<span class="' + scope.msg.code + '">' + tmpl + '</span>';
        if(tmpl.match('{{')){
            tmpl = $compile(tmpl)(scope);
        }
        el.append( tmpl );

  }};
}])


.factory('bvStrTmplParse', ["$filter", function($filter){
    function mkRenderer(expr){
        if(expr.charAt(0)=='%'){
            expr = expr.substring(2,expr.length-1);
            var chain = expr.split('|');
            var key = chain.shift();

            return function(data){
                var output = data[key];
                // Empty values are not passed to filters.
                if(output===null || output==='' || output===undefined)
                    return '';
                chain.forEach(function(filterSpec){
                    var filterArgs = filterSpec.split(':');
                    var filterName = filterArgs.shift();
                    try {
                        var filter = $filter(filterName);
                        filterArgs.unshift(output);
                        output = filter.apply(this, filterArgs);
                    } catch ( e ) {
                        console.warn(e);
                        return;
                    }
                });
                return output;
            };
        } else {
            // const
            return function(){ return expr; };
        }
    }
    return function(tmplStr){
        this.parsedTmpl = tmplStr.split(/(%\{[^}]+\})/).filter(function(str){ return str; }).map(
                                function(tmplFrag){ return mkRenderer(tmplFrag); }
                            );
        this.render = function(obj){
            var d = this.parsedTmpl.reduce(function(str,render){
                        return str + render(obj);
                    },'');
            return d;
        }

    }
}])
.directive('bvStrTmpl', ["bvStrTmplParse", function(bvStrTmplParse){
    // simple template string substitution, with $filter access.
    // evaluated against `scope` attr (shallow watched).
    // intended for user-defined template strings.

    // e.g.  <div bv-str-tmpl="tmpl" scope="foo"></div>
    // with $scope.tmpl = "%{name} %{type|wrap:()} -- %{desc|ucfirst}, at %{date|dateFmt:'MM/DD/YYYY'} // %{sz|filesize}";
    //      $scope.foo = { name: 'Example', date: '2019-06-06', desc: 'ok, go.', type: 'thing', sz: 1766339 };

    return {
        scope: {
            tmpl: '@bvStrTmpl',
            scope: '='
        },
            link: function(scope, el, attrs){

                var parsedTmpl;
                scope.$watch('tmpl', function(tmpl){
                    parsedTmpl = new bvStrTmplParse(tmpl);
                    if(scope.scope) el.html(parsedTmpl.render(scope.scope));
                });
                scope.$watch('scope', function(data){
                    el.html(parsedTmpl.render(data));
                });
        }
    };
}])

.directive('kwTriggerable', ["$state", "$window", "kwHotkeySvc", function( $state, $window, kwHotkeySvc){

    // registers hotkey actions with kmHotkeySvc.
    // deregisters when scope destroyed.

    return {
        link: function(scope, el, attrs){
            var key = attrs.kwAssignKey;
            var action;
            if(attrs.kwAction){
                action = function(){ scope.$eval(attrs.kwAction); };
            } else if(attrs.ngClick){
                action = function(){ scope.$eval(attrs.ngClick); };
            } else if(attrs.uiSref){
                var m = attrs.uiSref.split('(');
                var state = m[0];
                var params = (m[1]) ? m[1].substr(0, m[1].length-1) : null;
                var opts = scope.$eval(attrs.uiSrefOpts);
                action = function(){ var eparams=scope.$eval(params);
                    if(attrs.target){
                        $window.open( $state.href( state, eparams, opts ), attrs.target );
                    } else {
                        $state.go(state, eparams, opts);
                    }};
            } else {
                action = function(){ el[0].click(); };
            }
            var assignedKey = kwHotkeySvc.register(attrs.kwTriggerable, action, key);

            if(!key && assignedKey.length==1){
                // note this doesn't handle modifier keys (incl shift), special chars.
                // we should traverse child contents and look at textNodes, but for the moment all
                // uses are just the first text node, so we'll avoid doing this yet.
                var textNode;
                var matchedChar;
                var escapedKey = assignedKey[0].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

                var re = new RegExp(escapedKey,'i');
                var childnodes = $(el).contents();
                for (var i = 0; i < childnodes.length; i++) {
                    if(childnodes[i].nodeType==3){
                        var mtch = childnodes[i].textContent.match( re );
                        if(mtch){
                            textNode = childnodes[i];
                            matchedChar = mtch[0];
                            break;
                        }
                    }
                }

                // this only adds class to the character in menu .
                // should perhaps add key to pull-right element if requested ?

                if(textNode){
                    var splitat = textNode.textContent.search(re);
                    // var classname = (attrs.kwSubmenu) ? 'submenu-hotkey' : 'hotkey';
                    var classname = 'hotkey-key';
                    $(textNode).replaceWith( textNode.textContent.substr(0,splitat) + '<span class="' + classname + '">'+
                        matchedChar + '</span>' + textNode.textContent.substr(splitat+1) );
                }

            }
            // FIXME: there's no protection against multiple registrations, but a single deregistration will remove the handler.
            scope.$on('$destroy', function(){
                kwHotkeySvc.deregister(attrs.kwTriggerable);
            });
        }
    };
}])

.directive('kwOverdrive', ["$uibModal", "kwOverdriveTitleModel", "configService", "alertService", "userService", function( $uibModal, kwOverdriveTitleModel, configService, alertService, userService){
    return {
        scope: {
          kohaBib: '=',
          // previewOnImg: '@'  // look-inside button can find & attach to cover-img el.
        },
        templateUrl: '/app/static/partials/components/overdrive-dl.html',

        link: function(scope, el, attrs){
            scope.config = configService;
            scope.user = userService;

            if( ! configService.overdrive )
                return ;

            var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelvth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
            var deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

            scope.stringifyNum = function(n) {
              if (n < 20) return special[n];
              if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
              return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
            };

            scope.returnTitle = function(odTitle){
                odTitle.returnTitle().then(function(){
                    alertService.add({
                        msg: "Title returned.",
                        type: "success"
                    });
                });
            };
            scope.removeHold = function(odTitle){
                odTitle.removeHold().then(function(){
                    alertService.add({
                        msg: "Your request for this title has been canceled.",
                        type: "success"
                    });
                });
            };
            scope.overdrivePreview = function(){

                if(!scope.odTitle.sampleUri) return;
                $uibModal.open({
                    backdrop: 'static',
                    size: 'overdrive', //expand to near full width
                    window: 'modal',
                    templateUrl: '/app/static/partials/overdrivepreview-modal.html',
                    controller: ["$scope", "$sce", "uri", "title", function($scope,$sce,uri,title){
                        $scope.uri = $sce.trustAsResourceUrl(scope.odTitle.sampleUri);
                        $scope.title = title;
                    }],
                    resolve: {
                        title: function(){
                            return scope.kohaBib.title;
                        },
                        uri : function(){
                            return scope.odTitle.sampleUri;
                        }
                    }
                });
            };
            scope.checkout = function(){
                scope.odTitle.checkout().then(function(){
                    alertService.add({
                        msg: "Title successfully checked out.  Select 'Access Title' to download/access.",
                        type: "success"
                    }), function(fail){
                        console.warn(fail);
                        var msg = fail.message;
                        if(fail.errorCode=="PatronHasExceededCheckoutLimit")
                            msg = "Checkout limit reached.  You must return other digital titles to access this title.";
                        alertService.add({ msg: msg, type: "error" });
                    }
                });
            };
            scope.placeHold = function(){
                // set autoCheckout && email prefs.

                scope.odTitle.placeHold().then(function(){
                    var msg = "Title successfully requested.  When it is available, ";
                    if(userService.email)
                        msg += "You'll receive an email at " + userService.email + ' and ';
                    msg += " you can access the material from this page or your user account page. ";

                    alertService.add({
                        msg: msg,
                        type: "success"
                    });
                });
            };

            scope.formatIcon = {
                ebook: "/app/static/css/overdrive/ebook.png",
                audio: "/app/static/css/overdrive/audio.png",
                video: "/app/static/css/overdrive/video.png",
            };

            var dereg = scope.$watch('kohaBib', function(bib){

                if(bib && bib.marc.subfield("037b") == "OverDrive, Inc."){
                    var prodUUID = bib.marc.subfield("037a");
                    var re =/ContentDetails.htm\?ID=\{(.+)\}/i;
                    var odData = {};
                    bib.marc.fields('856').forEach(function(f){
                        if( (f.subfield("") == "Excerpt" || f.subfield("z") == "Sample") && f.subfield("u")){
                            odData.sampleUri = f.subfield("u").replace('http:','https:');
                        }
                        if(!prodUUID){
                            var m = f.subfield('u').match(re);
                            if(m) prodUUID = m[1];
                        }
                    });

                    if(prodUUID){
                        scope.odTitle = new kwOverdriveTitleModel(prodUUID, odData);

                        scope.$on('loggedin', function(){
                            scope.odTitle.fetching = true;
                            userService.overdrive.patron().finally(function(){
                                scope.odTitle.reload();
                            });
                        });
                        el.removeClass('hidden');

                        if( scope.odTitle.sampleUri){
                            if('previewOnImg' in attrs){
                                var target = el.closest('.cover-panel');
                                if(target.length) {
                                    var previewBtn = $('<a class="btn btn-outline-secondary btn-pill btn-sm od-preview-overlay" ' +
                                        ' title="Look Inside">'+
                                        '<i class="bi bi-search"></i></a>').click(function(e){
                                            scope.overdrivePreview();
                                        });
                                    previewBtn.prependTo(target);
                                    scope.$on('$destroy', function(){
                                        previewBtn.remove();
                                    })
                                } else {
                                    scope.includeSampleBtn = true;
                                }

                            } else {
                                scope.includeSampleBtn = true;
                            }
                        }
                    }

                    dereg();
                }
            });
        }
    };
}])

.directive('bvGlobalBreadcrumbs', ["bvSearchToPick", "kwApi", function(bvSearchToPick, kwApi){
    return {
        scope: true,
        templateUrl: '/app/static/partials/components/global-breadcrumbs.html',
        link: function(scope,el,attrs){
            // We're only doing search-to-pick breadcrumbs for now.

            scope.picker = bvSearchToPick;
            scope.$watch('picker.patronid', function(id){
                if(id){
                    scope.patron_name = id;
                    kwApi.Patron.get({id: id}, function(patron){
                        scope.patron_name = patron.surname + ', ' + patron.firstname;
                    });
                } else {
                    scope.patron_name = null;
                }
            });
        }
    };
}])

.directive('kwNovelistTitleList', function(){
    return {
        scope: {
            titleList: '=',
            showLessNum: '@',
            showMoreNum: '@'
        },
        templateUrl: '/app/static/partials/novelist-title-list.html',

        link: function(scope, el, attrs){
            var showRange = [ attrs.showLessNum||3, attrs.showMoreNum||13];
            scope.showingMore = false;
            scope.numShown = showRange[0];

            scope.$watch('showingMore', function(v){
                scope.numShown = (v) ? showRange[1] : showRange[0];
            });
            scope.$watch('titleList', function(list){
                if(list){
                    scope.hasMore = list.length > showRange[0];
                    list.forEach(function(title){
                        title.author_display = title.full_author || title.author;
                        title.title_display = title.full_title || title.full_name;
                        // TODO; series search link.
                    });
                }
            });

        },
        controller: ["$scope", function($scope){
            $scope.authorSearch = function(author){
                return "/app/search/author:(" + encodeURIComponent(author) + ")";
            };
        }]
    };
})
.directive('kwNovelistRelatedContent', function(){
    return {
        scope: {
            novelistContent: '='
        },
        templateUrl: '/app/static/partials/novelist-related-content.html',

        link: function(scope, el, attrs){
            scope.$watch('novelistContent', function(content){
                if(content && content.length==1) content[0]._showing = true;
            });
        },
        controller: ["$scope", function($scope){

        }]
    };
})
.directive('kwNovelistRelatedContentLink', ["$http", "SearchQuery", "$state", "kwLuceneParser", function( $http, SearchQuery, $state, kwLuceneParser){
    return {
        scope: {
            kwNovelistRelatedContentLink: '='
        },
        template: '<a class="novelist-rc" href ng-click="openModal(clink)">{{clink.title}}</a>',

        link: function(scope, el, attrs){
            scope.$watch('kwNovelistRelatedContentLink', function(content){
                scope.clink = {
                    title: content.title,
                    ui: content.ui,
                    url: content.links[0].url,
                    log_url: content.links[0].log_url
                };
            });
        },
        controller: ["$scope", "$uibModal", function($scope, $uibModal ){
            $scope.openModal = function(clink){
                // renders html content returned from novelist api in modal.
                var novelistui = clink.ui;
                var url = clink.url.replace('http://','//');
                var httpReq = $http.get(url);
                var modal = $uibModal.open({
                    template: '<span class="overlay-closer"><a href ng-click="$close()"><i class="bi bi-x-circle"></i></a></span>' +
                            '<div class="loading" ng-unless="modalContent"></div><iframe class="novelist-content"> </iframe>',
                    windowClass: 'novelistContent novelist-ui-' + novelistui,
                    size: 'lg',
                    controller: ["$scope", "$uibModalInstance", function($scope, $uibModalInstance){
                        httpReq.then(function(){
                            $scope.modalContent = true;
                        });
                    }]
                });
                modal.rendered.then(function(){
                    var $iframe = $('.novelist-ui-' + novelistui + ' iframe');
                    var iframewin = $iframe[0].contentWindow;
                    httpReq.then(function(rsp){

                        // response is json wrapped in parens ( what we supposed to eval it ?!)
                        var json = rsp.data.replace(/^\s*\(|\);\s*$/g, '');

                        try {
                            var htmldata = JSON.parse(json);
                            iframewin.document.write(htmldata.body);
                            $(iframewin.document).find('a').each(function(el){
                                if($(this).attr('data-novelist-custom-link-type')=='AUTILink'){
                                    var query = kwLuceneParser.composeSubqueries(
                                        [
                                            {field: 'author', q: $(this).attr('data-novelist-cl-author')},
                                            {field: 'title', q: $(this).attr('data-novelist-cl-title')},
                                        ]
                                        );
                                    var searchQuery = new SearchQuery( { q: query });
                                    $(this).click(function(){
                                        modal.close();
                                        $state.go( 'search-results.koha', searchQuery.stateParams() );
                                    });
                                }
                            });
                            iframewin.document.close();
                        } catch (e) {
                            console.warn(e);
                            modal.close();
                        }

                    }, function(err){
                        console.warn(err);
                        modal.close();
                    });
                });
            };
        }]
    };
}])
.directive('kwNovelistGoodreads', function(){
    return {
        scope: {
            novelistContent: '='
        },
        template: '<div class="goodreads"><span class="biblabel">GoodReads Rating:</span>' +
            '<rating ng-model="novelistContent.average_rating" readonly="true"></rating>' +
            '<span class="ratings-count">({{novelistContent.ratings_count}})</span>' +
            '<span class="goodreads-link">&nbsp;&nbsp;' +
            '<a href class="btn btn-xs btn-outline-secondary" ng-href="{{url}}">View on good<strong>reads</strong></a>' +
            '</span></div>',
        link: function(scope, el, attrs){
            scope.$watch('novelistContent', function(content){
                scope.url = '//goodreads.com/book/show/' + content.work_id;
            });
        }
    };
})
.directive('kwNovelistLexile', function(){
    return {
        scope: {
            novelistContent: '='
        },
        template: '<div class="novelist-lexile"><span class="biblabel">Reading Level:</span>' +
            '<ul><li ng-show="novelistContent.AudienceLevel"> <span class="audience"> Audience: <span class="value"> {{novelistContent.AudienceLevel}} </span> </span> </li>' +
            '<li ng-show="novelistContent.Lexile"> <span class="lexile"> Lexile: <span class="value"> {{novelistContent.Lexile}} </span>  [Grade: {{gradeLevel}}]</span> </li>' +
            '</ul></div>',
        link: function(scope, el, attrs){
           var lexileRanges = [[190, 530], [420, 650], [520, 820], [740, 940], [830, 1010], [925, 1070], [970, 1120], [1010, 1185], [1050, 1260], [1080, 1335], [1185, 1385], [1185, 1385]];
              // From Common Core (2012) //  i+1 == grade
            scope.$watch('novelistContent', function(content){
                if(content && content.Lexile){
                    var score = content.NumericLexile;
                    if(score < lexileRanges[0][0]){
                        scope.gradeLevel = "< 1";
                    } else if(score > lexileRanges[10][1]){
                        scope.gradeLevel = "12+";
                    } else {
                        var startGrade, endGrade;
                        lexileRanges.forEach(function(range, i){
                            if( range[0] <= content.NumericLexile && content.NumericLexile <= range[1] ){
                                endGrade = i+1;
                                if(!startGrade)
                                    startGrade = i+1;
                            }
                            scope.gradeLevel = (startGrade == endGrade) ? startGrade : ( startGrade + "-" + endGrade);
                      });
                    }

                }
                if(content)
                    scope.url = '//goodreads.com/book/show/' + content.work_id;
            });
        }
    };
})

.directive('logClickEvent', ["Logger", function(Logger) { // current use: log novelist clicks
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.bind('click', function(event) {
                Logger.logEvent(event.toElement.attributes.logurl.value);
            });
        }
    }
}])

.directive('kwMaybeLink', function(  ) {
    return {
        scope: { kwMaybeLink: '@' },
        link: function(scope, el, attrs) {
            scope.$watch('kwMaybeLink', function(uri){
                if(uri){
                    var p = el.parent('a.dynamic-link');
                    if(p.length){
                        p.attr('href', uri);
                    } else {
                        el.wrap('<a href="' + uri + '" class="dynamic-link"></a>');
                    }
                }
            });
        }
    };
})

.directive('bvBibShelfBrowse', ["userService", "kohaDlg", "$http", "configService", function( userService, kohaDlg, $http, configService ) {

    // renders button to open shelf browser from title, with location selection.
    return {
        templateUrl: '/app/static/partials/components/shelfbrowse-launcher.html',
        scope: { bib: '=' },
        link: function(scope, elem, attrs) {
            scope.$watch('bib', function(bib){

                if(!bib) return;
                function getItemAt (loc) {
                    var param = {
                        cn: loc.callnum,
                        align: 0,
                        window_size: 2
                    }
                    return $http.get( '/api/branch/'+loc.branch+'/item-shelf/'+loc.location, {params: param} ).then(
                        function(rsp){
                            if(!rsp.data.length) return null;
                            return parseInt(rsp.data[rsp.data.length-1].item_uri.match(/\d+/));
                        }).catch(function(e){
                            return null;
                        })
                }
                var locations = angular.copy(bib.summary.items_at)
                    .filter(function(w) {
                        if(w.count > w.suppressed_count) return (w.callnum && w.location);
                    });
                scope.location = {
                    preferred : [],
                    elsewhere : [],
                    count : locations.length,
                    showList : false
                };
                if(scope.location.count == 1){
                    getItemAt(locations[0])
                }
                scope.pickLocation = function($event){
                    $event.preventDefault();
                    $event.stopPropagation();
                    scope.location.showList = !scope.location.showList;
                }
                scope.openShelfBrowser = function(loc){
                    if(scope.location.count == 1) loc = locations[0];
                    getItemAt(loc).then(function(itemid){
                        if(itemid)
                            kohaDlg.browseShelf(itemid);
                    })
                    scope.location.showList = false;
                }

                userService.whenAnyUserDetails().then(function(user){

                    locations.forEach(function(loc){
                        loc.branchDisplay = configService.interpolator('branch').display(loc.branch);
                        if( user.branchcode == loc.branch ||
                            configService.search_group.branches.indexOf( loc.branch ) >= 0 ){
                                scope.location.preferred.push(loc);
                        } else {
                            scope.location.elsewhere.push(loc);
                        }
                    });
                    scope.location.preferred.sort(function(a,b) { return (a.branchDisplay < b.branchDisplay ? -1 : a.branchDisplay > b.branchDisplay ? 1 : 0) });
                    scope.location.elsewhere.sort(function(a,b) { return (a.branchDisplay < b.branchDisplay ? -1 : a.branchDisplay > b.branchDisplay ? 1 : 0) });
                    // TODO: if > 5 non-preferred, collapse
                });
            })

        }
    };
}])

.directive('kwStaffNav', ["syncTemplateCache", "kohaDlg", function( syncTemplateCache, kohaDlg) {
    return {
        restrict: 'E',
        template: syncTemplateCache.get('/app/static/partials/staff-nav.html'),
        link: function(scope, elem, attrs) {
            scope.openHotkeyModal = kohaDlg.hotkeyModal;
        }
    };
}])

.directive('kwCloudLibrary', ["userService", "configService", "kwCloudLibraryTitleModel", function (userService, configService, kwCloudLibraryTitleModel) {
    return {
        scope: {
          cloudBib: '=',
        },
        templateUrl: '/app/static/partials/components/cloudLibary-dl.html',
        link: function (scope, el, attrs) {
            scope.config = configService;

            var special = ['zeroth','first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelvth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
            var deca = ['twent', 'thirt', 'fourt', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

            scope.stringifyNum = function(n) {
              if (n < 20) return special[n];
              if (n%10 === 0) return deca[Math.floor(n/10)-2] + 'ieth';
              return deca[Math.floor(n/10)-2] + 'y-' + special[n%10];
            };

            scope.loginDlgOpen = function(){
                userService.whenAuthenticatedUser().then(function(){

                })
            }

            var dereg = scope.$watch('cloudBib', function (bib) {
                if ( bib && bib.marc.subfield("037b") == "cloudLibrary" ){
                    var prodUUID = bib.marc.subfield("037a");

                    if (!prodUUID) {
                        var re = /document_id-(.*)$/i;
                        bib.marc.fields('856').forEach(function(f){
                            var m = f.subfield('u').match(re);
                            if(m) prodUUID = m[1];
                        });
                    }

                    if (prodUUID) {
                        scope.cLTitle = new kwCloudLibraryTitleModel(prodUUID);
                        el.removeClass('hidden');
                    }

                    dereg();
                }
            });
        }
    };
}])


// TODO refactor into module

.directive('communityContentRating', ["communityContentSvc", "userService", function (communityContentSvc, userService) {
    return {
        scope: {
            isbn: '=',
        },
        templateUrl: function(elem,attrs) {
            return '/app/static/partials/components/community-content/rating-' + attrs.role + '.html';
        },
        link: function(scope, elem, attrs) {
            scope.svc = communityContentSvc;
            scope.ratingLoaded = false;
            scope.userRatingLoaded = false;
            scope.user = userService;

            scope.refreshRating = function() {
                communityContentSvc.getRating(scope.isbn).then(function(rv) {
                    scope.ratingLoaded = true;
                    scope.rating = rv;
                });
            };
            scope.refreshRating();

            communityContentSvc.getUserRating(scope.isbn).then(function(rating) {
                var dereg = scope.$watch('rating.rating', function(newVal, oldVal) {
                    if ((oldVal || oldVal === null) && (newVal!==oldVal)) {
                        if (!scope.userRating) {
                            scope.userRating = newVal;
                        }
                        dereg();
                    }
                });

                scope.userRatingLoaded = true;
                scope.userRating = rating;
                scope.$watch('userRating', function(newVal, oldVal) {
                    if (!userService.can({catalogue:{bib_ratings:'modify'}})) return;
                    if (newVal && (newVal !== oldVal)) {
                        communityContentSvc.setUserRating(scope.isbn, newVal).then(function(rv) {
                            scope.refreshRating();
                        });
                    }
                });
            });
        }
    };
}])

.directive('communityContentComments', ["communityContentSvc", "userService", function(communityContentSvc, userService) {
    return {
        scope: {
            isbn: '=',
        },
        templateUrl: function(elem,attrs) {
            return '/app/static/partials/components/community-content/comments-' + attrs.role + '.html';
        },
        link: function(scope, elem, attrs) {
            scope.svc = communityContentSvc;
            scope.commentsLoaded = false;
            scope.user = userService;

            communityContentSvc.getComments(scope.isbn).then(function(comments) {
                scope.commentsLoaded = true;
                scope.comments = comments;
            });
            scope.showComments = false;

            scope.newComment = '';

            scope.submit = function(e) {
                if (e.keyCode == 13 && !e.shiftKey) {
                    scope.submitComment();
                }
            };

            scope.submitComment = function() {
                communityContentSvc.addComment(scope.isbn, scope.newComment).then(function(newComment) {
                    scope.comments.unshift(newComment);
                    scope.newComment = '';
                }, function(err) {
                    // TODO something
                });
            };
        }
    };
}])

.directive('bvMasthead', ["$window", "syncTemplateCache", "$rootScope", function( $window, syncTemplateCache, $rootScope) {
    return {
        scope: false,
        template: syncTemplateCache.get('/app/static/partials/masthead/' + (KOHA.config.MastheadSkin || 'default') + '.html'),
        controller: ["$scope", function($scope) {
            $scope.userjs = function() {
                //console.log("*** onready function");
                $rootScope.$emit('MastheadReady');
                // $window.dispatchEvent(new Event('MastheadReady'));
                $window._mastheadReady = true;

            };
        }],
        link: function(scope, el, attrs) {

/*            console.log("=== bvMasthead link");
            $window.dispatchEvent(new Event('MastheadReady'));
            $window._mastheadReady = true;*/
        },
    };
}])
.directive('isAre', function() {
    return {
        scope: {
            'isAre' : '='
        },
        link: function(scope, el, attrs) {
            scope.$watch('isAre', function(isAre){
                el.text( (isAre==0 || isAre > 1) ? 'are' : 'is' );
            });
        },
    };
})
.directive('bvDateRecur', function() {
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/components/bv-date-recur.html',
        scope: {
            model: '=',  // $resource object.
            withTime: '=',
        },
        link: function(scope, el, attrs){
            scope.weekdays = [
                {id: 1, label: 'Mo'},
                {id: 2, label: 'Tu'},
                {id: 3, label: 'We'},
                {id: 4, label: 'Th'},
                {id: 5, label: 'Fr'},
                {id: 6, label: 'Sa'},
                {id: 7, label: 'Su'},
            ];
            scope.data = {
                pattern: [0,0,0,0,0,0,0],
                lhsLength: 4,
                weekdaySelected: [0,0,0,0,0,0,0],
            };
            scope.patternValid = [true,true,true,true];

            scope.isSet = function(s) {
                if (s === undefined || s === null || s === '') return false;
                if (s === 0 || s === '0') return false;
                return true;
            };

            scope.$watch('model', function(newVal, oldVal) {
                if (scope.dataUpdated) {
                    scope.dataUpdated = false;
                    return;
                }

                if (newVal) {
                    var halves = newVal.split('*');
                    var lhs = halves[0].split(':');
                    var rhs = halves[1].split(':');
                    scope.data.lhsLength = lhs.length;
                    for (var i=0; i<lhs.length; i++) {
                        scope.data.pattern[i] = lhs[i];
                    }
                    for ( i=0; i<rhs.length; i++) {
                        scope.data.pattern[i+lhs.length] = rhs[i];
                    }
                    var days = scope.data.pattern[3].split(',');
                    for ( i=0; i<7; i++) {
                        scope.data.weekdaySelected[i] = false;
                    }
                    days.forEach(function(d) {
                        scope.data.weekdaySelected[d-1] = true;
                    });

                    scope.modelUpdated = true;
                }
            });

            scope.$watch('data', function(newVal, oldVal) {
                if (scope.modelUpdated) {
                    scope.modelUpdated = false;
                    return;
                }
                if (newVal && !angular.equals(oldVal, newVal)) {
                    // Prevent indigestion

                    if (!angular.equals(oldVal.weekdaySelected, newVal.weekdaySelected)) {
                        var days = []
                        for (var i=0;i<7;i++) {
                            if (scope.data.weekdaySelected[i]) {
                                days.push("" + (i+1));
                            }
                        }
                        newVal.pattern[3] = days.join(',');
                        if (newVal.pattern[3] == '')
                            newVal.pattern[3] = '0';
                    }
                    scope.isValid = true;
                    for ( i=0; i<7; i++) {
                        newVal.pattern[i] = newVal.pattern[i].trim();
                        scope.patternValid[i] = true;
                        if (! /^[0-9,]*$/.test(newVal.pattern[i])) {
                            scope.isValid = false;
                            scope.patternValid[i] = false;
                        }
                    }

                    var lhs = [];
                    var rhs = [];
                    for ( i=0; i<newVal.lhsLength; i++)  {
                        lhs.push(newVal.pattern[i] || '0');
                    }
                    for ( i=newVal.lhsLength; i<7; i++) {
                        rhs.push(newVal.pattern[i] || '0'); 
                    }

                    var halves = [];
                    halves[0] = lhs.join(':');
                    halves[1] = rhs.join(':');
                    scope.model = halves.join('*')
                    scope.dataUpdated = true;
                }
            }, true);

            scope.shiftLeft = function() {
                if (scope.data.lhsLength > 1)
                    scope.data.lhsLength = scope.data.lhsLength - 1;
            };

            scope.shiftRight = function() {
                if (scope.data.lhsLength < 4)
                    scope.data.lhsLength = scope.data.lhsLength + 1;
            };
        },
    };
})

.directive('bvUserContent', ["$compile", "configService", function( $compile, configService){
    return {
        restrict: 'A',
        template: '<div class="usercontent"></div>',

        link: function(scope, element, attrs) {

//             // we bypass ng-bind-html and ngSanitize, since we just trustAsHtml()ed anyway.

            var cfg = {
                home: {
                    html: configService.layout.maincontent_html,
                    widgets: ['bvw-carousel']  // any widgets must have templates with same name.
                }
            };
            var contentBlock = attrs.bvUserContent;
            var tmpl = $('<div class="' + contentBlock + '"></div>').html($(cfg[contentBlock].html));

            element.html(tmpl);

            cfg[contentBlock].widgets.forEach(function(targetEl){
                // all widgets are assumed to be restrict: 'E'.
                var directives = tmpl.find(targetEl).wrap('<div class="bvwidget-wrapper"></div>');
                // FIXME:  This will potentially allow users to initiate compilation of attribute-bound
                // directives ... widgets should all be terminal and high priority.
                directives.each(function(i){
                    $compile($(this))(scope.$new());
                });
            });

        }
    };
}])

.directive('bvwCarousel', ["$http", "$q", "kwApi", function($http, $q, kwApi){
    return {
        restrict: 'E',
        templateUrl: '/app/static/partials/components/bvw-carousel.html',
        priority: 1000,
        terminal: true,
        scope: {
            shelfItem: '='
        },
// Note this directive is exposed to end users.

        link: function(scope, el, attrs) {
            // static attributes.
            var crsl = {
                interval: attrs.interval || 0,
                numShown: (attrs.numShown) ? parseInt(attrs.numShown) : 4, // FIXME: Make shrinkable.
                noWrap: attrs.hasOwnProperty('noWrap'),
                maxTitles: attrs.maxTitles || 50,
                searchQuery: attrs.searchQuery,
                searchSort: attrs.searchSort,
                shelfId: attrs.shelfId,
            };
            if(crsl.noWrap){
                el.addClass('no-wrap');
            }
            if(attrs.hasOwnProperty('shelfBrowser')){
                crsl.source = 'shelf-browser';
            } else {
                crsl.source = (crsl.searchQuery) ? 'search' :
                                    (crsl.shelfId) ? 'shelf' : undefined;
            }
            scope.crsl = crsl;
            crsl.slides = [];
        }, controller : ["$scope", function($scope){

            $scope.activeSlide = 0;
            $scope.$watch('crsl', function(crsl){
                if(!crsl) return;

                // a slide has an array of bib identifiers.
                var middleIndex = ($scope.crsl.numShown % 2) ? Math.floor($scope.crsl.numShown / 2) : undefined;
                var makeSlides = function(biblist, direction){
                    // returns an array of arrays.
                    var slides = [];
                    var slide = [];
                    biblist.forEach(function(titlehash, i){
                        if(direction==-1) slide.unshift( titlehash );
                            else slide.push( titlehash );
                        if( i == biblist.length-1 || ! ((i+1) % $scope.crsl.numShown)  ){
                            var fullSlide = angular.copy(slide);
                            // if(middleIndex &&fullSlide.length>middleIndex) fullSlide[middleIndex].classname = 'middle-item';
                            if(direction==-1) slides.unshift( { bibs: fullSlide });
                                else slides.push( { bibs: fullSlide });
                            slide.length = 0;
                        }
                    });
                    return slides;
                };

                // bibs:
                if($scope.crsl.source=='search'){
                    var solrParams = {
                        sort: $scope.crsl.searchSort,
                        rows: $scope.crsl.maxTitles || 50,
                        facet: false,
                        embed: 0,
                        spellcheck: false
                    };
                    // assume search query already encoded.
                    $http.get("/api/opac/"+$scope.crsl.searchQuery, { "params" : solrParams }).
                        then(function(rs){
                            $scope.crsl.slides = makeSlides(rs.data.hits.map(function(hit){ return {bibid: hit.id}; }));
                        });
                } else if($scope.crsl.source=='shelf'){
                    kwApi.WorksList.get({id: $scope.crsl.shelfId}, function(list){
                        console.log(list);
                        $scope.crsl.slides = makeSlides(list.works.map(function(bib){ return { bibid: bib.id}; }));
                    });
                } else if($scope.crsl.source=='shelf-browser' ) {
                    // start with dummy slide, just our bib;  will fill in remainder.
                    // note that the shelf-browser uses {bibid , itemid}, whereas the others just bibid.
                    if( ! $scope.crsl.numShown % 2 ) throw('Shelf browser needs odd number of elements.');
                    var half_slide_length = parseInt($scope.crsl.numShown / 2);
                    var initialItem;
                    $scope.$watch( 'shelfItem', function(item){
                        if(!item) return;

                        initialItem = {itemid: item.id, bibid: item.biblionumber, id: item.biblionumber};
                        var firstSlide = {
                            active: true,
                            bibs : [ initialItem ]
                        };

                        var shelf_size = 70;
                        var uri_to_id = function(uri){ return parseInt(uri.match(/\d+/),10); };
                        var wing_promises = {};
                        [-1,1].forEach(function(align){
                            wing_promises[align] = $http.get('/api/item/'+item.id+'/shelf', { params: {align: align, window_size: shelf_size / 2 }})
                                .then(function(rsp){
                                    var shelfitems = rsp.data.map(function(uris){
                                            return { itemid: uri_to_id(uris.item_uri), bibid: uri_to_id(uris.work_uri) };
                                        }).reduce(function(acc,cv,i){  // dedupe.
                                            var last_title = acc[acc.length-1] || initialItem;
                                            if( last_title.bibid != cv.bibid ){
                                                acc.push(cv);
                                            } else {
                                                last_title.copies = (last_title.copies || 0) + 1;
                                                // FIXME/TODO: not doing anything with this dupecount yet.
                                            }
                                            return acc;
                                        },[]);

                                    var half_first_shelf;
                                    if(align==-1){ // leftward
                                        shelfitems.reverse();
                                        half_first_shelf = shelfitems.splice(0, half_slide_length );
                                        for (var i = 0; i < half_first_shelf.length; i++) {
                                            half_first_shelf[i].id = half_first_shelf[i].bibid;
                                            firstSlide.bibs.unshift(half_first_shelf[i]);
                                        }
                                        return makeSlides(shelfitems,-1);
                                    } else { // rightward
                                        half_first_shelf = shelfitems.splice(0, half_slide_length );
                                        half_first_shelf.forEach(function(itm,i){
                                            itm.id = itm.bibid;
                                            firstSlide.bibs.push(itm);
                                        });
                                        return makeSlides(shelfitems);
                                    }
                                });
                        });
                        $q.all([wing_promises["-1"], wing_promises["1"]]).then(function(wings){
                            $scope.crsl.slides = wings[0].concat([firstSlide], wings[1]);
                            $scope.activeSlide = wings[0].length;
                        });
                    });

                    // we could watch for edges and load more, but we'll just have a large shelf.
                }
            });

        }]
    };
}])
.directive('bvwLazyCoverimgSlide', ["$timeout", function($timeout){
    // lazy loads a slide by waiting until previous slide is active,
    // then setting the bibid.
    // assumes a slide has array of objects 'bibs' with a bibid prop, and we set the id prop.
    // this is done since all these slides are added to the dom by uib-carousel, which would
    // otherwise cause all cover images to load but remain hidden.
    return {
        restrict: 'A',
        require: ['^uibCarousel','^bvwCarousel'],
        scope: {
            slideContents: '=bvwLazyCoverimgSlide',
            myIndex: '@'
        },
        link: function(scope, el, attrs, ctrls ) {
            var carouselCtrl = ctrls[0],
                bvwCarouselCtrl = ctrls[1];
            var parent = el.closest('.carousel');
            var myindex = attrs.myIndex; // static.

            var triggerImg = function(){
                    scope.slideContents.bibs.forEach(function(title){
                        if(!title.id) title.id = title.bibid;
                    });
            };

            var isEdge = (myindex===0 || myindex==carouselCtrl.slides.length);

            var dereg = scope.$watch( carouselCtrl.getCurrentIndex , function(activeIndex){
                // ui-bootstrap's carousel doesn't hide controls when no-wrap.
                if(isEdge){
                    if(activeIndex===0) parent.addClass('first-slide');
                        else parent.removeClass('first-slide');
                    if(activeIndex==carouselCtrl.slides.length-1) parent.addClass('last-slide');
                        else parent.removeClass('last-slide');
                }
                if( Math.abs(activeIndex - myindex) <= 1 ) {
                    // for this, prev, and next slides,
                    // set id, which triggers cover-img directive to load the bib
                    if(activeIndex==myindex){
                        triggerImg();
                    } else {
                        $timeout(function(){
                            triggerImg();
                        });
                    }
                }

                if(!isEdge) dereg();
            });
        }
    };
}])
.directive('backendSortable', function(){
    return {
        link: function(scope, el, attrs) {
            var asc = true;
            var sortspec = scope.$eval(attrs.backendSortable);
            if (sortspec.field == attrs.sortField) {
                var iconName = (sortspec.reverse)? 'icon-sort-up':'icon-sort-down';
                el.append('<i class="sorter icon '+ iconName + '"></i>').addClass('sortable sorted sorted-down');
            } else {
                el.append('<i class="sorter icon icon-sort"></i>').addClass('sortable');
            }

            el.click(function() {
                if(attrs.sortField == sortspec.field) {
                    sortspec.reverse = !sortspec.reverse;
                }
                sortspec.field = attrs.sortField;
                scope.$eval(attrs.sortChanged);

                var whichway = (sortspec.reverse) ? 'icon-sort-up' : 'icon-sort-down';
                var otherway = (sortspec.reverse) ? 'icon-sort-down' : 'icon-sort-up';
                var sortedDir = (sortspec.reverse) ? 'sorted-up' : 'sorted-down';

                el.siblings('[backend-sortable]').removeClass('sorted sorted-down sorted-up').children('i').removeClass('icon-sort-up icon-sort-down').addClass('icon-sort');
                el.removeClass('sorted-up sorted-down').addClass('sorted '+ sortedDir ).children('i').removeClass('icon-sort ' + otherway).addClass( whichway );
            });
        }
    };
})

.directive('bvPluralizeCount', function(){
    // like ng-pluralize, but dumber and less verbose to use.
    // <span bv-pluralize-count="count">thing</span>
    // if pluralization is more complicated than -s -es -or -ies, use ng-pluralize.
    var numStr = ['zero','one','two','three','four','five','six','seven','eight','nine','ten'];

    function naivePluralize (str){
        var esRe = /(s|x|ch|sh|zz)$/;
        return (str.charAt(str.length-1)=='y') ?
                    str.replace(/y$/,'ies') : (esRe.test(str)) ?
                    str.replace(esRe, '$1es') : str + 's';
    }

    return {
        restrict: 'A',
        scope: {
            cnt: '=bvPluralizeCount',
            // asText: '@',    // commented means it's implemented as static attribute.
            // ucfirst: '@'     // mostly for as-text.
        },
        link: function(scope,el,attrs){
            var origText = el.text().trim();
            el.text(' ');
            var pluralText = naivePluralize(origText);
            var cntEl = $('<span class="cnt"></span>').prependTo(el);
            var textEl = $('<span class="text"></span>').appendTo(el);
            var asText = 'asText' in attrs;
            var ucfirst = 'ucfirst' in attrs;
            scope.$watch('cnt', function(cnt){
                if(cnt===undefined) return;
                var num = cnt;
                if(asText && numStr[cnt]){
                    num = numStr[cnt];
                    if(ucfirst) num = num.charAt(0).toUpperCase() + num.slice(1);
                }
                textEl.text( (cnt==1) ? origText : pluralText);
                cntEl.text( num );
            });
        }
    };
})

.directive('bvBibSerialsSummary', ["kwApi", "$q", "configService", "$filter", function(kwApi, $q, configService, $filter) {

    return {
        templateUrl: '/app/static/partials/bib-serials-summary.html',
        scope: {
            iface: '@',
            periodicals: '=', // i.e. kwApi.Periodical.getForBib({bibid: bib.id})
            // expandable: '@'
        },
        link: function(scope,el,attrs) {

            scope.fixedView = !( 'expandable' in attrs );
            scope.view = {
                expanded: scope.fixedView,
            };
            var statusOrder = [ 'arrived', 'expected', 'late', 'missing', 'unavailable', 'deleted', 'claimed' ];
            var branchOrder = configService.interpolator('bv-branch').listCodes();
            // what about sortOwnBranchFirst ?
            //
            scope.staffNote = {};
            scope.publicNote = {};

            scope.$watch('periodicals', function(periodicalsRsrc){
                if(!periodicalsRsrc) return;
                var serialEdCnt = (scope.iface=='staff') ? configService.StaffSerialIssueDisplayCount :
                        configService.OPACSerialIssueDisplayCount;

                if(serialEdCnt){
                    var serialEditions = [];
                    var fetchedEditions = [];
                    scope.totalSubs = 0;
                    var branchSubs = {};
                    periodicalsRsrc.$promise.then(function(periodicals){
                        periodicals.forEach(function(periodical, pnum){
                            fetchedEditions.push( kwApi.Periodical.getSerialEditions({id: periodical.id},
                                function(editions){
                                    // serialEditions are sorted pubdate ASC
                                    serialEditions = serialEditions.concat( editions.slice( -1 * serialEdCnt));
                            }).$promise );
                            // in case of multiple periodicals, we just show pattern info from first.
                            if(pnum===0){
                                var pattern = periodical.receipt_patterns[periodical.pattern_index || 0].pattern;
                                var desc = $filter('bvDateRecur')(pattern);
                                if(desc != "[Invalid Pattern]") scope.pubPatternDesc = desc;
                            }
                            scope.totalSubs += periodical.total_subscriptions;
                            periodical.subscriptions.forEach(function(subSummary, i){
                                kwApi.Subscription.get({ id: subSummary.id, view: 'holdings' }, function(sub){
                                    if (sub.staff_note)
                                        scope.staffNote[sub.branch_code] = sub.staff_note;
                                    if (sub.opac_note)
                                        scope.publicNote[sub.branch_code] = sub.opac_note;
                                    if(branchSubs[sub.branch_code])
                                        branchSubs[sub.branch_code].push(sub);
                                    else
                                        branchSubs[sub.branch_code] = [ sub ];
                                    if(i+1==periodical.subscriptions.length){
                                        scope.branchSubs = [];
                                        configService.interpolator('bv-branch').listCodes().forEach(function(branchcode){
                                            if(branchSubs[branchcode])
                                                scope.branchSubs.push({branch: branchcode, sub: branchSubs[branchcode]});
                                        });
                                    }
                                });
                            });
                        });
                        $q.all(fetchedEditions).then(function(){

                            serialEditions.sort(function(a,b){
                                return (a.publication_date > b.publication_date ? -1 :
                                        a.publication_date < b.publication_date ? 1 : 0);
                            });
                            scope.serialEditions = serialEditions.slice(0, serialEdCnt);
                            scope.serialEditions.forEach(function(issue){
                                // status.branch is obj, status.branches is array.
                                issue.statuses = [];
                                statusOrder.forEach(function(statusCode){
                                    if((issue.count[statusCode]||{}).total){
                                        var stats = issue.count[statusCode];
                                        stats.status = statusCode;
                                        //  serialedition.statuses[i].branches[j].count
                                        stats.branches = [];
                                        branchOrder.forEach(function(branchcode){
                                            if(stats.branch[branchcode]){
                                                stats.branches.push({ code: branchcode, count: stats.branch[branchcode] });
                                            }
                                        });
                                        issue.statuses.push(issue.count[statusCode]);
                                    }
                                });
                            });
                        });
                    });
                }
            });
        }
    };
}])

.directive('bvTextualHoldings', function() {
    return {
        template: '<span class="text-label">{{label}} </span>' +
            '<a ng-show="!fixed" href ng-click="expand=!expand">[{{expand ? "hide" : "show"}}]</a>' +
            '<div ng-show="expand" ng-repeat="h in holdings">' +
            '<span class="holdings-prefix" ng-show="holdings.length>1 || h.tag!=\'866\'">({{h.prefix}})</span> {{h.value}}</div>',
        scope: {
            label: '@',
            marc: '=',
            staff: '@',
            fixed: '@',
        },
        link: function(scope, elem, attrs) {
            var fields = [
                {tag: '866', prefix: 'a'},
                {tag: '867', prefix: 'c'},
                {tag: '868', prefix: 'd'},
            ];

            if (scope.fixed) {
                scope.expand = true;
            }
            else {
                scope.expand = (scope.staff && (scope.staff !== 'false') ? true : false);
            }

            scope.holdings = [];
            scope.$watch('marc', function(newVal) {
                if (newVal) {
                    fields.forEach(function(f) {
                        scope.marc.fields(f.tag).forEach(function(marcField){
                            var subs = [];
                            if (scope.staff) {
                                if (marcField.subfield('x'))
                                    subs.push(marcField.subfield('x'));
                            }
                            else {
                                if (marcField.subfield('z'))
                                    subs.push(marcField.subfield('z'));
                            }
                            if (marcField.subfield('a'))
                                subs.push(marcField.subfield('a'));
                            if (subs.length) {
                                scope.holdings.push({
                                    tag: f.tag,
                                    prefix: f.prefix,
                                    value: subs.join(': '),
                                });
                            }
                        });
                    });
                }
            });
        }
    };
})

// Infrastructural stuff


// Directives to invoke a function call when a template and its children have loaded
//
// 1. Add the following to your template html AT THE BOTTOM
//
//  <bv-async-onready bvao-name="something" bvao-onready="myfunc" depth="2"></bv-async-onready>
//
// where "myfunc" is a function in your template's scope, "something" is an arbitrary name
// shared with bvao-async-onready-await directives, and "2" means that the function should
// fire after children of depth 2 have loaded. Note *should*. This works by enqueueing 
// cache-busted template loads. It should work either with depth- or breadth-first order
// but will NOT work if the backend returns template files severely out of order (see step 2)
//
// 2. (Optional) Preload your templates
//
// Add <!-- PRELOAD --> to any template you want preloaded, or '.preload' in any directory
// where you want all templates preloaded. Rerun wack-concat. Then replace all templateUrl
// statements with
//
//  template: syncTemplateCache.get('/app/static/partials/path/to/template.html');
//
// obviously you'll need to inject syncTemplateCache. Note, this has the side benefit of
// speeding up loading. You can't use ng-include, sorry, you'd need a specific directive
// that can handle a static synchronous template. This DOES NOT interact with $templateCache
// in any way
//
// 3. Use bv-async-onready-await to delay the function call until specific things are done
//
// Let's say you have a directive whose link function does DOM manipulation (questionable
// but okay). You can add bv-async-onready-await to the attributes
//
//  <my-directive bv-async-onready-await="my-directive-ready" bvao-name="something">
//
// then in the link (or post) function for myDirective
//  
// module.directive('myDirective'), function(bvAsyncOnreadySvc) {
//   return {
//     link: function(scope, elem, attrs) {
//       somePromise.then(function() {
//         bvAsyncOnreadySvc.childReady(attrs.bvaoName, attrs.bvAsyncOnreadyAwait);
//       });
//     }
//   }
// });
//          
// The 'pre' function of the bvAsyncOnreadyAwait directive tells the service to wait
// for the child signal

.directive('bvAsyncOnready', ["bvAsyncOnreadySvc", function(bvAsyncOnreadySvc) {
    var cbCount = 0;
    return {
        templateUrl: function(tElem, tAttrs) {
            //console.log(tElem[0].outerHTML);
            //console.log("== bvAsyncOnready " + tAttrs.depth + " compile");
            cbCount++;
            return '/app/static/partials/components/bv-async-onready/' + tAttrs.depth + '.html?' + cbCount;
        },
        scope: {
            name: '@bvaoName',
            onReady: '=bvaoOnready',
        },
        compile: function(elem, attrs, transclude) {
            bvAsyncOnreadySvc.register(attrs.bvaoName);
            //console.log("== bvAsyncOnready " + attrs.depth + " compile");
            return {
                pre: function(scope, elem, attrs) {
                    //console.log("== bvAsyncOnready " + attrs.depth + " pre with name " + scope.name);
                },
                post: function(scope, elem, attrs) {
                    if (scope.onReady) {
                        bvAsyncOnreadySvc.templateReady(scope.name, scope.onReady);
                    }
                    //console.log("== bvAsyncOnready " + attrs.depth + " post with name " + scope.name);
                }
            };
        },
    };
}])

.directive('bvAsyncOnreadyAwait', ["bvAsyncOnreadySvc", function(bvAsyncOnreadySvc) {
    return {
        template: false,
        scope: false,
        restrict: 'A',
        compile: function(tEl, tAt, transclude) {
            return {
                pre: function(scope, elem, attrs) {
                    var name = attrs.bvaoName;
                    var child = attrs.bvAsyncOnreadyAwait;
                    bvAsyncOnreadySvc.child(name, child);
                    //console.log("== bvAsyncOnreadyAwait registering " + child + " in " + name);
                },
                post: function(scope, elem, attrs) {
                    // TODO - support timeout if the normal signal sender never fires
                    //console.log("== bvAsyncOnreadyAwait post");
                }
            };
        },
    };
}])

// Directive pair to intentionally break isolate- or true-scoped directives across generations
// <div bv-register-scope="myscope">
//   <some-isolate-directive>
//     <another-isolate-directive>
//       <target-directive bv-inherit-scope="myscope">
//
// and now target-directive gets all of the div's scope elements as if inherited.


.directive('bvRegisterScope', ["$cacheFactory", function($cacheFactory) {
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, elem, attrs) {
            var cache = $cacheFactory.get('scopeRegistry') || $cacheFactory('scopeRegistry');
            cache.put(attrs.bvRegisterScope, scope);
        },
    };
}])

.directive('bvInheritScope', ["$cacheFactory", function($cacheFactory) {
    return {
        restrict: 'A',
        scope: false,
        link: function(applyTo, elem, attrs) {
            var cache = $cacheFactory.get('scopeRegistry') || $cacheFactory('scopeRegistry');
            var applyFrom = cache.get(attrs.bvInheritScope);
            if (applyFrom) {
                angular.forEach(applyFrom, function(val, key) {
                    if (key.substring(0,1) !== "$" && applyFrom.hasOwnProperty(key) && !applyTo.hasOwnProperty(key)) {
                        applyTo[key] = val;
                    }
                });
            }
        },
    };
}])

.directive('bvSameValueAs', function(){
  return {  // repeat password.
    restrict: 'A',
    require: '?ngModel',
    link: function(scope, elm, attr, ctrl) {
      if (!ctrl) return;

      var otherValue = 0;
      attr.$observe('bvSameValueAs', function(value) {
        otherValue = value;
        ctrl.$validate();
      });
      ctrl.$validators.nomatch = function(modelValue, viewValue) {
        return ctrl.$isEmpty(viewValue) || viewValue == otherValue;
      };
    }
  };

})


.directive('bvMvrForeach', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/foreach.html',
        restrict: 'E',
        scope: {
            i: '=model',
            context: '@',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    marcs: [{v:''}],
                    assertions: [{}],
                };
            }

            if (!scope.o.assertions)
                scope.o.assertions = [{}];
            if (!scope.o.marcs)
                scope.o.marcs = [{v:''}];
            
            scope.addMarc = function() {
                scope.o.marcs.push({v:''});
            };
            scope.deleteMarc = function(n) {
                scope.o.marcs.splice(n,1);
            };

            scope.addAssertion = function() {
                scope.o.assertions.push({});
            };
            scope.deleteAssertion = function(n) {
                scope.o.assertions.splice(n,1);
            };

            var checkValidity;
            checkValidity = function() {
                //console.log("Foreach " + scope.$id + " digest");

                var dsl;
                scope.valid = true;
                scope.o.marcs.forEach(function(m) {
                    if (!(m && m.v)) scope.valid = false;
                });

                var stripped = [];
                var dsl = [];
                for (var i=0; i<scope.o.assertions.length; i++) {
                    var a = scope.o.assertions[i];
                    if (a._valid) {
                        dsl[i] = a._dsl;
                        stripped[i] = angular.copy(a);
                        //delete stripped[i]._dsl;
                        //delete stripped[i]._valid;
                    }
                    else {
                        scope.valid = false;
                    }
                }

                if (scope.valid) {
                    scope.i._valid = true;
                    scope.i.marcs = scope.o.marcs;
                    scope.i.assertions = stripped;

                    scope.i._dsl = "foreach([" 
                        + scope.o.marcs.map(function(e) { return '"' + e.v + '"' }).join(',')
                        + "],\n  "
                        + dsl.map(function(e) { return '  ' + e }).join(' then ')
                        + "\n)\n";
                }
            };
            //scope.$on('mvr-register', function() {
                checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    checkValidity();
                }, true);
                scope.$on('$destroy', dereg);
            //});

        }
    };
})
.directive('bvMvrAssertion', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/assertion.html',
        restrict: 'E',
        scope: {
            i: '=model',
            context: '@',
            withThis: '@',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    message: 'Error occurred',
                    test: {},
                };
            }
            if (!scope.o.test)
                scope.o.test = {};
            if (!scope.o.message)
                scope.o.message = 'Error occurred';

            var checkValidity;
            checkValidity = function() {
                //console.log("Assert " + scope.$id + " digest");

                var dsl;
                scope.valid = (scope.o.message && scope.o.test && scope.o.test._valid);
                
                scope.i._valid = scope.valid;
                if (scope.valid) {
                    scope.i._dsl = "assert(\n    " + scope.o.test._dsl + ",\n    \"" + scope.o.message + "\"\n)";
                    scope.i.message = scope.o.message;
                    var stripped = angular.copy(scope.o.test);
                    //delete stripped._dsl;
                    //delete stripped._valid;
                    scope.i.test = stripped;
                }
            };
            //scope.$on('mvr-register', function() {
                checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    checkValidity();
                }, true);
                scope.$on('$destroy', dereg);
            //});

        }
    };
})

.directive('bvMvrBoolean', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/boolean.html',
        restrict: 'E',
        scope: {
            i: '=model',
            context: '@',
            withThis: '@',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    op: 'and',
                    parameters: [{dsl: '', valid: false}],
                };
            }
            if (!scope.o.parameters)
                scope.o.parameters =  [{}];
            if (!scope.o.op)
                scope.o.op = 'and';

            scope.addValue = function() {
                scope.o.parameters.push({});
            };
            scope.deleteValue = function(n) {
                scope.o.parameters.splice(n,1);
            };

            scope.$watch('o.op', function(newVal) {
                if ((newVal == 'if') || (newVal == 'iff')) {
                    while (scope.o.parameters.length < 2) {
                        scope.o.parameters.push({dsl: ''});
                    }
                    if (scope.o.parameters.length > 2)
                        scope.o.parameters.length = 2;
                }
            });

            var checkValidity;
            checkValidity = function() {
                //console.log("Boolean " + scope.$id + " digest");
                var dsl;
                if (scope.o.op == 'and' || scope.o.op == 'or') {
                    if (scope.o.parameters.length == 1) {
                        dsl = scope.o.parameters[0]._dsl;
                        scope.valid = scope.o.parameters[0]._valid;
                    }
                    else {
                        dsl = '(' + scope.o.parameters.map(function(e) { return e._dsl }).join(' ' + scope.o.op + ' ') + ')';
                        scope.valid = true;
                        scope.o.parameters.forEach(function(t) { if (!t._valid) scope.valid = false });
                    }
                }
                else if (scope.o.op == 'nor') {
                    if (scope.o.parameters.length == 1) {
                        dsl = '(not ' + scope.o.parameters[0]._dsl + ')';
                        scope.valid = scope.o.parameters[0]._valid;
                    }
                    else {
                        dsl = '(not (' + scope.o.parameters.map(function(e) { return e._dsl }).join(' or ') + '))';
                        scope.valid = true;
                        scope.o.parameters.forEach(function(t) { if (!t._valid) scope.valid = false });
                    }
                }
                else if (scope.o.op == 'if') {
                    if (scope.o.parameters.length != 2) {
                        scope.valid = false;
                    }
                    else {
                        dsl = '(' + scope.o.parameters[0]._dsl + ' implies ' + scope.o.parameters[1]._dsl + ')';
                        scope.valid = scope.o.parameters[0]._valid && scope.o.parameters[1]._valid;
                    }
                }
                else if (scope.o.op == 'iff') {
                    if (scope.o.parameters.length != 2) {
                        scope.valid = false;
                    }
                    else {
                        dsl = '(' + scope.o.parameters[0]._dsl + ' <=> ' + scope.o.parameters[1]._dsl + ')';
                        scope.valid = scope.o.parameters[0]._valid && scope.o.parameters[1]._valid;
                    }
                }
                scope.i._valid = scope.valid;
                if (scope.valid) {
                    scope.i._dsl = dsl;
                    scope.i.op = scope.o.op;
                    var stripped = angular.copy(scope.o.parameters);
                    stripped.forEach(function(p) {
                        //delete p._dsl;
                        //delete p._valid;
                    });
                    scope.i.parameters = stripped;
                }

            };
            //scope.$on('mvr-register', function() {
                checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    checkValidity();
                }, true);
                scope.$on('$destroy', dereg);
            //});

        }
    };
})


.directive('bvMvrBaseContextOption', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/base-context-option.html',
        restrict: 'E',
        scope: {
            i: '=model',
            withThis: '@',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    op: (scope.withThis ? 'no(@this)|F' : 'no(X)|F'),
                    xType: (scope.withThis ? '' : 'fields'),
                    xValue: '',
                    yType: '',
                    yValue: '',
                };
            }
            if (!scope.o.op) {
                scope.o.op = (scope.withThis ? 'no(@this)|F' : 'no(X)|F');
                scope.o.xType = (scope.withThis ? '' : 'fields');
                scope.o.yType = '';
            }

            scope.$watch('o.op', function(newVal) {
                if (newVal == 'no(X)|F' || newVal == 'maybe_one(X)|F' || newVal == 'one(X)|F' || newVal == 'any(X)|F') {
                    scope.o.xType = 'fields';
                    scope.o.yType = '';
                }
                else if (newVal == 'no(@this)|F' || newVal == 'maybe_one(@this)|F' || newVal == 'one(@this)|F' || newVal == 'any(@this)|F') {
                    scope.o.xType = '';
                    scope.o.yType = '';
                }
                else if (newVal == 'no(X)|S' || newVal == 'maybe_one(X)|S' || newVal == 'one(X)|S' || newVal == 'any(X)|S') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = '';
                }
                else if (newVal == 'count(X) == Y|F') {
                    scope.o.xType = 'fields';
                    scope.o.yType = 'number';
                }
                else if (newVal == 'count(@this) == Y|F') {
                    scope.o.xType = '';
                    scope.o.yType = 'number';
                }
                else if (newVal == 'count(X) == Y|S') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = 'number';
                }

                else if (newVal == 'no(X,Y)|F' || newVal == 'maybe_one(X,Y)|F' || newVal == 'one(X,Y)|F' || newVal == 'any(X,Y)|F' || newVal == 'all(X,Y)|F') {
                    scope.o.xType = 'fields';
                    scope.o.yType = 'field-subexpr';
                }
                else if (newVal == 'no(@this,Y)|F' || newVal == 'maybe_one(@this,Y)|F' || newVal == 'one(@this,Y)|F' || newVal == 'any(@this,Y)|F' || newVal == 'all(@this,Y)|F') {
                    scope.o.xType = '';
                    scope.o.yType = 'field-subexpr';
                }
                else if (newVal == 'no(X,Y)|S' || newVal == 'maybe_one(X,Y)|S' || newVal == 'one(X,Y)|S' || newVal == 'any(X,Y)|S' || newVal == 'all(X,Y)|S') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = 'subfield-subexpr';
                }

                else if (newVal == 'X') {
                    scope.o.xType = 'boolean';
                    scope.o.yType = '';
                }
                else {
                    console.log("?? " + newVal);
                }
            });

            var checkValidity;
            checkValidity = function() {
                var x = '', y = '';
                scope.valid = true;
                var xStripped = scope.o.xValue;
                var yStripped = scope.o.yValue;

                if (scope.o.xType == 'fields') {
                    if (typeof(scope.o.xValue) !== 'string') scope.o.xValue = '';
                    if (scope.o.xValue && scope.o.xValue !== '')
                        x = scope.o.xValue;
                    else
                        scope.valid = false;
                }
                else if (scope.o.xType == 'subfields') {
                    if (typeof(scope.o.xValue) !== 'string') scope.o.xValue = '';
                    if (scope.o.xValue && scope.o.xValue !== '')
                        x = scope.o.xValue;
                    else
                        scope.valid = false;
                }
                else if (scope.o.xType == 'boolean') {
                    if (typeof(scope.o.xValue) !== 'object') scope.o.xValue = {};
                    if (scope.o.xValue._valid) {
                        x = scope.o.xValue._dsl;
                        xStripped = angular.copy(scope.o.xValue);
                        //delete xStripped._dsl;
                        //delete xStripped._valid;
                    }
                    else
                        scope.valid = false;
                }

                if (scope.o.yType == 'number') {
                    if (typeof(scope.o.yValue) !== 'string') scope.o.yValue = '';
                    if (/^\-?[0-9]+(\.[0-9]+)?$/.test(scope.o.yValue))
                        y = scope.o.yValue;
                    else 
                        scope.valid = false;
                }
                else if (scope.o.yType == 'field-subexpr' || scope.o.yType == 'subfield-subexpr') {
                    if (typeof(scope.o.yValue) !== 'object') scope.o.yValue = {};
                    if (scope.o.yValue._valid) {
                        y = scope.o.yValue._dsl;
                        yStripped = angular.copy(scope.o.yValue);
                        //delete yStripped._dsl;
                        //delete yStripped._valid;
                    }
                    else
                        scope.valid = false;
                }

                scope.i._valid = scope.valid;
                if (scope.valid) {
                    scope.i._dsl = scope.o.op.replace(/\|.*/,'').replace('X',x).replace('Y',y);
                    if (!( /^\(.*\)$/.test(scope.i._dsl)))
                        scope.i._dsl = '(' + scope.i._dsl + ')';
                    scope.i.op = scope.o.op;
                    scope.i.xValue = xStripped;
                    scope.i.yValue = yStripped;
                    scope.i.xType = scope.o.xType;
                    scope.i.yType = scope.o.yType;
                }
            };
            //scope.$on('mvr-register', function() {
                checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    checkValidity();
                }, true);
                scope.$on('$destroy', dereg);
            //});

        }
    };
})

.directive('bvMvrFieldContextOption', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/field-context-option.html',
        restrict: 'E',
        scope: {
            i: '=model',
            withThis: '@',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    op: (scope.withThis ? 'no(@this)' : 'no(X)'),
                    xType: (scope.withThis ? '' : 'subfields'),
                    xValue: '',
                    yType: '',
                    yValue: '',
                };
            }
            if (!scope.o.op) {
                scope.o.op = (scope.withThis ? 'no(@this)' : 'no(X)');
                scope.o.xType = (scope.withThis ? '' : 'subfields');
                scope.o.yType = '';
            }

            scope.$watch('o.op', function(newVal) {
                if (newVal == 'no(X)' || newVal == 'maybe_one(X)' || newVal == 'one(X)' || newVal == 'any(X)') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = '';
                }
                else if (newVal == 'no(@this)' || newVal == 'maybe_one(@this)' || newVal == 'one(@this)' || newVal == 'any(@this)') {
                    scope.o.xType = '';
                    scope.o.yType = '';
                }
                else if (newVal == 'count(X) == Y') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = 'number';
                }
                else if (newVal == 'count(@this) == Y') {
                    scope.o.xType = '';
                    scope.o.yType = 'number';
                }

                else if (newVal == 'no(X,Y)' || newVal == 'maybe_one(X,Y)' || newVal == 'one(X,Y)' || newVal == 'any(X,Y)' || newVal == 'all(X,Y)') {
                    scope.o.xType = 'subfields';
                    scope.o.yType = 'subexpr';
                }
                else if (newVal == 'no(@this,Y)' || newVal == 'maybe_one(@this,Y)' || newVal == 'one(@this,Y)' || newVal == 'any(@this,Y)' || newVal == 'all(@this,Y)') {
                    scope.o.xType = '';
                    scope.o.yType = 'subexpr';
                }

                else if (newVal == 'indicator1 == X' || newVal == 'indicator1 != X' || newVal == 'indicator2 == X' || newVal == 'indicator2 != X') {
                    scope.o.xType = 'indicator';
                    scope.o.yType = '';
                }
                else if (newVal == 'indicators == X' || newVal == 'indicators != X') {
                    scope.o.xType = 'indicators';
                    scope.o.yType = '';
                }
                else if (newVal == 'X') {
                    scope.o.xType = 'boolean';
                    scope.o.yType = '';
                }
                else {
                    console.log("?? " + newVal);
                }
            });

            var checkValidity;

            checkValidity = function() {
                    //console.log("Field context " + scope.$id + " digest");
                    var x = '', y = '';
                    scope.valid = true;
                    var xStripped = scope.o.xValue;
                    var yStripped = scope.o.yValue;
                    if (scope.o.xType == 'subfields') {
                        if (typeof(scope.o.xValue) !== 'string') scope.o.xValue = '';
                        if (scope.o.xValue && scope.o.xValue !== '')
                            x = scope.o.xValue;
                        else
                            scope.valid = false;
                    }
                    else if (scope.o.xType == 'indicator') {
                        if ((typeof(scope.o.xValue) !== 'string') || (scope.o.xValue.length != 1)) scope.o.xValue = '';
                        if (/^[0-9.#]$/.test(scope.o.xValue))
                            x = scope.o.xValue;
                        else
                            scope.valid = false;
                    }
                    else if (scope.o.xType == 'indicators') {
                        if ((typeof(scope.o.xValue) !== 'string') || (scope.o.xValue.length != 2)) scope.o.xValue = '';
                        if (/^[0-9.#][0-9.#]$/.test(scope.o.xValue))
                            x = scope.o.xValue;
                        else
                            scope.valid = false;
                    }
                    else if (scope.o.xType == 'boolean') {
                        if (typeof(scope.o.xValue) !== 'object') scope.o.xValue = {};
                        if (scope.o.xValue._valid) {
                            x = scope.o.xValue._dsl;
                            xStripped = angular.copy(scope.o.xValue);
                            //delete xStripped._dsl;
                            //delete xStripped._valid;
                        }
                        else
                            scope.valid = false;
                    }

                    if (scope.o.yType == 'number') {
                        if (typeof(scope.o.yValue) !== 'string') scope.o.yValue = '';
                        if (/^\-?[0-9]+(\.[0-9]+)?$/.test(scope.o.yValue))
                            y = scope.o.yValue;
                        else
                            scope.valid = false;
                    }
                    else if (scope.o.yType == 'subexpr') {
                        if (typeof(scope.o.yValue) !== 'object') scope.o.yValue = {};
                        if (scope.o.yValue._valid) {
                            y = scope.o.yValue._dsl;
                            yStripped = angular.copy(scope.o.yValue);
                            //delete yStripped._dsl;
                            //delete yStripped._valid;
                        }
                        else
                            scope.valid = false;
                    }

                    scope.i._valid = scope.valid;
                    if (scope.valid) {
                        scope.i._dsl = scope.o.op.replace('X',x).replace('Y',y);
                        if (!( /^\(.*\)$/.test(scope.i._dsl)))
                            scope.i._dsl = '(' + scope.i._dsl + ')';
                        scope.i.op = scope.o.op;
                        scope.i.xValue = xStripped;
                        scope.i.yValue = yStripped;
                        scope.i.xType = scope.o.xType;
                        scope.i.yType = scope.o.yType;
                    }
                    
                };
                //scope.$on('mvr-register', function() {
                    //console.log("Field register");
                checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    checkValidity();
                }, true);
                scope.$on('$destroy', dereg);
            //});
        }
    };
})

.directive('bvMvrSubfieldContextOption', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/subfield-context-option.html',
        restrict: 'E',
        scope: {
            i: '=model',
        },
        link: function(scope, elem, attr) {
            if (scope.i && typeof(scope.i) == 'object') {
                scope.o = angular.copy(scope.i);
                scope.valid = scope.i._valid;
            }
            else {
                scope.i = {};
                scope.o = {
                    op: 'this == X',
                    xType: 'string',
                    xValue: '',
                };
            }
            if (!scope.o.op) {
                scope.o.op = 'this == X';
                scope.o.xType = 'string';
            }
                
            scope.xTypeOptions = [];

            scope.$watch('o.op', function(newVal) {
                if (newVal == 'this == X' || newVal == 'this != X') {
                    scope.xTypeOptions = [
                        {v:'string',d:'string'},
                        {v:'subfield',d:'subfield in this field'},
                        {v:'field',d:'another field/subfield'},
                    ];
                }
                else if (newVal == 'this =~ X' || newVal == 'this !~ X') {
                    scope.xTypeOptions = [
                        {v:'regex',d:'regular expression'},
                    ];
                }
                else if (newVal == 'length(this) == X' || newVal == 'length(this) < X' || newVal == 'length(this) > X') {
                    scope.xTypeOptions = [
                        {v:'number',d:'number'},
                    ];
                }
                else if (newVal == 'X') {
                    scope.xTypeOptions = [];
                    scope.o.xType = 'boolean';
                }
                else {
                    scope.xTypeOptions = [];
                }
            });


            scope.checkValidity = function() {
                //console.log("Subfield context " + scope.$id + " digest");
                var xStripped = scope.o.xValue, dsl = '';
                scope.valid = true;
                if (scope.o.xType == 'string') {
                    dsl = '"' + scope.o.xValue + '"';
                }
                else if (scope.o.xType == 'regex') {
                    if (scope.o.xValue !== '')
                        dsl = '"' + scope.o.xValue + '"';
                    else
                        scope.valid = false;
                }
                else if (scope.o.xType == 'number') {
                    if (/^\-?[0-9]+(\.[0-9]+)?$/.test(scope.o.xValue))
                        dsl = scope.o.xValue;
                    else
                        scope.valid = false;
                }
                else if ((scope.o.xType == 'field') || (scope.o.xType == 'subfield')) {
                    if (scope.o.xValue && scope.o.xValue !== '')
                        dsl = scope.o.xValue;
                    else
                        scope.valid = false;
                }
                else if (scope.o.xType == 'boolean') {
                    if (typeof(scope.o.xValue) !== 'object') scope.o.xValue = {};
                    if (scope.o.xValue._valid) {
                        dsl = scope.o._dsl;
                        xStripped = angular.copy(scope.o);
                        //delete scope.o._dsl;
                        //delete scope.o._valid;
                    }
                    else
                        scope.valid = false;
                }
                else {
                    scope.valid = false;
                }
                scope.i._valid = scope.valid;
                if (scope.valid) {
                    scope.i._dsl = '(' + scope.o.op.replace('X',dsl) + ')';
                    scope.i.op = scope.o.op;
                    scope.i.xValue = xStripped;
                    scope.i.xType= scope.o.xType;
                }

            };
            //scope.$on('mvr-register', function() {
                scope.checkValidity();

                var dereg = scope.$watch('o', function(newVal, oldVal) {
                    if (!(newVal && !angular.equals(newVal, oldVal))) return;

                    scope.checkValidity();
                }, true);
                scope.$on('$destroy', function() {
                    //console.log("Subfield DEregister");
                    dereg();
                });
            //});
        }
    };
})

.directive('bvMvrInputMarc', function() {
    return {
        templateUrl: '/app/static/partials/staff/tools/mvr/inc/marc-input.html',
        restrict: 'E',
        scope: {
            x: '=model',
            withField: '@',
            withSubfield: '@',
            withSubfields: '@',
            skipAt: '@',
        },
        link: function(scope, elem, attr) {
            var m;

            var parseMarc = function(s) {
                // This could probably be done with a single regex but I am beyond fried right now
                if (scope.skipAt)
                    s = '@' + s;
                if (typeof(s) !== 'string') {
                    return false;
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])\|([0-9.#])([0-9.#])([$^])\[([^\]]+)\]$/)) && scope.withField && scope.withSubfields) {
                    return {tag: m[1], ind1: m[2], ind2: m[3], subType: m[4], sub: m[5]};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])\|([0-9.#])([0-9.#])([$^])(.)$/)) && scope.withField && (scope.withSubfield || scope.withSubfields)) {
                    return {tag: m[1], ind1: m[2], ind2: m[3], subType: m[4], sub: m[5]};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])([$^])\[([^\]]+)\]$/)) && scope.withField && scope.withSubfields) {
                    return {tag: m[1], ind1: '', ind2: '', subType: m[2], sub: m[3]};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])([$^])(.)$/)) && scope.withField && (scope.withSubfield || scope.withSubfields)) {
                    return {tag: m[1], ind1: '', ind2: '', subType: m[2], sub: m[3]};
                }
                else if ((m = s.match(/^([$^])\[([^\]]+)\]$/)) && !scope.withField && scope.withSubfields) {
                    return {tag: '', ind1: '', ind2: '', subType: m[1], sub: m[2]};
                }
                else if ((m = s.match(/^([$^])(.)$/)) && !scope.withField && (scope.withSubfield || scope.withSubfields)) {
                    return {tag: '', ind1: '', ind2: '', subType: m[1], sub: m[2]};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])\|([0-9.#])([0-9.#])$/)) && scope.withField && !scope.withSubfields && !scope.withSubfield) {
                    return {tag: m[1], ind1: m[2], ind2: m[3], subType: '', sub: ''};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])$/)) && scope.withField && !scope.withSubfields && !scope.withSubfield) {
                    return {tag: m[1], ind1: '', ind2: '', subType: '', sub: ''};
                }
                else if ((m = s.match(/^\@([0-9.][0-9.][0-9.])([$^])(.)$/)) && scope.withField && (scope.withSubfield || scope.withSubfields)) {
                    return {tag: m[1], ind1: '', ind2: '', subType: m[2], sub: m[3]};
                }
                else {
                    return false;
                }
            };

            scope.v = {tag: '', ind1: '', ind2: '', sub: '', subType: '$'};
            var t = parseMarc(scope.x);
            if (t) {
                if (scope.withField) {
                    scope.v.tag = t.tag;
                    scope.v.ind1 = t.ind1;
                    scope.v.ind2 = t.ind2;
                }
                if (scope.withSubfields) {
                    scope.v.subType = t.subType;
                    scope.v.sub = t.sub;
                }
                else if (scope.withSubfield) {
                    scope.v.subType = '$';
                    scope.v.sub = t.sub.substr(0,1);
                }
            }

            scope.$watch('v', function(newVal, oldVal) {
                if (!(newVal && oldVal && !angular.equals(newVal,oldVal))) return;

                var s = '';
                if (scope.withField) {
                    if (!scope.skipAt)
                        s = s + '@';
                    s = s + scope.v.tag;
                    if (scope.v.ind1 !== '' || scope.v.ind2 !== '') {
                        s = s + '|' 
                            + (scope.v.ind1 !== '' ? scope.v.ind1 : '*')
                            + (scope.v.ind2 !== '' ? scope.v.ind2 : '*');
                    }
                }
                if (scope.withSubfields) {
                    if ((scope.v.sub.length > 1) || (scope.v.sub == '['))
                        s = s + scope.v.subType + '[' + scope.v.sub + ']';
                    else
                        s = s + scope.v.subType + scope.v.sub;
                }
                else if (scope.withSubfield) {
                    s = s + scope.v.subType + scope.v.sub.substr(0,1);
                }

                if (parseMarc(s)) {
                    scope.x = s;
                    scope.valid = true;
                }
                else {
                    scope.x = false;
                    scope.valid = false;
                }
            }, true);

        }
    };
})
;

})();

(function () {
'use strict';

angular.module('kohastaff.apps', []).config(["staffLegacyAppProvider", "$stateProvider", function(staffLegacyAppProvider, $stateProvider) {
    
    staffLegacyAppProvider

    //================= Admin ===============
    .appState('admin', {
        title: 'Administration',
        permissions: {parameters: '*'},
        url: '/admin',
        templateUrl: 'admin.html',
        controller: 'StaffAdministrationCtrl',
        matchRoute: {
            path: '/bvcgi/admin/admin-home.pl',
        },
        widget: {
            addToSidebar: false
        }
    })
    .appState('admin.sysprefs', {
        title: 'Global System Preferences',
        permissions: {parameters: 'syspref'},
        url: '/sysprefs?tab&op',
        legacyUrl: '/admin/systempreferences.pl',
        matchRoute: function(params) {
            return ('searchfield' in params ? false : true);
        },
        widget: {
            group: 'global',
            description: 'Manage global system preferences like MARC flavor, date format, administrator email, etc.',
        }
    })
    .appState('admin.sysprefs-search', {
        title: 'Search System Preferences',
        permissions: {parameters: 'syspref'},
        url: '/sysprefs/:searchfield?tab&op',
        legacyUrl: '/admin/systempreferences.pl',
        matchRoute: function(params) {
            return ('searchfield' in params ? true : false);
        },
        widget: {
            group: 'global',
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix col-sm-12',
            templateUrl: '/app/static/partials/staff/tabs/sysprefSearchTab.html',
            addToSidebar: false,
        }
    })
    .appState('admin.branches', {
        title: 'Libraries and Groups',
        permissions: {parameters: {branch: '*'}},
        url: '/branches?op&branchcode&branchname&categorycode', 
        legacyUrl: '/admin/branches.pl',
        matchRoute: true,
        widget: {
            group: 'basic',
            description: 'Define libraries and groups',
        }
    })
    .widget('admin.vendors', {
        title: 'Vendors',
        permissions: {parameters: {branch: '*'}},
        group: 'basic',
        description: 'Define vendors',
        displayIf: function() { return KOHA.config.AcqLists; },

        //registerState: false,
        //matchRoute: false,
        //widget: {}
    })
    .appState('admin.itemtypes', {
        title: 'Item Types',
        permissions: {parameters: 'item'},
        url: '/itemtypes?op&itemtype&page',
        legacyUrl: '/admin/itemtypes.pl',
        matchRoute: true,
        widget: {
            group: 'basic',
            description: 'Define item types used for circulation rules',
        }
    })
    .appState('admin.itemfields', {
        title: 'Item Fields',
        permissions: {parameters: 'item'},
        url: '/itemfields',
        controller: 'StaffAdminItemFieldsCtrl',
        templateUrl: 'itemfields.html',
        matchRoute: false,
        widget: {
            group: 'basic',
            description: 'Define custom item-level data',
        }
    })
    .appState('admin.marcdisplaytmpl', {
        title: 'MARC Display Templates',
        permissions: 'parameters',
        url: '/marc-display-tmpl',
        controller: 'StaffMarcDisplayTmplCtrl',
        templateUrl: 'admin/marc-display-tmpl.html',
        matchRoute: false,
        widget: {
            group: 'catalog',
            description: 'Create templates that define how MARC data is displayed; ' +
                         'Assign templates to control search results and detail views.',
        }
    })
    .appState('admin.itemstatusconfig', {
        title: 'Custom Item Statuses',
        permissions: {parameters: 'item'},
        url: '/itemstatusconfig',
        controller: 'StaffAdminItemStatusConfigCtrl',
        templateUrl: 'itemstatusconfig.html',
        matchRoute: false,
        widget: {
            group: 'basic',
            description: 'Define custom item statuses that affect circulation ',
        }
    })
    .appState('admin.accounttypes', {
        title: 'Invoice Account Types',
        permissions: {parameters: 'invoice'},
        url: '/accounttypes',
        controller: 'StaffAdminAccountTypesCtrl',
        templateUrl: 'admin/accounttypes-config.html',
        matchRoute: false,
        widget: {
            group: 'circ',
            description: 'Define invoice / fee types and parameters',
        }
    })
    .appState('admin.categories', {
        title: 'Patron Categories',
        permissions: {parameters: 'patron'},
        url: '/categories?op&categorycode',
        legacyUrl: '/admin/categorie.pl',
        matchRoute: true,
        widget: {
            group: 'patrons',
            description: 'Define patron categories',
        }
    })
    .appState('admin.cities', {
        title: 'Cities and Towns',
        permissions: {parameters: 'location'},
        url: '/cities?op&city_name&city_zipcode&cityid',
        legacyUrl: '/admin/cities.pl',
        matchRoute: true,
        widget: {
            group: 'patrons',
            description: 'Define cities and towns that your patrons live in',
        }
    })
    .appState('admin.roadtype', {
        title: 'Road Types',
        permissions: {parameters: 'location'},
        url: '/roadtype?op&roadtypeid&road_type',
        legacyUrl: '/admin/roadtype.pl',
        matchRoute: true,
        widget: {
            group: 'patrons',
            description: 'Define road types (street, avenue, way, etc.). Road types display as authorized values when adding/editing patrons and can be used in geographic statistics',
        }
    })
    .appState('admin.patron-attr-types', {
        title: 'Patron Attribute Types',
        permissions: {parameters: 'patron'},
        url: '/patron-attr-types?op&code',
        legacyUrl: '/admin/patron-attr-types.pl',
        matchRoute: true,
        widget: {
            group: 'patrons',
            description: 'Define extended attributes (identifiers and statistical categories) for patron records',
        }
    })
    .widget('admin.circ.rules', {
        title: 'Circulation Policies',
        permissions: {parameters: {circ_policy: '*'}},
        group: 'circ',
        description: 'Manage circulation/fines policies, rules, recall rules, and terms',
    })
    .appState('admin.circ-exceptions', {
        title: 'Circulation Exceptions',
        permissions: {parameters: 'circ_exception'},
        url: '/circ-exceptions?op',
        legacyUrl: '/admin/circ_exceptions.pl',
        matchRoute: true,
        widget: {
            group: 'circ',
            description: 'Manage behavior when circulation exceptions arise',
        }
    })
    .appState('admin.transfer-limits', {
        title: 'Library Transfer Limits',
        permissions: {parameters: {transfer_limit: '*'}},
        url: '/transfer-limits',
        legacyUrl: '/admin/branch_transfer_limits.pl',
        matchRoute: true,
        widget: {
            group: 'circ',
            description: 'Limit the ability to transfer items between libraries based on the library sending, the library receiving, and the item type involved. These rules only go into effect if the preference UseBranchTransferLimits is set to ON.',
        }
    })
    .appState('admin.item-circ-alerts', {
        title: 'Item Circulation Alerts',
        permissions: {parameters: {circ_alert: '*'}},
        url: '/item-circ-alerts?id&branch&action',
        legacyUrl: '/admin/item_circulation_alerts.pl',
        matchRoute: true,
        widget: {
            group: 'circ',
            description: 'Define rules for check-in and checkout notifications for combinations of libraries, patron categories, and item types',
        }
    })
    .appState('admin.fine-threshold-rules', {
        title: 'Fine Threshold Rules',
        permissions: {parameters: {fine_rule: '*'}},
        url: '/fine-threshold-rules?id&branch&branchcode&op&type',
        legacyUrl: '/admin/fine-threshold-rules.pl',
        matchRoute: true,
        widget: {
            group: 'circ',
            description: 'Set up thresholds to forgive fines based on assigned parameters.',
        }
    })
    .appState('admin.authvals', {
        title: 'Authorized Values',
        permissions: {parameters: 'authval'},
        url: '/authvals?op&id&category&searchfield&page',
        legacyUrl: '/admin/authorised_values.pl',
        matchRoute: true,
        widget: {
            group: 'catalog',
            description: 'Define categories and authorized values for them.',
        }
    })
    .appState('admin.class-sources', {
        title: 'Classification Sources',
        permissions: {parameters: 'cataloging'},
        url: '/class-sources?op&sort_rule&class_source',
        legacyUrl: '/admin/classsources.pl',
        matchRoute: true,
        widget: {
            group: 'catalog',
            description: 'Define classification sources (i.e., call number schemes) used by your collection. Also define filing rules used for sorting call numbers.',
        }
    })
    .appState('admin.matching-rules', {
        title: 'Record Matching Rules',
        permissions: {parameters: 'cataloging'},
        url: '/matching-rules.pl?op&matcher_id',
        legacyUrl: '/admin/matching-rules.pl',
        matchRoute: true,
        widget: {
            group: 'catalog',
            description: 'Manage rules for automatically matching MARC records during record imports.',
        }
    })
    .appState('admin.z3950-targets', {
        title: 'Z39.50 Client Targets',
        permissions: {parameters: 'netsvc'},
        url: '/z3950-targets?op&offset&searchfield',
        legacyUrl: '/admin/z3950servers.pl',
        matchRoute: true,
        widget: {
            group: 'additional',
            description: 'Define which servers to query for MARC data in the integrated Z39.50 client.',
        }
    })
    .widget('admin.ncip.agencies', {
        title: 'NCIP Config',
        permissions: {parameters: {netsvc: '*'}},
        group: 'additional',
        description: 'Manage NCIP configuration',
    })
    .widget('admin.cron.index', {
        title: 'Cron Scripts',
        permissions: {admin: 'cron_jobs'},
        description: 'Manage system cron scripts',
        group: 'additional',
    })
    .widget('admin.appconfig', {
        title: 'Discovery Layer Configuration',
        permissions: { admin: '*' },
        description: 'Configure Discovery Layer interface',
        group: 'additional'
    })
    //================= Reports ===============
    
    .appState('reports', {
        title: 'Reports',
        permissions: {reports: '*'},
        url: '/reports',
        templateUrl: 'reports.html',
        controller: 'StaffReportsCtrl',
        matchRoute: {
            path: '/bvcgi/admin/reports-home.pl',
        },
        widget: {
            addToSidebar: false
        }
    })
    .widget('reports.sql.index', {
        title: 'SQL Reports',
        permissions: {reports: 'run'},
        group: 'guided',
        description: 'Manage or run guided and SQL reports',
    })
    .widget('reports.sql.new', {
        title: 'New SQL Report',
        permissions: {reports: 'run'},
        group: 'guided',
        description: 'Manage or run guided and SQL reports',
    })
    .appState('reports.guided-build', {
        title: 'Guided Reports Wizard',
        url: '/guided-build?phase&reports&tag&specify_parameters&specified_param&areas&order_by&page&id&submit',
        permissions: {reports: 'run'},
        legacyUrl: '/reports/guided_reports.pl',
        matchRoute: true,
        widget: {
            group: 'guided',
            description: 'Build a new guided report',
        }
    })
    .appState('reports.dictionary', {
        title: 'Dictionary',
        url: '/dictionary.pl?phase&id',
        legacyUrl: '/reports/dictionary.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'dictionary',
            description: 'Use the dictionary to define custom criteria for reporting',
        }
    })
    .appState('reports.patron-stats', {
        title: 'Patron Stats',
        url: '/patron-stats',
        legacyUrl: '/reports/borrowers_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'stats',
            description: 'Generate statistics about patrons',
        }
    })
    .appState('reports.catalog-stats', {
        title: 'Catalog Stats',
        url: '/catalog-stats',
        legacyUrl: '/reports/catalogue_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'stats',
            description: 'Generate statistics about catalog records',
        }
    })
    .appState('reports.circ-stats', {
        title: 'Circulation Stats',
        url: '/circ-stats',
        legacyUrl: '/reports/issues_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'stats',
            description: 'Generate statistics about item circulation',
        }
    })
    /* FIXME - need a replacement report
    .appState('reports.serials-stats', {
        title: 'Serials Stats',
        url: '/serials-stats',
        legacyUrl: '/reports/serials_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'stats',
            description: 'Generate statistics about serials subscriptions',
        }
    }) */
    .appState('reports.issues-avg-stats', {
        title: 'Average Loan Time',
        url: '/issues-avg-stats',
        legacyUrl: '/reports/issues_avg_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'stats',
            description: 'Generate statistics about average checkout periods',
        }
    })
    .appState('reports.inactive-patrons', {
        title: 'Inactive Patrons',
        url: '/inactive-patrons',
        legacyUrl: '/reports/borrowers_out.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'inactive',
            description: 'Find patrons with no checkouts',
        }
    })
    .appState('reports.inactive-items', {
        title: 'Inactive Items',
        url: '/inactive-items',
        legacyUrl: '/reports/catalogue_out.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'inactive',
            permissions: 'reports',
        }
    })
    .appState('reports.patron-issues-top', {
        title: 'Most Active Patrons',
        url: '/patron-issues-top',
        legacyUrl: '/reports/bor_issues_top.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'top',
            description: 'Find patrons with the most checkouts',
        }
    })
    .appState('reports.item-issues-top', {
        title: 'Most Circulated Items',
        url: '/item-issues-top',
        legacyUrl: '/reports/cat_issues_top.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'top',
            description: 'Find the most circulated items',
        }
    })
    .appState('reports.items-lost', {
        title: 'Items Lost',
        url: '/items-lost',
        legacyUrl: '/reports/itemslost.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'other',
            description: 'Find items marked as lost',
        }
    })
    .appState('reports.catalog-by-itemtype', {
        title: 'Catalog by Item Type',
        url: '/catalog-by-itemtype?report_name',
        legacyUrl: '/reports/manager.pl?report_name=itemtypes',
        permissions: 'reports',
        matchRoute: true,
        widget: {
            group: 'other',
            description: 'View catalog group by item types',
        }
    })
    .appState('reports.nostate-reports-lib', {
        title: 'LibLime Reports Library',
        matchRoute: false,
        registerState: false,
        widget: {
            template: '<div class="staff-widget-title"><a target="_blank" href="https://cdn.bibliovation.com/c/Docs/LibLimeSampleReports.htm">LibLime Reports Library</a></div>',
            group: 'resources',
            addToSidebar: false,        // Added explicitly by the sidebar template
            displayIf: function() { return KOHA.config.useExternalCDNs; },
        }
    })
    .appState('reports.nostate-mysql', {
        title: 'MySQL Documentation',
        matchRoute: false,
        registerState: false,
        widget: {
            template: '<div class="staff-widget-title"><a target="_blank" href="http://dev.mysql.com/doc">MySQL Documentation</a></div>',
            group: 'resources',
            addToSidebar: false,        // Added explicitly by the sidebar template
            displayIf: function() { return KOHA.config.useExternalCDNs; },
        }
    })
    
    // No widgets, but present for route matching purposes
    .appState('reports.manager', {
        title: 'Reports Manager',
        url: '/manager?report_name',
        legacyUrl: '/reports/manager.pl',
        permissions: 'reports',
        matchRoute: function(params) { 
            return ((params.report_name != 'itemtypes') ? true : false);
        },
        widget: false
    })

    .appState('reports.stats-screen', {
        title: 'Reports Stats Screen',
        url: '/stats-screen?time&time2',
        legacyUrl: '/reports/stats.screen.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: false,
    })
    .appState('reports.stats-print', {
        title: 'Reports Stats Print',
        url: '/stats-print?time&time2',
        legacyUrl: '/reports/stats.print.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: false,
    })
    .appState('reports.legacy-acq-stats', {
        title: 'Legacy Acquisitions Stats',
        url: '/legacy-acq-stats',
        legacyUrl: '/reports/acquisitions_stats.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: false,
    })
    .appState('reports.reserve-report', {
        title: 'Reserve Report',
        url: '/reserve-report?time&branch&sort',
        legacyUrl: '/reports/reservereport.pl',
        permissions: 'reports',
        matchRoute: true,
        widget: false,
    })
    
    //================= Serials ===============
    
    .widget('serials', {
        title: 'Serials Home',
        permissions: 'serials',
        group: 'basic',
        description: 'Serials Home',

        //registerState: false,
        //matchRoute: false,
        //widget: {}
    })

    //================= Circ ===============
    
    .appState('circ', {
        title: 'Circulation',
        url: '/circ',
        templateUrl: 'circ.html',
        controller: 'StaffCirculationCtrl',
        permissions: {circulate: '*'},
        matchRoute: {
            path: '/bvcgi/circ/circulation-home.pl',
        },
        widget: {}
    })
    .appState('circ.checkout', {
        title: 'Check Out',
        url: '/checkout',
        template: 'Please enter barcode or patron search terms in the resident search box.',
        permissions: {circulate: '*'},
        matchRoute: false,
        widget: {
            group: 'circ',
        },
    })
    .appState('circ.checkin', {
        title: 'Check In',
        url: '/checkin/:barcode',
        params: {
            barcode: { dynamic: true, value: null }
        },
        permissions: {circulate: '*'},
        templateUrl: 'circ/checkin.html',
        controller: 'StaffCircCheckinCtrl',
        matchRoute: {
            path: '/bvcgi/circ/returns.pl',
        },
        widget: {
            group: 'circ',
        },
    })
    .appState('circ.offline-circ', {
        title: 'Offline Circulation',
        url: '/offline-circ',
        templateUrl: 'circ/offline-circ.html',
        controller: 'StaffCircOfflineCtrl',
        matchRoute: false,
        permissions: { circulate: 'offline_circ' },
        widget: {
            group: 'circ',
        },
    })
    .appState('circ.checkin-search', {
        title: 'Check In',
        url: '/checkin',
        template: 'Please enter barcode.',
        permissions: {circulate: '*'},
        matchRoute: false,
        widget: {
            addToSidebar: false,
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
            group: 'searches',
            templateUrl: '/app/static/partials/staff/tabs/checkinTab.html',
            addTitle: true,
        },
    })
    .appState('circ.notes', {
        title: 'Notes',
        url: '/notes?op&note_id&borrowernumber',
        legacyUrl: '/circ/notes.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
    })
    .appState('circ.transfer', {
        title: 'Transfer',
        url: '/transfer',
        legacyUrl: '/circ/branchtransfers.pl',
        permissions: {circulate: 'transfer'},
        matchRoute: true,
        widget: {
            group: 'circ',
        }
    })
    .appState('circ.course-reserves', {
        title: 'Course Reserves',
        url: '/course-reserves?op&submit&limit&instructor_borrowernumber&course_reserve_id&course_id&ErrorCourseReservesExist',
        legacyUrl: '/circ/courses.pl',
        permissions: {circulate: 'put_coursereserves'},
        matchRoute: true,
        widget: {
            group: 'circ',
        }
    })
    .appState('circ.ill-requests', {
        title: 'Interlibrary Loan Requests',
        url: '/ill-requests/:id?op',
        params: {
            id: null
        },
        legacyUrl: '/circ/ill.pl',
        permissions: {circulate: 'manage_ill_requests'},
        matchRoute: true,
        widget: {
            group: 'circ',
        }
    })
    .appState('circ.callslips', {
        title: 'Call Slip Requests',
        url: '/callslips?callslip_id&op&biblionumber',
        legacyUrl: '/circ/callslips.pl',
        permissions: {circulate: '*'},
        matchRoute: function(params) {
            return ('barcode' in params ? false : true);
        },
        widget: {
            group: 'reports',
        }
    })
    .appState('circ.holds-queue', {
        title: 'Holds Queue',
        url: '/holds-queue',
        templateUrl : 'circ-holds-queue.html',
        controller: 'StaffCircHoldsQueueCtrl',
        permissions: {circulate: '*'},
        matchRoute: {
            path: '/bvcgi/circ/view_holdsqueue.pl',
        },
        widget: {
            group: 'reports',
        }
    })
    .appState('circ.waiting-reserves', {
        title: 'Holds Awaiting Pickup',
        url: '/waiting-reserves',
        legacyUrl: '/circ/waitingreserves.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: {
            group: 'reports',
        }
    })
    .appState('circ.cancelled-holds', {
        title: 'Cancelled Holds',
        url: '/cancelled-holds',
        legacyUrl: '/circ/cancelledholds.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: {
            group: 'reports',
        }
    })    
    .appState('circ.transfers', {
        title: 'Transfers To Receive',
        url: '/transfers?itemnumber',
        legacyUrl: '/circ/transferstoreceive.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: {
            group: 'reports',
        }
    })
    .appState('circ.overdues', {
        title: 'Overdues',
        url: '/overdues?patron_attr_filter_agency&pattron_attr_filter_department&patron_attr_filter_TOWNSHIP&order&showall&borname&borcat&itemtype&borflag&branch&pickupbranch&op&search',
        legacyUrl: '/circ/overdue.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: {
            group: 'reports',
            description: 'WARNING! This report can be very resource intensive on systems with large numbers of overdue items',
        }
    })
    .appState('circ.callslips-search', {
        title: 'Fill Call Slip',
        url: '/callslips-search?barcode&op&biblionumber&callslip_id',
        legacyUrl: '/circ/callslips.pl',
        permissions: {circulate: '*'},
        matchRoute: function(params) {
            return ('barcode' in params ? true : false);
        },
        widget: {
            addToSidebar: false,
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
            group: 'searches',
            templateUrl: '/app/static/partials/staff/tabs/callslipFillTab.html',
            addTitle: true,
        }
    })

    // No widgets, just routes and matching

    .appState('circ.fastcat', {
        title: 'Circulation',
        url: '/fastcat?borrowernumber&duedatespec&location&barcode&branch&stickyduedate',
        legacyUrl: '/circ/fastcat.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('circ.fast-add', {  // adds an item, but from within patron view.
            url: '/fast-add?op&format&borrowernumber&biblionumber&holdingbranch',
            legacyUrl: '/catalogue/fastadd.pl',
            matchRoute: true,
            widget: false
        })
    .appState('circ.billing', {
        title: 'Circ Billing',
        url: '/billing?from&to&order',
        legacyUrl: '/circ/billing.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('circ.pending-reserves', {
        title: 'Circ Pending Reserves',
        url: '/pending-reserves?order&from&to&theme',
        legacyUrl: '/circ/pendingreserves.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('circ.branch-printer', {
        title: 'Circ Select Branch Printer',
        url: '/branch-printer',
        legacyUrl: '/circ/selectbranchprinter.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: false,
    })

    //================= Patrons ===============
    
    .appState('patrons', {
        title: 'Patrons',
        url: '/patrons',
        templateUrl: 'patrons.html',
        controller: 'StaffPatronsCtrl',
        permissions: {borrowers: '*'},
        matchRoute: false,
        widget: false
    })
    .appState('patrons.browse', {
        title: 'Search/Browse Patrons',
        url: '/browse',
        legacyUrl: '/members/members-home.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {}
    })
    .appState('patrons.addnew', {
        title: 'Add New Patron',
        url: '/addnew?op&step&destination&guarantorid&categorycode&category_type&copy&firstname&surname&email&dateofbirth&address&address2&city&state&zipcode&country&phone&notify',
        legacyUrl: '/members/memberentry.pl',
        params: { op: 'add' },
        permissions: { borrowers: { create: '*'} },
        matchRoute: function(params) {
                return ((params.op != 'add') ? false : true);
        },
        widget: {},
    })
    .appState('patrons.patron_reg', {
        title: 'Patron Registrations',
        url: '/patron_reg',
        templateUrl: 'patrons/patron_reg.html',
        controller: 'StaffPatronRegCtrl',
        permissions: { borrowers: 'create' },
        matchRoute: false,
        widget: {
            addToSidebar: true,
            group: 'patrons',
            displayIf: function() { return KOHA.config.patronSelfRegister; },
        }
    })
    .appState('patrons.search', {
        title: 'Search Patrons',
        url: ('/search/:member?quicksearch&startfrom&resultsperpage&theme&orderby&from_list_id&currPage'
                + '&advanced_patron_search&borrowernumber&cardnumber&categorycode&dateenrolled_after'
                + '&dateenrolled_before&dateexpiry_after&dateexpiry_before&branchcode&sort1&sort2&userid'
                + '&dateofbirth_after&dateofbirth_before&surname&firstname&address&city&zipcode&B_address'
                + '&B_city&B_zipcode&email&emailpro&phone&opacnotes&borrowernotes&debarred&gonenoaddress'
                + '&lost&limit&offset&add_to_list&list_id&list_name&type'),
        legacyUrl: '/members/member.pl',
        permissions: {borrowers: '*'},
        params: {
            member: null
        },
        matchRoute: true,
        widget: {
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
            templateUrl: '/app/static/partials/staff/tabs/patronSearchTab.html',
            addTitle: true,
            addToSidebar: false,
        }
    })
    .appState('patrons.bulk-edit', {
        title: 'Bulk Edit Patrons',
        url: '/bulk-edit?param_borrower_list&delete',
        legacyUrl: '/members/bulkedit.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: false,
    })
    // .appState('patrons.basket', {
        // basket is legacy child window.
    //     title: 'Patron Borrower Basket',
    //     url: '/basket?op&borrower_list&print&verbose',
    //     legacyUrl: '/members/borrower-basket.pl',
    //     permissions: {borrowers: '*'},
    //     matchRoute: true,
    //     widget: false,
    // })

    .appState('patron', {
        title: 'Patron',
        url: '/patron/:borrowernumber',
        templateUrl: 'patrons.html',  // note we reuse patrons template.
        controller: 'StaffPatronCtrl',
        matchRoute: false
    })

    .appState('patron.checkout', {
        title: 'Check Out',
        url: '/checkout?duedatespec&barcode&branch&dateexpiry&stickyduedate&mute_soundon&view_all&fa',
        legacyUrl: '/circ/circulation.pl',
        permissions: {circulate: '*'},
        matchRoute: function(qp){
            var params = {...qp};
            if(params.fastadd){
                delete params.barcode;
                delete params.fastadd;
            }
            return {
                state: 'staff.patron.checkout',
                params: params
            }

        },
// fixme: probably no widget here.
        widget: {
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
            templateUrl: '/app/static/partials/staff/tabs/checkoutTab.html',
            addTitle: true,
            addToSidebar: true,
        }
    })
    .appState('patron.details', {
        title: 'Details',
        url: '/details?reregistration&error&print&bnum&op&as',
        legacyUrl: '/members/moremember.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })

    .appState('patron.modification-log', {
        title: 'Patron Modification Log',
        url: '/log-viewer?object&src&do_it&modules',
        legacyUrl: '/tools/viewlog.pl?do_it=1&modules=MEMBERS',
        matchRoute: false,  // see tools.viewlog
        permissions: {tools: 'view_system_logs'},
        widget: {},
    })
    .appState('patron.messaging', {
        title: 'Messages',
        url: '/messaging?message_endpoint_id',
        legacyUrl: '/members/messaging.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })
    .appState('patron.proxy-relationships', {
        title: 'Proxy Borrowing',
        url: '/proxy-relationships?redirect&proxy_borrowernumber&op',
        legacyUrl: '/members/proxy_relationships.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })
    .appState('patron.password', {
        title: 'Patron Password',
        url: '/password',
        legacyUrl: '/members/member-password.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('patron.permissions', {
        title: 'Patron Permissions',
        url: '/permissions?member',
        legacyUrl: '/members/member-flags.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })
    .appState('patron.accounting', {
        title: 'Patron Accounting',
        url: '/accounting?view',
        templateUrl: 'patrons/accounting.html',
        controller: 'StaffPatronAccountingCtrl',
        permissions: {borrowers: '*'},
        matchRoute: false,
        widget: {},
    })
    .appState('patron.status', {
        title: 'Patron Status',
        url: '/status?destination&cardnumber&status&reregistration',
        legacyUrl: '/members/setstatus.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('patron.edit', {
        title: 'Edit Patron',
        url: '/edit?op&step&destination&guarantorid&categorycode&category_type&borrowernumber',
        legacyUrl: '/members/memberentry.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('patron.preferences', {
        title: 'Patron Preferences',
        url: '/preferences?op',
        legacyUrl: '/members/edit_user_prefs.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })
    .appState('patron.group', {
        title: 'Patron Group',
        url: '/group',
        templateUrl: 'patrons/group.html',
        controller: 'StaffPatronGroupCtrl',
        permissions: {borrowers: '*'},
        matchRoute: false,
        widget: {},
    })
    .appState('patron.lost-items', {
        title: 'Lost Items',
        url: '/lost_items',
        permissions: {borrowers: '*'},
        templateUrl: 'patrons/lost-items.html',
        controller: 'StaffPatronLostItemsCtrl',
        matchRoute: false,
        widget: {},
    })
    .appState('patron.reading-record', {
        title: 'History',
        url: '/reading-record?order&cardnumber&limit',
        legacyUrl: '/members/readingrec.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: {},
    })
    .appState('patron.merge', {
        title: 'Patron Merge',
        url: '/merge',
        templateUrl: 'patrons/merge.html',
        controller: 'StaffPatronMergeCtrl',
        permissions: {borrowers: { merge: '*'}},
        matchRoute: false,
        widget: {},
    })
    .appState('patron.delete', {
        title: 'Patron Delete',
        url: '/delete',
        templateUrl: 'patrons/delete.html',
        controller: 'StaffPatronDeleteCtrl',
        permissions: {borrowers: { delete: '*'}},
        matchRoute: false,
        params: {
            member: null
        },
        widget: {},
    })
    .appState('patron.guarantor-search', {
        title: 'Patron Guarantor Search',
        url: '/guarantor-search?theme&member&orderby',
        legacyUrl: '/members/guarantor_search.pl',
        permissions: {borrowers: '*'},
        matchRoute: true,
        widget: false,
    })

    //================= Tools ===============
    
    .appState('tools', {
        title: 'Tools',
        url: '/tools',
        legacyUrl: '/tools/tools-home.pl',
        templateUrl: 'tools.html',
        controller: 'StaffToolsCtrl', 
        permissions: {tools: '*'},
        matchRoute: true,
        widget: {
            addToSidebar: false
        }
    })
    .appState('tools.news', {
        title: 'News',
        url: '/news?op&id&ids',
        legacyUrl: '/tools/koha-news.pl',
        permissions: {tools: 'edit_news'},
        matchRoute: true,
        widget: {
            description: 'Write news for the OPAC and staff interfaces',
        }
    })
    .appState('tools.calendar', {
        title: 'Calendar',
        url: '/calendar?branchcode&op&summary_length',
        permissions: {tools: 'edit_calendar'},
        legacyUrl: '/tools/holidays.pl',
        matchRoute: true,
        widget: {
            description: 'Define days when the library is closed',
        }
    })
    .appState('tools.comments', {
        title: 'Comments',
        url: '/comments/:reviewid?op',
        params: {
            reviewid: null
        },
        legacyUrl: '/reviews/reviewswaiting.pl',
        permissions: {tools: 'moderate_comments'},
        matchRoute: true,
        widget: {
            description: 'Moderate patron comments',
        }
    })
    .appState('tools.tags', {
        title: 'Tags',
        url: '/tags?op&test&tags&approved&tag&from&to&approver&approved_by&limit_page&offset',
        legacyUrl: '/tags/review.pl',
        permissions: {tools: 'moderate_comments'},
        matchRoute: true,
        widget: {
            description: 'Moderate patron tags',
        }
    })
    .appState('tools.patron-lists', {
        title: 'Patron Lists',
        url: '/patron-lists/:list_id?op&list_name&member',
        params: {
            list_id: null
        },
        legacyUrl: '/tools/member_lists.pl',
        permissions: {borrowers: 'lists'},
        matchRoute: true,
        widget: {
            description: 'Create, delete, and edit Patron Lists',
        }
    })
    .appState('tools.record-attributes', {
        title: 'Record Attributes',
        url: '/record-attributes',
        legacyUrl: '/tools/record_attributes.pl',
        permissions: {tools: 'record_attributes'},
        matchRoute: true,
        widget: {
            description: 'Search and remove Record Attributes in bulk',
        }
    })
    .widget('tools.bim.index', {
        title: 'Batch Item Editor',
        permissions: {editcatalogue: {item_list: 'process'}},
        description: 'Batch edit or delete item records',

        //registerState: false,
        //matchRoute: false,
        //widget: {}
    })
    .widget('tools.mvr.index', {
        title: 'MARC Validation Rulesets',
        permissions: {editcatalogue: {mvr: 'get'}},
        description: 'View or modify MARC Validation Rulesets',
    })
    .widget('tools.ledger-importer.scripts', {
        title: 'Import/Export Factory',
        permissions: {admin: {io_factory: '*'}},
        description: 'Create import batches from spreadsheet or MARC data, and export catalog data in sheet form',

        //registerState: false,
        //matchRoute: false,
        //widget: {}
    })
    .appState('tools.marc-stage', {
        title: 'MARC Import-Stage',
        url: '/marc-stage',
        legacyUrl: '/tools/stage-marc-import.pl',
        permissions: {tools: 'stage_marc_import'},
        matchRoute: true,
        widget: {
            description: 'Stage MARC bibliographic records into the reservoir',
        }
    })
    .appState('tools.marc-batch-edit', {
        title: 'MARC Import-Batch Edit',
        url: '/marc-batch-edit',
        templateUrl: 'tools/marc-batch-edit.html',
        permissions: {tools: 'batch_edit_marc'},
        resolve: {
            batches: [ "resolvedPermissions", "importBatchService",function(resolvedPermissions, importBatchService) {
                return importBatchService.getList();
            }],
        },
        controller: 'StaffMarcBatchEditCtrl',
        matchRoute: false,
        widget: {
            description: 'Batch Edit staged MARC records using ruleset templates',
        }
    })
    .appState('tools.marc-manage', {
        title: 'MARC Import-Manage Stgd',
        url: '/marc-manage/:import_batch_id?op&offset',
        params: {
            import_batch_id: null
        },
        legacyUrl: '/tools/manage-marc-import.pl',
        permissions: {tools: 'manage_staged_marc'},
        matchRoute: true,
        widget: {
            description: 'Manage staged MARC records, including completing and reversing imports',
        }
    })
    .appState('tools.export', {
        title: 'Export Bibliographic/Holdings',
        url: '/export',
        legacyUrl: '/tools/export.pl',
        permissions: {tools: 'export_catalog'},
        matchRoute: true,
        widget: {
            description: 'Export bibliographic and holdings data',
        }
    })
    .appState('tools.callnumber', {
        title: 'Call Number Browse',
        url: '/callnumber',
        permissions: {catalogue: 'access'},
        templateUrl: 'tools/callnumber.html',
        controller: 'StaffCallNumberBrowseCtrl',
        matchRoute: false,
        widget: {
            description: 'Browse by call number',
        }
    })
    .appState('tools.import-patrons', {
        title: 'Import Patrons',
        url: '/import-patrons?sample',
        legacyUrl: '/tools/import_borrowers.pl',
        permissions: {tools: 'import_patrons'},
        matchRoute: true,
        widget: {
            description: 'Import patron data',
        }
    })
    .appState('tools.delete-patrons', {
        title: 'Delete Patrons',
        url: '/delete-patrons',
        legacyUrl: '/tools/cleanborrowers.pl',
        permissions: {tools: 'delete_anonymize_patrons'},
        matchRoute: true,
        widget: {
            description: 'Delete patrons who have not borrowed since a specific date',
        }
    })
    .appState('tools.upload-patron-images', {
        title: 'Upload Patron Images',
        url: '/upload-patron-images?cardnumber&borrowernumber&op',
        legacyUrl: '/tools/picture-upload.pl',
        permissions: {tools: 'batch_upload_patron_images'},
        matchRoute: true,
        widget: {
            description: 'Upload patron images in batch or one at a time',
        }
    })
    .widget('tools.mte.index', {
        title: 'Message Templates',
        permissions: {tools: {edit_notices: '*'}},
        description: 'Define and modify message templates (notifications for overdues, letters, etc)',
    })
    .widget('tools.send-message', {
        title: 'Send Messages',
        permissions: {tools: 'send_general_messages'},
        description: 'Send general-purpose messages and notifications',
    })
    .appState('tools.print-messages', {
        title: 'Print Messages',
        url: '/print-message',
        permissions: {tools: 'print_messages'},
        templateUrl: 'tools/print-messages.html',
        controller: 'StaffPrintMessagesCtrl',
        matchRoute: false,
        widget: {
            description: 'Print messages not sent by email',
        }
    })
    .appState('tools.overdue-rules', {
        title: 'Overdue Notices/Triggers',
        url: '/overdue-rules',
        legacyUrl: '/tools/overduerules.pl',
        permissions: {tools: 'edit_notice_status_triggers'},
        matchRoute: true,
        widget: {
            description: 'Set notice/status triggers for overdue items',
        }
    })
    .appState('tools.log-viewer', {
        title: 'Log Viewer',
        url: '/log-viewer?object&src&do_it&modules&biblionumber&borrowernumber',
        legacyUrl: '/tools/viewlog.pl',
        permissions: {tools: 'view_system_logs'},
        matchRoute: function(qp) {
            var params = {};
            if ('src' in qp) params.src = qp.src;
            if ('do_it' in qp) params.do_it = qp.do_it;
            if (qp.modules == 'CATALOGUING') {
                if ('object' in qp) params.biblionumber = qp.object;
                return {
                    state: 'staff.bib.log',
                    params: params
                };
            } else if(qp.modules == 'MEMBERS'){
                if ('object' in qp) params.borrowernumber = qp.object;
                return {
                    state: 'staff.patron.modification-log',
                    params: params
                };
            } else {
                if ('modules' in qp) params.modules = qp.modules;
                if ('object' in qp) params.object = qp.object;
                return {
                    state: 'staff.tools.log-viewer',
                    params: params
                };
            }
        },
        widget: {
            description: 'Browse the system logs',
        }
    })
    .appState('tools.event-log', {
        title: 'Event Log',
        url: '/event-log',
        templateUrl: 'tools/event-log.html',
        permissions: {tools: 'view_system_logs'},
        controller: 'StaffEventLogCtrl',
        resolve: {
            fieldMap: ["kwApi", function(kwApi) {
                return kwApi.ActionLog.getFieldMap().$promise;
            }]
        },
        matchRoute: false,
        widget: {
            description: 'View and manage the event log',
        }
    })
    .appState('tools.dlso-log-viewer', {
        title: 'DLSO Log Viewer',
        url: '/dlso-log-viewer',
        modal: true,
        controller: ["awLogService", function(awLogService) {
            awLogService.startModal();
        }],
        permissions: {tools: 'dlso'},
        matchRoute: false,
        widget: {
            description: 'View DLSO ingest logs',
            displayIf: function() { return KOHA.config.geospatialSearch; },
        }
    })
    .appState('tools.dlso-by-uuid', {
        title: 'UUID Lookup',
        url: '/dlso-by-uuid',
        modal: true,
        controller: function() {
            var UUID = prompt("Please Enter GUIDE/UUID : ", "");
            if (UUID == null)
                return;

            var patt = new RegExp("(GUIDE://[0-9]{4}/)?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$");
            if (patt.test(UUID)) {
                var scrubbedUUID = patt.exec(UUID)[2];
                window.open(
                "../../app/static/fullviewer.html?uuid="+scrubbedUUID ,
                "width=1200,height=600,scrollbars=no,toolbar=no,screenx=0,screeny=0,location=no,titlebar=no,directories=no,status=no,menubar=no");
            } else {
                alert("UUID/GUIDE must be in correct GUIDE or UUID format: e.g. GUIDE://1111/11111111-1111-1111-1111-111111111111 or 11111111-1111-1111-1111-111111111111 ");
            }
        },
        permissions: {tools: 'dlso'},
        matchRoute: false,
        widget: {
            description: 'View DLSO by UUID',
        }
    })
    .appState('tools.inventory', {
        title: 'Inventory/Stocktaking',
        url: '/inventory',
        legacyUrl: '/tools/inventory.pl',
        permissions: {tools: 'inventory'},
        matchRoute: true,
        widget: {
            description: 'Perform inventory (stocktaking) of your catalog',
        }
    })
    .appState('tools.fine-threshold-forgiveness', {
        title: 'Fine Threshold Forgiveness',
        url: '/fine-threshold-forgiveness?op&id&branchcode&branch',
        legacyUrl: '/tools/fine-threshold-forgiveness.pl',
        permissions: {tools: 'fine_threshold_forgive'},
        matchRoute: true,
        widget: {
            description: 'Select fine threshold rules to run them',
        }
    })
    .appState('tools.forgiven-fines', {
        title: 'Forgiven Fines',
        url: '/forgiven-fines?op&file_id',
        legacyUrl: '/tools/forgiven-fines.pl',
        permissions: {tools: 'fine_threshold_forgive'},
        matchRoute: true,
        widget: {
            description: 'Download and manage past forgiven fines',
        }
    })
    .widget('tools.trace.index', {
        title: 'System Tracing',
        permissions: {tools: {trace: '*'}},
        description: 'Trace performance and exception conditions',
    })
    .appState('tools.substitute-user', {
        title: 'Substitute User',
        url: '/substitute-user?mode',
        templateUrl: 'tools/substitute-user.html',
        permissions: {substitute_user: '*'},
        resolve: {
            roles: ["kwApi", function(kwApi) {
                return kwApi.Patron.getRoleList().$promise;
            }],
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
            vendors: ["kwApi", function(kwApi) {
                return kwApi.Vendor.getList().$promise;
            }],
            mode: ["$stateParams", function($stateParams) {
                return $stateParams.mode;
            }],
        },
        controller: 'StaffSubstituteUserCtrl',
        matchRoute: false,
        widget: {
            description: 'Open a new tab as another user',
        }
    })
    // No widgets, just state
    .appState('tools.patron-lists-bulkedit-confirm', {
        title: 'Patron Lists Bulk Edit Confirm',
        url: '/patron-lists-bulkedit-confirm',
        legacyUrl: '/tools/member_lists_bulkedit_confirm.pl',
        permissions: {borrowers: 'lists'},
        matchRoute: true,
        widget: false,
    })
    .appState('tools.patron-lists-bulkedit', {
        title: 'Patron Lists Bulk Edit',
        url: '/patron-lists-bulkedit?list_id',
        legacyUrl: '/tools/member_lists_bulkedit.pl',
        permissions: {borrowers: 'lists'},
        matchRoute: true,
        widget: false,
    })
    
   
    //================= Labels ===============
    //Now in its own state though we inject the widgets into the tools controller
    
    .appState('labels', {
        title: 'Tools',
        url: '/labels',
        templateUrl: 'tools.html',
        controller: 'StaffToolsCtrl', 
        permissions: {tools: 'label_creator'},
        matchRoute: false,
    })
    .appState('labels.creator', {
        title: 'Layouts',
        url: '/creator?op&layout_id&batch_id',
        legacyUrl: '/labels/label-home.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: {
            description: 'Create printable labels and barcodes from catalog data',
        }
    })
    .appState('labels.template', {
        title: 'Templates',
        url: '/template/:tmpl_id?op&tmpl_code&width&height&topmargin&leftmargin&columns&rows&colgap&rowgap&batch_id',
        params: {
            tmpl_id: null
        },
        legacyUrl: '/labels/label-templates.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: {},
    })
    .appState('labels.profile', {
        title: 'Printer Profiles',
        url: '/profile/:prof_id?op&batch_id',
        params: {
            prof_id: null
        },
        legacyUrl: '/labels/label-profiles.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: {},
    })
    .appState('labels.batch-manager', {
        title: 'Manage Label Batches',
        url: '/batch-manager?type&debug&op&labelid&batch_id&cardid&borrowernumber&itemnumber',
        legacyUrl: '/labels/label-manager.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: {},
    })
    .appState('labels.spine-create', {
        title: 'Quick Spine Creator',
        url: '/spine-create?item',
        legacyUrl: '/labels/spinelabel-home.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: {
            description: 'Enter a barcode to generate a printable spine label',
        }
    })
    .appState('labels.spine-print', {
        title: 'Print Spine Label',
        url: '/labels-spine-print?barcode',
        legacyUrl: '/labels/spinelabel-print.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.print-pdf', {
        title: 'Labels Print PDF',
        url: '/labels-print-pdf/?batch_id&type',
        legacyUrl: '/labels/label-print-pdf.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.print-csv', {
        title: 'Labels Print CSV',
        url: '/labels-print-csv/?batch_id&type',
        legacyUrl: '/labels/label-print-csv.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })    
    .appState('labels.layout-edit', {
        title: 'Label Edit Layout',
        url: '/layout/:layout_id/edit', 
        legacyUrl: '/labels/label-edit-layout.pl',
        params: {
            layout_id: null
        },
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.item-search', {
        title: 'Label Item Search',
        url: '/item-search?batch_id&resultsperpage&i_itemnumber&datefrom&startfrom&dateto&ccl_query&op',
        legacyUrl: '/labels/label-item-search.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.profile-edit', {
        title: 'Label Edit Printer Profile',
        url: '/profile/:prof_id/edit?op&offset_horz&offset_vert&creep_horz&creep_vert&unit',
        legacyUrl: '/labels/label-edit-profile.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.profile-create', {
        title: 'Label Create Printer Profile',
        url: '/profile/:prof_id/create?op&printername&paper_bin&tmpl_id&offset_horz&offset_vert&creep_horz&creep_vert&unit',
        legacyUrl: '/labels/label-create-profile.pl',
        params: {
            prof_id: null
        },
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.template-edit', {
        title: 'Label Edit Template',
        url: '/template/:tmpl_id/edit?width&height&topmargin&leftmargin&columns&rows&colgap&rowgap&prof_id',
        legacyUrl: '/labels/label-edit-template.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.template-save', {
        title: 'Label Create Template',
        url: '/template/create?op&batch_id&fontsize&fonts&units&rowgap&colgap&rows&cols&leftmargin&topmargin&label_width&label_height&page_width&page_height&tmpl_desc&tmpl_code',
        legacyUrl: '/labels/label-create-template.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })
    .appState('labels.test-split', {
        title: 'Label Test Split',
        url: '/test-split?r&t',
        legacyUrl: '/labels/label-testsplit.pl',
        permissions: {tools: 'label_creator'},
        matchRoute: true,
        widget: false,
    })


    //================= Bib Details ===============

    .appState('bib', {
        title: 'Bib',
        url: '/bib/:biblionumber',
        matchRoute: false,
        templateUrl: 'bib.html',
        controller: 'StaffBibCtrl',
        params: {
            biblionumber: null,
        },
    })
    .appState('bib.details', {
        title: 'Detail',
        url: '/details?q&item&type&bi&time',
        templateUrl: 'bib-detail-staff.html',
        legacyUrl: '/catalogue/detail.pl',
        matchRoute: true,
        // matchRoute: false,
        widget: {
            addToDock: false
        },
        controller: 'StaffBibDetailCtrl'
    })
    .appState('bib.marc', {
        title: 'MARC',
        url: '/marc?frameworkcode&popup&subscriptionid',
        template: '<kw-staff-toolbar-cat bib="bib"></kw-staff-toolbar-cat><h2>MARC data view of {{bib.title}}</h2><div id="marc-view" marc-view="{{marc}}" marc-type="{{type}}"></div>',
        controller: ["$scope", "$stateParams", "bibService", "$http", function($scope, $stateParams, bibService, $http) {
            bibService.get($stateParams.biblionumber).then(bib => {
                $scope.bib = bib;
                $http.get('/api/work/' + $scope.bib.id + '/exports/text').then(r => {
                    $scope.marc = r.data;
                    $scope.type = 'MARC';
                });
            });
        }],
        matchRoute: false,
        widget: {
            addToDock: false
        }
    })
    .appState('bib.isbd', {
        title: 'ISBD',
        url: '/isbd',
        legacyUrl: '/catalogue/ISBDdetail.pl',
        matchRoute: true,
        widget: {
            addToDock: false
        }
    })
    .appState('bib.migrate', {
        title: 'Migrate Item',
        url: '/item-migrate',
        permissions: {editcatalogue: {item: '*'}},
        templateUrl: 'item-migrate.html',
        controller: 'StaffItemMigrateCtrl',
        matchRoute: false,
        widget: false
    })
    .appState('bib.items', {
        title: 'Edit Items',
        url: '/items?edit',  // optional (consumed) param indicates that modal (or sub-table editor) is open.  May repeat.
        permissions: {editcatalogue: {item: '*'}},
        templateUrl: 'items-detail.html',
        controller: 'StaffItemsCtrl',
        // resolve: {
        //     holdings: function($stateParams, resolvedPermissions, bibService, loading) {
        //         loading.add('bib.items.resolve');
        //         return bibService.holdings($stateParams.biblionumber, { cache: false });
        //     }
        // },
        matchRoute: false,
        reloadOnSearch: false,
        params: {
            edit: {
                value: undefined,
                array: true
            }
        },
        widget: {
            addToDock: false,
        }
    })
    .appState('bib.items_status', {
        title: 'Item Statuses',
        url: '/items/circstatus?itemid',
        permissions: {catalogue : {access: '*'}},  // also FIXME.
        templateUrl: 'items-circ-status.html',
        controller: 'StaffItemsCircStatusCtrl',
        matchRoute: false,
        widget: {
            addToDock: false
        }
    })
    // .appState('bib.item', {
    //     url: '/item/:itemnumber?type&bi&subject&title',
    //     legacyUrl: '/catalogue/moredetail.pl',
    //     matchRoute: function(qp) {
    //         return ('itemnumber' in qp ? true : false);
    //     },
    //     widget: false
    // })
    .appState('bib.items_status_legacy', {
        title: 'Item Statuses',
        url: '/items/circstatus-legacy',
        permissions: {catalogue : {access: '*'}},  // also FIXME.
        legacyUrl: '/catalogue/moredetail.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.holds', {
        title: 'Holds',
        url: '/holds?multi_hold&biblionumbers&findborrower&cardnumber&restrict&page&as',
        legacyUrl: '/reserve/request.pl',
        matchRoute: true,
        widget: {
            addToDock: false
        }
    })
    .appState('bib.history', {
        title: 'Checkout History',
        url: '/history?itemnumber',
        legacyUrl: '/catalogue/issuehistory.pl',
        matchRoute: true,
        widget: {
            addToDock: false
        }
    })
    .appState('bib.item-circ-history', {
        title: 'Item Checkout History',
        url: '/items/:itm/circ-history',
        legacyUrl: '/circ/bookcount.pl',
        permissions: {circulate: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('bib.log', {
        title: 'Modification Log',
        url: '/log?src&do_it&modules',
        legacyUrl: '/tools/viewlog.pl?modules=CATALOGUING&do_it=1',
        matchRoute: false,      // see tools.log-viewer
        widget: {
            addToDock: false
        }
    })
    .appState('bib.merge', {
        url: '/merge?confirm_bib_merge&bib_merge_id&cancel_bib_merge&bib_save_id_lookup&bib_save_id&op',
        legacyUrl: '/catalogue/mergebib.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.export', {
        url: '/export?op&format',
        legacyUrl: '/catalogue/export.pl',
        matchRoute: true, // FIXME -- just for download.
        widget: false
    })
    .appState('bib.showmarc', {
        url: '/showmarc?importid&viewas',
        legacyUrl: '/catalogue/showmarc.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.detail-print', {
        url: '/detail-print',
        legacyUrl: '/catalogue/detailprint.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.labeled-marc', {
        url: '/labeled-marc?frameworkcode&popup',
        legacyUrl: '/catalogue/labeledMARCdetail.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.holds-modify', {
        title: 'Modify Holds',
        url: '/holds-modify?CancelBorrowerNumber&CancelBiblioNumber&CancelItemnumber',
        legacyUrl: '/reserve/modrequest.pl',
        matchRoute: true,
        widget: false
    })
    .appState('bib.holds-place', {
        title: 'Place Holds',
        url: '/holds-place',
        legacyUrl: '/reserve/placerequest.pl',
        matchRoute: true,
        widget: false
    })

    // Place non-biblio-specific catalog/* under 'cat' state with same controller and template
    
    .appState('cat', {
        url: '/cat',
        matchRoute: false,
        templateUrl: 'bib.html',
        controller: 'StaffBibCtrl',
    })
    .appState('cat.facets', {
        url: '/facets?facet&solr_fq&offset&solr_query',
        legacyUrl: '/catalogue/facets.pl',
        matchRoute: true,
        widget: false
    })

// TODO: Replace with /bib/:id/item/{:id}/edit

    // .appState('cat.additem', {
    //     url: '/additem?biblionumber&itemnumber&error&items&op&mfhd_id&frameworkcode&new_mfhd_id',
    //     legacyUrl: '/cataloguing/additem.pl',
    //     permissions: {editcatalogue: '*'},
    //     matchRoute: true,
    //     widget: false
    // })

    // ============ Auth ===========
    
    .appState('authorities', {
        url: '/authorities',
        title: 'Authorities',
        matchRoute: false,
        templateUrl: 'authorities.html',
        controller: 'StaffAuthoritiesCtrl',
        widget: {
            addToSidebar: false
        }
    })
    .appState('authorities.search', {
        title: 'Search Authorities',
        url: '/search/:q?op&authtypecode&start&authid&index&idx&orderby&value_mainstr',
        legacyUrl: '/authorities/authorities-home.pl',
        matchRoute: true, // FIXME - partition this route
        permissions: {editcatalogue: '*'},
        widget: {
            outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
            templateUrl: '/app/static/partials/staff/tabs/authSearchTab.html',
            addToSidebar: false,
            addTitle: true
        }
    })
    .appState('authorities.detail', {
        title: 'Authorities Detail',
        url: '/:authid/detail?rcn',
        legacyUrl: '/authorities/detail.pl',
        permissions: {editcatalogue: '*'},
        matchRoute: true,
        widget: false,
    })
    .appState('authorities.show-marc', {
        url: '/show-marc?id&importid&viewas',
        legacyUrl: '/catalogue/auth-showmarc.pl',
        matchRoute: true,
        widget: false
    })
    .appState('authorities.cart', {
        url: '/cart?op&authIdparent&authid&authids',
        legacyUrl: '/authorities/authcart.pl',
        matchRoute: true,
        widget: false
    })

    // ============== Other top-level states ==========

    .appState('lists', {
        title: 'Lists',
        url: '/lists?display&viewshelf&shelfoff&shelves&op&shelfnumber&itemoff&sortfield',
        legacyUrl: '/virtualshelves/shelves.pl',
        matchRoute: true,
        widget: {}
    })
    .widget('marced.home', {
        title: 'Cataloging Editor',
        permissions: {editcatalogue: '*'},
        //registerState: false,
        //matchRoute: false,
        //widget: {}
    })
    .appState('about', {
        title: 'About',
        url: '/about',
        matchRoute: false,
        modal: true,
        widget: {
            template: '<div class="staff-widget-page-title"><a href="/app/version">About</a></div>',
            titleClass: 'staff-widget-page-title',
        },
    })
    .appState('acquisitions', {
        title: 'Acquisitions',
        url: '/acquisitions',
        legacyUrl: '/acqui/getit.pl',
        matchRoute: true,
        permissions: {acquisition: '*'},
        controller: ["$scope", function($scope) {
            $scope.masthead.docked = 'auto';
        }],
        widget: {
            titleClass: 'staff-widget-page-title',
            displayIf: function() { return KOHA.config.GetItAcquisitions; },
        }
    })
    .widget('catalog-search', {
        title: 'Search Catalog',
        outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
        templateUrl: '/app/static/partials/staff/tabs/catSearchTab.html',
        addTitle: true,
        addToSidebar: false,
    })
    .widget('barcode-search', {
        title: 'Barcode Search',
        outerClass: 'staff-widget-outer staff-widget-outer-bounded clearfix',
        templateUrl: '/app/static/partials/staff/tabs/barcodeSearch.html',
        addTitle: true,
        addToSIdebar: false,
    })
    .widget('search-title', {
        title: 'Search',
        permissions: {circulate: '*'},
        titleClass: 'staff-widget-page-title',
        template: '<div class="staff-widget-page-title"><a href="/app/search">Search</a></div>'
    })
    ;

}]);


})();

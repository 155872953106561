/* global pdfMake */
(function () {
'use strict';

angular.module('kohapac').config(["staffLegacyAppProvider", "$stateProvider", "$injector",
            function(staffLegacyAppProvider, $stateProvider, $injector) {

    // See staff-apps.js for app declarations and menus

    $stateProvider.state('staff', {
        url: '/app/staff',
        templateUrl: '/app/static/partials/staff/index.html',
        controller: 'StaffCtrl',
        data: {
            pageSubTitle: 'Staff Home',
        },
        resolve: {
            widgetIdx: function() { return staffLegacyAppProvider.widgets; },
            widgetList: function() { return staffLegacyAppProvider.widgetDock; },
            resolvedPermissions: ["userService", function(userService) {
                return userService.whenAuthenticatedUserCan({catalogue: {access: '*'}}, null, {redirectOnFail: '/'});
            }],
        },
    }).state('staff.marced', {
        url: '/marced',
        abstract: true,
        resolve: {
            loadMarced: ["$ocLazyLoad", "$injector", function($ocLazyLoad, $injector){

                var scripts = [
                    '/marced/css/select2.css',
                    '/marced/css/angular-ui-select.min.css',
                    '/marced/lib/leaflet/leaflet.css',
                    '/marced/css/app.css',
                    '/marced/lib/pazpar_client.js',
                    '/marced/js/services.js',
                    '/marced/js/directives.js',
                    '/marced/js/filters.js',
                    '/marced/js/controllers.js',
                    '/marced/lib/leaflet/leaflet.js',
                    '/marced/js/geobuilder.js',
                    '/marced/js/app.js'
                ];

                return $ocLazyLoad.load(
                        { serie:true, files:scripts.map(function(s){ return s; }) }
                    ).then(function(){
                        return $injector.get('kmConfigSvc').loaded;
                    });
            }]
        },
        templateUrl: '/marced/index.html',
        data: {
            pageSubTitle: 'Cataloging Editor',
            module: 'marced'
        },
        controller: ["$scope", function($scope) {

            $scope.masthead.docked = 'auto';
            $scope.showSidebar = false;
            $scope.globalfooter.visible = false;
            $scope.$on("$destroy", function(){
                $scope.globalfooter.visible = true;  //FIXME: should be managed elsewhere.
            });

        }]
      })
        //// FIXME: Use FutureStates.
        .state('staff.marced.home', {
            url: '?clone&z3950',
            views: {
                'main@staff.marced': {
                    templateUrl: '/marced/partials/home.html',
                    controller: 'kmNavbarCtrl'
                },
                marcedNav: {
                    templateUrl: '/marced/partials/kmNavbar.html',
                    controller: 'kmNavbarCtrl'
                }
            }
      })
      .state('staff.marced.editor', {
    //    parent: 'staff.marced',  // blah. for ref consisitency, use fully qualified name.
        url: '/edit/:record',
        views: {
            'main': {
                templateUrl: '/marced/partials/editor-container.html'
            },
            marcedNav: {
                templateUrl: '/marced/partials/kmNavbar.html',
                controller: 'kmNavbarCtrl'
            },
            'editor@staff.marced.editor': {
                templateUrl: '/marced/partials/editor.html',
                controller: 'EditorCtrl as editor'
            },
            'metadata@staff.marced.editor': {
                templateUrl: '/marced/partials/metadata.html',
                controller: 'MetadataCtrl as recordMeta'
            },
            'tabs@staff.marced.editor': {
                templateUrl: '/marced/partials/tabs.html',
                controller: 'TabsCtrl'
            },
            'help': {
                templateUrl: '/marced/partials/help.html',
                controller: 'HelpCtrl'
            }
        },
        data: {
            editor: true
        },
        params: {
          reload: false
        },
        resolve: {
            recordid: ["$stateParams", function($stateParams){ return $stateParams.record; }],
            split: function(){ return false; }
        }
      })
      .state('staff.marced.editor.splitview', {
        url: '/splitview/:refrecord',
        views: {
            'editor_ref@staff.marced.editor': {
                templateUrl: '/marced/partials/editor.html',
                controller: 'EditorCtrl as editor'
            },
            'metadata_ref@staff.marced.editor': {
                templateUrl: '/marced/partials/metadata.html',
                controller: 'MetadataCtrl as recordMeta'
            }
        },
        resolve: {
            recordid: ["$stateParams", function($stateParams){ return $stateParams.refrecord; }],
            split: function(){ return true; }
        },
        data: {
            editor: true
        }
      })

      .state('staff.admin.appconfig', {
        url: '/config',
        templateUrl: '/app/static/partials/config-modal.html',  // Not a modal anymore.
        controller: 'AppConfigCtrl',
        data: {
            requiresAuth: true,
            pageSubTitle: 'Discovery Layer Editor',
        },
        resolve: {
            resolvedPermissions: ["userService", function(userService) {
                return userService.whenAuthenticatedUserCan('superlibrarian', null, {redirectOnFail: '/'});
            }],
        }
    })
    .state('config', {
        url: '/app/config',
        redirectTo: 'staff.admin.appconfig',
    })

    .state('staff.admin.vendors', {
        url: '/vendors',
        templateUrl: '/app/static/partials/staff/vendors/index.html',
        controller: 'StaffVendorsCtrl',
        resolve: {
            vendors: ["kwApi", function(kwApi) {
                return kwApi.Vendor.getList().$promise;
            }],
        },
    })

    .state('staff.admin.vendor', {
        url: '/vendor/:vendor_id',
        templateUrl: '/app/static/partials/staff/vendors/detail.html',
        controller: 'StaffVendorsDetailCtrl',
        resolve: {
            vendor: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                //console.log($stateParams.vendor_id);
                return kwApi.Vendor.get({id: $stateParams.vendor_id}).$promise;
            }]
        },
    })
    .state('staff.admin.vendor-ledgers', {
        url: '/vendor/:vendor_id/ledgers',
        templateUrl: '/app/static/partials/staff/vendors/ledgers.html',
        controller: 'StaffVendorsLedgersCtrl',
        resolve: {
            vendor: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                //console.log($stateParams.vendor_id);
                return kwApi.Vendor.get({id: $stateParams.vendor_id}).$promise;
            }],
            ledgers: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.Vendor.getLedgers({id: $stateParams.vendor_id}).$promise;
            }],
            ledgerPriceModels: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.Ledger.getPriceModelConfig({id: $stateParams.ledger_id}).$promise;
            }],
        },
    })
    .state('staff.admin.vendor-ledger', {
        url: '/vendor/:vendor_id/ledger/:ledger_id',
        templateUrl: '/app/static/partials/staff/vendors/ledger-detail.html',
        controller: 'StaffVendorsLedgerDetailCtrl',
        resolve: {
            vendor: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                //console.log($stateParams.vendor_id);
                return kwApi.Vendor.get({id: $stateParams.vendor_id}).$promise;
            }],
            ledger: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.Ledger.get({id: $stateParams.ledger_id}).$promise;
            }],
            ledgerPriceModels: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.Ledger.getPriceModelConfig({id: $stateParams.ledger_id}).$promise;
            }],
        },
    })

    // Circ policies
    .state('staff.admin.circ', {
        url: '/circ',
        templateUrl: '/app/static/partials/staff/admin/circ.html',
        controller: 'StaffAdminCircBaseCtrl',
    })

    .state('staff.admin.circ.config', {
        url: '/config',
        templateUrl: '/app/static/partials/staff/admin/circ/config.html',
        controller: 'StaffAdminCircConfigCtrl',
        resolve: {
            circScopes: ["kwApi", function(kwApi) {
                return kwApi.CircControl.getScopes().$promise;
            }],
            circControl: ["kwApi", function(kwApi) {
                return kwApi.CircControl.query().$promise;
            }],
        },
    })
    .state('staff.admin.circ.policies', {
        url: '/policies',
        templateUrl: '/app/static/partials/staff/admin/circ/policies.html',
        controller: 'StaffAdminCircPoliciesCtrl',
    })
    
    .state('staff.admin.circ.policy', {
        url: '/policy/:id',
        templateUrl: '/app/static/partials/staff/admin/circ/policy.html',
        controller: 'StaffAdminCircPolicyCtrl',
        resolve: {
            policy: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                if ($stateParams.id > 0) {
                    return kwApi.CircPolicy.get({id: $stateParams.id}).$promise;
                }
                else {
                    return {args: '{}'};
                }
            }],
        },
    })
    .state('staff.admin.circ.rules', {
        url: '/rules',
        templateUrl: '/app/static/partials/staff/admin/circ/rules.html',
        controller: 'StaffAdminCircRulesCtrl',
    })
    .state('staff.admin.circ.recall-rules', {
        url: '/recall-rules',
        templateUrl: '/app/static/partials/staff/admin/circ/recall-rules.html',
        controller: 'StaffAdminCircRecallRulesCtrl',
    })
    
    .state('staff.admin.circ.recall-rule', {
        url: '/recall-rule/:id',
        templateUrl: '/app/static/partials/staff/admin/circ/recall-rule.html',
        controller: 'StaffAdminCircRecallRuleCtrl',
    })
    
    .state('staff.admin.circ.termsets', {
        url: '/termsets',
        templateUrl: '/app/static/partials/staff/admin/circ/termsets.html',
        controller: 'StaffAdminCircTermsetsCtrl',
    })
    
    .state('staff.admin.circ.termset', {
        url: '/termset/:id',
        templateUrl: '/app/static/partials/staff/admin/circ/termset.html',
        controller: 'StaffAdminCircTermsetCtrl',
        resolve: {
            termset: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                if ($stateParams.id > 0) {
                    return kwApi.CircTermset.get({id: $stateParams.id}).$promise;
                }
                else {
                    return {args: '{}'};
                }
            }],
            termDates: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                if ($stateParams.id > 0) {
                    return kwApi.CircTermset.getDates({id: $stateParams.id}).$promise;
                }
                else {
                    return {args: '[]'};
                }
            }],
        },
    })
    
    .state('staff.admin.ncip', {
        url: '/ncip',
        templateUrl: '/app/static/partials/staff/admin/ncip.html',
        controller: 'StaffAdminNcipBaseCtrl',
    })
    .state('staff.admin.ncip.agencies', {
        url: '/agencies',
        templateUrl: '/app/static/partials/staff/admin/ncip/agencies.html',
        controller: 'StaffAdminNcipAgenciesCtrl',
    })
    .state('staff.admin.ncip.agency', {
        url: '/agency/:id',
        templateUrl: '/app/static/partials/staff/admin/ncip/agency.html',
        controller: 'StaffAdminNcipAgencyCtrl',
        resolve: {
            ncipAgency: [ "kwApi", "$stateParams", function(kwApi, $stateParams) {
                if ($stateParams.id > 0) {
                    return kwApi.NcipAgency.get({id: $stateParams.id}).$promise;
                }
                else {
                    return {args: '{}'};
                }
            }],
        },
    })
    
    // Serials
    .state('staff.serials', {
        url: '/serials',
        templateUrl: '/app/static/partials/staff/serials.html',
        controller: 'StaffSerialsCtrl',
    })

    .state('staff.serials.index', {
        url: '/serials?title&issn',
        templateUrl: '/app/static/partials/staff/serials/index.html',
        controller: 'StaffSerialsIndexCtrl',
        resolve: {
            periodicals: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.Periodical.search({title: $stateParams.title, issn: $stateParams.issn, start: 0, count: 20}).$promise;
            }]
        }
    })

    .state('staff.serials.periodical', {
        url: '/publication/{id:any}?bib_id',
        templateUrl: '/app/static/partials/staff/serials/periodical.html',
        controller: 'StaffSerialsPeriodicalCtrl',
        resolve: {
            periodical: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id) {
                    return kwApi.Periodical.get({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when({});
                }
            }],
            subscriptions: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id) {
                    return kwApi.Periodical.getSubscriptions({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when([]);
                }
            }],
            serialEditions: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id) {
                    return kwApi.Periodical.getSerialEditions({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when([]);
                }
            }],
        },
    })
    .state('staff.serials.subscription', {
        url: '/subscription/:id?parent',
        templateUrl: '/app/static/partials/staff/serials/subscription.html',
        controller: 'StaffSerialsSubscriptionCtrl',
        resolve: {
            subscription: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id) {
                    return kwApi.Subscription.get({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when({});
                }
            }],
            serialInstances: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id) {
                    return kwApi.Subscription.getSerialInstances({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when([]);
                }
            }],
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        },
    })

    .state('staff.serials.receive', {
        url: '/receive/:ids',
        templateUrl: '/app/static/partials/staff/serials/subscription-receive.html',
        controller: 'StaffSerialsSubscriptionReceiveCtrl',
        resolve: {
            subscriptions: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                var ids = $stateParams.ids.split(',');
                var promises = ids.map(function(id) {
                    return kwApi.Subscription.get({id: id}).$promise;
                });

                return $q.all(promises);
            }],
            serialInstances: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                var ids = $stateParams.ids.split(',');
                var promises = ids.map(function(id) {
                    return kwApi.Subscription.getSerialInstances({id: id, scope: 'unreceived'}).$promise;
                });

                return $q.all(promises);
            }],
        },
    })

    .state('staff.serials.edition', {
        url: '/edition/:id',
        templateUrl: '/app/static/partials/staff/serials/edition.html',
        controller: 'StaffSerialsEditionCtrl',
        resolve: {
            serialEdition: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                return kwApi.SerialEdition.get({id: $stateParams.id}).$promise;
            }],
            serialInstances: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                return kwApi.SerialEdition.getSerialInstances({id: $stateParams.id}).$promise;
            }],
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        },
    })

    .state('staff.serials.claims', {
        url: '/claims',
        templateUrl: '/app/static/partials/staff/serials/claims.html',
        controller: 'StaffSerialsClaimsCtrl',
        resolve: {
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        },
    })

    .state('staff.serials.expires', {
        url: '/renew',
        templateUrl: '/app/static/partials/staff/serials/expires.html',
        controller: 'StaffSerialsExpiresCtrl',
        resolve: {
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        },
    })

    .state('staff.serials.schedule-templates', {
        url: '/schedule-templates',
        templateUrl: '/app/static/partials/staff/serials/schedule-templates.html',
        controller: 'StaffSerialsScheduleTemplatesCtrl',
        resolve: {
            templates: ["kwApi", function(kwApi) {
                return kwApi.SerialPatternTemplate.query().$promise;
            }]
        }
    })
    .state('staff.serials.chronology-templates', {
        url: '/chronology-templates',
        templateUrl: '/app/static/partials/staff/serials/chronology-templates.html',
        controller: 'StaffSerialsChronologyTemplatesCtrl',
        resolve: {
            templates: ["kwApi", function(kwApi) {
                return kwApi.SerialChronologyTemplate.query().$promise;
            }]
        }
    })
    .state('staff.serials.sequence-templates', {
        url: '/sequence-templates',
        templateUrl: '/app/static/partials/staff/serials/sequence-templates.html',
        controller: 'StaffSerialsSequenceTemplatesCtrl',
        resolve: {
            templates: ["kwApi", function(kwApi) {
                return kwApi.SerialSequenceTemplate.query().$promise;
            }]
        }
    })
    .state('staff.serials.vendors', {
        url: '/vendors',
        templateUrl: '/app/static/partials/staff/serials/vendors.html',
        controller: 'StaffSerialsVendorsCtrl',
        resolve: {
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        }
    })

    // Batch Item Mutator. BIM because I'm going to get really tired of typing BatchItemMutator.
    
    .state('staff.tools.bim', {
        url: '/bim',
        templateUrl: '/app/static/partials/staff/bim.html',
        controller: 'StaffBimCtrl',
    })

    .state('staff.tools.bim.index', {
        url: '/index',
        templateUrl: '/app/static/partials/staff/bim/index.html',
        controller: 'StaffBimIndexCtrl',
        resolve: {
            itemLists: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.ItemList.query({view: 'details', start: 0, sort: 'created_date', reverse: 1}).$promise;
            }],
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        }
    })

    .state('staff.tools.bim.view', {
        url: '/view/:id',
        templateUrl: '/app/static/partials/staff/bim/details.html',
        controller: 'StaffBimDetailsCtrl',
        resolve: {
            itemList: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.ItemList.get({id: $stateParams.id}).$promise;
            }],
            itemListEntries: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.ItemListEntry.query({view: 'details', item_list_id: $stateParams.id, start: 0, limit: 20, sort: 'title', reverse: 0}).$promise;
            }]
        }
    })

    // Message Template Editor
    
    .state('staff.tools.mte', {
        url: '/mte',
        template: '<div ui-view></div>',
        controller: 'StaffMteCtrl',
    })
    .state('staff.tools.mte.index', {
        url: '/index?mode&branch',
        templateUrl: '/app/static/partials/staff/tools/mte/index.html',
        controller: 'StaffMteIndexCtrl',
        resolve: {
            templates: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.MessageTemplate.tree().$promise;
            }],
            branches: ["kwApi", function(kwApi) {
                return kwApi.Branch.getList().$promise;
            }],
        }
    })
    .state('staff.tools.mte.view', {
        url: '/view/:id',
        templateUrl: '/app/static/partials/staff/tools/mte/details.html',
        controller: 'StaffMteDetailsCtrl',
        resolve: {
            template: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.MessageTemplate.get({id: $stateParams.id}).$promise;
            }],
        }
    })

    .state('staff.tools.ledger-importer', {
        url: '/ledger-importer',
        templateUrl: '/app/static/partials/staff/tools/ledger-importer.html',
        controller: 'StaffLedgerImportBaseCtrl',
    })

    .state('staff.tools.ledger-importer.scripts', {
        url: '/scripts',
        templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-index.html',
        controller: 'StaffLedgerImportScriptsCtrl',
        resolve: {
            importScripts: ["kwApi", function(kwApi) {
                return kwApi.ImportScript.getList().$promise.then(function(rv) {
                    rv.forEach(function(rec) {
                        rec.is_approved = (rec.tainted === null || rec.tainted === '0' || rec.tainted === 0) ? true : false;
                    });
                    return rv;
                });
            }],
        },
    })
    .state('staff.tools.ledger-importer.scriptlets', {
        url: '/scriptlets',
        templateUrl: '/app/static/partials/staff/tools/ledger-importer/scriptlet-index.html',
        controller: 'StaffLedgerImportScriptletsCtrl',
        resolve: {
            importScriptlets: ["kwApi", function(kwApi) {
                return kwApi.ImportScriptlet.getList({view: 'index'}).$promise;
            }],
        },
    })
    .state('staff.tools.ledger-importer.script', {
        url: '/script/:id',
        templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-update.html',
        controller: 'StaffLedgerImportScriptCtrl', 
        resolve: {
            importScript: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                if ($stateParams.id === 'NEW') {
                    return {script_type: 'sheet-single', _embed: {scriptlets: []}};
                }
                else {
                    return kwApi.ImportScript.get({id: $stateParams.id}).$promise.then(function(rv) {
                        rv.is_approved = true;
                        rv._embed.scriptlets.forEach(function(s) {
                            if (s.tainted !== 0 && s.tainted !== '0') {
                                rv.is_approved = false;
                            } 
                        });
                        return rv;
                    });
                }
            }],
            importScriptlets: ["kwApi", function(kwApi) {
                return kwApi.ImportScriptlet.getList().$promise;
            }],
            vendors: ["kwApi", function(kwApi) {
                return kwApi.Vendor.getList().$promise;
            }],
        },
    })
    .state('staff.tools.ledger-importer.script-runs', {
        url: '/script/:id/runs',
        templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-runs.html',
        controller: 'StaffLedgerImportScriptRunsCtrl',
        resolve: {
            importScriptRuns: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.ImportScript.getRuns({id: $stateParams.id}).$promise;
            }],
        },
    })

    .state('staff.reports.sql', {
        url: '/sql',
        template: '<div ui-view></div>',
        controller: 'StaffReportBaseCtrl',
    })

    .state('staff.reports.sql.index', {
        url: '/index',
        templateUrl: '/app/static/partials/staff/reports/index.html',
        controller: 'StaffReportIndexCtrl',
        resolve: {
            reports: ["kwApi", function(kwApi) {
                return kwApi.Report.query().$promise;
            }],
            report_tags: ["kwApi", function(kwApi) {
                return kwApi.ReportTag.query().$promise;
            }],
        },
    })
    .state('staff.reports.sql.new', {
        url: '/new',
        templateUrl: '/app/static/partials/staff/reports/details.html',
        controller: 'StaffReportNewCtrl',
    })
    .state('staff.reports.sql.report', {
        url: '/report/:id',
        templateUrl: '/app/static/partials/staff/reports/details.html',
        controller: 'StaffReportDetailsCtrl',
        resolve: {
            report: ["kwApi", "$stateParams", function(kwApi,$stateParams) {
                return kwApi.Report.get({id: $stateParams.id}).$promise;
            }],
            runs: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.Report.getRuns({id: $stateParams.id}).$promise;
            }],
        },
    })
    .state('staff.reports.sql.runs', {
        url: '/report/:id/runs/:rid',
        templateUrl: '/app/static/partials/staff/reports/run-details.html',
        resolve: {
            report: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.Report.get({id: $stateParams.id}).$promise;
            }],
            run: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.Report.getRun({id: $stateParams.id, rid: $stateParams.rid, start: 0, count: 20}).$promise;
            }],
            fullrun: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.Report.getRun({id: $stateParams.id, rid: $stateParams.rid}).$promise;
            }],
        },
        controller: 'StaffReportRunDetailsCtrl',
    })

    // Cron jobs
    
    .state('staff.admin.cron', {
        url: '/cron',
        template: '<div ui-view></div>',
        controller: 'StaffCronCtrl',
        resolve: {
            cronScripts: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.CronScript.query().$promise.then(function(rv) {
                    return rv.filter(function(s) { return s.enabled; });
                });
            }],
        },

    })
    .state('staff.admin.cron.index', {
        url: '/index',
        templateUrl: '/app/static/partials/staff/admin/cron/index.html',
        controller: 'StaffCronIndexCtrl',
        resolve: {
            cronJobs: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.CronJob.query().$promise;
            }],
        }
    })
    .state('staff.admin.cron.view', {
        url: '/view/:id',
        templateUrl: '/app/static/partials/staff/admin/cron/details.html',
        controller: 'StaffCronDetailsCtrl',
        resolve: {
            cronJob: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id > 0) {
                    return kwApi.CronJob.get({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when({
                        cron_script_id: 0,
                        description: '', 
                        args: [],
                        purge_after: "30",
                        schedule: '0:0:0:1*0:0:0',
                        save_stderr: true,
                        save_stdout: true,
                    });
                }
            }],
            cronJobRuns: ["$stateParams", "kwApi", "$q", function($stateParams, kwApi, $q) {
                if ($stateParams.id > 0) {
                    return kwApi.CronJob.getRuns({id: $stateParams.id}).$promise;
                }
                else {
                    return $q.when([]);
                }
            }],
        }
    })
    .state('staff.admin.cron.runs', {
        url: '/:id/runs/:rid',
        templateUrl: '/app/static/partials/staff/admin/cron/run-details.html',
        resolve: {
            cronJob: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.CronJob.get({id: $stateParams.id}).$promise;
            }],
            run: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.CronJob.getRun({id: $stateParams.id, rid: $stateParams.rid}).$promise;
            }],
        },
        controller: 'StaffCronRunDetailsCtrl',
    })

    .state('staff.tools.trace', {
        url: '/trace',
        template: '<div ui-view></div>',
        controller: 'StaffTraceCtrl',
        resolve: {
            traceOptions: ["kwApi", function(kwApi) {
                return kwApi.SessionApiMetadata.options().$promise;
            }],
        },
    })
    .state('staff.tools.trace.index', {
        url: '/index',
        templateUrl: '/app/static/partials/staff/tools/trace/index.html',
        controller: 'StaffTraceIndexCtrl',
        resolve: {
            traces: ["kwApi", "userService", function(kwApi, userService) { 
                return userService.whenAuthenticatedUserDetails().then(function() {
                    if (userService.can({tools: {trace: 'self'}}) || userService.can({tools: {trace: 'other'}})) {
                        return kwApi.SessionApiMetadata.query({member_id: userService.id}).$promise;
                    }
                    else {
                        return [];
                    }
                });
            }]
        },
    })
    .state('staff.tools.trace.view', {
        url: '/view/:id',
        templateUrl: '/app/static/partials/staff/tools/trace/view.html',
        controller: 'StaffTraceViewCtrl',
        resolve: {
            trace: ["kwApi", "$stateParams", function(kwApi, $stateParams) {
                return kwApi.SessionApiMetadata.get({id: $stateParams.id}).$promise;
            }]
        },
    })

    .state('staff.tools.mvr', {
        url: '/mvr',
        templateUrl: '/app/static/partials/staff/tools/mvr.html',
        controller: 'StaffMvrCtrl',
    })

    .state('staff.tools.mvr.index', {
        url: '/index',
        templateUrl: '/app/static/partials/staff/tools/mvr/index.html',
        controller: 'StaffMvrIndexCtrl',
        resolve: {
            rulesets: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                return kwApi.MarcValidationRuleset.query({view: 'brief', start: 0, limit: 20, sort: 'title', reverse: 0}).$promise;
            }],
        }
    })

    .state('staff.tools.mvr.view', {
        url: '/view/:id',
        templateUrl: '/app/static/partials/staff/tools/mvr/details.html',
        controller: 'StaffMvrDetailsCtrl',
        resolve: {
            ruleset: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                if ($stateParams.id > 0) {
                    return kwApi.MarcValidationRuleset.get({id: $stateParams.id}).$promise;
                }
                else {
                    return {};
                }
            }],
        }
    })
    .state('staff.tools.send-message', {
        url: '/send-message',
        templateUrl: '/app/static/partials/staff/tools/send-message.html',
        controller: 'StaffSendMessageCtrl',
    })

    ;
}]);


angular.module('kohastaff.controllers', ['ngFileUpload'])

.factory('bvStaffDlg', ["$uibModal", "configService", "userService", "kwApi", function($uibModal, configService, userService, kwApi){

    // globally available staff-side modal dialogs.
    return {
        patronSelect: function(){
            // returns promise resolving to patron id.
            var modalInstance = $uibModal.open({
                        backdrop: true,
                        templateUrl: '/app/static/partials/staff/patron-select-modal.html',
                        controller: ["$scope", "$uibModalInstance", function($scope, $uibModalInstance){
                            $scope.selectPatron = function(id){
                                $uibModalInstance.close(id);
                            };
                        }],
                        windowClass: "modal patron-select-modal",
                        size: 'md',
            });
            return modalInstance.result;

        },
        marcImportItem: function(){
            var modalInstance = $uibModal.open({
                backdrop: true,
                templateUrl: '/app/static/partials/staff/marc-import-item-modal.html',
                controller: //  'MarcImportItemCtrl',
                 ["$scope", "$uibModalInstance", function($scope, $uibModalInstance){
                    $scope.item = new kwApi.Item();
                    var defaults = {
                        homebranch: userService.login_branch,
                        holdingbranch: userService.login_branch,
                    };
                    configService.ItemFields.forEach(function(fieldDef){
                        if(fieldDef.editor.default) defaults[fieldDef.code] = fieldDef.editor.default;
                    });
                    $scope.item.$applyDefaults(defaults);

                    $scope.visibleFields = configService.ItemFields.filter(function(fieldDef){ return fieldDef.visibility; });
                    $scope.itemTemplates = kwApi.ItemTemplate.
                            getList( { branchcode: userService.login_branch }).
                            sort(function(a,b){ return a.name.localeCompare(b.name);});

                    $scope.applyTmpl = function(tmpl){
                        if(tmpl) $scope.item.$applyDefaults( tmpl.template );
                        else $scope.item.$applyDefaults( defaults );
                    };
                    $scope.save = function(){
                        var minimal_item = {};
                        // # flatten custom fields .
                        angular.forEach($scope.item, function(v,k){
                            if( configService.ItemFields.find( function(f){ return f.code==k && f.editor.readonly; })){
                                return; // next;
                            }  // should prob also skip if no marc subfield defined.
                            if(k=='fields'){
                                angular.forEach(v, function(fv, fk){
                                    if(fv) minimal_item[fk] = fv;
                                });
                            } else {
                                if(v) minimal_item[k] = v;
                            }
                        });

                        $uibModalInstance.close(minimal_item);
                    };
                }],
                windowClass: "modal import-item-modal",
                size: 'md',
            });
            return modalInstance.result;
        }
    };

}])

.controller('StaffCtrl', ["$scope", "$state", "userService", "$timeout", "$location", "alertService", "kohaSearchSvc", "widgetIdx", "widgetList", "$rootScope", "staffLegacyApp", "$sce", "$http", "SearchQuery", "bibService", "kohaDlg", "bvStaffDlg", "$uibModal", "bvSearchToPick", "awLogService", function($scope, $state, userService, $timeout, $location, alertService,
        kohaSearchSvc, widgetIdx, widgetList, $rootScope, staffLegacyApp, $sce, $http, SearchQuery,
        bibService, kohaDlg, bvStaffDlg, $uibModal, bvSearchToPick, awLogService) {
    var params = $location.search();
    $scope.isPrintView = (params && params.wack_view == 'print');
    if ($scope.isPrintView) {
        $timeout(function() { 
            window.print();
        }, 1000);
    }

    $scope.setDefaultWidgetColumns = function() {
        $scope.widgetColumns = [
            [
                'staff.circ', 'staff.patrons.checkout-search', 'staff.circ.checkin-search', 'staff.circ.transfers',
                'staff.patrons.search',
                'staff.search-title', 'staff.catalog-search', 'staff.lists',
            ], [
                'staff.marced.home', 'staff.tools.batch-item-edit', 'staff.serials', 'staff.authorities', 
                'staff.acquisitions', 'staff.reports', 'staff.admin', 'staff.admin.sysprefs', 'staff.tools', 'staff.about'
            ], [
            ]
        ];
    };

    $scope.populateWidgetDock = function() {
        var isUsed = {};
        angular.forEach($scope.widgetColumns, function(col) {
            angular.forEach(col, function(widget) {
                isUsed[widget] = true;
            });
        });
        $scope.unusedStaffWidgets = jQuery.grep(widgetList,function(w) { return !isUsed[w]; });
    }

    if (userService.merged_prefs.staff_home_widgets) {
        $scope.widgetColumns = userService.merged_prefs.staff_home_widgets;
    }
    else {
        $scope.setDefaultWidgetColumns();
    }
    $scope.populateWidgetDock();
    $scope.oldWidgetColumns = angular.copy($scope.widgetColumns);


    $scope.configIsDirty = false;
    $scope.$watch('widgetColumns', function(newVal, oldVal) {
        if (newVal && oldVal && !angular.equals(newVal, $scope.oldWidgetColumns)) {
            $scope.configIsDirty = true;
        }
    }, true);

    $scope.cancelConfig = function() {
        $scope.widgetColumns = angular.copy($scope.oldWidgetColumns);
        $scope.configIsDirty = false;
    };

    $scope.saveConfig = function() {
        userService.addPrefs({staff_home_widgets: $scope.widgetColumns}).then(function() {
            $scope.configIsDirty = false;
            $scope.oldWidgetColumns = angular.copy($scope.widgetColumns);
        });
    };

    $scope.resetConfig = function() {
        $scope.setDefaultWidgetColumns();
        $scope.configIsDirty = true;
        $scope.populateWidgetDock();
        $scope.oldWidgetColumns = angular.copy($scope.widgetColumns);
    };

    $scope.widget = widgetIdx;
    $scope.user = userService;
    $scope.simpleSearch = kohaSearchSvc.doSimpleSearch;
    $scope.showSidebar = true;

    $scope.showWidget = function(widget) {
        if (widget.permissions) {
            if (!userService.can(widget.permissions)) {
                return false;
            }
        }
        if (widget.displayIf) {
            if (!widget.displayIf())
                return false;
        }
        return true;
    };


    $scope.splitColumns = function(ary,cols) {
        var n = Math.ceil(ary.length / cols);
        var rv = [];
        var i;
        for (i=0; i<cols; i++) {
            rv.push(ary.slice(i*n, (i+1)*n));
        }
        return(rv);
    };


    $scope.sortableOptions = {
        placeholder: "staff-widget",
        connectWith: ".staff-app-box"
    };

    $scope.resetLegacyApp = function(w) {
        if ($scope.currentState.name == w.state) {
            $state.reload();
        }
    };
    
    $scope.staffnews = [];
    $http.get('/api/opac-news?staff', {
        cache: true
    }).then(function (rsp) {
        var data = rsp.data;
        var news = [];
        for (var id in data) {
            news.push({
                title: data[id].title,
                entry: $sce.trustAsHtml(data[id]['new']),
                date: data[id].timestamp,
                order: Number(data[id].number)
            });
        }
        $scope.staffnews = news.sort(function(a,b) {return a.order - b.order})
    });   

    var iframe_listener = function(e){
        if (window.location.origin != e.origin) return;
        var modal;
        // listen for:
        //   * location changes.
        //   * authentication loss
        //   * do-search to trigger Wack search from legacy staff.


        if ((e.data.action == 'route' || e.data.action == 'iframe-location') && e.data.path) {
            console.log("Caught " + e.data.action + " path=" + e.data.path + " search=" + e.data.search);
            
            if (!$scope.showSidebar) {
                $timeout(function() {
                    $scope.showSidebar = true;
                });
            }
            if (/^\/bvcgi/.test(e.data.path)) {
                if (e.data.path == '/bvcgi/members/member.pl' && e.data.search == '') {
                    return; // Don't follow the form POST with an empty results GET. #173271444
                }
                else if (e.data.path == '/bvcgi/circ/callslips.pl' && e.data.search == '') {
                    return; // Don't follow the form POST immediately with a GET. #181140428
                }
                e.data.path = e.data.path.replace(/\%2F/g, '/');
                console.log("Updated path to " + e.data.path);
                staffLegacyApp.matchRoute(e.data, (e.data.action == 'route'));
            }
            else if(/^\/app\//.test(e.data.path)){
                $timeout(function(){
                    $location.url(e.data.path + e.data.search);
                });
            }

            // we'll get this twice on session expiry.

/*            if (e.data.authRequired && e.data.authRequired != 'nopermission'){
                $rootScope.$broadcast('loginRequired', {sessionExpired: e.data.authRequired=='timed_out'});
                // In the event of no auth the listener will redirect to /
            }*/

            //$timeout(angular.noop); // Trigger a digest cycle to ensure changed iframe src is picked up.

        }
        else if (e.data.action == 'iframe-resize') {
            //console.dir(window.location);
            if (window.location.pathname != '/app/staff/acquisitions') {
                $('#staff-iframe').height(e.data.height);
            }
        }
        else if (e.data.action == "do-search"){
            // searchToHold/ searchToIssue:
            if(e.data.searchToPick){
                var stp = e.data.searchToPick;
                if(stp.action == 'issue'){
                    bvSearchToPick.issueTo( stp.borrowernumber );
                } else {
                    bvSearchToPick.holdFor( stp.borrowernumber );
                }
            }
            $timeout(function(){
                if(e.data.query){
                    var search = new SearchQuery({q: e.data.query});
                    search.go();
                } else {
                    $location.url('/app/search');
                }
            });
        } else if (e.data.action == 'details'){
            $timeout(function(){
                $location.url('/app/work/'+ e.data.id);
            });
        } else if (e.data.action == 'clearCache'){
            bibService.clearCache(e.data.bibid);
        }
        else if(e.data.action == 'view-aw-log'){
            $location.url('/app/staff/tools/dlso-log-viewer')
        }
        else if(e.data.action === 'place-a-hold'){
            var bibids = (e.data.bibids) ? e.data.bibids : [ e.data.bibid ];
            modal = kohaDlg.placeHold(bibids, e.data.borrowernumber);
            if(e.data.refresh){
                modal.then(function(rs){
                    $state.reload();
                });
            }
        }
        else if (e.data.action == 'view-by-uuid'){
            var UUID = prompt("Please Enter GUIDE/UUID : ", "");
            if (UUID == null)
                return;

            var patt = new RegExp("(GUIDE://[0-9]{4}/)?([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$");
            if(patt.test(UUID)){
                var scrubbedUUID = patt.exec(UUID)[2];

                window.open(
                    "../../app/static/fullviewer.html?uuid="+scrubbedUUID ,
                    "width=1200,height=600,scrollbars=no,toolbar=no,screenx=0,screeny=0,location=no,titlebar=no,directories=no,status=no,menubar=no");
            }
            else {
                alert("UUID/GUIDE must be in correct GUIDE or UUID format: e.g. GUIDE://1111/11111111-1111-1111-1111-111111111111 or 11111111-1111-1111-1111-111111111111 ");
            }
        }
        else if (e.data.action == 'back-to-results') {
            $timeout(function() {
                var search = kohaSearchSvc.currentSearch();
                if(search){
                    $location.path( search.asUrl() ).hash('kohabib-' + e.data.id);
                }
            });
        }
        else if (e.data.action == 'step-results') {
            var bibid = e.data.id;
            var dir = e.data.dir;
            var newSearch;
            var search = kohaSearchSvc.currentSearch();
            if (!search || !search.results) {
                $timeout(function() {
                    alertService.add({msg: "You are viewing a single record; there is no search to page through!", type: "info"});
                });
            }

            else if (search.results.isFirstOnPage(bibid) && dir == -1) {
                if (search.results.pager.page == 1) {
                    $timeout(function() {
                        alertService.add({msg: "You have reached the first search result", type: "info"});
                    });
                    return;
                } else {
                    search.page--;
                    newSearch = new SearchQuery(search);
                    kohaSearchSvc.currentSearch(search);
                    newSearch.fetch().then(function(rs){
                         $state.go('staff.bib.details', { biblionumber: rs.bibs[rs.bibs.length - 1].id });
                    });
                    return;
                }
            } else if (search.results.isLastOnPage(bibid) && dir == 1) {
                if (search.results.pager.page == search.results.pager.numPages) {
                    $timeout(function() {
                        alertService.add({msg: "You have reached the last search result", type: "info"});
                    });
                    return;
                } else {
                    search.page++;
                    newSearch = new SearchQuery(search);
                    kohaSearchSvc.currentSearch(search);
                    newSearch.fetch().then(function(rs){
                         $state.go('staff.bib.details', { biblionumber: rs.bibs[0].id });
                    });
                    return;
                }
            } else {
                var targetbibid = search.results.step(bibid, dir);
                if (targetbibid) {
                    $timeout(function() {
                        $state.go('staff.bib.details', {biblionumber: targetbibid}, {reload: true});
                        //console.log("Set to " + $location.url());
                    });
                }
            }
        } else if (e.data.action == 'add-marc-import-item') {

            console.warn( e );

            modal = bvStaffDlg.marcImportItem().then(function(item){
                if(item){
                    console.log(item);
                    var msg = {
                        action: "add-marc-import-item",
                        path: window.location.pathname,
                        item: item
                    };
                    var childframe = e.source;
                    childframe.postMessage(msg, window.location.origin||"*");
                }
            });

        }

    };

    if(window.addEventListener){
        window.addEventListener("message", iframe_listener);
        $scope.$on('$destroy', function () {
            window.removeEventListener("message", iframe_listener);
        });
    } else {
        window.attachEvent("onmessage", iframe_listener);
        $scope.$on('$destroy', function () {
            window.detachEvent("onmessage", iframe_listener);
        });
    }

}])

.controller('StaffToolsCtrl', ["$scope", "staffLegacyApp", function($scope, staffLegacyApp) {
    $scope.widgets = staffLegacyApp.getWidgets('tools');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('tools');
    $scope.menuColumns = $scope.splitColumns($scope.widgets, 2);

    $scope.labelWidgets = staffLegacyApp.getWidgets('labels');
    $scope.labelWidgetMenu = staffLegacyApp.getWidgetSidebar('labels');
    $scope.labelMenuColumns = $scope.splitColumns($scope.labelWidgets, 1);

    $scope.isLabelState = function(s) {
        return (s.match(/^staff\.labels/) ? true : false);
    };
}])

.controller('StaffBibCtrl', ["$scope", "staffLegacyApp", "$stateParams", function($scope, staffLegacyApp, $stateParams) {
    $scope.bibid = $stateParams.biblionumber;
    $scope.widgets = staffLegacyApp.getWidgets('bib');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('bib');
    $scope.menuColumns = $scope.splitColumns($scope.widgets, 3);
}])

.controller('StaffAuthoritiesCtrl', ["$scope", "staffLegacyApp", "$stateParams", "$state", function($scope, staffLegacyApp, $stateParams, $state) {
    $scope.authid = $stateParams.authid;
    $scope.widgets = staffLegacyApp.getWidgets('authorities');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('authorities');
    $scope.menuColumns = $scope.splitColumns($scope.widgets, 3);
}])

.controller('StaffPatronsCtrl', ["$scope", "staffLegacyApp", "$stateParams", function($scope, staffLegacyApp, $stateParams) {
    $scope.widgets = staffLegacyApp.getWidgets('patrons');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('patrons');
    $scope.menuColumns = $scope.splitColumns($scope.widgets, 1);
}])

.controller('StaffPatronCtrl', ["$scope", "staffLegacyApp", "$stateParams", "kwApi", "$state", function($scope, staffLegacyApp, $stateParams, kwApi, $state) {
    $scope.patronid = $stateParams.borrowernumber;
    $scope.widgets = staffLegacyApp.getWidgets('patron');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('patron');
    $scope.menuColumns = $scope.splitColumns($scope.widgets, 1);
    $scope.patron = kwApi.Patron.get({ id: $scope.patronid });
}])

.controller('StaffAdministrationCtrl', ["$scope", "staffLegacyApp", "userService", function($scope, staffLegacyApp, userService) {
    $scope.widgets = staffLegacyApp.getWidgets('admin');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('admin');
    $scope.menuGroup = staffLegacyApp.getWidgetGroups('admin');
    userService.whenAuthenticatedUserDetails().then(function(details){
        $scope.canViewSuperlibrarianGuide =  details.superlibrarian;
    });    
}])

.controller('StaffReportsCtrl', ["$scope", "staffLegacyApp", function($scope, staffLegacyApp) {
    $scope.widgets = staffLegacyApp.getWidgets('reports');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('reports');
    $scope.menuGroup = staffLegacyApp.getWidgetGroups('reports');
}])

.controller('StaffCirculationCtrl', ["$scope", "staffLegacyApp", function($scope, staffLegacyApp) {
    $scope.widgets = staffLegacyApp.getWidgets('circ');
    $scope.widgetMenu = staffLegacyApp.getWidgetSidebar('circ');
    $scope.menuGroup = staffLegacyApp.getWidgetGroups('circ');
}])

.controller('StaffPatronRegCtrl', ["$scope", "patronRegSvc", "$window", function($scope, patronRegSvc, $window) {
    $scope.syncApps = function () {
        patronRegSvc.getPatronApps().then(function(r) {
            $scope.patron_regs = r;
            $scope.patron_regs.forEach(function(d) {
                d.reg_data = angular.fromJson(d.reg_data);
                const data = d.reg_data;
                const sortKeys = "firstname,surname,email,dateofbirth,address,address2,city,state,zipcode,country,phone";
                const keys = Object.keys(data).filter(key => sortKeys.includes(key));
                const sortedObj = Object.fromEntries(
                    keys.sort((a, b) => sortKeys.indexOf(a) - sortKeys.indexOf(b))
                        .map(key => [key, data[key]])
                );
                d.reg_data = sortedObj;
            });
        });
    }

    $scope.rejectApp = function (d) {
        patronRegSvc.rejectAppSvc(d).then(function() {
            $scope.syncApps();
        });
    }

    $scope.approveApp = function (d) {
        patronRegSvc.approveAppSvc(d).then(function() {
            $scope.syncApps();
            const qs = new URLSearchParams(d.reg_data).toString();
            $window.open("/app/staff/patrons/addnew?op=add&notify=1&" + qs);
        });
    }

    $scope.syncApps();
}])

.controller('StaffMarcBatchEditCtrl', ["$scope", "batches", "importBatchService", "$uibModal", function($scope, batches, importBatchService, $uibModal) {
    var batchSVC = importBatchService;
    $scope.order = batchSVC.sort;
    $scope.pager = batchSVC.pager;
    $scope.currentPage = 1;

    $scope.pageChanged = function () {
        $scope.pager.setPage($scope.currentPage);
        batchSVC.toPage($scope.currentPage);
    }

    $scope.$watch('order', function(newVal) {
        if (newVal) {
            batchSVC.getList();
        }
    }, true);

    $scope.classOf = function(n) {
        return 'import-batch-' + n.import_status;
    };

    // We don' need no steenkin ... oh, wait, actually we do
    $scope.batches = batches;

    $scope.canFilter = function(n) {
        return (n.import_status === 'staged');
    };

    $scope.filter = function(n) {
        $uibModal.open({
            backdrop: true,
            templateUrl: '/app/static/partials/staff/tools/marc-batch-edit/filter.html',
            controller: 'StaffMarcBatchEditFilterCtrl', // ew
            windowClass: 'modal',
            resolve: {
                batch: function() { return n; },
                berts: ["bertService", function(bertService) {
                    return bertService.getList();
                }],
            }
        });
    };
}])

.controller('StaffMarcBatchEditFilterCtrl', ["$scope", "batch", "berts", "$uibModalInstance", "importBatchService", "alertService", function($scope, batch, berts, $uibModalInstance, importBatchService, alertService) {
    $scope.batch = batch;
    $scope.filters = [{}];
    $scope.berts = berts;

    $scope.filterSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: ['title','description'],
        create: false,
        render: {
            item: function(rec, escape) {
                if (!rec) return '';
                return '<div>' + escape(rec.title) + ' - <small>' + escape(rec.description) + '</small></div>';
            },
            option: function(rec, escape) {
                if (!rec) return '';
                return '<div>' + escape(rec.title) + ' - <small>' + escape(rec.description) + '</small></div>';
            },
        },
    };

    $scope.rmFilter = function(idx) {
        $scope.filters.splice(idx,1);
    };

    $scope.addFilter = function(idx) {
        $scope.filters.push({});
    };

    $scope.canStart = function() {
        var ok = true;
        if (!$scope.filters.length)
            return false;
        $scope.filters.forEach(function(f) {
            if (!(f.selected))
                ok = false;
        });
        return ok;
    };

    $scope.mode = {async: 1};

    $scope.start = function() {
        var filter_ids = $scope.filters.map(function(f) { return f.selected; });
        importBatchService.filter(batch, filter_ids, $scope.mode.async).then(function() {
            $uibModalInstance.close();
            if (1 == $scope.mode.async) 
                alertService.add({msg: "Import batch edit started; you will be notified when it is complete", type: "info"});
            else 
                alertService.add({msg: "Batch edit complete", type: "info"});
        }, function(err) {
            alertService.addApiError(err,'Can\'t edit import batch');
            $uibModalInstance.close();
        });
    };
}])

.controller('StaffCatalogSearchCtrl', ["$scope", "$injector", "configService", "$state", "$timeout", "$rootScope", "SearchQuery", "kohaSearchSvc", function($scope, $injector, configService, $state, $timeout, $rootScope,
            SearchQuery, kohaSearchSvc){
        $scope.inputQuery = {q : ""};
        $scope.clearMeOut = function(){
            if(configService.geospatialSearch && ARCHVIEW.mapLayers && ARCHVIEW.mapLayers.length > 0){
                var mapComptrollerSvc = $injector.get('mapComptrollerSvc');
                if(mapComptrollerSvc) mapComptrollerSvc.clearAOIAndDontSearch();
                $rootScope.$broadcast("triggerClearAll");
            }
            $scope.inputQuery.q = "";
            kohaSearchSvc.clearCurrentSearch();
//            $state.go("search", undefined, {inherit:false});
        };

        $scope.$on('$stateChangeSuccess', function () {
            if($state.includes('search-results')){
                $timeout(function(){
                    var curSrch = kohaSearchSvc.currentSearch();
                    if(curSrch){
                        var q = curSrch.q;
                        if(/^\([^\(\)]+\)$/.test(q)){
                            q = q.replace(/^\(|\)$/g, '');
                        }
                        $scope.inputQuery.q = q;
                    }
                });
            } else {
                $scope.inputQuery.q = "";
            }
        });
        $scope.$on('clearUserData', function() {
            $scope.inputQuery.q = '';
        });

        $scope.submitSearch = function(){
            var search = new SearchQuery({q: $scope.inputQuery.q});
            kohaSearchSvc.doSimpleSearch(search);
        };
}])

.controller('StaffCallNumberBrowseCtrl', ["$scope", "userService", "kwApi", "loading", "Pager", "bvDownloadSvc", function($scope, userService, kwApi, loading, Pager, bvDownloadSvc){
    $scope.results = [];
    $scope.browse = {
        branch: null,
        ccode: null,
        callnumber: null,
    };
    $scope.ccodes_list = $scope.config.listCodes('ccode');
    $scope.browse.ccode = $scope.ccodes_list[0];
    var pagelength = 100;
    var filterResults = null;
    $scope.pageData = [];
    $scope.page = 1;

    $scope.pageChanged = function (page) {
        var begin = ((page - 1) * pagelength);
        var end = begin + pagelength;
        if ($scope.search) {
            $scope.pageData = filterResults.slice(begin, end);
        }
        else {
            $scope.pageData = $scope.results.slice(begin, end);
        }
    }

    $scope.filterSearch = function () {
        filterResults = $scope.results.filter(function(i) {
            var re = new RegExp("^" + $scope.search, "i");
            if (re.test(i.callnumber)) return i;
        });
        $scope.pager = new Pager({count: filterResults.length, pagelength: pagelength});
        var begin = 0;
        var end = begin + pagelength;
        $scope.pageData = filterResults.slice(begin, end);
    }

    userService.getAccessibleBranchesAndGroups('circulate.circulate_remaining_permissions').then(function(b) {
        $scope.branches_list = b.branches;
        $scope.branches_list.forEach( function (branch) {
            if (branch.branchcode == userService.login_branch) {
                $scope.browse.branch = branch;
                $scope.browse.fetch();
            }
        });
    }, function(e) {
        console.error("Branch list loading error in StaffCallNumberBrowseCtrl: " + e);
    });

    $scope.browse.fetch = function() {
        loading.add();
        $scope.page = 1;
        kwApi.Item.getCnBrowse({branch: $scope.browse.branch.branchcode, ccode: $scope.browse.ccode},
            function success(d) {
                $scope.results = [];
                d.forEach(function(i) {
                    $scope.results.push({biblionumber: i[2], itemnumber: i[0], callnumber: i[1] ? i[1] : '<null>', bibtitle: i[4], barcode: i[3]});
                });
                if ($scope.search) {
                    $scope.filterSearch();
                }
                else {
                    $scope.pager = new Pager({count: $scope.results.length, pagelength: pagelength});
                }
                $scope.pageChanged(1);
                loading.resolve();
            },
            function fail(e) {
                console.error(e);
                loading.resolve();
            }
        );
    }

    let getTheData = () => {
        let output = "\"callnumber\",\"itemnumber\",\"biblionumber\",\"barcode\",\"title\"\n";
        for (var i = 0; i < $scope.pageData.length; i++) {
            output += "\"" + $scope.pageData[i]['callnumber'] + "\",";
            output += "\"" + $scope.pageData[i]['itemnumber'] + "\",";
            output += "\"" + $scope.pageData[i]['biblionumber'] + "\",";
            output += "\"" + $scope.pageData[i]['barcode'] + "\",";
            output += "\"" + $scope.pageData[i]['bibtitle'].replace( /\"/g, '""' ) + "\","; // double-quote is the escape character
            output += "\n";
        }
        return output;
    };

    $scope.fetchFile = () => {
        bvDownloadSvc.fetch({fetchData: getTheData, fileName: 'cn_browse.csv'});
    };
}])

.controller('StaffCircCheckinCtrl', ["$scope", "$http", "$uibModal", "$timeout", "$stateParams", "$state", "kwApi", "configService", "userService", "alertService", "kwBeepSvc", "$location", function($scope, $http, $uibModal, $timeout, $stateParams, $state, kwApi, configService, userService, alertService, kwBeepSvc,
    $location) {

    $scope.checkin_params = { barcode: '', exemptfine: false, date_override: null, dropbox: false };

    $scope.datePickOpts = {
        minDate: '1970-01-01',
        maxDate: new Date()
    };
    $scope.checkin_history = []; // FIXME: This should probably persist beyond controller.

    var max_history_length = 50;

    $scope.checkins = {
        list: [],   // ordered.
        history: {}, // by item_id.
        add: function(checkin){
            // add or replace item.
            if(!checkin.item)return;
            var item_id = checkin.item.id;
            this.list = this.list.filter(function(chk){ return (chk.item.id != item_id); });
            this.list.unshift(checkin);
            this.history[checkin.item.id] = checkin;
            if(this.list.length > max_history_length){
                var self = this;
                var removed = this.list.splice( max_history_length );
                removed.forEach(function(checkin){ delete self.history[checkin.item.id]; });
            }
        },

    };

    $scope.soundon = configService.soundon;

    $scope.visible_history = configService.numReturnedItemsToShow || 10;

    $scope.messages = [];

    $scope.cfg = configService;

    var bcel = $("#checkinBarcode");
    $scope.returnFocus = function(test_view){
        // FIXME: EWW.
        if(test_view){  // i.e. model doesn't update unless it's a valid date.
            if( !$scope.date_override ) return;
        }

        bcel.focus();
    };
    bcel.focus();
    $scope.toggleDropbox = function(){
        // Note we dropbox date is dependent on item's circControl branch, so we don't prefetch.
        $scope.checkin_params.dropbox = !$scope.checkin_params.dropbox;
        $scope.returnFocus();
    };

    var thisbranch = userService.login_branch;

    $scope.clear = function(){
        $scope.messages = [];
        $scope.checkin_params.barcode = '';
        $scope.transfer = undefined;
        $scope.tabsearch.checkin.barcode = '';
    };

    $scope.doCheckin = function(item_id){

        if(!$scope.checkin_params.barcode) return;

        var params = {
                        barcode: $scope.checkin_params.barcode,
                        exemptfine: $scope.checkin_params.exemptfine,
                        dropbox: $scope.checkin_params.dropbox
                        };
        if($scope.checkin_params.date_override) params.date_override = $scope.checkin_params.date_override.toISOString();

        $scope.clear();

        kwApi.Item.checkin({ id: item_id }, params,
                function success(item){

                    var sideband = angular.copy(item._embed);
                    delete item._embed;
                    $scope.messages = angular.copy(sideband.notices);

                    var checkin = {
                        item: item,
                        issue: sideband.issue_summary,  // may be undefined.
                        bib_summary: sideband.bib_summary,
                        hold: undefined,
                        messages: angular.copy(sideband.notices),
                        patron: undefined,
                        checkin_note: undefined
                    };

                    if (sideband.checkin_note)
                        checkin.checkin_note = new kwApi.ItemCheckinNote( sideband.checkin_note );
                    if (sideband.trapped_hold)
                        checkin.hold = new kwApi.Hold( sideband.trapped_hold );
                    if (sideband.issue_summary)
                        checkin.overdue = dayjs(sideband.issue_summary.duedate).isBefore();

                    $scope.checkins.add( checkin );

                    var alert_tone = 'beep';

                    if(checkin.checkin_note){
                        alert_tone = 'warn';
                        $uibModal.open({
                            templateUrl: '/app/static/partials/staff/circ/item-checkin-note-modal.html',
                            animation: false,
                            // keyboard: false,
                            size: 'sm',
                            windowClass: 'item-checkin-note',
                            backdrop: 'static',
                            resolve: {
                                checkin_note: function(){ return checkin.checkin_note; },
                                item: function(){ return item; },
                                checkin: function(){ return true; }
                            },
                            controller: 'ItemCheckinNoteCtrl'
                        });
                    }

                    if(item.in_transit){
                        $scope.transfer = {
                            to: item.in_transit.to,
                            bibid: item.biblionumber,
                            title: checkin.bib_summary.title,
                        };
                        alert_tone = 'warn';
                    }
                    $scope.cancelTransfer = function(){
                        item.$cancelTransfer().then(function(rsp){
                            console.log(rsp);
                            $scope.clear();
                            $scope.checkins.history[item.id].item.in_transit = undefined;
                            alertService.add({ msg: "Transfer cancelled.", type: "success"});
                        });
                    };

                    if(checkin.hold){
                        // hold-transit modal...
                        // note that actions taken in this modal can affect the parent item.
                        // if it's on hold, we need confirm hold and possibly initiate xfer.

                        var checkinScope = $scope;
                        alert_tone = 'alert';
                        $uibModal.open({
                            templateUrl: '/app/static/partials/staff/circ/hold-transfer-modal.html',
                            animation: false,
                            // keyboard: false,
                            backdrop: 'static',
                            controller: ["$scope", function($scope) {
                                $scope.cfg = configService;
                                $scope.checkin = checkin;
                                $scope.send_to = (item.in_transit||{}).to;
                                $scope.today = new Date();
                                if(checkin.hold){
                                    kwApi.Patron.get( {id: checkin.hold.borrowernumber}, function(p){ $scope.patron = p; });
                                    if(checkin.hold.branchcode != thisbranch){
                                        $scope.send_to = checkin.hold.branchcode;
                                    }
                                }
                                $scope.confirmHold = function(print_slip) {
                                    checkin.hold.$confirm_fill( { item_id: item.id } ).then(function success(rsp){
                                            if(checkin.hold.branchcode != thisbranch){
                                                // confirming hold initiated a transfer.
                                                //FIXME: should probably just refresh item from server; this transfer info is incomplete
                                                checkinScope.checkins.history[item.id].item.in_transit = { to : checkin.hold.branchcode };

                                            }
                                            //  ELSE -  FIXME: we need to show that the item is marked to fill hold...
                                            // either message 'filling local hold' or 'transfer to ....'

                                            if (print_slip) {
                                                checkin.hold.$print_slip( { send_to: !!$scope.send_to } ).then(function success(rsp){
                                                    var utf8Data = decodeURIComponent(escape(rsp.data));
                                                    var printWindow = window.open();
                                                    printWindow.document.write(utf8Data);
                                                    printWindow.document.close();
                                                    printWindow.print();
                                                }, function err(e){
                                                    console.error("Failed to print hold slip: " + e.data);
                                                    alertService.addApiError(e,'Failed to print hold slip');
                                                });
                                            }
                                        }, function err(e){
                                            alertService.addApiError(e,'Failed to confirm hold');
                                        });
                                    $scope.$close();
                                };

                                $scope.ignoreHold = function(){
                                    if(checkin.hold.found){
                                        checkin.hold.$requeue();
                                        checkinScope.messages.push({ code: 'hold_requeued'});
                                    }
                                    else if(checkin.hold){
                                        checkinScope.messages.push({ code: 'unhandled_hold'});
                                    }
                                    $scope.$close();
                                };
                            }],
                        });
                    } // endif checkin.hold


                    if(checkin.issue){
                        checkin.issue.patron_display = checkin.issue.borrowernumber;
                        kwApi.Patron.get( { id: checkin.issue.borrowernumber }, function(p){
                            checkin.issue.patron_display = p.surname + ', ' + p.firstname + ' (' + p.categorycode + ')';
                            checkin.issue.patron_note = p.borrowernotes;
                        });
                    } else {
                        $scope.messages.push({ code: 'not_issued'} );
                    }

                    if($scope.soundon) kwBeepSvc.play(alert_tone);

                }, function fail(rsp){

                    if(angular.isObject(rsp.data)){

                        if(rsp.data.lost_item){
                            // staff must handle lost_item, can resubmit afterwards.
                            var lostItem = new kwApi.LostItem( rsp.data.lost_item );
                            var checkinScope = $scope;
                            $uibModal.open({
                                templateUrl: '/app/static/partials/staff/circ/lost-item-modal.html',
                                animation: false,
                                keyboard: false,
                                backdrop: 'static',
                                controller: ["$scope", function($scope) {

                                    // FIXME: duplicated in circ-status controller.
                                    $scope.context = 'checkin';
                                    $scope.loserName = lostItem.borrowernumber;
                                    kwApi.Patron.get({id: lostItem.borrowernumber}, function(patron){
                                        $scope.loser = patron;
                                        $scope.loserName = patron.surname + ', ' + patron.firstname;
                                    });

                                    $scope.canHandleLostItem = userService.can({borrowers: 'delete_lost_items'});
                                    $scope.canRefundLostItem = configService.RefundReturnedLostItem;
                                    $scope.lost = {
                                        remove: !!$scope.canHandleLostItem,
                                        refund: ($scope.canRefundLostItem) ? 'LOSTRETURNED' : ''
                                    };

                                    $scope.handleLost = function() {

                                        lostItem.$checkin( {
                                                id: lostItem.id,
                                                remove: $scope.lost.remove,
                                                refund: $scope.lost.refund
                                            }).then(function success(li_rsp){
                                                checkinScope.checkin_params.barcode = lostItem.barcode;
                                                checkinScope.doCheckin(lostItem.itemnumber);
                                            }, function err(e){
                                                alertService.addApiError(e,'Failed to handle lost item');
                                            });
                                        $scope.$close();
                                    };

                                    $scope.cancel = function(){
                                        checkinScope.messages.push({ code: 'checkin_fail'});
                                        $scope.$close();
                                    };
                                }],
                            });
                        }
                    } else {
                        if(rsp.status==404){
                            $scope.messages = [ { code: 'not_found'} ];
                        } else if(/^403 Item is withdrawn/.test(rsp.data)){
                            $scope.messages = [ { code: 'withdrawn'} ];
                        }
                        alertService.addApiError(rsp,'Checkin failed');

                        bcel.focus();
                    }
                    if($scope.soundon) kwBeepSvc.play('warn');
                }
        );
        bcel.focus();
    };

    if($stateParams.barcode){
        $scope.checkin_params.barcode = $stateParams.barcode;
        $scope.doCheckin();
        $location.path('/app/staff/circ/checkin/').replace(); // param barcode is marked 'dynamic', the view is NOT updated when the URL is changed
    }

}])

.controller('ItemCheckinNoteCtrl', ["$scope", "checkin_note", "item", "checkin", "userService", "kwApi", "alertService", function($scope, checkin_note, item, checkin, userService, kwApi, alertService){

    $scope.checkin_note = checkin_note;

    $scope.editable = !checkin && userService.can({circulate: {item_checkin_notes: 'modify'}});
    $scope.deletable = userService.can({circulate: {item_checkin_notes: 'delete'}});

    if (!checkin_note) {
        $scope.checkin_note = new kwApi.ItemCheckinNote({item_id: item.id});
        $scope.deletable = false;
    }
    else if (checkin_note.$promise) {
        checkin_note.$promise.then(
            function(){  },
            function(){  // assume 404.
                $scope.checkin_note = new kwApi.ItemCheckinNote({item_id: item.id});
                $scope.deletable = false;
            });
    }
    else { // note was embedded in item
        checkin_note.$resolved = true;
    }

    $scope.saveNote = function(){
        if(!$scope.editable) return;
        var method = ($scope.checkin_note.$resolved) ? 'update' : 'save';
        kwApi.ItemCheckinNote[method]($scope.checkin_note).$promise.then(function(note) {
            alertService.add({msg: 'Checkin note saved.', type: 'success'});
            $scope.checkin_note = note;
            $scope.$close($scope.checkin_note);
        }, function(err) {
            alertService.addApiError(err,'Failed to save note');
            $scope.$close();
        });
    };
    $scope.deleteNote = function(){
        if(!$scope.deletable) return;
        kwApi.ItemCheckinNote.delete($scope.checkin_note).$promise.then(function() {
            delete $scope.checkin_note.note;
            $scope.checkin_note.$resolved = false;
        });
        $scope.$close($scope.checkin_note);
    };

}])

.controller('StaffCircOfflineCtrl', ["$scope", "loading", "kwApi", "configService", "alertService", "userService",
                                    function($scope, loading, kwApi, configService, alertService, userService) {

    $scope.extActive = false;

    function clearCirc (){
        console.log('clearing data');
            window.postMessage({
            type: 'offline-circ-app',
            do: 'clear-circ'
        }, '*');
    }

    $scope.extension = {
        config: null,
        checkouts: null
    };
    $scope.checkouts = [];

    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {

        var msg = event.data;
        if( event.source != window || msg.type !='offline-circ-ext' ) return;
        // msg.do = 'data' is the only msg we receive anymore.

        if(msg.do == 'data'){

            $scope.$apply( function(){
                $scope.extActive = true;
                $scope.extension.config = msg.data.config || {};
                $scope.checkouts = msg.data.checkouts || [];
                $scope.noData = !$scope.checkouts.length
            });

            // if no config data, send it.
            if( !msg.data.config || 
                msg.data.config.libraryName != $scope.extension.config.libraryName ||
                msg.data.config.hostname != $scope.extension.config.hostname
            ){
                window.postMessage({
                    type: 'offline-circ-app',
                    do: 'set-config',
                    data: {
                        libraryName: configService.pageTitle,
                        hostname: window.location.host
                    }
                }, '*');
            }

        }
    }

    $scope.$watch('checkouts', function(checkouts){
        if(checkouts){
            var map = {};
            checkouts.forEach(function(co){
                map[co.patron]++;
            });
            $scope.patronCount = Object.keys(map).length;
        }
    });

    $scope.clearAll = function(){
        $scope.synced = undefined;
        $scope.checkouts = [];
    };

    $scope.doCheckouts = function(){

        $scope.synced = {
            failed: [],
            patronCount: 0,
            issued: [],
            complete: false
        };
        var seenPatron = {};
        loading.add( 'processing_offline_circ' );
        var unwatch = $scope.$watch('synced.complete', function(done){
            if(!done) return;
            loading.resolve( 'processing_offline_circ' );
            alertService.add({
                msg: 'Offline Circulation upload complete.  ' + $scope.synced.issued.length +
                      ' Successful checkouts cleared.',
                type: 'success'});
            // optionally clear failures?
            // todo:  allow edits of failed checkouts and resubmittal.
            // automatically clear successes.

             $scope.checkouts = [];
             clearCirc();
            unwatch();
        });

        $scope.checkouts.forEach(function(co){
            kwApi.Item.offlineCheckout({
                barcode: co.item,
                cardnumber: co.patron,
                ts: co.ts
            }, function(item){
                console.log(item);
                $scope.synced.issued.push(co);
                if(!seenPatron[co.patron]) $scope.synced.patronCount++;
                seenPatron[co.patron] = true;
                if($scope.synced.issued.length + $scope.synced.failed.length == $scope.checkouts.length)
                        $scope.synced.complete = true;
            }, function(fail){
                console.warn(fail);
                var error = fail.data.replace(/^\d\d\d ?/,'');
                if(error=="Not Found") error = "Barcode not found";
                $scope.synced.failed.push( {
                    patron: co.patron,
                    item: co.item,
                    ts: co.ts,
                    error: error
                });
                if($scope.synced.issued.length + $scope.synced.failed.length == $scope.checkouts.length)
                        $scope.synced.complete = true;
            });

        });

    };

    $scope.dlErrors = function(){
        var output = "\nPatron cardnumber, Item barcode, Timestamp, Error\n\n";
        $scope.synced.failed.forEach(function(f){
            output += f.patron + ", " + f.item + ", " + f.ts + ", " + f.error + "\n";
        });
        window.open('data:text/csv;charset=utf-8,' + escape(output));
    };
    $scope.dlSuccess = function(){
        var output = "\nPatron cardnumber, Item barcode, Timestamp\n\n";
        $scope.synced.issued.forEach(function(f){
            output += f.patron + ", " + f.item + ", " + f.ts + "\n";
        });
        window.open('data:text/csv;charset=utf-8,' + escape(output));
    };

    function getExtension() {
        var url;
        if(window.navigator.userAgent.match('Firefox')){
            url = "https://addons.mozilla.org/addon/bibliovation-offline-circ/";
        } else if(window.chrome){
            url = "https://chrome.google.com/webstore/detail/lnihpohmbofgfebfamjgkhiofkpfifdj";
        } else {
            alert("Please use Chrome or Firefox to use this feature.");
            return;
        }
        window.open(url, '_blank');
    }
    $scope.goStore = getExtension;


}])

// Reports

.controller('StaffReportBaseCtrl', ["$scope", "$state", "userService", "loading", "alertService", "kwApi", "$uibModal", "$q", function($scope, $state, userService, loading, alertService, kwApi, $uibModal, $q) {
    // context container
    $scope.c = {
        showPager: true,
        page: {
            start: 0,
            count: 10,
            page: 1,
        },
        order: {
            field: 'date_created',
            reverse: true,
        },
        filterTags: [],
    };

    $scope.readonly = userService.can({reports: 'update'}) ? false : true;

    if (userService.merged_prefs.sqlreports_numresults) {
        $scope.c.page.count = userService.merged_prefs.sqlreports_numresults;
    }

    $scope.report_types = [
        { label: 'Tabular', value: 'Tabular' },
        { label: 'Matrix', value: 'Matrix' },
    ];

    $scope.matrix_sort_types = [
        { label: 'Alphabetical', value: 'alpha' },
        { label: 'Numeric', value: 'numeric' },
    ];

    $scope.runReport = function(report, schedule, email) {
        loading.add();
        kwApi.Report.getParameters({id: report.id}).$promise.then(function(parameters) {
            loading.resolve();
            var p;
            if (parameters.length) {
                p = $uibModal.open({
                    templateUrl: '/app/static/partials/staff/reports/parameters.html',
                    windowClass: 'modal',
                    controller: ["$scope", function($scope) {
                        $scope.parameters = parameters;
                        $scope.data = {};
                        $scope.close = function() {
                            $scope.$close($scope.data);
                        };
                        $scope.cancel = function() {
                            $scope.$close(undefined);
                        };
                    }],
                }).result;
            }
            else {
                p = $q.when({});
            }

            p.then(function(paramValues) {
                if (paramValues === undefined) return;

                loading.add();
                var op = (schedule ? 'schedule' : 'execute');
                kwApi.Report[op]({
                    id: report.id, 
                }, {
                    when: schedule, parameters: paramValues, email: email
                }).$promise.then(function(rv) {
                    loading.resolve();
                    alertService.add({msg: "Report " + (schedule ? "scheduled" : "started") + "; you will be notified when it is complete", type: 'info'});
                    $state.reload();
                }, function(err) {
                    loading.resolve();
                    alertService.addApiError(err, "Unable to " + (schedule ? "schedule" : "start") + " report");
                    $state.reload();
                });
            });
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, "Unable to get report parameters");
        });
    };

    $scope.scheduleReport = function(report) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/reports/schedule.html',
            size: 'lg',
            controller: ["$scope", function($scope) {
                $scope.data = {type: 'at', cron: '0:0:0:1*0:0:0'};
                if ((report._embed || {}).scheduled) {
                    if (report._embed.scheduled.cron_spec) {
                        $scope.data.type = 'repeat';
                        $scope.data.cron = report._embed.scheduled.cron_spec;
                    }
                    else if (report._embed.scheduled.scheduled_time) {
                        $scope.data.type = 'at';
                        var at = report._embed.scheduled.scheduled_time.split(' ');
                        $scope.data.atDate = at[0];
                        $scope.data.atTime = at[1];
                    }
                }

                $scope.isValid = function() {
                    $scope.err = false;
                    if ($scope.data.type === 'repeat') {
                        if (!$scope.data.cron) {
                            $scope.err = 'No repeat interval defined';
                            return false;
                        }
                    }
                    else if ($scope.data.type === 'at') {
                        if (!$scope.data.atDate) {
                            $scope.err = 'No scheduled date defined';
                            return false;
                        }
                        if (!$scope.data.atTime) {
                            $scope.err = 'No scheduled time defined';
                            return false;
                        }
                        if (! /^\d\d:\d\d:\d\d$/.test($scope.data.atTime)) {
                            $scope.err = 'Invalid time (use HH:MM:SS)';
                            return false;
                        }
                    }
                    if ($scope.data.email) {
                        if (!$scope.data.recipient) {
                            $scope.err = 'No recipient email address defined';
                            return false;
                        }
                    }
                    return true;
                };
            }],
        }).result.then(function(rv) {
            if (rv) {
                var obj = {};
                if (rv.type === 'repeat') {
                    obj.cron = rv.cron;
                }
                else {
                    obj.at = dayjs(rv.atDate).format().substr(0,10) + ' ' + rv.atTime;
                }
                var email;
                if (rv.email === true) {
                    email = rv.recipient;
                }
                return $scope.runReport(report, obj, email);
            }
        });
    };
}])

.controller('StaffReportIndexCtrl', ["$scope", "reports", "report_tags", "kwApi", "loading", "$uibModal", "alertService", "kohaDlg", "userService", function($scope, reports, report_tags, kwApi, loading, $uibModal, alertService, kohaDlg, userService) {

    $scope.c = { // should be unneeded but sandbox throws `TypeError: c is undefined` from restoreView
        showPager: true,
        page: {
            start: 0,
            count: 10,
            page: 1,
        },
        order: {
            field: 'date_created',
            reverse: true,
        },
        filterTags: [],
    };
    const pidRegex = new RegExp('^pid:\\d+$');
    const tagID = 'pid:' + userService.id;
    $scope.username = userService.username;
    $scope.reports = reports;
    $scope.reports.forEach(function(n){
        n._embed.tags.find(function(elm, idx) {
            if (elm === tagID) n._embed.tags[idx] = 'My list';
        });
        n._embed.tags = n._embed.tags.filter(function(t) {
            return !pidRegex.test(t);
        });
    });
    $scope.tags = report_tags.filter(function(t) {
        return !pidRegex.test(t.name);
    });

    $scope.selected = {};
    $scope.displayReports = [];
    $scope.totalFiltered = 0;

    $scope.refresh = function(clearSelection) {
        // filter by tags
        var displayList;
        var filterTags = $scope.c.filterTags;
        if (filterTags.length) {
            displayList = reports.filter(function(r) {
                var hasTag = {};
                (r._embed.tags || []).forEach(function(tag) {
                    hasTag[tag] = true;
                });
                for (var i=0; i<filterTags.length; i++) {
                    if (!hasTag[filterTags[i]])
                        return false;
                }
                return true;
            });
        }
        else {
            displayList = reports;
        }

        $scope.totalFiltered = displayList.length;
        
        displayList.sort(function(a,b) {
            var ordf = $scope.c.order.field;
            if (typeof a[ordf] !== 'string') {
                return -n;
            } else if (typeof b[ordf] !== 'string') {
                return n;
            }
            var n = ($scope.c.order.reverse ? -1 : 1);
            if (a[ordf].toUpperCase() < b[ordf].toUpperCase()) {
                return -n;
            }
            else if (a[ordf].toUpperCase() > b[ordf].toUpperCase()) {
                return n;
            }
            else {
                return 0;
            }
        });
        displayList = $scope.c.showPager
            ? displayList.slice($scope.c.page.start, $scope.c.page.start + (1*$scope.c.page.count))
            : displayList;

        $scope.displayReports.replaceWith(displayList);

        if (clearSelection)
            $scope.selected = {};
    };

    $scope.pageChanged = function() {
        $scope.c.page.start = ($scope.c.page.page-1) * $scope.c.page.count;
        $scope.refresh();
    };

    $scope.sortChanged = function() {
        $scope.c.page.start = 0;
        $scope.c.page.page = 1;
        $scope.refresh();
    };

    $scope.$watch('c.order', function(newVal) {
        if (!newVal) return;
        $scope.sortChanged();
    }, true);

    $scope.tagsChanged = function() {
        $scope.c.page.start = 0;
        $scope.c.page.page = 1;
        $scope.refresh(true);
    };

    $scope.saveView = () => {
        let view = { filter_tags: $scope.c.filterTags, page_order: $scope.c.order };
        userService.setPref("reports_view_options", view);
        alertService.add({msg: "View options saved", type: "info"});
    }

    $scope.restoreView = () => {
        let view = userService.getPref("reports_view_options")||{};
        view.filter_tags && view.filter_tags.forEach((i) => {
            $scope.addFilterTag(i);
        });
        if (view.page_order) $scope.c.order = view.page_order;
    }

    $scope.addFilterTag = function(tag) {
        if (!$scope.c.filterTags.includes(tag)) {
            $scope.c.filterTags.push(tag);
            $scope.tagsChanged();
        }
    };

    $scope.removeFilterTag = function(tag) {
        $scope.c.filterTags = $scope.c.filterTags.filter(function(t) { return (t != tag) });
        $scope.tagsChanged();
    };

    $scope.batchToggleMyTag = function() {
        $scope.batchToggleTag(tagID);
    }

    $scope.batchToggleTag = function(tag, isNew) {
        loading.add();
        var op;
        if (isNew) {
            op = 'add-tag';
        }
        else {
            op = 'delete-tag';
            $scope.reports.forEach(function(r) {
                if ($scope.selected[r.id] && !r._embed.tags.includes(tag)) {
                    const tagIDRegex = new RegExp('^' + tagID + '$');
                    if (!(r._embed.tags.includes('My list') && tagIDRegex.test(tag))) {
                        op = 'add-tag';
                    }
                }
            });
        }

        var ids = Object.keys($scope.selected).filter(function(k) { return $scope.selected[k] });
        kwApi.Report.forAll({action: op, ids: ids, tag: tag}).$promise.then(function() {
            loading.resolve();
            $scope.reload();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Unable to apply tags');
        });
        if ($scope.c.filterTags.length) $scope.selected = {};
    };

    $scope.batchAddNewTag = function() {
        var origScope = $scope;
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/reports/new-tag.html',
            windowClass: 'modal',
            controller: ["$scope", function($scope) {
                $scope.pattern = /^(?!My list$|pid:\d+$).*$/;
                $scope.newtag = {name: ''};
                $scope.close = function(s) {
                    origScope.batchToggleTag(s, true);
                    $scope.$close();
                };
                $scope.cancel = function() {
                    $scope.$close();
                };
            }],
        });
    };

    $scope.batchClearTags = function() {
        loading.add();
        var ids = Object.keys($scope.selected).filter(function(k) { return $scope.selected[k] });
        kwApi.Report.forAll({action: 'clear-tags', ids: ids}).$promise.then(function() {
            loading.resolve();
            $scope.reload();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Unable to apply tags');
        });
        if ($scope.c.filterTags.length) $scope.selected = {};
    };

    $scope.batchDelete = function() {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete these reports? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;

            loading.add();
            var ids = Object.keys($scope.selected).filter(function(k) { return $scope.selected[k] });
            $scope.loading = true;
            kwApi.Report.forAll({action: 'delete', ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Unable to apply tags');
            });
        });
    };

    $scope.showPager = function(v) {
        $scope.c.showPager = v;
        $scope.refresh();
    };


    $scope.total_checked = 0;
    $scope.$watch('selected', function(v) {
        var n = 0;
        angular.forEach(v, function(val, key) {
            if (val) n++;
        });
        $scope.total_checked = n;
    }, true);

    $scope.reload = function() {
        loading.add();
        kwApi.Report.query().$promise.then(function(r) {
            $scope.reports = reports = r;
            $scope.reports.forEach(function(n){
                n._embed.tags.find(function(elm, idx) {
                    if (elm === tagID) n._embed.tags[idx] = 'My list';
                });
                n._embed.tags = n._embed.tags.filter(function(t) {
                    return !pidRegex.test(t);
                });
            });
            return kwApi.ReportTag.query().$promise;
        }).then(function(t) {
            $scope.tags = report_tags = t.filter(function(x) {
                return !pidRegex.test(x.name);
            });
            loading.resolve();
            $scope.refresh();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Unable to reload list');
            $scope.refresh();
        });
    };

    $scope.restoreView();
    $scope.refresh(true);

    $scope.run = function(r) {
        r.listClass = '';
        $scope.runReport(r);
    };

    $scope.$on('sys.signal.Report', function(evnt, args) { 
        angular.forEach($scope.reports, function(r) {
            if (r.id == args.report_id) {
                if (args.type == 'done')
                    r.listClass = 'report-done';
                else
                    r.listClass = 'report-failed';
            }
        });
    });
}])

.controller('StaffReportDetailsCtrl', ["$scope", "report", "runs", "kwApi", "loading", "alertService", "userService", "$q", function($scope, report, runs, kwApi, loading, alertService, userService, $q) {
    $scope.order = {
        field: 'updated_time',
        reverse: true,
    };

    $scope.update_report = function() {
        loading.add();
        if ($scope.report.type == 'Matrix') {
            $scope.report.metadata.matrix = $scope.matrixDef;
        }

        kwApi.Report.put({id: $scope.report.id}, $scope.report).$promise.then(function() {
            loading.resolve();
            alertService.add({msg: "Report updated", type: "info"});
            $scope.reload_report();
        }, function(err) {
            loading.resolve();
            $scope.loading = false;
            alertService.addApiError(err, "Save failed");
            $scope.reload_report();
        });
    };

    $scope.reload_report = function() {
        loading.add();
        kwApi.Report.get({id: $scope.report.id}).$promise.then(function(rep) {
            loading.resolve();
            report = rep;
            $scope.refresh_report();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Reload failed');
        });
    };
    
    $scope.refresh_report = function() {
        $scope.report = report;

        if (report.type == 'Matrix') {
            $scope.matrixDef = $scope.report.metadata.matrix;
        }
        else {
            $scope.matrixDef = {
                row: '',
                col: '',
                val: '',
                row_sort: 'alpha',
                col_sort: 'alpha',
            };
        }
    };
    $scope.refresh_report();

    $scope.canRemove = function(n) {
        if (n.status == 'started')
            return false;
        else if (userService.can({async_jobs: 'modify'}))
            return true;
        else if (userService.id == n.user_id)
            return true;
        else
            return false;
    };

    $scope.reload_runs = function() {
        loading.add();
        kwApi.Report.getRuns({id: $scope.report.id}).$promise.then(function(r) {
            loading.resolve();
            runs = r;
            $scope.refresh_runs();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Reload failed');
        });
    };

    $scope.refresh_runs = function() {
        var lst = angular.copy(runs);
        lst.sort(function(a,b) {
            var n = ($scope.order.reverse ? -1 : 1);
            if (a[$scope.order.field] < b[$scope.order.field]) {
                return -n;
            }
            if (a[$scope.order.field] > b[$scope.order.field]) {
                return n;
            }
            else {
                return 0;
            }
        });
        $scope.runs = lst;
    };

    $scope.$watch('order', function(newVal) {
        if (newVal) {
            $scope.refresh_runs();
        }
    }, true);

    $scope.refresh_runs();

    $scope.remove_run = function(n) {
        loading.add();
        kwApi.Report.deleteRun({id: report.id, rid: n.id}).$promise.then(function() {
            return kwApi.Report.getRuns({id: report.id}).$promise;
        }).then(function(r) {
            runs = r;
            $scope.refresh_runs();
            loading.resolve();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Remove failed');
            $scope.loading = false;
        });
    };

    $scope.$on('sys.signal.Report', function(evnt, args) { 
        if (args.report_id == report.id)
            $scope.reload_runs();
    });
 
    $scope.validate_sql = function() {
        var valid = kwApi.Report.$validateSqlPattern($scope.report.savedsql);
        $scope.reportForm.sql_command.$invalid = !valid;
    };    
}])

.controller('StaffReportRunDetailsCtrl', ["$scope", "report", "fullrun", "run", "kwApi", "loading", "alertService", "$sce",
                                    function($scope, report, fullrun, run, kwApi, loading, alertService, $sce) {
    $scope.report = report;
    if (report.type_name == 'matrix') {
        $scope.matrixDef = angular.extend({
            row: "(SQL SELECT column 1)",
            col: "(SQL SELECT column 2)",
            val: "(SQL SELECT column 3)",
        }, $scope.report.metadata.matrix);
    }

    $scope.refresh = function(r) {
        $scope.run = r;
        $scope.data = r.return_value;
        if ($scope.data.is_tabular) {
            $scope.data.isHtml = [];
            for (var i=0; i<$scope.data.header.length; i++) {
                if ($scope.data.header[i].match(/_html$/)) {
                    $scope.data.isHtml[i] = true;
                    for (var j=0; j<$scope.data.rows.length; j++) {
                        $sce.trustAsHtml($scope.data.rows[j][i]);
                    }
                }
                else {
                    $scope.data.isHtml[i] = false;
                }
            }
        }
        else {
            $scope.data.isHtml = false;
            if ($scope.data.val_field && $scope.data.val_field.match(/_html$/)) {
                $scope.data.isHtml = true;
                angular.forEach($scope.data.rows, function(row) {
                    angular.forEach(row, function(col) {
                        $sce.trustAsHtml(col);
                    });
                });
            }
        }
    };

    $scope.reload = function() {
        loading.add();
        kwApi.Report.getRun({
            id: report.id,
            rid: run.id, 
            sort: $scope.order.field,
            dir: ($scope.order.reverse ? 'DESC' : 'ASC'),
            start: $scope.start,
            count: $scope.count,
        }).$promise.then(function(r) {
            loading.resolve();
            $scope.refresh(r);
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, "Unable to load report run data");
        });
    };

    if ($scope.isPrintView) {
        $scope.refresh(fullrun);
    }
    else {
        $scope.refresh(run);
    }

    $scope.has_biblionumber = ('biblio_column' in $scope.data) ? 1 : 0;
    $scope.has_itemnumber = ('item_column' in $scope.data) ? 1 : 0;

    $scope.start = 0;
    $scope.count = 20;
    $scope.pager = new KOHA.Pager({numResults: $scope.data.total_rows, offset: 0, numPerPage: $scope.count});
    $scope.toPage = function(page) {
        $scope.start = (page-1) * $scope.count;
        $scope.reload();
    };
    $scope.order = {
        field: '',
        reverse: false,
    };
     
    $scope.$watch('order', function(newVal) {
        if (newVal && newVal.field) {
            $scope.reload();
        }
    }, true);


    $scope.exportCsvLink = function(delim) {
        if (delim == 'tab') delim = "\t";
        return kwApi.Report.$exportDownloadLink(report.id, run.id, {format: 'csv', delimiter: delim});
    };
    $scope.exportExcelLink = function() {
        return kwApi.Report.$exportDownloadLink(report.id, run.id, {format: 'xlsx'});
    };

    $scope.exportToBatch = function() {
        loading.add();
        kwApi.Report.exportRunAsBatch({id: report.id, rid: run.id}, {}).$promise.then(function(batch ) {
            loading.resolve();
            alertService.add({msg: "Report exported to <a href=\"/app/staff/tools/marc-manage/" + batch.id + "\">import batch " + batch.id + "</a>", type: "info"});
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t export to import batch');
        });
    };
    $scope.exportToItemList= function() {
        loading.add();
        kwApi.Report.exportRunAsItemList({id: report.id, rid: run.id}, {}).$promise.then(function(batch ) {
            loading.resolve();
            alertService.add({msg: "Report exported to <a href=\"/app/staff/tools/bim/list/" + batch.id + "\">item list " + batch.id + "</a>", type: "info"});
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t export to item list');
        });
    };

}])

.controller('StaffReportNewCtrl', ["$scope", "kwApi", "alertService", "loading", "$state", function($scope, kwApi, alertService,  loading, $state) {

    $scope.report = {
        type_name: 'Tabular',
        metadata: {},
    };

    $scope.report_types = [{
        label: 'Tabular', value: 'Tabular'
    }, {
        label: 'Matrix', value: 'Matrix'
    }];

    $scope.matrix_sort_types = [{
        label: 'Alphabetical', value: 'alpha'
    }, {
        label: 'Numeric', value: 'numeric'
    }];

    $scope.create_report = function() {
        if ($scope.report.type == 'Matrix') {
            $scope.report.metadata.matrix = $scope.matrixDef;
        }

        loading.add();
        kwApi.Report.save($scope.report).$promise.then(function(rep) {
            loading.resolve();
            alertService.add({msg: "Report created", type: "info"});
            $state.go('staff.reports.sql.report', {id: rep.id}, {reload: true});
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Report creation failed');
        });
    };
    
    $scope.validate_sql = function() {
        var valid = kwApi.Report.$validateSqlPattern($scope.report.savedsql);
        $scope.reportForm.sql_command.$invalid = !valid;
    };    
}])


.controller('StaffPrintMessagesCtrl', ["$scope", "$http", "$uibModal", function ($scope, $http, $uibModal) {
    $scope.numSelected = 0;

    $scope.viewDetails = function(s) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/tools/print-messages-detail.html',
            windowClass: 'modal',
            controller: ["$scope", function($scope) {
                $scope.rec = s[0];
            }],
        });
    };

    $scope.gridOptions = {
        enableSorting: true,
        enableColumnResizing: true,
        enableFiltering: true,
        minRowsToShow: 20,
        showGridFooter:true,
        enableFullRowSelection: true,
        rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" class="ui-grid-cell" ng-dblclick="grid.appScope.viewDetails([row.entity])" ui-grid-cell></div>',
        columnDefs: [
            { field: 'id' },
            { field: 'firstname', displayName: 'First Name' },
            { field: 'surname', displayName: 'Last Name' },
            { field: 'cardnumber', displayName: 'Card Number' },
            { field: 'branchcode', displayName: 'Branch Code' },
            { field: 'title' },
            { field: 'code', displayName: 'Message Type' },
            { field: 'created_time', displayName: 'Created', cellFilter: 'dateFmt' },
            { field: 'content', visible: false },
        ]
    };

    $http.get('/api/message?op=get_fails', { authRequired: true }).then(function (rsp) {
        $scope.gridOptions.data = rsp.data;
    });

    $scope.gridOptions.onRegisterApi = function(gridApi) {
        $scope.myGridApi = gridApi;
        $scope.myGridApi.selection.on.rowSelectionChanged($scope, function(row) {
            if (row.isSelected) {
                $scope.numSelected++;
            }
            else {
                $scope.numSelected--;
            }
        });
        $scope.myGridApi.selection.on.rowSelectionChangedBatch($scope, function(rows) {
            rows.forEach(function(row) {
                if (row.isSelected) {
                    $scope.numSelected++;
                }
                else {
                    $scope.numSelected--;
                }
            });
        });
    };

    var getDocDefinition = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();
        var marginsConfig = $scope.config.PrintMessagesMargins.split(/\s*,\s*/).map(Number);

        var isPositiveInteger = function (x) {
            return (typeof x === 'number') && (x % 1 === 0) && (x > 0);
        }

        for (var i = 0; i < 4; i++) { // Safety dance
            if ( !isPositiveInteger(marginsConfig[i]) ) marginsConfig[i] = 50;
        }

        if (marginsConfig.length > 4) marginsConfig = marginsConfig.slice(0, 4);

        var docDefinition = { content: [], pageMargins: marginsConfig };

        gridSelection.forEach(function(el) {
            if (el.content == null) {
                el.content = 'There was no content for ID# ' + el.id;
            }
            docDefinition.content.push( { text: el.content, fontSize: 12, pageBreak: 'after', preserveLeadingSpaces: true } );
        });

        delete docDefinition.content[docDefinition.content.length-1].pageBreak; // Delete blank last page.
        return docDefinition;
    }

    $scope.printMessages = function () {
        var docDefinition = getDocDefinition();
        pdfMake.createPdf(docDefinition).print();
    }

    $scope.downloadMessages = function () {
        var docDefinition = getDocDefinition();
        pdfMake.createPdf(docDefinition).download();
    }

    $scope.markPrinted = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();

        gridSelection.forEach(function(el) {
            el.delivery_status = 'sent';
            $http.put('/api/message/' + el.id, JSON.stringify(el));
        });

        $scope.myGridApi.selection.clearSelectedRows();

        $http.get('/api/message?op=get_fails', { authRequired: true }).then(function (rsp) {
            $scope.gridOptions.data = rsp.data;
        });
    }

}])

.directive('uniqueSubfield', function() {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
        // FIXME: genericize.
        scope.$watchCollection(function(){ return scope.itemfields.map(function(f){ return f.subfield; }); },
            function(n,o){
                if(ctrl.$viewValue && jQuery.grep(n, function(v){ return v == ctrl.$viewValue ; }).length > 1){
                    ctrl.$setValidity('unique', false);
                } else {
                    ctrl.$setValidity('unique', true);
                }
            });
    }
  };
})
.directive('kwPatronSummary', ["$stateParams", function($stateParams) {
  return {
    templateUrl: '/app/static/partials/staff/patron-summary.html',
    scope: true,
    link: function(scope, elm, attrs, ctrl) {
        if(!$stateParams.borrowernumber){
            return;
        }
        // FIXME: This controller relies on parent scope's patron,
        // reloaded on state changes within the patron module (same patron).
        // We don't refresh on edit due to lack of appropriate signal.

        scope.imgurl = function(){
            return scope.patron.has_image ?
                    '/bvcgi/members/patronimage.pl?crdnum=' + scope.patron.cardnumber
                    : '/intranet-tmpl/prog/img/patron-blank.png';
        }
    }
  };
}])

.controller('StaffBibDetailCtrl', ["$scope", "configService", "kwApi", "bibService", "$state", function($scope, configService, kwApi, bibService, $state ) {

    var bibid = $state.params.biblionumber;
    bibService.get(bibid).then(function(bib){
        $scope.bib = bib;
        if(bib.isSerial){
            kwApi.Periodical.getForBib({bibid: bibid}, function(pubs){
                if(pubs.length) $scope.periodicals = pubs;
            });
        }
    });
    bibService.holdings(bibid, { cache: false, iface: 'staff' }).then(function(holdings){
        $scope.holdings = holdings;
    });
    $scope.config = configService;

}])

.controller('StaffMarcDisplayTmplCtrl', ["$scope", "$http", "configService", "alertService", "kwApi", "kohaDlg", "$uibModal", "bibService", "marcBibSpec", "loading", function($scope, $http, configService, alertService,
        kwApi, kohaDlg, $uibModal, bibService, marcBibSpec, loading) {

    var interfaces = {
        result: {
            desc: 'Search results',
            defaultTmpl: {}
        },
        detail:  {
            desc: 'Bib detail (public)',
            defaultTmpl: {}
        },
        staffDetail: {
            desc: 'Staff detail',
            defaultTmpl: {}
        }
    };
    $scope.interfaces = interfaces;

    angular.forEach( interfaces, function( ifaceDef, iface ){
        console.log(iface);
        ifaceDef.activeID = configService.marcDisplayTmpl[iface].id;
    });
    console.log(interfaces);

    kwApi.MarcDisplayTmpl.getDefaults( {}, function(defaults){
        defaults.forEach(function(t){
            console.log(t);
            var iface = t.id.replace(/__/g, '');
            $scope.interfaces[iface].defaultTmpl = t;
        });
    });

    $scope.templates = kwApi.MarcDisplayTmpl.getAll();
    $scope.tmplNodeTmplUrl = '/app/static/partials/staff/admin/marcTmplNodeTmpl.html';

    $scope.isTmplActive = function(tmpl){ // ignores defaults.
        for( var iface in interfaces ){
            if(interfaces[iface].activeID == tmpl.id)
                return true;
        }
        return false;
    };
    $scope.dirtyActive = function(){
        for (var iface in interfaces){
            if(interfaces[iface].activeID){
                if(interfaces[iface].activeID != configService.marcDisplayTmpl[iface].id)
                    return true;
            } else {
                console.warn('i dont think this is needed.  theres always active id')
                if(configService.marcDisplayTmpl[iface].id != '__'+iface+'__')
                    return true;
            }
        }
        return false;
    };
    $scope.saveActive = function(){
        var param = {};
        angular.forEach(interfaces, function(def, iface){
            param[iface] = /^__/.test(def.activeID) ? null : def.activeID;
        });
        $http.post('/api/marcdisplaytmpl?op=set_active', param).then(function(rsp){
            location.search = '_flush_cache';  //doesn't do anything - would need to reload.
        }, function(fail){
            console.warn(fail);
            loading.resolve();
            alertService.addApiError(fail);
        });
    }

    $scope.preview = {
        show: false,
    };

    $scope.newTmplDef = { id: "", clone: "" };
    $scope.createTmpl = function(tmplOpt){

        if( !tmplOpt.id || $scope.templates.find(function(tmpl){ return tmplOpt.id == tmpl.id; })){
            alertService.add({msg: 'Invalid template name .', type: 'error'});
            return;
        }

        var t = new kwApi.MarcDisplayTmpl({ id: tmplOpt.id, tmpl: [], isNew: true });
        if(tmplOpt.clone){
            var sourceTmpl;
            if(/^__/.test(tmplOpt.clone)){
                var iface= tmplOpt.clone.replace(/__/g, '');
                sourceTmpl = interfaces[iface].defaultTmpl;
            } else {
                sourceTmpl = $scope.templates.find(
                    function(tmpl){ return tmplOpt.clone==tmpl.id; });
            }
            t.tmpl = angular.copy(sourceTmpl.tmpl);
        }
        $scope.openTmpl( t );
        $scope.newTmplDef.id = $scope.newTmplDef.clone = "";
    };

    $scope.openTmpl = function(tmplOrId){

        if(typeof tmplOrId == 'object')
            $scope.editing = tmplOrId;
        else if($scope.templates[tmplOrId])
            $scope.editing =  $scope.templates[tmplOrId];
    }

    $scope.closeTmpl = function(){
        $scope.preview.show = false;
        $scope.editing = null;
    }

    $scope.saveTmpl = function(){

        var isNew = $scope.editing.isNew;
        $scope.editing.$save().then(function(ok){
            if(isNew)
                $scope.templates.push($scope.editing);
            $scope.editing = null;

        }).catch(function(e){
            console.warn(e);
        });

    }
    $scope.delTmpl = function( tmpl ){
        console.log(tmpl);
        if($scope.isTmplActive(tmpl))
            throw 'no bueno';

        kohaDlg.dialog({

        }).result.then(function(doit){
            if(doit){
                tmpl.$delete().then(function(){
                    var i = $scope.templates.indexOf(tmpl);
                    if(i>-1)
                        $scope.templates.splice(i,1);
                }, function(e){
                    console.log(e);
                    alertService.addApiError(e, 'Could not delete template.');
                })
            }
        })

    }
    function collapseChildren (node) {
        node._isNodeCollapsed = true;
        if( node.childNodes ){
            node.childNodes.forEach(collapseChildren);
        }
    }
    $scope.collapseAll = function(){
        $scope.editing.tmpl.forEach(collapseChildren);
    }
    $scope.cloneDefault = function(iface){

        $scope.editing.tmpl = angular.copy($scope.interfaces[iface].defaultTmpl.tmpl);
    };
    $scope.marcLabeled = function(tagspec){
        // FIXME: Doesn't work for wildcards.
        return (tagspec||'').length > 2 ? marcBibSpec.hasMarcLabel( tagspec.substring(0,3) ) : false;
    }
    $scope.nodeTypeName = function(block){
        var nodeType = block.section ? 'Section' : (block.fields ? 'MARC field group' : 'Bib data');
        var labels = (block.label) ? [ block.label ]: [];
        if((block.fields||{}).length)
            labels.push( block.fields[0].tagspec + (block.fields.length>1 ? ' ...' : '') );
        return nodeType + ': ' + labels.join(' | ');

    }
    $scope.nodeLabel = function(node){

    }
    var defaultObj = {
        section: {
            section: true,
            label: "",
            className: "",
            childNodes: []
        },
        marc: {
            label: "",
            join: "",
            filterLast: "",
            fields: []
        },
        data: {
            label: "",
            className: "",
            join: "",
            data: ""
        },
        fieldSet: {
            tagSpec: "",
            label: ""
        },
        postProcess: {
            id: "",
            options: {}
        }
    }
    var select = {
        data: ["language", "score", "format"],
        postProcess: {
            wrapInner: {
                desc: "Wraps the rendered fields in an html element.",
                opt: {
                    element: {
                        desc: "Element, e.g. 'h2'",
                        type: "text"
                    }
                }
            },
            searchTrigger: {
                desc: "Links to a catalog search via rcn or other search field.",
                opt: {
                    field: {
                        desc: "Index to search against if no rcn.  (defaults to keyword/any)",
                        type: "text"
                    }
                }
            },
            collapseFields: {
                desc: "Hides some/all fields, adding a 'show more' link to reveal.",
                opt: {
                    trigger: {
                        desc: "Minimum number of fields to trigger collapse.",
                        type: "number"
                    },
                    show: {
                        desc: "Number of fields to show when collapsed.",
                        type: "number"
                    }
                }
            },
            detailLink: {
                desc: "This should be an option for wrapInner, i think.",
                opt: {}
            },
            marc856: {
                desc: "Adds 856 link functionality.",
                opt: {}
            },
            linkedRecord: {
                desc: "Adds links to catalog records linked by $w/$o [76X-78X].",
                opt: {
                    itemCount: {
                        desc: "Include count of items attached to the linked record.",
                        type: "checkbox"
                    }
                }
            }
        }
    }
    $scope.newElTypes = {
        section: 'Section',
        marc: 'MARC Field Data',
        data: 'Non-field data'
    };
    $scope.selectOpts = select;
    $scope.sortableOptions = {
        connectWith: ".sortable-tree",
        handle: ".drag-handle"
    };
    $scope.defaultClassName = function(tmplNode){
        if(tmplNode.label)
            return tmplNode.label.toLowerCase().replace(/[^a-z ]+.*$/,'')
                    .replace(/\s+/g,'-') + '-data'; // from bvMarcDisplay directive.
    }
    $scope.toggleSectionCollapse = function(node, state){

        if(node.collapse)
            node.collapse = null;
        else
            node.collapse = { showText: '', hideText: ''};
    }
    $scope.addPostProcessor = function(id, node){
        node.push( { id: id, options: {} });
    }
    $scope.addEl = function(type, tmplTree, index){
        // adds after index to parent element.

        if(!defaultObj[type]){
            throw "can't insert element.";
        }
        var target = getParentNodeArray(tmplTree);

        if(!angular.isArray(target))
            throw "Invalid parent node";
        if(typeof index == 'undefined'){
            target.unshift(angular.copy(defaultObj[type]));
        } else {
            target.splice(index, 0, angular.copy(defaultObj[type]) )
        }


    };
    $scope.appendEl = function(type, obj){
        if(type in defaultObj)
            obj.push(angular.copy(defaultObj[type]));
    };
    $scope.rmEl = function( array, i ){
        array.splice(i,1);
    };

    function getParentNodeArray (tmplTree){
        // tmplTree is array of refs to parent nodes,
        // including current node.

        if(tmplTree.length < 2)
            throw "can't find element.";
        var target = tmplTree[ tmplTree.length-2 ];
        if(target.section)
            target = target.childNodes;
        return target;
    }
    $scope.rmTmplNode = function(node, tmplTree, i){

        if(typeof i == 'undefined' || tmplTree.length < 2){
            throw "can't find element.";
        }
        var target = getParentNodeArray(tmplTree);

        if(target[i] != node){
            console.log( angular.copy( target[i]));
            throw "Bad traversal";
        }
        target.splice(i,1);
    }


}])
.directive('marcDisplayPreviewPanel', ["bibService", "$compile", function(bibService, $compile){
    var wrapping = {
        detail: ['<div id="bib-detail-view"></div>','<div class="bib-details"></div>'],
        staffDetail: ['<div class="staff-bib-details"></div>','<div class="bib-details"></div>'],
        result: ['<div id="searchResults"></div>','<div class="resultslist-view"></div>',
                    '<ol id="search-results-list"></ol>','<li class="search-result"></li>',
                    '<div class="bib-result"></div>', '<div class="bib-details"></div>']
    };
    return {
        scope: {
            tmpl: '='
        },
        template:
            '<div class="card panel-default tmpl-preview">' +
            '  <div class="card-header">' +
            '    <div class="float-end form-inline">' +
            '      <input class="form-control" ng-model="bibid" placeholder="Bib ID">' +
            '      <select class="form-select" ng-model="iface" ng-options=" k as v for ( k, v ) in ifaceOptions">' +
            '      </select><button class="btn btn-sm btn-primary" ng-click="render()">Load/Update</button>' +
            '    </div>' +
            '    <h5>Preview MARC Display| {{bib.title}}</h5>' +
            '  </div>' +
            '  <div class="card-body">' +
            '    <div class="bib-preview"></div>' +
            '  </div>' +
            '</div>',


        link: function(scope, el, attrs){

            scope.iface = 'result';
            scope.ifaceOptions = { detail: 'Public Detail',
                staffDetail: 'Staff Detail',
                staffResult: 'Search Result',
                result: 'Search Result (public)'};
            // FIXME: .staff is  applied in parent no matter.
            var insertTarget = el.find('.bib-preview');
            var previewTmpl = '<div bv-marc-display bib="preview.bib" marc-template="{{tmpl}}"'+
                        'solrbib="preview.solrbib" holdings="preview.holdings"></div>';
            scope.render = function(){
                bibService.get( scope.bibid ).then(function(bib){
                    scope.bib = bib;
                    bibService.holdings( scope.bibid ).then(function(holdings){
                        scope.preview = {
                            solrbib: { score: 0.833 },  // fudge it.
                            bib: bib,
                            holdings: holdings
                        };

                        var $marcDisplay = $compile(previewTmpl)(scope);
                        var isPublic = /^staff/.test(scope.iface);
                        var wrapper = wrapping[scope.iface] || wrapping.result;

                        var wrapped = wrapper.reduceRight(function(wrappedEl,html){
                            return wrappedEl.wrap(html).parent();
                        }, $marcDisplay);

                        if(isPublic)
                            wrapped.addClass('non-staff');
                        insertTarget.html(wrapped);
                        console.log(wrapped);
                    })
                })
            }
        }
    }
}])
.controller('StaffAdminItemFieldsCtrl', ["$scope", "$http", "configService", "alertService", "kohaDlg", function($scope, $http, configService, alertService, kohaDlg) {
    // allow user to define custom item fields.
    // will be stored in items.fields as json .

    var xformType = function(fieldDef){
        if(fieldDef.typeAsString){
            if(fieldDef.typeAsString.substr(0,9)== "authval__"){
                fieldDef.authval = true;
                fieldDef.type = fieldDef.typeAsString.substr(9);
            } else {
                fieldDef.type = fieldDef.typeAsString;
                fieldDef.authval = false;
            }
            delete fieldDef.typeAsString;
        } else {
            if(fieldDef.authval){
                fieldDef.typeAsString = "authval__" + fieldDef.type;
            } else {
                fieldDef.typeAsString = fieldDef.type;
            }
        }
        return fieldDef;
    };
    $scope.updateType = function(fieldDef){
            if(fieldDef.typeAsString.substr(0,9)== "authval__"){
                fieldDef.authval = true;
                fieldDef.type = fieldDef.typeAsString.substr(9);
            } else {
                fieldDef.type = fieldDef.typeAsString;
                fieldDef.authval = false;
            }
    };

    $http.get('/api/syspref/ItemFields').then(function(rsp){
        configService.ItemFields = rsp.data.ItemFields;
        $scope.itemfields = angular.copy(configService.ItemFields).map(xformType);
    });

    $scope.cfg = configService;

    $scope.visibilityOptions = [
        { label: 'Results', val: 'results' },
        { label: 'Public', val: 'public' },
        { label: 'Staff', val: 'staff' },
        { label: 'Edit', val: 'edit' },
        { label: 'None', val: false }
    ];
    $scope.secondaryOptions = [
        { label: 'Yes', val: 'staff' },
        { label: 'Pub', val: 'public' },
        { label: 'No', val: false }
    ];
    // TODO: Provide type options from api.

    $scope.datatypes = [
        { label: "Text", typeAsString: "string", group: "Data types" },
        { label: "Number", typeAsString: "int", group: "Data types" },
        { label: "Date", typeAsString: "date", group: "Data types" },
        { label: "Decimal", typeAsString: "decimal", group: "Data types" },
        { label: "Codabar", typeAsString: "codabar", group: "Data types" },
        { label: "Link", typeAsString: "uri", group: "Data types" },
        { label: "Availability", typeAsString: "_availability", group: "Derived data" },
    ];

    configService.authvalList
        .map(function(av){ return { label: av, typeAsString: "authval__"+av, group: "Authorised values" }; })
        .forEach(function(def){ $scope.datatypes.push(def); });

    $scope.addRow = function(where){

        var newRow = { code: '', label: '', type: "string", custom: true,
                        visibility: 'staff', editor: { } };
        if(where){
            $scope.itemfields.push(newRow);
        } else {
            $scope.itemfields.unshift(newRow);
        }
    };
    $scope.removeRow = function(i){
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: "This action cannot be undone (once you save).  Removing a custom field definition may cause custom data stored in item records to be inaccessible.",
        }).result.then(function(rv) {
            if (rv)  $scope.itemfields.splice(i,1);
        });
    };
    $scope.save = function(){

        if($scope.itemfieldsform.$invalid){
            kohaDlg.dialog({
                type: "notify",
                alertClass: "warning",
                heading: "Invalid fields",
                message: "Item fields must have non-empty and unique codes."
            });
            return;
        }
        var toSend = { ItemFields: angular.copy($scope.itemfields).map(xformType) };
        $http.put('/api/syspref/ItemFields', JSON.stringify(toSend))
            .then(function(r){
                    $scope.itemfieldsform.$setPristine();
                    alertService.add({msg: "Changes successfully saved", type: "success"});
                    $http.get('/api/syspref/ItemFields').then(function(rsp){
                        console.dir(rsp);
                        configService.ItemFields = rsp.data.ItemFields;
                        $scope.itemfields = angular.copy(configService.ItemFields).map(xformType);
                    });
                }, function(err){
                    alertService.addApiError(err,'Error saving data');
            });
    };

    $scope.resetDefault = function(){
        var message = "This action cannot be undone. ";
        message += 'Custom item field data will be inaccessible if their definitions are removed.';
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: message,
            // buttons: [{val: true, label: 'Update', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}]
        }).result.then(function(rv) {
            if (rv) {
                $http.post('/api/syspref/ItemFields', $.param({ op: 'reset' }),{headers:{'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                    .then(function(rsp){
                        console.log(rsp);
                        $scope.itemfieldsform.$setPristine();
                        alertService.add({msg: "Default values restored.", type: "success"});
                        configService.ItemFields = rsp.data.ItemFields;
                        $scope.itemfields = angular.copy(configService.ItemFields).map(xformType);
                    }, function(e){
                        alertService.addApiError(e,'Itemfields data failed to reset');
                        console.warn(e);
                    });
            }
        });

    };

}])

.controller('StaffItemMigrateCtrl', ["$scope", "$stateParams", "alertService", "bibService", "$http", function( $scope, $stateParams, alertService, bibService, $http ) {

    bibService.get( $stateParams.biblionumber ).then( function( bib ) {
        $scope.bib = bib;
    });

    $scope.migrateItem = function ( barcode ) {
        if ( ! barcode ) {
            return;
        }
        else {
            $http.post( '/api/work/' + $scope.bib.id + '?op=migrate-item&barcode=' + barcode, { authRequired: true } ).then( function( rsp ) {
                alertService.add( { type: 'success', msg: 'The item was migrated successfully' } );
                $scope.barcode = undefined;
            }, function( err ) {
                console.error( err );
                alertService.addApiError(err,'Save failed');
            });
        }
    };
}])

.controller('StaffItemsCtrl', ["$scope", "$uibModal", "$stateParams", "$state", "$q", "configService", "bibService", "alertService", "kohaDlg", "$http", "loading", function($scope, $uibModal, $stateParams, $state, $q, /*holdings,*/
        configService, bibService, alertService, kohaDlg, $http, loading ) {

    var self = this;

// item edit interface.
// holdings table with modal item editor.

    bibService.get($stateParams.biblionumber).then(function(bib){
        $scope.bib = bib;
    });

    var holdingsP = bibService.holdings($stateParams.biblionumber, { cache: false }).then(function(holdings){
        $scope.holdings = holdings;
        return holdings;
    });

    $scope.iface = 'edit';
        $scope.itemGroups = {
            toDelete: {},
            deleteCount: function(){ return Object.keys(this.toDelete).length ;},
            clear: function(){
                $scope.holdings.clearModState();
                this.toDelete = {};
            }
        };

    $scope.mfhdAsStr = function(mfhd){
        return mfhd.id + ': ' + mfhd.location.homebranch + '| ' +
                    mfhd.location.location + '| ' + mfhd.location.itemcallnumber;
    };

    $scope.deleteItems = function( itemids ){
        var holdings = $scope.holdings;
        var failed = {};
        var promises = [];
        if( ! itemids ) itemids = Object.keys($scope.itemGroups.toDelete);
        var itemDesc = (itemids.length>1) ? (itemids.length + " items ") :
                        ( " item " + holdings.item[itemids[0]].id +
                                " [" + holdings.item[itemids[0]].barcode + "]");

        var firstConfirm = kohaDlg.dialog({
                    message: "Confirm deletion of " + itemDesc + '.  ' +
                                'This action cannot be undone.',
                    buttons: [ { label: 'Delete !', val: true, btnClass: 'btn-danger'},
                                {label: 'Cancel', val: false, btnClass: 'btn-outline-secondary'} ],
                });
        return firstConfirm.result.then(function(ok){
            if(!ok){
                $scope.itemGroups.toDelete = {};
                $scope.holdings.clearModState();
                return null;
            }

            itemids.forEach(function(itemid){
                var deferred = $q.defer();
                promises.push(deferred.promise);
                holdings.item[itemid].$delete().then(function(rsp){
                    alertService.add({ type: 'success', msg: 'ITEM successfully deleted.'});
                    holdings.rmItem(itemid);
                    deferred.resolve();
                }, function(e){
                    if(e.status=='409'){
                        failed[itemid] = e.data;
                    } else {
                        alertService.add({ type: 'error', msg: 'Item delete failed: ' + e.data });
                    }
                    deferred.resolve();
                });

            });

            return $q.all(promises).then(function(){

                if(Object.keys(failed).length){
                    var reallyDelete = $uibModal.open({
                        backdrop: false,
                        templateUrl: '/app/static/partials/staff/item-delete-confirm.html',
                        controller: 'StaffItemDeleteModalCtrl',
                        resolve: {
                            holdings: function() { return $scope.holdings; },
                            items: function() { return failed; }
                        }
                    });
                    return reallyDelete.result.then(function(confirmed){
                        if(confirmed){
                            var retries = [];
                            angular.forEach(failed, function(failedConstraints, itemid){
                                var params = {};
                                Object.keys(failedConstraints).forEach(function(check){
                                    params[check] = 0;
                                });
                                var item = holdings.item[itemid];
                                var retry = $q.defer();
                                retries.push(retry.promise);
                                item.$delete(params).then(function(rsp){
                                    $scope.holdings.rmItem(itemid);
                                    alertService.add({ type: 'success', msg: 'Item ' + itemid + ' successfully deleted.'});
                                }, function(e){
                                    item._saveStatus = 'deleteFailed';
                                    alertService.addApiError(e,'Item cannot be deleted');
                                }).finally(function(itm){
                                    retry.resolve(itm);
                                });
                            });
                            return $q.all(retries);
                        }
                    });
                }
            }).finally(function(){
                $scope.itemGroups.toDelete = {};

                // FIXME: if this is last of a dummy mfhd, it's left hanging.
            });
        });
    };

    $scope.mvItems = function(target_id, src){
        // if item_id is null, move all items in mfhd.
        // if target_id is null, unlink them.
        if(!src) return;
        var mfhd = src.mfhd;
        var item_id = src.item_id;

        if(mfhd && item_id && !mfhd._dummy && $scope.holdings.item[item_id].mfhd_id != mfhd.id){
                console.warn("MFHD ID MISMATCH");
                return;
        }
        loading.add('mfhd.link');
        var itemsToMove = ( item_id ) ? [ $scope.holdings.item[item_id] ] : mfhd.items;
        var moved = itemsToMove.map(function(item){
            return item.$relinkMfhd({ target_mfhd_id: target_id });
        });
        $q.all(moved).then(function(all){
            $scope.holdings.refresh().then(function(h){
                loading.resolve('mfhd.link');
                itemsToMove.forEach(function(item){
                    h.item[item.id]._saveStatus = 'modified';
                });
            });
        }, function(f){
            loading.resolve('mfhd.link');
            console.warn(f);
        });
    };


    $scope.openEditModal = function(target){
        if(!target) target = {};
        var openEditor = function() {
            if(target.item){ // will clear on save.
                $scope.holdings.clearModState();
                $scope.holdings.item[target.item]._saveStatus = 'editing';
            }
            return $uibModal.open({
                backdrop: false,
                templateUrl: '/app/static/partials/staff/item-edit-modal.html',
                controller: 'StaffItemEditCtrl',
                resolve: {
                    holdings: function() { return $scope.holdings; },
                    itemid: function() { return target.item; },
                    mfhdid: function(){ return target.mfhd; },
                    clone: function(){ return target.clone; }
                }
            }).result.then( function(edited){
                if(edited){
                    edited.id = target.item;
                    return afterEdits(edited);
                } else {
                    $scope.holdings.clearModState();
                }

            });
        };
        var permissionFail   = function(e) {
            alertService.add({msg: "You do not have permission to edit records for this branch.", type: "error"})
            console.error(e.statusText);
        };

        if (target.item) {
            return $http.head('/api/item/' + target.item).then(openEditor, permissionFail);
        } else {
            return openEditor();
        }

    };

    function afterEdits(edited) {

            //editor modal returns {
                    //  id: original item id if not 'add'.
                    //  item: itemresource,
                    //  multiAdd: array of extra created items,
                    //  duplicate: another modal result. }

        var maybeRefresh = $q.when(false);

        // if(edited.multi) maybeRefresh = $scope.holdings.refresh();
        if(edited.id){
            // FIXME:
            // if user modifies home branch,
            // need to move mfhds or trigger refresh.
                // ...handle this in editor ctrl.

        }

        maybeRefresh.then(function(){
            $scope.holdings.clearModState(); //no need if refreshed.
            if(edited.id){
                $scope.holdings.item[edited.item.id]._saveStatus = 'modified';
            } else {
                $scope.holdings.item[edited.item.id]._saveStatus = 'added';
            }

            if(edited.duplicate)
                edited.duplicate.then(afterEdits);
            if(edited.multi){
                edited.multi.forEach(function(item){
                    $scope.holdings.item[item.id]._saveStatus = 'added';
                });
            }
        })
    }
    if($stateParams.edit ){
        var seen = {};
        var arr = (angular.isArray($stateParams.edit)) ?
                $stateParams.edit : [ $stateParams.edit ];

        arr.forEach(function(itemid){
            if(!itemid) itemid = 0;
            if(!seen[itemid])
                holdingsP.then(function(){
                    $scope.openEditModal({ item: parseInt(itemid) }).then(function(){
                        $state.go( '.', { edit: null }, { notify: false } );
                    });
                });
            seen[itemid] = true;
        });
    }

}])

.controller('StaffAdminItemStatusConfigCtrl', ["$scope", "kwApi", "alertService", function($scope, kwApi, alertService){
    $scope.itemstatuses = kwApi.ItemStatus.getAll();

    $scope.addRow = function(status){
        $scope.itemstatuses.push( new kwApi.ItemStatus() );
    };
    $scope.saveRow = function(status){
    console.log(status);

        if(status.id){
            status.$update({id: status.id}).then(function(j){
                alertService.add({msg: "Item Status updated.", type: "success"})
            }, function(e){
                alertService.addApiError(e,'Item status update failed');
                console.warn(e.data);
            });
        } else {
            status.$save({ op: 'create' }).then(function () {
                alertService.add({msg: "Item Status created.", type: "success"})
            }, function (e) {
                alertService.addApiError(e,'Item status creation failed');
                console.warn(e.data);
            });
        }
    };
    $scope.deleteRow = function(status, i){
        console.log(i);
        var remove = function(i){
            $scope.itemstatuses.splice(i,1);
        };
        if(status.id){
            status.$delete({id: status.id}, function(rv){
                alertService.add({msg: "Item Status deleted.", type: "success"});
                remove(i);  // todo animate
            }, function(e){
                alertService.add({msg: "Item Status delete failed: " + e.data.replace(/\d\d\d/, '')
                    .replace('Item status', ''), type: "error"});
                console.warn(e.data);
            });
        } else {
            remove(i);
        }
    };
}])
.controller('StaffItemsCircStatusCtrl', ["$scope", "$http", "$stateParams", "$q", "$uibModal", "$filter", "$state", "kwApi", "kohaDlg", "configService", "bibService", "userService", "alertService", "$timeout", "bvItemSvc", "$window", "Pager", "$anchorScroll", function($scope, $http,$stateParams,$q, $uibModal, $filter,
     $state, kwApi, kohaDlg, configService, bibService, userService, alertService, $timeout, bvItemSvc, $window, Pager, $anchorScroll) {

    if(!$stateParams.biblionumber) $state.go('staff');
    bibService.get($stateParams.biblionumber).then(function(bib){
        $scope.bib = bib;
    });
    $scope.collapsed = {};
    $scope.display = configService.display;
    $scope.tmp = {};
    $scope.pager = {};
    $scope.pageData = [];
    var pagelength = 10;
    var oneTime = false;

    $scope.pageChanged = function(){
        if($stateParams.itemid && !oneTime){
            oneTime = true;
            var itemIndex = $scope.items.findIndex( function(item){ return item.id == $stateParams.itemid } );
            $scope.pager.page = Math.ceil((itemIndex+1)/pagelength);
            $scope.pageData = $scope.items.slice($scope.pager.offset(), $scope.pager.rangeEnd());
        }
        else if ($scope.items.length <= pagelength){
            $scope.pageData = $scope.items;
        }
        else {
            $scope.pageData = $scope.items.slice($scope.pager.offset(), $scope.pager.rangeEnd());
        }

        $scope.pageData.forEach(function(item){
            if(!$scope.tmp[item.id].issues){
                updateIssues(item);
                $scope.tmp[item.id].showIssuedata = true;
            }
        });
    };

    $scope.onlyShow = $stateParams.itemid;
    $scope.showAll = function() {
        $scope.onlyShow = false;
        $scope.collapsed = {};
        $timeout(function(){
            $anchorScroll('item-'+$stateParams.itemid);
        },200);
    };
    $scope.loadIssuedata = function(item){
        if( ! $scope.tmp[item.id].issues)
            updateIssues(item);
    };
    $scope.canUserEdit = userService.can({editcatalogue: { item: 'edit' }});
    $scope.canUserModStatus = function(branchcode) {
        return userService.canInBranch({editcatalogue: { item: 'status' }}, branchcode);
    };

    $scope.printSpineLabel = function (item) {
        $window.open("/app/staff/labels/spine-create?item=" + item.itemnumber);
    };
    var updateIssues = function(item){
        var stash = $scope.tmp[item.id];
        stash.issues = kwApi.Issue.itemIssues({id: item.id}, function(issues){
            // if(!issues.length) stash.neverBorrowed = true;
            // stash.renewalCount = issues.reduce(function(acc,cv){ return acc + cv.renewals||0; }, 0);
            var thisYear = dayjs().year();
            stash.circ = {
                    issues: { total: issues.length, ytd: 0},
                    renewals: { total: 0, ytd: 0},
                    lastIssue: issues[0]
                };
            issues.forEach(function(issue){
                stash.circ.renewals.total += Number(issue.renewals)||0;
                if(dayjs(issue.issuedate).year()==thisYear){
                    stash.circ.issues.ytd++;
                    stash.circ.renewals.ytd += Number(issue.renewals)||0;
                }
            });

        });
    };
    var updateLostItem = function(item){
        var stash = $scope.tmp[item.id];
        var lostitem = kwApi.LostItem.get({ item_id: item.id }, function(){
            stash.lostitem = lostitem;
            lostitem.loserName = lostitem.borrowernumber;
            kwApi.Patron.get({id: lostitem.borrowernumber}, function(patron){
                lostitem.loserName = patron.surname + ', ' + patron.firstname;
                // TODO: make a directive with cached name.
            });
        }, function(f){
            stash.lostitem = null;
        });
    };
    var stashStatuses = function(item){
        if(!$scope.tmp[item.id]) $scope.tmp[item.id] = {};
        $scope.tmp[item.id].wthdrawn = item.wthdrawn;
        $scope.tmp[item.id].damaged = item.damaged;
        $scope.tmp[item.id].itemlost = item.itemlost||'';
        $scope.tmp[item.id].itemnotes = item.itemnotes;
    };
    kwApi.Item.workItems( { id: $stateParams.biblionumber }, function(items){
        $scope.pager = new Pager({ count: items.length, pagelength: pagelength });
        $scope.items = bvItemSvc.sort(items, '_sorthomebranch');
        $scope.items.forEach(function(item){
            stashStatuses(item);

            // If lost, find borrower.
            if(item.itemlost){
                updateLostItem(item);
            }
            var onlyItemShown = $stateParams.itemid == item.id;

            if($stateParams.itemid)
                $scope.collapsed[item.id] = !onlyItemShown;

            bvItemSvc.itemIsAvailable(item);
        });

        $scope.pageChanged();
    });
    $scope.date = $filter('kohaDate');

    $scope.updatingLost = function(item){
        if(!$scope.tmp[item.id].itemlost && !item.itemlost) return false;
        return ( $scope.tmp[item.id].itemlost != item.itemlost);
    };
    $scope.options = { damaged: configService.interpolator('damaged').options(),
            itemlost: configService.interpolator('lost').options(),
            withdrawn: configService.interpolator('withdrawn').options() };

    $scope.updateCircStatus = function(item, status){
        var param = { op: 'set_status' };
        param[status] = $scope.tmp[item.id][status];
        if(status=='itemlost' && ! param[status]) param[status] = null; // dynamic types ftw.
        var lost_item = $scope.tmp[item.id].lostitem;
        var maybeWarn = $q.when(true);
        if(status=='itemlost' && param.itemlost=='lost'){
            var dlg = {'message': 'Patron will be charged for lost item.  Continue?'};
            maybeWarn = kohaDlg.dialog(dlg).result;
        } else if(status=='itemlost' && param.itemlost!='lost' && item.itemlost=='lost'){
            var lost_handled = $q.defer();
            maybeWarn = lost_handled.promise;
            $uibModal.open({
                templateUrl: '/app/static/partials/staff/circ/lost-item-modal.html',
                animation: false,
                keyboard: false,
                backdrop: 'static',
                controller: ["$scope", function($scope) {

                    // Note this tmpl is used in checkin as well, duplicate controller logic.
                    $scope.context = 'lost_status';

                    $scope.canHandleLostItem = userService.can({borrowers: 'delete_lost_items'});
                    $scope.canRefundLostItem = configService.RefundReturnedLostItem;
                    $scope.lost = {
                        remove: !!$scope.canHandleLostItem,
                        refund: ($scope.canRefundLostItem) ? 'LOSTRETURNED' : ''
                    };

                    if(lost_item){
                        kwApi.Patron.get({id: lost_item.borrowernumber}, function(patron){
                            $scope.loser = patron;
                            $scope.loserName = patron.surname + ', ' + patron.firstname;
                        });
                        $scope.loserName = lost_item.borrowernumber;
                    } else {
                        $scope.noLostItemRecord = true;
                    }

                    $scope.handleLost = function() {

                        if( $scope.noLostItemRecord ){
                            lost_handled.resolve(true);
                        } else if( ! $scope.canHandleLostItem ){
                            lost_item.$defer( { id: lost_item.id } ).then(
                                function(){
                                    lost_handled.resolve(true);
                                });

                        } else {
                            lost_item.$checkin( {
                                    id: lost_item.id,
                                    remove: $scope.lost.remove,
                                    refund: $scope.lost.refund
                                }).then(function success(rsp){
                                    lost_handled.resolve(true);
                                }, function err(e){
                                    alertService.addApiError(e,'Failed to handle lost item');
                                    lost_handled.reject();
                                });
                        }

                        $scope.$close();
                    };
                    $scope.cancel = function(){
                        lost_handled.resolve(false);
                        $scope.$close();
                    };
                }],
            });
        }

        // $scope.tmp[item.id].showIssuedata = false;  // triggers reload in view via ngIf.
        maybeWarn.then(function(ok){
            if(ok){
                $http.post('/api/item/'+item.id, param).then(function(rsp){
                    stashStatuses(rsp.data);
                    // This is flaky -- should use $resource and get new item instance.
                    item[status] = rsp.data[status];
                    item.onloan = rsp.data.onloan;
                    item.statuses = rsp.data.statuses;
                    item.$is_available = bvItemSvc.itemIsAvailable(item);
                    item.timestamp = rsp.data.timestamp; // watched by availability directiv.
                    updateLostItem(item);
                }, function(f){
                    console.warn(f);
                    var oldstatus = (status=='itemlost') ? item[status] || '' : item[status];
                    $scope.tmp[item.id][status] = oldstatus;
                    if(f.data=='400 No patron to assign.'){
                        alertService.add({
                            msg: 'No patron found to assign lost item.  [invalid lost status].',
                            type: 'error',
                            persist: true });
                    }
                }).finally(function testHoldsOnItemMod(){
                    var itemlvl = false;
                    var callModal = false;

                    kwApi.Work.get( { id: $scope.bib.id }, function(work){
                        // $scope.tmp[item.id].showIssuedata = true;
                        if (work.summary.holdable_count == 0 && work.summary.holds_count > 0) {
                            callModal = true;
                        }
                        else if (item.on_hold && ( // This is written out since `item.circulates` is not reliable.
                            (item.damaged > 0 && !configService.AllowHoldsOnDamagedItems)
                                || item.wthdrawn > 0 || item.notforloan > 0 || item.itemlost)
                        ){
                            callModal = true;
                            itemlvl = true;
                        }

                        if (callModal) {
                            $uibModal.open({
                                backdrop: false,
                                templateUrl: '/app/static/partials/staff/holds-cancel-modal.html',
                                controller: 'CancelBibHoldsModalCtrl',
                                resolve: {
                                    itemlvl: function() { return itemlvl; },
                                    bibid: function() { return $stateParams.biblionumber; },
                                    itemid: function() { return item.id; },
                                }
                            });
                        }
                    });
                });
            } else {
                $scope.tmp[item.id][status] = item[status];
            }
        });
    };

    $scope.itemstatuses = configService.itemstatuses;
    $scope.addStatus = function(item){
        if(!$scope.tmp[item.id].newstatus) return;
        if( item.statuses.filter(function(stat){
            return $scope.tmp[item.id].newstatus==stat.id; }).length ){
                $scope.tmp[item.id].newstatus = null;//dupe
                return;
        }
        $scope.tmp[item.id].showIssuedata = false;  // triggers reload in view via ngIf.
        item.$setStatus({statusid: $scope.tmp[item.id].newstatus}).then(function(){
            $scope.tmp[item.id].newstatus = null;
            $scope.tmp[item.id].showIssuedata = true;
        }, function(e){
            console.warn(e);
        });
    };
    $scope.rmStatus = function(item, status){
        $scope.tmp[item.id].showIssuedata = false;
        item.$clearStatus({statusid: status.id}).then(function(){
            $scope.tmp[item.id].showIssuedata = true;
        });
    };

}])

.controller('StaffItemDeleteModalCtrl', ["$scope", "items", function($scope, items){

    // injected items : itemid as key and response from item.$delete as val.

    $scope.items = items;
    var re = /_/g;
    $scope.failureDisplay = function(failure){
        return "Item is " + failure.replace(re,' ');
    };

}])

.controller('CancelBibHoldsModalCtrl', ["$scope", "$uibModalInstance", "$http", "alertService", "itemlvl", "bibid", "itemid", function($scope, $uibModalInstance, $http, alertService, itemlvl, bibid, itemid){
    $scope.itemlvl = itemlvl;
    $scope.bibid = bibid;
    $scope.itemid = itemid;

    $scope.cancelItemHolds = function (bibid, itemid) {
        var myHeaders = {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'};
        $http.post('/api/holds-queue/' + bibid, $.param({op: 'cancel-item-holds', itemid: itemid}), {headers: myHeaders, authRequired: true})
            .then( function () {
                alertService.add({msg: 'All holds for the item have been canceled.', type: 'success'});
            }, function (e) {
                alertService.addApiError(e,'Failed to cancel holds');
            });
        $uibModalInstance.close();
    };

    $scope.cancelAllHolds = function (bibid) {
        var myHeaders = {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'};
        $http.post('/api/holds-queue/' + bibid, $.param({op: 'cancel-all'}), {headers: myHeaders, authRequired: true})
            .then( function () {
                alertService.add({msg: 'All holds have been canceled.', type: 'success'});
            }, function (e) {
                alertService.addApiError(e,'Failed to cancel holds');
            });
        $uibModalInstance.close();
    };

}])

.controller('StaffItemEditCtrl', ["$scope", "$uibModalInstance", "$uibModal", "$q", "kwApi", "configService", "userService", "alertService", "holdings", "itemid", "mfhdid", "clone", "$http", "kohaDlg", "bvItemSvc", function($scope, $uibModalInstance,
                $uibModal, $q, kwApi, configService, userService, alertService, holdings,
                itemid, mfhdid, clone, $http, kohaDlg, bvItemSvc){

    // edit modal controller.
    if(itemid && mfhdid) console.warn("Cannot move item to new MFHD while editing.");

    $scope.saveOptions = {
        addMulti: 1,
        dupe: false
    };
    $scope.vis = {
        optionsDrop: false
    };

    $scope.toggleDupe = function(){
        $scope.saveOptions.dupe = !$scope.saveOptions.dupe;
        $scope.saveOptions.addMulti = 1;
        $scope.vis.optionsDrop = false;
    };

    $scope.holdings = holdings;

    var cloneItem = function(item){
        if(typeof item != "object") item = holdings.item[item];
        if(!item) return;
        var newItem = angular.copy(item);
        delete newItem.id;
        delete newItem.itemnumber;
        delete newItem.uuid;
        delete newItem.itemlost;
        delete newItem.damaged;
        delete newItem.wthdrawn;
        if(newItem.barcode && +newItem.barcode < 9007199254740991){ // 9007199254740991 = Number.MAX_SAFE_INTEGER (ES6)
            var nextBarcode = +newItem.barcode + 1;
            newItem.barcode = isNaN(nextBarcode) ? undefined : nextBarcode.toString();
        } else {
            newItem.barcode = undefined;
        }
        return newItem;
    };

    var target_mfhd_id;

    var newItemDefaults = function(){
        var bib_cn;
        if(/^\d{3}\w+$/.test(configService.itemcallnumber)){
            var field = holdings.bib.marc.field(configService.itemcallnumber.substr(0,3));
            if(field){
                bib_cn = configService.itemcallnumber.substr(3).split('').map(
                                function(sfcode){ return field.subfield(sfcode); }).filter(
                                function(val){ return val; }).join(' ');
            }
        }
        var defaults = {
            biblionumber: holdings.bib.id,
            homebranch: userService.login_branch,
            holdingbranch: userService.login_branch,
            itemcallnumber: bib_cn
        };

        configService.ItemFields.forEach(function(fieldDef){
            if(fieldDef.editor.default) defaults[fieldDef.code] = fieldDef.editor.default;
        });
        return defaults;
    };

    if(itemid){

        $scope.item = angular.copy(holdings.item[itemid]);
    } else {
        if(clone){
            $scope.item = cloneItem(clone);
            target_mfhd_id = mfhdid || $scope.item.mfhd_id;

        } else {
            $scope.item = new kwApi.Item();
            $scope.item.$applyDefaults(newItemDefaults());

            if(mfhdid){
                $scope.item.holdingbranch = $scope.item.homebranch = holdings.mfhd[mfhdid].location.homebranch;
                if(!holdings.mfhd[mfhdid]._dummy){
                    ['location','ccode','itemcallnumber'].forEach(function(field){
                        if(holdings.mfhd[mfhdid].location[field])
                            $scope.item[field] = holdings.mfhd[mfhdid].location[field];
                    });
                    target_mfhd_id = +mfhdid;
                }
            }
        }
    }

    var confVisibleFields = configService.ItemFields.filter(function(fieldDef){ return fieldDef.visibility; });
    var userHiddenFields = userService.getPref('staff.item_edit.hidden_fields');

    userHiddenFields ? $scope.userHidden = userHiddenFields : $scope.userHidden = [];

    if ($scope.userHidden && $scope.userHidden.length > 0) {
        $scope.showHideBtn = false;
        $scope.visibleFields = confVisibleFields.filter( function(f){ return $scope.userHidden.indexOf(f.code) < 0; });
    }
    else {
        $scope.showHideBtn = true;
        $scope.visibleFields = confVisibleFields;
    }

    $scope.resetHidden = function(){
        $scope.showSaveHideBtn = false;
        $scope.userHidden = [];
        $scope.visibleFields = confVisibleFields;
        $scope.saveHidden();
    };

    $scope.selectHidden = function(){
        $scope.showHideBtn = false;
        $scope.showSaveHideBtn = true;
    };

    $scope.hideField = function(f){
        $scope.userHidden.push(f);
        $scope.visibleFields = confVisibleFields.filter( function(f){ return $scope.userHidden.indexOf(f.code) < 0; });
    };

    $scope.saveHidden = function(){
        userService.setPref('staff.item_edit.hidden_fields', $scope.userHidden);
        alertService.add({msg: "Edit item field visibility preference successfully updated.", type: "success" });
        ($scope.userHidden && $scope.userHidden.length == 0) ? $scope.showHideBtn = true : $scope.showHideBtn = false;
        $scope.showSaveHideBtn = false;
    };

    var editorRV = {
        item: $scope.item,
        id: itemid,
        doNotClose: false
    };

    $scope.save = function(item){
        if(!item) item = $scope.item;
        var requestedBarcode = item.barcode;

        if(item.id){
            // copy edited item's values back to holdings
            // to retain holdings object's multiple refs to item.
            item = holdings.item[item.id].$copyFrom( item );
            editorRV.item = item;
            item.$put().then(function(editedItem){

                holdings.modified( editedItem.timestamp);

                alertService.add({msg: "Item successfully updated.", type: "success" });
                editorRV.doNotClose = false;
                if(requestedBarcode && item.barcode != requestedBarcode){
                    alertService.add({msg: "Requested Barcode unavailable: " + requestedBarcode});
                }

                $scope.itemlvl = false;
                var callModal = false;

                $http.get('/api/work/' + $scope.holdings.bib.id).then(function (r) {
                    if (r.data.summary.holdable_count === 0 && r.data.summary.holds_count > 0) {
                        callModal = true;
                    }
                    else if (holdings.item && holdings.item[item.id].on_hold && !holdings.item[item.id].circulates){
                        callModal = true;
                        $scope.itemlvl = true;
                    }

                    if (callModal) {
                        $uibModal.open({
                            backdrop: false,
                            templateUrl: '/app/static/partials/staff/holds-cancel-modal.html',
                            controller: 'CancelBibHoldsModalCtrl',
                            resolve: {
                                itemlvl: function() { return $scope.itemlvl; },
                                bibid: function() {  return $scope.holdings.bib.id },
                                itemid: function() { return item.id; },
                            }
                        });
                    }
                });
                return item;
            }, function(e){
                console.warn('Ensure caller reloads holdings.');
                // or maybe just do it here.
                saveError(e);
                editorRV.error = true;
                editorRV.doNotClose = true;
                return item;
            }).then(function(item){
                if (!editorRV.doNotClose) $uibModalInstance.close(editorRV);
            });
        } else {

            var createItem = item.$create().then(function(newItem){

                alertService.add({msg: "Item successfully added", type: "success" });
                editorRV.doNotClose = false;
                if(requestedBarcode && item.barcode != requestedBarcode){
                    alertService.add({msg: "Requested Barcode unavailable: " + requestedBarcode});
                }

                if(target_mfhd_id){
                    return newItem.$relinkMfhd( {target_mfhd_id: target_mfhd_id} ).
                        then(function(){
                            newItem.mfhd_id = target_mfhd_id; // ideally should happen in $relinkMfhd.
                            return newItem;
                        }).
                        catch(function(err){
                            // this should trigger full refresh.
                            console.warn(err);
                            alertService.addApiError(err,'Item could not be linked to holdings record');
                    });
                }
                return newItem;

            }).catch(function(e){
                saveError(e);
                editorRV.error = true;

                return $q.reject(e);
            });

            createItem.then(function(newItem){
                if($scope.saveOptions.addMulti <= 1){
                    holdings.addItem( newItem );
                }
                console.log(holdings);
                if($scope.saveOptions.dupe){
                        var addAnotherModal = $uibModal.open({
                            backdrop: false,
                            templateUrl: '/app/static/partials/staff/item-edit-modal.html',
                            controller: 'StaffItemEditCtrl',
                            resolve: {
                                holdings: function() { return holdings; },
                                itemid: function() { return null; },
                                mfhdid: function(){ return mfhdid; },
                                clone: function(){ return angular.copy(item); }
                            }
                        });

                        // in each case, might return a nested modal.
                        editorRV.duplicate = addAnotherModal.result;
                        return editorRV;

                } else if($scope.saveOptions.addMulti > 1){

                    var items = [ cloneItem(item) ];
                    for (var i = 0; i < $scope.saveOptions.addMulti - 2; i++) {
                        items.push(cloneItem(items[i]));
                    }

                    var extraItemCount = 0;

                    $scope.saveOptions.addMulti = 1;

                    var lastPromise = items.reduce(
                        function(itemPromise,curItem){
                            return itemPromise.then(
                                function () {
                                    return curItem.$create().then(
                                        function(newItem){
                                            extraItemCount++;
                                            if(target_mfhd_id)
                                                return newItem.$relinkMfhd( {target_mfhd_id: target_mfhd_id});
                                            else
                                                return newItem;
                                    }, function(fail){
                                        console.warn('failed multi Item create.');
                                        console.log( fail ); // FIXME: force refresh.
                                        return $q.reject(fail); }
                                )}
                            )}, $q.when(true));

                    return lastPromise.then(function(lastItem){
                        alertService.add({type: "success",
                            msg: extraItemCount + ' additional items added.' });
                        editorRV.doNotClose = false;
                        editorRV.multi = items;
                        holdings.addItem( items.concat( newItem ) );

                        return editorRV;

                    }, function(err){
                        editorRV.error = true;
                        alertService.addApiError(err,'Error encountered in Add Multiple Copies');
                    });

                }
            }).finally(function(val){
                if (!editorRV.doNotClose) $uibModalInstance.close(editorRV);
            });

        }
    };
    function saveError (e) {
console.warn(e);
        var msg = "There was an error saving the item record: ";

        var data;
        try{ data = JSON.parse(e.data); } catch(err) { data = e.data; }
        var field = data.constraint;

        if(e.status == '409'){
            editorRV.doNotClose = true; // Leave the modal open on duplicates.
            msg += data.message + "  '" + data.value + "' violates " + data.constraint + ' constraint.';
            // This is only for barcode atm. (FIXME)
            if($scope.itemForm && $scope.itemForm[field]) $scope.itemForm[field].$setValidity('duplicate', false);
            var unwatch = $scope.$watch( 'item.'+field, function(nv,ov){
                if(nv != ov){
                    // assume any change is ok.
                    $scope.itemForm[field].$setValidity('duplicate', true);
                    unwatch();
                }
            });
        } else {
            msg += e.statusText + ' ' + e.data;
        }
        alertService.add( { msg: msg, type: "error"});
    }

   $scope.itemTemplates = kwApi.ItemTemplate.
            getList( { branchcode: userService.login_branch }).
            sort(function(a,b){ return a.name.localeCompare(b.name);});
    $scope.activeDefaults = {
        tmpl: null,
        scope: 'item'  //  { item, session }.
    };

    $scope.setTmplScope = function(){
        var scope = $scope.activeDefaults.scope;
        $scope.activeDefaults.scope = (scope=='session') ? 'session' : 'item';
        if(scope=='session'){
            userService.clientSession.newItemTemplate = $scope.activeDefaults.tmpl;
        } else {
            userService.clientSession.newItemTemplate = null;
            if(scope=='delete'){
                var i = $scope.itemTemplates.indexOf($scope.activeDefaults.tmpl);
                if(i<0) console.warn( 'no find tmpl ');
                $scope.activeDefaults.tmpl.$delete({}, function(tmpl){
                    $scope.itemTemplates.splice(i,1);
                    tmpl.id = undefined;
                });
            } else if(scope=='clear'){
                $scope.item.$applyDefaults(newItemDefaults());
                $scope.activeDefaults.tmpl = null;
            }
        }
    };
    $scope.applyTmpl = function(tmpl){

        $scope.vis.optionsDrop = false;
        // default values override all extant values (for create only)
        $scope.activeDefaults.tmpl = tmpl;
        if(!$scope.activeDefaults.scope) $scope.activeDefaults.scope = 'item';
        // sterilize against bad tmpl data.
        configService.ItemFields.filter( function(f){ return f.editor.readonly; }
                    ).map( function(field){ return field.code; }
                    ).forEach(function(ro_field){ delete tmpl[ro_field] ;});

        $scope.item.$applyDefaults(tmpl.template);
    };

    if(userService.clientSession.newItemTemplate){
        if( !itemid )
            $scope.applyTmpl(userService.clientSession.newItemTemplate);
        $scope.activeDefaults.scope = 'session';
    }
    $scope.saveTmpl = function(){
        var tmplName = ($scope.activeDefaults.tmpl||{}).name;
        kohaDlg.dialog({
            heading: "Save default values",
            message: "Save item defaults for this session ?",
            inputs: [{ name: 'name', label: 'Name', val: tmplName },
                    { name: 'save' , label: 'Save for future sessions?', type: 'checkbox' }],
            buttons: [{val: true, label: 'Save', btnClass: 'btn-primary'},
                        {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                var tmpldata = {};

                angular.forEach($scope.item, function(v,k){
                    if( !bvItemSvc.fieldDef[k] || bvItemSvc.fieldDef[k].editor.readonly ){
                        return; // next;
                    }
                    if(v) tmpldata[k] = v;  // This won't override itemfield defaults.
                });
                if($scope.activeDefaults.tmpl){
                    $scope.activeDefaults.tmpl.name = rv.name;
                    $scope.activeDefaults.tmpl.template = tmpldata;
                } else {
                    $scope.activeDefaults.tmpl = new kwApi.ItemTemplate( {
                        name: rv.name,
                        template: tmpldata,
                        branchcode: userService.login_branch
                    });
                }
                userService.clientSession.newItemTemplate = $scope.activeDefaults.tmpl;
                if(rv.save){
                    if($scope.activeDefaults.tmpl.id){
                        $scope.activeDefaults.tmpl.$put();
                    } else {
                        $scope.activeDefaults.tmpl.$create({}, function(tmpl){
                            $scope.itemTemplates.push(tmpl);
                            $scope.activeDefaults.scope = 'session';
                        });
                    }
                }
            }
        });
    };

}])

.controller('StaffSubstituteUserCtrl', ["$scope", "configService", "roles", "vendors", "branches", "$location", "$timeout", "kwApi", "alertService", "userService", "mode", "$q", function($scope, configService, roles, vendors, branches, $location, $timeout,
         kwApi, alertService, userService, mode, $q) {

    $scope.currentUser = userService.username;
    $scope.config = configService;
    $scope.roles = roles;
    $scope.branches = branches.sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });
    $scope.vendors = vendors;
    $scope.mode = mode || 'specific';

    try {
        $scope.branchParameters = JSON.parse(configService.TemplateBranchParameters);
    }
    catch (e) {
        $scope.branchParameters = [];
        console.warn(e);
    }

    try {
        $scope.userParameters = JSON.parse(configService.TemplateUserParameters);
    }
    catch (e) {
        $scope.userParameters = [];
        console.warn(e);
    }

    var checkValid = function(newVal) {
        $scope.isValid = true;
        var errors = [];
        if ($scope.mode == 'specific') {
            if (!newVal.user) {
                $scope.isValid = false;
                errors.push("User");
            }
        }
        else if ($scope.mode == 'generic-user') {
            if (!newVal.branch) {
                $scope.isValid = false;
                errors.push("Branch");
            }
            if (!newVal.category) {
                $scope.isValid = false;
                errors.push("Category");
            }
            $scope.userParameters.forEach(function(p) {
                if (p.required && !newVal.userParameter[p.field]) {
                    $scope.isValid = false;
                    errors.push(p.label);
                }
            });
        }
        else if ($scope.mode == 'generic-branch') {
            if (!newVal.category) {
                $scope.isValid = false;
                errors.push("Category");
            }
            $scope.userParameters.forEach(function(p) {
                if (p.required && !newVal.userParameter[p.field]) {
                    $scope.isValid = false;
                    errors.push(p.label);
                }
            });
            $scope.branchParameters.forEach(function(p) {
                if (p.required && !newVal.branchParameter[p.field]) {
                    $scope.isValid = false;
                    console.log("Missing " + p.field);
                    errors.push(p.label);
                }
            });
        }
        $scope.missing = (errors.length ? errors.join(', ') : null);
    };

    $scope.su = {
        userRoles: [],
        branchParameter: {},
        userParameter: {},
    };

    $scope.$watch('su', function(newVal) {
        checkValid($scope.su);
    }, true);

    $scope.$watch('mode', function(newVal) {
        checkValid($scope.su);
    });

    $scope.patronSelectConfig = {
        load: function(query, callback) {
            kwApi.Patron.getSuEligibleList({searchValue: query}).$promise.then(function(rv) {
                callback(rv);
            }, function(err) {
                callback();
            });
        },
        loadThrottle: 600,
        maxItems: 1,
        valueField: 'borrowernumber',
        labelField: 'firstname', 
        searchField: ['firstname','surname','email','userid','card_number'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                return '<div>' + item.firstname + ' ' + item.surname + ' (' + item.branch_name + ')</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                var deets = [];
                if (item.card_number)
                    deets.push('#' + item.card_number);
                else
                    deets.push('<i>(no card number)</i>');

                if (item.userid && item.userid != item.card_number)
                    deets.push(item.userid);
                if (item.email)
                    deets.push(item.email);
                return '<div><div>' + item.firstname + ' ' + item.surname + ' (' + item.branch_name + ')</div>'
                    + '<div><small>' + deets.join(' - ') + '</small></div>';
            }
        }
    };

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'branchcode',
        labelField: 'branchname',
        searchField: ['branchname','branchcode'],
    };
    
    $scope.roleSelectConfig = {
        valueField: 'id',
        labelField: 'name',
        searchField: ['name'],
    };

    if (!$scope.mode)
        $scope.mode = 'specific';

    $scope.canGenericBranch = userService.can({substitute_user: 'generic'});
    $scope.canGenericUser = userService.can({substitute_user: {generic: 'branch'}});
    $scope.canSpecificUser = userService.can({substitute_user: 'specific'});


    $scope.categories = [];
    angular.forEach(configService.patroncats,function(val,key) {
        if ($scope.canGenericBranch || (val.category_type != 'S')) {
            $scope.categories.push({label: val.description, value: key});
        }
    });

    var makeGeneric = function() {
        var user = {};
        if ($scope.mode == 'generic-user') {
            user.branchcode = $scope.su.branch;
        }
        else {
            user.branch = {};
            angular.forEach($scope.su.branchParameter, function(val, key) {
                var matches = key.match(/^attributes\.(.+)/);
                if (matches && matches.length == 2) {
                    if (!user.branch.attributes)
                        user.branch.attributes = {};
                    user.branch.attributes[matches[1]] = val;
                }
                else {
                    user.branch[key] = val;
                }
            });
        }

        angular.forEach($scope.su.userParameter, function(val, key) {
            user[key] = val;
        });

        user.roles = [];
        angular.forEach($scope.su.userRoles, function(role) {
            user.roles.push(role);
        });

        return kwApi.Patron.makeTemplatePatron({},user).$promise;
    };

    var makeTicket = function() {
        var promise;
        if ($scope.mode == 'specific') {
            promise = $q.when({id: $scope.su.user});
        }
        else {
            promise = makeGeneric();
        }
        return promise.then(function(member) {
            console.log("Switching to member ID " + member.id);
            return kwApi.Patron.generateSuTicket({id: member.id},{}).$promise;
        });
    }

    $scope.openCurrentSession = function() {
        makeTicket().then(function(rv) {
            userService.applyProxyTicket(rv.ticket);
            $scope.resetSession();
        });
    };

    $scope.openNewSession = function() {
        makeTicket().then(function(rv) {
            var ticket = rv.ticket;
            var url = $location.protocol() + '://' + $location.host();
            if ($location.port() != 80)  {
                url = url + ':' + $location.port();
            }
            $scope.ticketUrl = url + '/?proxy_ticket=' + rv.ticket;
        });
    };

    $scope.resetSession = function() {
        $timeout(function() {
            $scope.ticketUrl = null;
        }, 500);
    };
}])

// FIXME - this is going to become intractable. We should consider loading and unloading staff apps as needed
// and move some of the common stuff to services

.controller('StaffVendorsCtrl', ["$scope", "$state", "vendors", "messageBox", "alertService", "loading", "$uibModal", "kwApi", "kwFileUploadSvc", function($scope, $state, vendors, messageBox, alertService, loading, $uibModal, kwApi, kwFileUploadSvc) {
    $scope.load = function(list) {
        angular.forEach(list, function(v) {
            if (v.status == 'Unavailable') {
                if (v.status_message)
                    v.status = 'Unavailable - Visible';
                else
                    v.status = 'Unavailable - Hidden';
            }
            
            var pmp = v.price_model_parameters;
            v.vd_breakpoint = pmp.breakpoint_type == '<' ? 'End Before' :
                pmp.breakpoint_type == '<=' ? 'End w/Incl' :
                pmp.breakpoint_type == '>' ? 'Start After' :
                pmp.breakpoint_type == '>=' ? 'Start w/Incl' : pmp.breakpoint_type;

            v.vd_basis = pmp.basis == 'none' ? 'None' :
                pmp.basis == 'count' ? 'Item Count' :
                pmp.basis == 'list' ? 'Total List' :
                pmp.basis == 'net' ? 'Total Disc' : pmp.basis;

            v.vd_method = pmp.volume_discount_overrides_base == '1' ? 'Superseding' : 'Cumulative'
        });
        if ($scope.vendors)
            $scope.vendors.replaceWith(list);
        else 
            $scope.vendors = list;
    };

    $scope.load(vendors);

    $scope.reload = function() {
        loading.add();
        kwApi.Vendor.getList().$promise.then(function(data) {
            loading.resolve();
            $scope.load(data);
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Reload failed');
        });
    };

    $scope.update = function(sels) {
        $state.go('staff.admin.vendor', {vendor_id: sels[0].id});
    };

    $scope.ledgers = function(sels) {
        $state.go('staff.admin.vendor-ledgers', {vendor_id: sels[0].id});
    };

    $scope.deprecate = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to deprecate " + (sels.length>1 ? 'these vendors' : 'this vendor') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.Vendor.deprecateAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Deprecate failed');
            });
        });
    };

    $scope.purge = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to purge " + (sels.length>1 ? 'these vendors' : 'this vendor') + '? This will PERMANENTLY delete all associated bibs and items!'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.Vendor.purgeAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Purge failed');
            });
        });
    };

    $scope.create = function() {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/detail.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            controller: 'StaffVendorsDetailCtrl',
            resolve: {
                vendor: function() { return {
                    price_model_parameters: {
                        basis: 'none',
                        breakpoint_type: '>=',
                        volume_discount_overrides_base: '0',
                    },
                    admin_percent: 0,
                    status: 'Available',
                } },
            },
            size: 'lg',
            backdropClick: false,
        }).result.then(function() {
            $scope.reload();
        });
    };


    $scope.classTable = function(sels) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/vendor-classification-table.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: ["$scope", "ctable", "vendor", function($scope, ctable, vendor) {
                $scope.ctable = ctable;
                $scope.applyTable = function(rows) {
                    $scope.ctable.length = 0;
                    angular.forEach(rows, function(row) {
                        $scope.ctable.push({branch_code: row[0], classification: row[1]});
                    });
                };
                $scope.save = function() {
                    loading.add();
                    kwApi.Vendor.setClassificationTable({id: vendor.id}, {table: $scope.ctable}).$promise.then(function() {
                        loading.resolve();
                        $scope.$close();
                    }, function(err) {
                        loading.resolve();
                        alertService.addApiError(err,'Update failed');
                    });
                };
            }],
            resolve: {
                vendor: function() { return sels[0]; },
                ctable: function() { 
                    return kwApi.Vendor.getClassificationTable({id: sels[0].id}).$promise;
                },
            },
        }).result.then(function(data) {
            $scope.reload();
        });
    };


    $scope.download = function(sels) {
        var ids = [];
        angular.forEach(sels, function(s) { ids.push(s.id) });
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/vendors-download.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: ["$scope", function($scope) {
                $scope.scopeOptions = [{display: 'All vendors', value: 'all'}];
                if (sels.length>0)
                    $scope.scopeOptions.push({display: 'Selection only (' + sels.length + ' vendors)', value: 'selected'});
                else
                    $scope.scopeOptions.push({display: 'Headers only', value: 'selected'});

                $scope.formdata = {
                    format: 'xlsx',
                    scope: 'selected',
                    vendor_ids: ids.join('.'),
                };
                $scope.vendor_count = sels.length;
            }]
        });
    };

    $scope.downloadVdTables = function(sels) {
        var ids = [];
        angular.forEach(sels, function(s) { ids.push(s.id) });
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/vendors-download-vd-tables.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: ["$scope", function($scope) {
                $scope.scopeOptions = [{display: 'All vendors', value: 'all'}];
                if (sels.length>0)
                    $scope.scopeOptions.push({display: 'Selection only (' + sels.length + ' vendors)', value: 'selected'});
                else
                    $scope.scopeOptions.push({display: 'Headers only', value: 'selected'});

                $scope.formdata = {
                    format: 'xlsx',
                    scope: 'selected',
                    vendor_ids: ids.join('.'),
                };
                $scope.vendor_count = sels.length;
            }]
        });
    };

    $scope.upload = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Vendors',
            description: 'Choose a spreadsheet in Excel (.xlsx) or CSV (.csv) format. You can download a '
                        + 'set of headers first to set up your spreadsheet (unhighlight any rows and click '
                        + 'the download button)',
            instructions: true,
            formdata: {
                format: '',
                update_action: 'update'
            },
            inputs: [
                {name: 'format', type: 'select', label: 'Format', 
                    instructions: "Select the file format (Excel or comma-separated value), or use Automatic " 
                            + "and the format will be assumed from the file extension (.csv or .xslx)",
                    values: [
                        {value: '', display: 'Automatic (from file extension)'},
                        {value: 'xlsx', display: 'Excel (.xlsx)'},
                        {value: 'csv', display: 'Comma-separated (.csv)'}
                    ]
                },
                {name: 'update_action', type: 'select', label: 'Action', 
                    instructions: "Update - add or replace vendor records (match on Vendor Code). "
                            + "Update / Delete - requires a Delete column; if non-empty, any matching vendor " 
                            + "(match on Vendor Code) will be deleted. "
                            + "Add Only - only add new ledgers; any match on Vendor Code is an error. " 
                            + "Replace - completely replace all vendors.",
                    values: [
                        {value: 'update', display: 'Update'},
                        {value: 'update_delete', display: 'Update / Delete'},
                        {value: 'insert', display: 'Add Only'},
                        {value: 'replace', display: 'Replace'},
                    ]
                },
            ],
            url: '/api/vendor?op=upload'
        }).then(function() {
            $scope.reload();
        });
    };

    $scope.uploadVdTables = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Discount Tables',
            description: 'Choose a spreadsheet in Excel (.xlsx) or CSV (.csv) format. You can download a '
                        + 'set of headers first to set up your spreadsheet (unhighlight any rows and click '
                        + 'the download button). Each spreadsheet row must include a valid Vendor Code, '
                        + 'Level, and Amount, and may include volume discount parameters (Volume Discount '
                        + 'Basis, Volume Discount Type, and Breakpoint Type)',
            instructions: true,
            formdata: {
                format: '',
            },
            inputs: [
                {name: 'format', type: 'select', label: 'Format', 
                    instructions: "Select the file format (Excel or comma-separated value), or use Automatic " 
                            + "and the format will be assumed from the file extension (.csv or .xslx)",
                    values: [
                        {value: '', display: 'Automatic (from file extension)'},
                        {value: 'xlsx', display: 'Excel (.xlsx)'},
                        {value: 'csv', display: 'Comma-separated (.csv)'}
                    ]
                },
            ],
            url: '/api/vendor/?op=upload-vd-table'
        }).then(function() {
            $scope.reload();
        });

    };
}])

.controller('StaffVendorsDetailCtrl', ["$scope", "$state", "vendor", "kwApi", "loading", "alertService", function($scope, $state, vendor, kwApi, loading, alertService) {
    $scope.isUpdate = (vendor.id ? true : false);
    $scope.vendor = vendor;

    if ($scope.vendor.status == 'Unavailable') {
        if ($scope.vendor.status_message)
            $scope.vendor.status = 'Unavailable - Visible';
        else
            $scope.vendor.status = 'Unavailable - Hidden';
    }

    vendor.admin_percent = '' + Math.floor(1000*vendor.admin_percent)/10;

    if (!$scope.vendor.price_model_parameters)
        $scope.vendor.price_model_parameters = {};
    if (!$scope.vendor.price_model_parameters.volume_discount_structure)
        $scope.vendor.price_model_parameters.volume_discount_structure = [];

    angular.forEach($scope.vendor.price_model_parameters.volume_discount_structure, function(ent) {
        ent.amount = '' + Math.floor(1000*ent.amount)/10;
    });

    $scope.addDiscountTier = function() {
        $scope.vendor.price_model_parameters.volume_discount_structure.push({level: '0.00', amount: '0.00'});
    };
    $scope.rmDiscountTier = function(i) {
        $scope.vendor.price_model_parameters.volume_discount_structure.splice(i,1);
    };

    $scope.cancel = function() {
        if ($scope.isUpdate) {
            $state.go('staff.admin.vendors');
        }
        else {
            console.log("Executing close");
            $scope.$close();
        }
    };

    $scope.save = function(data) {
        data = angular.copy(data);
        console.log("Save");
        if (data.status == 'Unavailable - Hidden') {
            data.status = 'Unavailable';
            data.status_message = '';
        }
        else if (data.status == 'Unavailable - Visible') {
            if (!data.status_message)
                data.status_message = 'Not Available';
        }
        data.admin_percent = data.admin_percent / 100;
        angular.forEach(data.price_model_parameters.volume_discount_structure, function(ent) {
            ent.amount = ent.amount/100;
        });

        loading.add();
        //console.dir(data);
        var saved = ($scope.isUpdate ? kwApi.Vendor.put({id: data.id}, data) : kwApi.Vendor.save(data));
        saved.$promise.then(function() {
            loading.resolve();
            if ($scope.isUpdate) {
                $state.go('staff.admin.vendors');
            }
            else {
                $scope.$close();
            }
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Save failed');
        });
    };

    $scope.setDiscountTable = function(data) {
        $scope.vendor.price_model_parameters.volume_discount_structure.length = 0;
        angular.forEach(data, function(row) {
            $scope.vendor.price_model_parameters.volume_discount_structure.push({level: row[0], amount: row[1]});
        });
    };
}])

.controller('StaffVendorsLedgersCtrl', ["$scope", "$state", "vendor", "ledgers", "ledgerPriceModels", "messageBox", "alertService", "loading", "$uibModal", "kwApi", "kwFileUploadSvc", "$q", "kwImportFactorySvc", function($scope, $state, vendor, ledgers, ledgerPriceModels, messageBox, alertService, loading, $uibModal, kwApi, kwFileUploadSvc, $q, kwImportFactorySvc) {
    $scope.vendor = vendor;

    $scope.load = function(list) {
        list.forEach(function(e) {
            e.display_status = e.access_status;
        });

        if ($scope.ledgers)
            $scope.ledgers.replaceWith(list);
        else 
            $scope.ledgers = list;
    };

    $scope.load(ledgers);

    $scope.reload = function() {
        loading.add();
        kwApi.Vendor.getLedgers({id: vendor.id}).$promise.then(function(data) {
            loading.resolve();
            $scope.load(data);
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Reload failed');
        });
    };

    $scope.back = function() {
        $state.go('staff.admin.vendors');
    };

    $scope.update = function(sels) {
        $state.go('staff.admin.vendor-ledger', {vendor_id: vendor.id, ledger_id: sels[0].id});
    };

    $scope.deprecate = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to deprecate " + (sels.length>1 ? 'these ledgers' : 'this ledger') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.Ledger.deprecateAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Deprecate failed');
            });
        });
    };

    $scope.purge = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to purge " + (sels.length>1 ? 'these ledgers' : 'this ledger') + '? This will PERMANENTLY delete all associated bibs and items!'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.Ledger.purgeAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Purge failed');
            });
        });
    };

    $scope.create = function() {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledger-detail.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: 'StaffVendorsLedgerDetailCtrl',
            resolve: {
                vendor: function() { return vendor; },
                ledger: function() { return {
                    price_model: 'BaseItem DiscountNone',
                    is_default: '0',
                    price_model_parameters: {},
                    vendor_code: vendor.id,
                    access_status: 'Available',
                    pricing_status: 'Available',
                    product_type: 'OTAC',
                    product_subtype: 'PnM',
                } },
                ledgerPriceModels: ["$stateParams", "kwApi", function($stateParams, kwApi) {
                    return kwApi.Ledger.getPriceModelConfig({id: $stateParams.ledger_id}).$promise;
                }],
            },
            size: 'lg',
        }).result.then(function() {
            $scope.reload();
        });
    };

    $scope.pricing = function(sels) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledger-pricing.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: 'StaffVendorsLedgerPricingCtrl',
            resolve: {
                ledgerPriceModels: function() { return ledgerPriceModels; },
                vendor: function() { return vendor; },
                ledger: function() { 
                    var ldg = sels[0];
                    ldg.price_models = ldg.price_model.split(' ');
                    return ldg;
                },
                doSave: function() { return true; }
            },
            size: 'lg',
        }).result.then(function(data) {
            $scope.reload();
        });
    };

    $scope.download = function(sels) {
        var ids = [];
        angular.forEach(sels, function(s) { ids.push(s.id) });
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledgers-download.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: ["$scope", function($scope) {
                $scope.scopeOptions = [{display: 'All ledgers for this vendor', value: 'all'}];
                if (sels.length>0)
                    $scope.scopeOptions.push({display: 'Selection only (' + sels.length + ' ledgers)', value: 'selected'});
                else
                    $scope.scopeOptions.push({display: 'Headers only', value: 'selected'});

                $scope.formdata = {
                    format: 'xlsx',
                    scope: 'selected',
                    ledger_ids: ids.join('.'),
                };
                $scope.vendor_id = vendor.id;
                $scope.ledger_count = sels.length;
            }]
        });
    };

    $scope.downloadPricing = function(sels) {
        var ids = [];
        angular.forEach(sels, function(s) { ids.push(s.id) });
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledgers-download-pricing.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: ["$scope", function($scope) {
                $scope.scopeOptions = [{display: 'All ledgers for this vendor', value: 'all'}];
                if (sels.length>0)
                    $scope.scopeOptions.push({display: 'Selection only (' + sels.length + ' ledgers)', value: 'selected'});
                else
                    $scope.scopeOptions.push({display: 'Headers only', value: 'selected'});

                $scope.formdata = {
                    format: 'xlsx',
                    scope: 'selected',
                    type: 'discount',
                    ledger_ids: ids.join('.'),
                };
                $scope.vendor_id = vendor.id;
                $scope.ledger_count = sels.length;
            }]
        });
    };

    $scope.upload = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Ledgers',
            description: 'Choose a spreadsheet in Excel (.xlsx) or CSV (.csv) format. You can download a '
                        + 'set of headers first to set up your spreadsheet (unhighlight any rows and click '
                        + 'the download button)',
            instructions: true,
            formdata: {
                format: '',
                update_action: 'update'
            },
            inputs: [
                {name: 'format', type: 'select', label: 'Format', 
                    instructions: "Select the file format (Excel or comma-separated value), or use Automatic " 
                            + "and the format will be assumed from the file extension (.csv or .xslx)",
                    values: [
                        {value: '', display: 'Automatic (from file extension)'},
                        {value: 'xlsx', display: 'Excel (.xlsx)'},
                        {value: 'csv', display: 'Comma-separated (.csv)'}
                    ]
                },
                {name: 'update_action', type: 'select', label: 'Action', 
                    instructions: "Update - add or replace ledger records (match on Ledger Code). "
                            + "Update / Delete - requires a Delete column; if non-empty, any matching ledger " 
                            + "(match on Ledger Code) will be deleted. "
                            + "Add Only - only add new ledgers; any match on Ledger Code is an error. " 
                            + "Replace - completely replace all ledgers for this vendor.",
                    values: [
                        {value: 'update', display: 'Update'},
                        {value: 'update_delete', display: 'Update / Delete'},
                        {value: 'insert', display: 'Add Only'},
                        {value: 'replace', display: 'Replace'},
                    ]
                },
            ],
            url: '/api/vendor/' + vendor.id + '/ledgers?op=upload'
        }).then(function() {
            $scope.reload();
        });
    };

    $scope.uploadPricing = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Price Tables',
            description: 'Choose a spreadsheet in Excel (.xlsx) or CSV (.csv) format. You can download a '
                        + 'set of headers first to set up your spreadsheet (unhighlight any rows and click '
                        + 'the download button). Each spreadsheet row must include a valid ledger code '
                        + 'in addition to the pricing information',
            instructions: true,
            formdata: {
                format: '',
                type: 'discount',
                criteria: 'table',
            },
            inputs: [
                {name: 'format', type: 'select', label: 'Format', 
                    instructions: "Select the file format (Excel or comma-separated value), or use Automatic " 
                            + "and the format will be assumed from the file extension (.csv or .xslx)",
                    values: [
                        {value: '', display: 'Automatic (from file extension)'},
                        {value: 'xlsx', display: 'Excel (.xlsx)'},
                        {value: 'csv', display: 'Comma-separated (.csv)'}
                    ]
                },
                {name: 'type', type: 'select', label: 'Table Type', 
                    instructions: "Choose either 'List Price' to upload a table to determine list pricing, or "
                                + "'Discount' to upload a table to determine discount percentage.",
                    values: [
                        {value: 'base', display: 'List Price'},
                        {value: 'discount', display: 'Discount'},
                    ]
                },
                {name: 'criteria', type: 'select', label: 'Criteria', 
                    instructions: "Choose either 'From Table' to automatically determine the price criteria "
                                + "from the table, or 'From Ledger' to use whatever criteria are already "
                                + "specified in the ledger price parameters",
                    values: [
                        {value: 'ledger', display: 'From Ledger'},
                        {value: 'table', display: 'From Table'},
                    ]
                },
            ],
            url: '/api/vendor/' + vendor.id + '/ledgers?op=upload-pricing'
        }).then(function() {
            $scope.reload();
        });
    };

    $scope.factory = function(sels) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledgers-select-factory.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            resolve: {
                scripts: function() {
                    var deferred = $q.defer();
                    kwApi.ImportScript.getList().$promise.then(function(data) {
                        var scripts = [];
                        angular.forEach(data, function(d) {
                            if (d.script_type == 'sheet-single') {
                                d._script_category = "Spreadsheet";
                            }
                            else if (d.script_type == 'sheet-full') {
                                d._script_category = "Spreadsheet";
                            }
                            else {
                                d._script_category = "MARC";
                            }
                            if (d.primary_vendor_code === null)
                                d._primary_vendor_code = '(any vendor)';
                            else 
                                d._primary_vendor_code = d.primary_vendor_code;
                            if (d.embed_primary_ledger_code === null)
                                d._embed_primary_ledger_code = '(any ledger)';
                            else
                                d._embed_primary_ledger_code = d.embed_primary_ledger_code;

                        });
                        angular.forEach(data, function(d) {
                            if (d.primary_ledger_id == sels[0].id) {
                                scripts.push({
                                    value: d,
                                    display: (d.name + ' - ' + d._script_category + ' (' + d._primary_vendor_code + ' / ' + d._embed_primary_ledger_code + ') ' )
                                });
                            }
                        });
                        if (scripts.length > 0) {
                            scripts[0].selected = 1;
                        }

                        angular.forEach(data, function(d) {
                            if (d.primary_ledger_id != sels[0].id) {
                                scripts.push({
                                    value: d.id,
                                    display: (d.name + ' - ' + d.script_type + ' (' + d.primary_vendor_code + ' / ' + d.embed_primary_ledger_code + ') ' )
                                });
                            }
                        });
                        deferred.resolve(scripts);
                    }, function() { deferred.reject() });
                    return deferred.promise;
                }
            },
            controller: ["$scope", "scripts", function($scope, scripts) {
                $scope.scripts = scripts;
                $scope.formdata = {};
                angular.forEach(scripts, function(s) {
                    if (s.selected)
                        $scope.formdata.script = s.value;
                });
            }]
        }).result.then(function(data) {
            if (data) {
                kwImportFactorySvc.executeScript(data, sels[0].vendor_code, sels[0].id)
            }
        });
    };


}])

.controller('StaffVendorsLedgerDetailCtrl', ["$scope", "$state", "vendor", "ledger", "ledgerPriceModels", "kwApi", "loading", "alertService", "$uibModal", function($scope, $state, vendor, ledger, ledgerPriceModels, kwApi, loading, alertService, $uibModal) {
    //console.dir(ledgerPriceModels);
    $scope.isUpdate = (ledger.id ? true : false);
    $scope.vendor = vendor;
    $scope.ledger = ledger;


    $scope.oldStatus = ($scope.isUpdate ? $scope.ledger.access_status : '');
    $scope.indexBibs = '1';

    $scope.subtypes = {
        'OTAC': [
            {id: 'PnM', value: 'Pick and Mix (PnM)'},
            {id: 'DDA', value: 'Demand-Driven Acquisition (DDA)'}
        ],
        'SUB': [
            {id: 'SUB', value: 'Subscription (SUB)'}
        ],
        'UPAK': [
            {id: 'UPOT', value: 'One Time (UPOT)'},
            {id: 'UPR', value: 'Renewable (UPR)'}
        ]
    };

    $scope.price_models = ledgerPriceModels;
    $scope.selectConfig = {
        valueField: 'model',
        labelField: 'title', 
        searchField: ['title'],
        create: false,
    };

    if ($scope.vendor.status == 'Unavailable') {
        if ($scope.vendor.status_message)
            $scope.vendor.status = 'Unavailable - Visible';
        else
            $scope.vendor.status = 'Unavailable - Hidden';
    }
    vendor.admin_percent = '' + Math.floor(1000*vendor.admin_percent)/10;

    if (!$scope.vendor.price_model_parameters)
        $scope.vendor.price_model_parameters = {};
    if (!$scope.vendor.price_model_parameters.volume_discount_structure)
        $scope.vendor.price_model_parameters.volume_discount_structure = [];

    angular.forEach($scope.vendor.price_model_parameters.volume_discount_structure, function(ent) {
        ent.amount = '' + Math.floor(1000*ent.amount)/10;
    });

    $scope.ledger.price_models = $scope.ledger.price_model.split(' ');
    // TODO ledger translations

    $scope.cancel = function() {
        if ($scope.isUpdate) {
            $state.go('staff.admin.vendor-ledgers', {vendor_id: vendor.id});
        }
        else {
            $scope.$close();
        }
    };

    $scope.save = function(data) {
        data = angular.copy(data);
        //console.dir(data);

        data.price_model = data.price_models.join(' ');

        if ($scope.oldStatus == $scope.ledger.access_status)
            $scope.indexBibs = '0';

        loading.add();
        var saved = ($scope.isUpdate ? kwApi.Ledger.put({id: data.id, reindex: $scope.indexBibs}, data) : kwApi.Ledger.save({reindex: $scope.indexBibs}, data));

        saved.$promise.then(function() {
            loading.resolve();
            if ($scope.isUpdate) {
                $state.go('staff.admin.vendor-ledgers', {vendor_id: vendor.id});
            }
            else {
                $scope.$close();
            }
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Save failed');
        });
    };

    $scope.pricing = function() {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/vendors/ledger-pricing.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            controller: 'StaffVendorsLedgerPricingCtrl',
            resolve: {
                ledgerPriceModels: function() { return ledgerPriceModels; },
                vendor: function() { return vendor; },
                ledger: function() { return $scope.ledger; },
                doSave: function() { return false; }
            },
            size: 'lg',
        }).result.then(function(data) {
            if (data) {
                $scope.ledger.price_model_parameters = data;
            }
        });
    };
}])

.controller('StaffVendorsLedgerPricingCtrl', ["$scope", "ledger", "ledgerPriceModels", "kwApi", "loading", "alertService", "doSave", function($scope,  ledger, ledgerPriceModels, kwApi, loading, alertService, doSave) {
    var modelDefs = {};
    angular.forEach(ledgerPriceModels, function(m) {
        modelDefs[m.model] = m;
    });

    $scope.price_model_parameters = angular.copy(ledger.price_model_parameters);

    $scope.ledgerParameters = [];
    $scope.byIndex = {};
    angular.forEach(ledger.price_models, function(model) {
        var pdesc = modelDefs[model].parameter_descriptions;
        for (var i=0; i<pdesc.length; i=i+2) {
            var paramName = pdesc[i];
            var defs = pdesc[i+1];
            var value = $scope.price_model_parameters[paramName];
            if (defs.map === 'percent')
                value = (100*value).toFixed(2);
            if ((defs.type == 'criteria') && !value)
                value = [];
            else if (defs.type == 'criteria-table') {
                var linked = $scope.byIndex[defs.link];
                var ivCount = (linked.value || []).length;
                var dvCount = (defs.dependent_variables || []).length;
                if (value) {
                    for (var j=0; j<value.length; j++) {
                        if (!value[j]) value[j] = [];
                        for (var k=ivCount; k<ivCount+dvCount; k++)
                            if (defs.dependent_variables[k-ivCount].map == 'percent')
                                value[j][k] = (100*value[j][k]).toFixed(2);
                    }
                }
                else {
                    value = [];
                }
            }

            $scope.byIndex[paramName] = angular.extend({name: paramName, value: value}, defs);
            $scope.ledgerParameters.push($scope.byIndex[paramName]);
        }
    });

    $scope.addValue = function(param) {
        param.push('');
    };

    $scope.cancel = function() {
        $scope.$close();
    };

    $scope.save = function() {
        var data = angular.copy($scope.ledgerParameters);
        var newParams = {};
        angular.forEach(data, function(defs) {
            if (defs.map === 'percent')
                defs.value = (1*defs.value) / 100;
            if ((defs.type == 'criteria') && !defs.value)
                defs.value = [];
            else if (defs.type == 'criteria-table') {
                var linked = $scope.byIndex[defs.link];
                var ivCount = (linked.value || []).length;
                var dvCount = (defs.dependent_variables || []).length;
                if (defs.value) {
                    for (var j=0; j<defs.value.length; j++) {
                        if (!defs.value[j]) defs.value[j] = [];
                        for (var k=ivCount; k<ivCount+dvCount; k++) {
                            if (defs.dependent_variables[k-ivCount].map == 'percent')
                                defs.value[j][k] = (1*defs.value[j][k])/100;
                        }
                    }
                }
                else {
                    defs.value = [];
                }
            }
            newParams[defs.name] = defs.value;
        });
        if (doSave) {
            var ledgerCopy = angular.copy(ledger);
            ledgerCopy.price_model_parameters = newParams;
            loading.add();
            kwApi.Ledger.put({id: ledgerCopy.id}, ledgerCopy).$promise.then(function() {
                loading.resolve();
                $scope.$close();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Save failed');
            });
        }
        else {
            $scope.$close(newParams);
        }
    };
}])

.controller('StaffLedgerImportBaseCtrl', ["$scope", "configService", "$uibModal", "kwImportFactorySvc", function($scope, configService, $uibModal, kwImportFactorySvc) {
    $scope.pipelineTypes = [
        {value: 'header', description: 'Header', withType: {'sheet-single': true, 'sheet-full': true}},
        {value: 'sheet', description: 'Sheet', withType: {'sheet-full': true}},
        {value: 'row', description: 'Row', withType: {'sheet-single': true}},
        {value: 'marc', description: 'MARC', withType: {'marc-single': true}},
        {value: 'bibselect', description: 'Catalog Record Select', withType: {'bib-analysis': true}},
        {value: 'bib', description: 'Catalog Record', withType: {'bib-analysis': true}},
        {value: 'bibfinalize', description: 'Catalog Record Finalize', withType: {'bib-analysis': true}},
    ];

    $scope.scriptTypes = [
        {value: 'sheet-single', description: 'Process an Excel spreadsheet, one row at a time', brief: 'Spreadsheet (row at a time)'},
        {value: 'sheet-full', description: 'Process an Excel spreadsheet, all rows at once', brief: 'Spreadsheet (entire sheet)'},
        {value: 'marc-single', description: 'Process a MARC batch, one record at a time', brief: 'MARC batch (record at a time)'},
        {value: 'bib-analysis', description: 'Analyze or export catalog records', brief: 'Analysis/export (record at a time)'},
    ];

    var byValue = {};
    angular.forEach($scope.scriptTypes, function(t) {
        byValue[t.value] = t.brief;
    });
    configService.addDisplay('import_script_type', byValue);

    byValue = {};
    angular.forEach($scope.pipelineTypes, function(t) {
        byValue[t.value] = t.description;
    });
    configService.addDisplay('import_scriptlet_type', byValue);


    // FIXME - This should be moved into a service most likely

    $scope.executeScript = function(script) {
        kwImportFactorySvc.executeScript(script, script.primary_vendor_code, script.primary_ledger_id);
    };

    $scope.editComponent = function(component) {
        var ptype;
        angular.forEach($scope.pipelineTypes, function(p) {
            if (p.value == component.script_type)
                ptype = p;
        });

        return $uibModal.open({
            templateUrl: '/app/static/partials/staff/tools/ledger-importer/scriptlet-update.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            backdrop: 'static',
            controller: 'StaffLedgerImportScriptletCtrl',
            resolve: {
                importScriptlet: ["kwApi", function(kwApi) {
                    if (component.id) {
                        return kwApi.ImportScriptlet.get({id: component.id}).$promise;
                    }
                    else {
                        return component;
                    }
                }],
                pipelineType: function() {
                    return ptype;
                },
                pipelineTypes: function() {
                    return $scope.pipelineTypes;
                },
            },
            size: 'lg',
        }).result;
    };
}])

.controller('StaffLedgerImportScriptsCtrl', ["$scope", "importScripts", "$state", "loading", "alertService", "messageBox", "kwApi", "$uibModal", function($scope, importScripts, $state, loading, alertService, messageBox, kwApi, $uibModal) {
    $scope.importScripts = importScripts;
    $scope.update = function(sels) {
        $state.go('staff.tools.ledger-importer.script', {id: sels[0].id});
    };

    $scope.create = function() {
        $state.go('staff.tools.ledger-importer.script', {id: 'NEW'});
    };

    $scope.components = function() {
        $state.go('staff.tools.ledger-importer.scriptlets');
    };

    $scope.reload = function() {
        kwApi.ImportScript.getList().$promise.then(function(data) {
            data.forEach(function(rec) {
                rec.is_approved = (rec.tainted === null || rec.tainted === '0' || rec.tainted === 0) ? true : false;
            });
            $scope.importScripts.replaceWith(data);
        });
    };

    $scope.execute = function(sels) {
        $scope.executeScript(sels[0]);
    };

    $scope.runLogs = function(sels) {
        $state.go('staff.tools.ledger-importer.script-runs', {id: sels[0].id});
    };

    $scope.copy = function(sels) {
        kwApi.ImportScript.deepCopy({id: sels[0].id}, {}).$promise.then(function(newRec) {
            $state.go('staff.tools.ledger-importer.script', {id: newRec.id});
        });
    };

    $scope.createFromTemplate = function() {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-new-from-template.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            backdrop: 'static',
            controller: ["$scope", "importScripts", function($scope,importScripts) {
                $scope.importScripts = [];
                angular.forEach(importScripts, function(s) {
                    if (s.is_template == 1)
                        $scope.importScripts.push(s);
                });
                //console.dir($scope.importScripts);
                $scope.formdata = {selected: null, args: {}};
            }],
            resolve: {
                importScripts: ["kwApi", function(kwApi) {
                    return kwApi.ImportScript.getList().$promise;
                }],
            },
        }).result.then(function(formdata) {
            if (formdata) {
                kwApi.ImportScript.deepCopy({id: formdata.selected.id}, formdata.args).$promise.then(function(newRec) {
                    $state.go('staff.tools.ledger-importer.script', {id: newRec.id});
                });
            }
        });
    };

    $scope.delete = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to delete " + (sels.length>1 ? 'these scripts' : 'this script') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.ImportScript.deleteAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Delete failed');
            });
        });
    };
}])


.controller('StaffLedgerImportScriptletsCtrl', ["$scope", "importScriptlets", "$state", "loading", "alertService", "messageBox", "kwApi", "$uibModal", function($scope, importScriptlets, $state, loading, alertService, messageBox, kwApi, $uibModal) {
    $scope.importScriptlets = importScriptlets;
    $scope.importScriptlets.forEach(function(c) {
        c.is_approved = (c.tainted === null || c.tainted === 0 || c.tainted === '0') ? true : false;
    });
    $scope.update = function(sels) {
        $scope.editComponent(sels[0]).then(function() {
            $scope.reload();
        });
    };

    $scope.scripts = function() {
        $state.go('staff.tools.ledger-importer.scripts');
    };

    $scope.create = function() {
        $scope.editComponent({code:'', description:'', name:'', script_type: '', isNew: true}).then(function(newComponent) {
            $scope.reload();
        });
    };

    $scope.reload = function() {
        kwApi.ImportScriptlet.getList({view: 'index'}).$promise.then(function(data) {
            $scope.importScriptlets.replaceWith(data);
            $scope.importScriptlets.forEach(function(c) {
                c.is_approved = (c.tainted === null || c.tainted === 0 || c.tainted === '0') ? true : false;
            });
        });
    };

    $scope.delete = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to delete " + (sels.length>1 ? 'these components' : 'this component') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.ImportScriptlet.deleteAll({ids: ids}).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Delete failed');
            });
        });
    };

    $scope.approve = function(sels) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/tools/ledger-importer/scriptlet-approve.html',
        }).result.then(function(pw) {
            if (pw) {
                var ids = [];
                angular.forEach(sels, function(sel) { ids.push(sel.id); });
                loading.wrap(
                    kwApi.ImportScriptlet.approveAll({ids: ids, password: pw}).$promise,
                    "Unable to approve components"
                ).then(function() {
                    $scope.reload();
                });
            }
        });
    };
}])

.controller('StaffLedgerImportScriptCtrl', ["$scope", "$state", "loading", "alertService", "kwApi", "importScript", "importScriptlets", "vendors", "$uibModal", "$q", "configService", function($scope, $state, loading, alertService, kwApi, importScript, importScriptlets, vendors, $uibModal, $q, configService) {

    $scope.vendors = vendors;
    $scope.vendors.unshift({branchcode: null, branchname: '(No Associated Vendor)'});
    $scope.infomart = configService.infomart;

    $scope.ledgers = [];

    $scope.config = configService;

    importScript.components = {
        header: [],
        sheet: [],
        row: [],
        marc: [],
        bibselect: [],
        bib: [],
        bibfinalize: [],
    };
    var tainted = [];
    angular.forEach(importScript._embed.scriptlets, function(c) {
        if (c.tainted) {
            tainted.push(c.id);
            c.display_name = c.name + ' [Unapproved]';
        }
        else {
            c.display_name = c.name + ' [Approved]';
        }
        angular.forEach(c.parameters, function(p) {
            p.value = c.embed_parameter_values[p.name];
        });
        importScript.components[c.script_type].push(c);
    });
    delete importScript._embed;
    $scope.tainted = tainted.join(' ');

    $scope.importScript = importScript;
    if (importScript.primary_vendor_code) {
        kwApi.Vendor.getLedgers({id: importScript.primary_vendor_code}).$promise.then(function(ledgers) {
            $scope.ledgers = ledgers;
        });
    }

    $scope.availableComponents = {};

    $scope.loadScriptlets = function(recs) {
        $scope.availableComponents.length = 0;
        angular.forEach($scope.pipelineTypes, function(ptype) {
            var t = ptype.value;
            $scope.availableComponents[t] = [
                {display_name: 'New component (blank)...', description: 'New component...', isNew: true},
                {display_name: 'New component (from copy)...', description: 'New component (from copy)...', isNew: true, isCopy: true},
            ];
        });
        angular.forEach(recs, function(rec) {
            if (rec.script_type && $scope.availableComponents[rec.script_type]) {
                if (rec.tainted) {
                    rec.display_name = rec.name + ' [Unapproved]';
                }
                else {
                    rec.display_name = rec.name + ' [Approved]';
                }
                $scope.availableComponents[rec.script_type].push(rec);
            }
        });
    };

    $scope.reloadScriptlets = function() {
        var deferred = $q.defer();
        kwApi.ImportScriptlet.getList().$promise.then(function(data) {
            $scope.loadScriptlets(data);
            deferred.resolve(data);
        }, function() {
            deferred.reject();
        });
        return deferred.promise;
    };

    $scope.loadScriptlets(importScriptlets);

    $scope.save = function(orig) {
        var data = angular.copy(orig);
        delete data.components;

        var scriptlets = [];
        angular.forEach($scope.pipelineTypes, function(pt) {
            var t = pt.value;
            angular.forEach(orig.components[t], function(c) {
                if (c.id) {
                    var scriptlet = {id: c.id, embed_parameter_values: {}};
                    angular.forEach(c.parameters, function(p) {
                        scriptlet.embed_parameter_values[p.name] = p.value;
                    });
                    scriptlets.push(scriptlet);
                }
            });
        });
        data.scriptlets = scriptlets;
        loading.add();
        var saved = (data.id ? kwApi.ImportScript.put({id: data.id}, data) : kwApi.ImportScript.save({}, data));
        saved.$promise.then(function(newRec) {
            loading.resolve();
            $state.go('staff.tools.ledger-importer.scripts');
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Save failed');
        });
    };

    $scope.cancel = function() {
        $state.go('staff.tools.ledger-importer.scripts');
    };

    $scope.$watch('importScript.components', function() {
        var tainted = [];
        $scope.importScript.is_approved = true;
        angular.forEach($scope.importScript.components, function(val, key) {
            val.forEach(function(c) {
                if (c.tainted !== 0 && c.tainted !== '0') {
                    tainted.push(c.id);
                    $scope.importScript.is_approved = false;
                }
            });
        });
        $scope.tainted = tainted.join(' ');
    }, true);


    $scope.editComponentAndReload = function(component) {
        var deferred = $q.defer();
        $scope.editComponent(component).then(function(data) {
            if (data) {
                data.display_name = data.name + ' [Unapproved]';
                angular.extend(component,data);
                $scope.reloadScriptlets().then(function() {
                    deferred.resolve(data);
                }, function() {
                    deferred.resolve(data);
                });
            }
            else {
                deferred.reject();
            }
        });
        return deferred.promise;
    };

    $scope.newComponent = {
        header: null,
        sheet: null,
        row: null,
        marc: null,
        bibselect: null,
        bib: null,
        bibfinalize: null,
    };

    $scope.$watch('newComponent', function(newVal, oldVal) {
        angular.forEach(oldVal, function(val, key) {
            if (newVal[key] !== null && newVal[key] !== oldVal[key]) {
                var component = newVal[key];
                if (component.isCopy) {
                    $uibModal.open({
                        templateUrl: '/app/static/partials/staff/tools/ledger-importer/scriptlet-copy.html',
                        dialogClass: 'xmodal',
                        backdropClass: 'modal-backdrop',
                        controller: ["$scope", "availableComponents", function($scope, availableComponents) {
                            $scope.availableComponents = availableComponents;
                            $scope.formdata = {selected: null};
                        }],
                        resolve: {
                            availableComponents: function() {
                                var rv = [];
                                angular.forEach($scope.availableComponents[key], function(v) {
                                    if (!v.isNew) {
                                        rv.push(v);
                                    }
                                });
                                return rv;
                            }
                        },
                        size: 'sm',
                    }).result.then(function(clone) {
                        if (clone) {
                            component = angular.copy(clone);
                            component.name = "Copy of " + component.name;
                            component.id = null;
                            component.isNew = true;
                            $scope.editComponentAndReload(component).then(function(newComponent) {
                                $scope.importScript.components[key].push(newComponent);
                            });
                        }
                        $scope.newComponent[key] = null;
                    });
                }
                else if (component.isNew) {
                    $scope.editComponentAndReload({code:'', description:'', name:'', script_type: key, isNew: true}).then(function(newComponent) {
                        $scope.importScript.components[key].push(newComponent);
                    });
                    $scope.newComponent[key] = null;
                }
                else {
                    $scope.importScript.components[key].push(component);
                    $scope.newComponent[key] = null;
                }
            }
        });
    }, true);

    $scope.$watch('importScript.primary_vendor_code', function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== null) {
            importScript.primary_ledger_id = null;
            kwApi.Vendor.getLedgers({id: newVal}).$promise.then(function(ledgers) {
                $scope.ledgers = ledgers;
            });
        }
    });

/*    $scope.$watch('importScript.script_type', function(newVal, oldVal) {
        if (newVal !== oldVal && newVal !== null) {
            importScript.import_type = null;
        }
    });*/
}])
.controller('StaffLedgerImportScriptletCtrl', ["$scope", "kwApi", "importScriptlet", "pipelineType", "pipelineTypes", "kwFileUploadSvc", "loading", "alertService", function($scope, kwApi, importScriptlet, pipelineType, pipelineTypes, kwFileUploadSvc, loading, alertService) {
    $scope.importScriptlet = importScriptlet;
    $scope.isUpdate = ($scope.importScriptlet.id ? true : false);
    $scope.pipelineType = pipelineType;
    $scope.pipelineTypes = pipelineTypes;
    $scope.hasParameters = (importScriptlet.parameters && importScriptlet.parameters.length>0 ? "1" : "0");
    if (!importScriptlet.parameters) importScriptlet.parameters = [];

    $scope.uploadCode = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Script',
            description: 'Upload Perl code.',
            instructions: false,
            formdata: {},
            inputs: [],
            url: '/api/import-scriptlet/' + importScriptlet.id + '?op=upload-code'
        }).then(function(rec) {
            angular.extend($scope.importScriptlet, rec.data);
        });
    };

    $scope.save = function(data) {
        loading.add();
        var saved = ($scope.isUpdate ? kwApi.ImportScriptlet.put({id: data.id}, data) : kwApi.ImportScriptlet.save({}, data));
        saved.$promise.then(function(newRec) {
            loading.resolve();
            $scope.$close(newRec);
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Save failed');
        });
    };

}])

.controller('StaffLedgerImportScriptRunsCtrl', ["$scope", "$state", "importScriptRuns", "$uibModal", "messageBox", "kwApi", "alertService", "loading", "$stateParams", function($scope, $state, importScriptRuns, $uibModal, messageBox, kwApi, alertService, loading, $stateParams) {
    $scope.runs = importScriptRuns;

    $scope.back = function() {
        $state.go('staff.tools.ledger-importer.scripts');
    };

    $scope.reload = function() {
        kwApi.ImportScript.getRuns({id: $stateParams.id}).$promise.then(function(data) {
            $scope.runs.replaceWith(data);
        });
    };

    $scope.view = function(sels) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/tools/ledger-importer/script-execute-results.html',
            dialogClass: 'xmodal',
            backdropClass: 'modal-backdrop',
            backdropClick: false,
            backdrop: 'static',
            size: 'lg',
            controller: ["$scope", "data", "import_script_id", "$sce", function($scope,data,import_script_id, $sce) {
                $scope.data = data;
                if ($scope.data.debug) {
                    angular.forEach($scope.data.debug, function(d) {
                        $sce.trustAsHtml(d.debug);
                    });
                }
                if (data.queue_id) {
                    $scope.fileDownloadUrl = '/api/import-script/' + import_script_id + '?view=download&qid=' + encodeURIComponent(data.queue_id) + '&as=' + encodeURIComponent(data.export_as);
                }
                else if (data.export_file) {
                    $scope.fileDownloadUrl = '/api/import-script/' + import_script_id + '?view=download&file=' + encodeURIComponent(data.export_file) + '&as=' + encodeURIComponent(data.export_as);
                }
            }],
            resolve: {
                data: function() { return sels[0].results; },
                import_script_id: function() { return sels[0].import_script_id; },
            }
        });
    };

    $scope.delete = function(sels) {
        messageBox.confirm({
            title:      "Are you sure?",
            message:    "Are you sure you want to delete " + (sels.length>1 ? 'these run logs' : 'this run log') + '?'
        }).then(function(result) {
            loading.add();
            var ids = [];
            angular.forEach(sels, function(sel) { ids.push(sel.id); });
            kwApi.ImportScriptRun.forAll({action: 'delete', ids: ids}).$promise.then(function(rv) {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Delete failed');
            });
        });
    };
}])

.controller('StaffCircHoldsQueueCtrl', ["$scope", "$http", "$window", "kwApi", "userService", "loading", "$uibModal", "alertService", function ($scope, $http, $window, kwApi, userService, loading, $uibModal, alertService) {
    $scope.showGrid = true;
    $scope.order = {
        field: 'title',
        reverse: false
    }

    $scope.selectedBranch = null;
    var headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' };

    userService.getAccessibleBranchesAndGroups('circulate.circulate_remaining_permissions').then(function(b) {
        $scope.branches = b.branches;
        $scope.branches.forEach( function (branch) {
            if ( branch.branchcode == userService.login_branch ) {
                $scope.selectedBranch = branch;
            }
        });
    }, function(e){
        console.error("Branch list loading error in StaffCircHoldsQueueCtrl: " + e);
    });

    $scope.numSelected = 0;

    $scope.viewDetails = function(s) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/circ-holds-queue-detail.html',
            windowClass: 'modal',
            controller: ["$scope", function($scope) {
                $scope.rec = s[0];
            }],
        });
    };

    $scope.gridOptions = {
        enableGridMenu: true,
        enableSorting: true,
        enableColumnResizing: true,
        enableFiltering: true,
        minRowsToShow: 20,
        showGridFooter:true,
        enableFullRowSelection: true,
        exporterPdfDefaultStyle: {fontSize: 9},
        exporterPdfHeader: { text: " Holds Queue", style: 'headerStyle' },
        exporterPdfFooter: function ( currentPage, pageCount ) {
            return { text: " " + currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
        },
        exporterPdfCustomFormatter: function ( docDefinition ) {
            docDefinition.styles.headerStyle = { fontSize: 22, bold: true, alignment: 'center' };
            docDefinition.styles.footerStyle = { fontSize: 10, bold: true, alignment: 'center' };
            return docDefinition;
        },
        rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" class="ui-grid-cell" ng-dblclick="grid.appScope.viewDetails([row.entity])" ui-grid-cell></div>',
        columnDefs: [
            { field: 'reservenumber', visible: false },
            { field: 'holdingbranch', displayName: 'Branch' },
            { field: 'title' },
            { field: 'author'},
            { field: 'details' },
            { field: 'ccode', displayName: 'Collection' },
            { field: 'location', displayName: 'Shelving Location' },
            { field: 'itemcallnumber', displayName: 'Call Number' },
            { field: 'enumchron', displayName: 'Enum/Chron' },
            { field: 'barcode_display', displayName: 'Barcode' },
            { field: 'patron' },
            { field: 'notes' },
            { field: 'reservedate', displayName: 'Date', cellFilter: 'dateFmt' },
            { field: 'pickbranch', displayName: 'Send To' },
        ]
    };

    $scope.getHolds = function () {
        if ($scope.myGridApi) { $scope.myGridApi.selection.clearSelectedRows(); }

        loading.add();
        var selectedBranch = $scope.selectedBranch ? $scope.selectedBranch.branchcode : '';
        $http.get('/api/holds-queue/' + selectedBranch, { authRequired: true }).then(function (rsp) {
            angular.forEach(rsp.data, function(hold){ // Take care of bib details manipulation here.

                var details = '';

                if (hold.publishercode) {
                    details += hold.publishercode;
                }

                if (hold.publicationyear) {
                    details += '\n,' + hold.publicationyear;
                }
                else if (hold.copyrightdate) {
                    details += '\n,' + hold.copyrightdate;
                }

                if (hold.pages) {
                    details += '\n: ' + hold.pages;
                }

                if (hold.size) {
                    details += '\n' + hold.size;
                }

                if (hold.isbn) {
                    details += 'ISBN: ' + hold.isbn;
                }

                hold.details = details;
                hold.patron = hold.surname + ', ' + hold.firstname + ' (' + hold.cardnumber + ')';

                if (hold.item_level_request == undefined) {
                    hold.barcode_display = 'Selected copy: '+ hold.barcode;
                }
                else {
                    hold.barcode_display = hold.barcode;
                }

                if (hold.items_out_of_circulation == 1) {
                    hold.items_available = 'No';
                }
                else {
                    hold.items_available = 'Yes';
                }
            });
            $scope.gridOptions.data = rsp.data ? rsp.data : ''; // undef grid data breaks the display
            $scope.holdsqueue = rsp.data;
            loading.resolve();
        });
    };

    $scope.gridOptions.onRegisterApi = function(gridApi) {
        gridApi.core.registerColumnsProcessor(hidePrefColumns);

        function hidePrefColumns (columns) {
            var userPrefs = userService.merged_prefs.holds_queue_column;
            for (var property in userPrefs) {
                if (userPrefs.hasOwnProperty(property)) {
                    if (userPrefs[property] === false) {
                        delete userPrefs[property];
                    }
                }
            }
            var rolePrefs = userService.role_prefs.holds_queue_column;
            var yourPrefs = $.extend({}, rolePrefs, userPrefs);
            if (yourPrefs) {
                columns.forEach( function (c, key) {
                    if (yourPrefs[c.field]) {
                        $scope.gridOptions.columnDefs.forEach( function (def, i) {
                            if (def.field === c.field) {
                                $scope.gridOptions.columnDefs.splice(i, 1);
                            }
                        });
                    }
                });
            }
            return columns;
        }

        $scope.myGridApi = gridApi;
        $scope.myGridApi.selection.on.rowSelectionChanged($scope, function(row) {
            if (row.isSelected) {
                $scope.numSelected++;
            }
            else {
                $scope.numSelected--;
            }
        });
        $scope.myGridApi.selection.on.rowSelectionChangedBatch($scope, function(rows) {
            rows.forEach(function(row) {
                if (row.isSelected) {
                    $scope.numSelected++;
                }
                else {
                    $scope.numSelected--;
                }
            });
        });
    };

    $scope.viewTitle = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();
        $window.open('/app/staff/bib/' + gridSelection[0].biblionumber + '/details');
    }

    $scope.viewPatron = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();
        window.open('/app/staff/patron/' + gridSelection[0].borrowernumber + '/checkout');
    }

    $scope.cancelHolds = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();

        if ( confirm('Confirm the cancellation of selected holds') ) {
            gridSelection.forEach( function (el) {
                    $http.post('/api/holds-queue/' + el.biblionumber, $.param( { op: 'cancel', borrowernumber: el.borrowernumber } ),
                            { headers: headers, authRequired: true });
            });
        }

        $scope.myGridApi.selection.clearSelectedRows();
        $scope.getHolds();
    }

    $scope.traceHolds = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows();
        $scope.myGridApi.selection.clearSelectedRows();

        gridSelection.forEach( function (el) {
            var params = { op: 'trace', passbranch: el.holdingbranch, reservenumber: el.reservenumber };
            $http.post('/api/holds-queue/' + el.biblionumber, $.param(params), {headers: headers , authRequired: true})
                .then( function () {
                    alertService.add({msg: 'Barcode traced: ' + el.barcode, type: 'success'});
                    $scope.getHolds();
                }, function (e) {
                    console.error(e);
                    alertService.add({msg: 'Barcode could not be traced: ' + el.barcode, type: 'error'});
                });
        });
    }

    $scope.passHolds = function () {
        var gridSelection = $scope.myGridApi.selection.getSelectedRows(),
            skipped = [];

        $scope.myGridApi.selection.clearSelectedRows();

        gridSelection.forEach( function (el) {
            if (el.item_level_request) { // Cannot pass on item-level holds.
                skipped.push(el.barcode);
            }
            else {
                var params = { op: 'pass', passbranch: el.holdingbranch, reservenumber: el.reservenumber };
                $http.post('/api/holds-queue/' + el.biblionumber, $.param(params), {headers: headers, authRequired: true})
                    .then( function () {
                        alertService.add({msg: 'Barcode passed: ' + el.barcode, type: 'success'});
                        $scope.getHolds();
                    }, function (e) {
                        console.error(e);
                        alertService.add({msg: 'Barcode could not be passed: ' + el.barcode, type: 'error'});
                    });
            }
        });

        if (skipped.length > 0) {
            var msg = "The following barcodes are item level holds and cannot be passed: " + skipped.join(", ");
            alert(msg);
        }
    }

    $scope.validateBarcode = function () {
        var rows = $scope.myGridApi.selection.getSelectedRows(),
            row  = rows[0];

        $scope.myGridApi.selection.clearSelectedRows();

        if ( row.barcode == "Any available copy" ) {
            var modal = $uibModal.open({
                    backdrop: true,
                    templateUrl: '/app/static/partials/staff/circ/hold-barcode-checkin-modal.html',
                    controller: ["$scope", "$uibModalInstance", function ($scope, $uibModalInstance) {
                        $scope.userBarcode = '';
                        $scope.barcodeSubmit = function (barcode) {
                            $uibModalInstance.close(barcode);
                        }
                    }]
            });

            modal.result.then( function (rv) {
                return rv ? $scope.checkInHold(rv, row.biblionumber, row.reservenumber) : false;
            });
        }
        else {
            $scope.checkInHold(row.barcode, row.biblionumber, row.reservenumber);
        }

    }

    $scope.checkInHold = function (barcode, biblionumber, reservenumber) {
        $window.open('/app/staff/circ/checkin/' + barcode);

        var params = { op: 'checkIn', reservenumber: reservenumber };
        $http.post('/api/holds-queue/' + biblionumber, $.param(params), {headers: headers, authRequired: true})
            .then( function () {
                alertService.add({msg: 'Hold checked in', type: 'success'});
                $scope.getHolds();
            }, function (e) {
                console.error(e);
                alertService.addApiError(e,'Hold could not be checked in');
            });
    }

    $scope.selectAll = function() {
        $scope.myGridApi.selection.selectAllRows();
    }

    $scope.clearAll = function() {
        $scope.myGridApi.selection.clearSelectedRows();
    }

    $scope.getHolds();
}])

.directive('kwPatronSelect', ["$http", "$state", "configService", "$timeout", "userService", function($http, $state, configService, $timeout, userService) {
    // typeahead or barcode search for patron.
    // use: <kw-patron-select target-state="target.state" />
    //    shows error if patron not found, otherwise triggers state change.
    // or: <kw-patron-select on-select="doSomething($id)" />
    //    note in this form it may still trigger a patron search if circAutoCompl is off . [FIXME]
    // optional attributes:
    //    require-select =>  no search expansion; must select from list.

    return {
        templateUrl: '/app/static/partials/staff/patron-quick-select.html',
        scope: { targetState: '@', // static value.
                    onSelect: '&'
                },
        link: function(scope, elm, attrs, ngModelCtrl) {
            // $timeout(function(){
                // elm.find('input').first().focus();
            // },2);
            // Note ui-bootstrap's modal steals focus unless
            // an input has autofocus attribute.

            scope.withSort = 'withSort' in attrs;
            scope.requireSelect = ('requireSelect' in attrs);

            scope.autocomplete = (scope.requireSelect || attrs.onSelect) || configService.CircAutocompl;

            scope.psearch = {
                //query value will become patron id if search is successful.
                query: '',
                shadowQuery: '',
                noResults: false,
                orderby: '',  // default is name
                loading: false
            };

            scope.clearSearch = function(){

                scope.psearch.query = scope.psearch.shadowQuery = '';
                $timeout(function(){
                    scope.psearch.loading = scope.psearch.noResults = false;
                    // manually setting since ui-bootstrap 0.13 typeahead
                    // remains open after auto-select on barcode match.
                });
            };

            scope.findPatron = function(){
                if(!scope.requireSelect){
                    scope.expandPatronSearch();
                }
            };

            scope.go = function(patron){
                scope.clearSearch();
                if(attrs.targetState){
                    var rs = $state.go(attrs.targetState, { borrowernumber: patron.id }, { reload: true , inherit: false });
                } else if(scope.onSelect){
                    scope.onSelect({ '$id': patron.id });
                }
            };

            scope.expandPatronSearch = function(q){
                var query = (scope.autocomplete) ? scope.psearch.shadowQuery : scope.psearch.query;
                $state.go('staff.patrons.search', { member: query }, { reload: false });
                scope.clearSearch();
            };

            var nameRegex = /([a-zA-Z-]+)\s*(,?)\s*([a-zA-Z-]+)?/;  // this should really be done on backend.

            scope.searchPatrons = function(val, maxResults) {
                scope.psearch.shadowQuery = val;
                var params = {
                    cache: true,
                    view: 'picker',
                    limitQuery: maxResults || 25,
                    branchcode: userService.login_branch,
                    orderBy: scope.psearch.orderby
                };

                var m = nameRegex.exec(val);
                if(m && m[3]){
                    params.searchBoth = true;
                    if(m[2]==','){
                        params.searchFirst = m[3];
                        params.searchLast = m[1];
                    } else {
                        params.searchFirst = m[1];
                        params.searchLast = m[3];
                    }
                } else {
                    params.searchValue = val;
                }

                return $http.get('/api/patron', { 'params': params }).then( function (response) {
                    var responses = response.data;
                    responses = responses.map( function (d) {
                        return {
                            id: d.id,
                            label: d.name + ' (' + d.card_number + ') -- ' + d.id + ' ' + d.branch_code,
                            cardnumber: d.card_number
                        };
                    });
                    // auto-match cardnumber:
                    if(responses.length==1 && val == responses[0].cardnumber){
                        scope.go( responses[0] );
                        return []; // otherwise dropdown doesn't close.

                    }
                    // note typeahead-select-on-exact tests that the $viewValue matches the label returned above.

                    return responses;
                });
            };
        }
    };
}])

.controller('StaffEventLogCtrl', ["$scope", "$timeout", "$http", "$uibModal", "kwApi", "loading", "alertService", "userService", "$sce", "fieldMap", "$filter", function ($scope, $timeout, $http, $uibModal, kwApi, loading, alertService, userService, $sce, fieldMap, $filter) {
    $scope.fieldMap = fieldMap;

    var moduleName = {
        'ACQLIST': 'Infomart',
        'CATALOG': 'Catalog access',
        'CATALOGUING': 'Cataloging',
        'AUTHORITIES': 'Authorities',
        'CIRCULATION': 'Circulation',
        'MEMBERS': 'User',
        'SOLRSYNC': 'SOLR Sync',
        'SERIAL': 'Serials',
        'SYSTEMPREFERENCE': 'System Config'
    };

    var transformObject = function(e) {
        if (moduleName[e.module])
            e.module = moduleName[e.module];

        if (!e.link && e.object) {
            var id = e.object;
            var name;
            switch (e.object_type) {
                case 'ItemList':
                    name = e.list_name ? e.list_name : "(List " + id + ")";
                    e.link = "<a href='/app/me/acqlists/" + e.object + "'>" + name + "</a>";
                    break;
                case 'ImportBatch':
                    e.link = "<a href='/app/staff/tools/marc-manage/" + id + "'>Batch " + id + "</a>";
                    break;
                case 'Bib':
                    e.link = "<a href='/app/work/" + id + "'>Bib " + id + "</a>";
                    break;
                case 'Item':
                    if (e.bib_id) {
                        //e.link = "Item " + id + " in <a href='/app/work/" + e.bib_id + "'>Bib " + e.bib_id + "</a>";
                        e.link = "Item " + id + " in <a href='/app/staff/bib/" + e.bib_id + "/details'>Bib " + e.bib_id + "</a>";
                    }
                    break;
                case 'Subscription':
                    e.link = "<a href='/app/staff/serials/subscription/" + id + "'>Subscription " + id + "</a>";
                    break;
                case 'Issue':
                    if (e.bib_id) {
                        //e.link = "Issue " + id + " in <a href='/app/work/" + e.bib_id + "'>Bib " + e.bib_id + "</a>";
                        e.link = "Issue " + id + " in <a href='/app/staff/bib/" + e.bib_id + "/details'>Bib " + e.bib_id + "</a>";
                    }
                    break;
                case 'VendorBranch':
                    e.link = "<a href='/app/staff/admin/vendor/" + e.object + "'>" + e.object + "</a>";
                    break;
                case 'Ledger':
                    if (e.vendor_id)
                        e.link = "<a href='/app/staff/admin/vendor/" + e.vendor_id + "/ledger/" + e.object + "'>" + e.object + "</a>";
                    break;
                default:
                    e.link = id;
                    break;
            }
        }

        if (e.link)
            $sce.trustAsHtml(''+e.link);

        if (e.member_name) {
            e.member_name = "<a href='/app/staff/patron/" + e.user + "'>" + e.member_name + "</a>";
        }
        else if (e.user === "0") {
            e.member_name = 'System User';
        }
        else if (e.user === "-1") {
            e.member_name = 'Anonymous User';
        }
        else if (e.user) {
            e.member_name = "<a href='/app/staff/patron/" + e.user + "'>Member #" + e.user + "</a>";
        }
        if (e.member_name)
            $sce.trustAsHtml(e.patron_name);
            
    };

    if (!($scope.limits = userService.getPref('staff.event_log.limits'))) {
        $scope.limits = {
            module: undefined,
            since: '1 day',
            start_date: undefined,
            end_date: undefined,
            mode: 'logs',
            y1: 'All',
            dummy: 0
        };
        userService.setPref('staff.event_log.limits', $scope.limits);
        $scope.limits.since = '1 day';
    }

    $scope.switchMode = function(mode) {
        $scope.limits.mode = mode;
    };

    $scope.groupBy = function(group) {
        $scope.limits.y1 = group;
    };

    $scope.viewModule = function(module) {
        if (module === '') 
            $scope.limits.module = undefined;
        else
            $scope.limits.module = module;
    };

    $scope.viewSince = function(since) {
        if (since === '') 
            $scope.limits.since = undefined;
        else
            $scope.limits.since = since;
        $scope.limits.start_date = undefined;
        $scope.limits.end_date = undefined;
    };

    $scope.calendar = {
        opened: {
            'start_date': false,
            'end_date': false,
        },
        dateFormat: 'yyyy-MM-dd',
        open: function(which) {
            if ($scope.limits.start_date === undefined)
                $scope.limits.start_date = new Date();
            if ($scope.limits.end_date === undefined)
                $scope.limits.end_date = new Date();

            $scope.limits.since = undefined;

            $timeout(function(){
                $scope.calendar.opened[which] = true;
            });
        }
    };

    $scope.$watch('limits', function(newVal, oldVal) {
        if (newVal && oldVal) {
            userService.setPref('staff.event_log.limits', $scope.limits);
        }

        if (newVal) {
            $scope.settingsDescription = "Viewing ";
            var params = {};
            params.view = newVal.mode;
            if (newVal.module) {
                params.module = newVal.module;
                $scope.settingsDescription += moduleName[params.module];
            }
            
            if (params.view == 'stats') {
                $scope.settingsDescription += " statistics derived from activity";
            }
            else {
                $scope.settingsDescription += " activity";
            }

            if (newVal.since) {
                params.since = newVal.since;
                $scope.settingsDescription += " since " + params.since + " ago";
            }
            if (newVal.start_date) {
                params.start_date = dateFormat(newVal.start_date);
                $scope.settingsDescription += " from " + $filter('kohaDate')(params.start_date);
            }
            if (newVal.end_date) {
                params.end_date = dateFormat(newVal.end_date);
                $scope.settingsDescription += " to " + $filter('kohaDate')(params.end_date)
            }
            if (newVal.y1 && (params.view == 'stats')) {
                params.y1 = newVal.y1;
                if (params.y1 != 'All')
                    $scope.settingsDescription += " grouped by " + params.y1;
            }
            if (params.view == 'stats') {
                $scope.stats(params);
            }
            else {
                $scope.load(params);
            }

        }
    }, true);

    //$scope.deleteBefore = $scope.deleteBefore.setDate($scope.deleteBefore.getDate() - 30);

    var dateFormat = function (date) {
        var d = new Date(date);
        var curr_date = d.getDate();
        if (curr_date < 10)
            curr_date = "0" + curr_date;
        var curr_month = d.getMonth() + 1;
        if (curr_month < 10)
            curr_month = "0" + curr_month;
        var curr_year = d.getFullYear();
        var date_string = "" + curr_year + "-" + curr_month + "-" + curr_date;
        return date_string;
    }

    $scope.deleteEventLog = function () {
        var result = confirm("This action cannot be undone. Confirm deletion:");
        var date_string = dateFormat($scope.deleteBefore);
        if (result)
            return $http({method: 'DELETE', url: '/api/event-log?date=' + date_string, authRequired: true});
    }

    $scope.logs = [];
    $scope.load = function (params) {
        loading.add();
        kwApi.ActionLog.getList(params).$promise.then(function(data) {
            angular.forEach(data, function(row) {
                transformObject(row);
            });
            loading.resolve();

            $scope.logs.replaceWith(data);
        }, function(err) {
            alertService.addApiError(err,'Load error');
            loading.resolve();
        });
    };
    
    $scope.statsGrid = {
        data: [],
        columnDefs: [],
        //enableFiltering: true,
        enableSorting: true,
        flatEntityAccess: true,
        enableColumnResizing: true,
        enablePagination: false,
        enableColumnMenus: true,
        enableRowSelection: false,
        enableRowHeaderSelection: false,
        showGridFooter: true,
        enableFooterTotalSelected: false,
        multiSelect: false,
        enableGridMenu: true,
    };
        
    $scope.stats = function (params) {
        loading.add();
        kwApi.ActionLog.getStats(params).$promise.then(function(rv) {
            console.dir(rv);
            $scope.statsGrid.data.length = 0;
            angular.forEach(rv.data, function(row) {
                var obj = {};
                for (var i=0; i<rv.columns.length; i++) {
                    obj[""+i] = row[i];
                }
                $scope.statsGrid.data.push(obj);
            });
                
            $scope.statsGrid.columnDefs.length = 0;
            for (var i=0; i<rv.columns.length; i++) {
                if (i == 0 && rv.columns[i] == 'All')
                    continue;

                $scope.statsGrid.columnDefs.push({
                    field: ""+i,
                    sortable: true,
                    displayName: rv.columns[i]
                });
            }

            loading.resolve();
        }, function(err) {
            alertService.addApiError(err,'Load error');
            loading.resolve();
        });
    };


    $scope.details = function(e) {
        $uibModal.open({
            title: 'Event Details',
            templateUrl: '/app/static/partials/staff/tools/event-log/details.html',
            controller: ["$scope", function($scope) {
                $scope.moduleName = moduleName;
                $scope.rows = [];
                angular.forEach(fieldMap, function(fm) {
                    if (fm.field in e[0])
                        $scope.rows.push({field: fm.value, value: e[0][fm.field], html: fm.html});
                });
            }]
        });
    };

    $scope.download = function () {
        $uibModal.open({
            title:          'Export',
            templateUrl:    '/app/static/partials/staff/tools/event-log/export.html',
            resolve: {
                limits: function() {
                    return $scope.limits;
                }
            },
            controller: ["$scope", "limits", function ($scope, limits) {
                $scope.format = 'xlsx';
                $scope.limits = limits;
                var params = {};
                if (limits.module)
                    params.module = limits.module;
                if (limits.since)
                    params.since = limits.since;
                if (limits.start_date)
                    params.start_date = dateFormat(limits.start_date);
                if (limits.end_date)
                    params.end_date = dateFormat(limits.end_date);
                if (limits.y1)
                    params.y1 = limits.y1;
                params.view = limits.mode;

                var p = [];
                angular.forEach(params, function(val, key) {
                    p.push(key + '=' + encodeURIComponent(val));
                });
                    
                $scope.uriParams = p.join('&');
            }]
        });
    };

    $scope.purge = function(since) {
        $uibModal.open({
            title: 'Purge',
            templateUrl: '/app/static/partials/staff/tools/event-log/purge.html',
            resolve: {
                limits: function() {
                    return $scope.limits;
                }
            },
            controller: ["$scope", "limits", function($scope, limits) {
                $scope.since = since;
                $scope.limits = limits;
                $scope.doPurge = function() {
                    loading.add();
                    kwApi.ActionLog.purge({age:since},{}).$promise.then(function(rv) {
                        alertService.add({msg: "Log entries purged", type: "success" });
                        loading.resolve();
                        $scope.limits.dummy++;
                        $scope.$close();
                    }, function(err) {
                        alertService.addApiError(err,'Purge failed');
                        $scope.$close();
                    });
                }
            }]
        });
    };
}])
.controller('StaffAdminAccountTypesCtrl', ["$scope", "kwApi", "alertService", function ($scope, kwApi, alertService) {

    $scope.accounttypes = kwApi.AccountType.getList({debit: true}, function(invoicetypes){
        console.log(invoicetypes);
        invoicetypes.forEach(function(invoicetype){

        })
    });

    $scope.addAcctType = function(){
        var newrow = new kwApi.AccountType( { class: 'invoice', data: { can_invoice: true, invoice: true } } );
        console.log(newrow);
        $scope.accounttypes.push( newrow );
    };

    $scope.saveAcctType = function(accttype){
        if(accttype.data.default_amount){
            var amt = parseFloat(accttype.data.default_amount).toFixed(2);
            accttype.data.default_amount = (amt === 0 || amt == 'NaN') ? '' : amt;
        }
        if(accttype.id){
            accttype.$put({}, function(rsp){
                console.log(rsp);
            }, function(err){
                alertService.addApiError(err,'Save failed');
            });

        } else {
            for (var i = 0; i < $scope.accounttypes.length; i++) {
                if($scope.accounttypes[i].id && accttype.accounttype.toLowerCase() == $scope.accounttypes[i].accounttype.toLowerCase()){
                    alertService.add({msg: "Duplicate Account Code.", type: "error"});
                    return;
                }
            }
            accttype.$save( { op: 'create' }, function(rsp){
                console.log(rsp);
                alertService.add({msg: "New Invoice type added.", type: "success"});
            } );
        }

    };
    $scope.rmAcctType = function(accttype){
        accttype.$delete({}, function(rsp){
            console.log(rsp);
            console.log(accttype);
            for (var i = $scope.accounttypes.length - 1; i >= 0; i--) {
                if(accttype.id == $scope.accounttypes[i].id) $scope.accounttypes.splice(i,1);
            }
            alertService.add({msg: "Invoice type deleted.", type: "success"});

        }, function(fail){
            console.warn(fail);
            alertService.add({msg: "Failed to delete Invoice type.  " + fail.data.replace(/\d\d\d\s*/,''), type: "error"});

        });
    };
}])

.controller('StaffPatronAccountingCtrl', ["$scope", "$state", "$http", "$uibModal", "$q", "kwApi", "alertService", "configService", "kohaDlg", "userService", "loading", "$sce", function ($scope, $state, $http, $uibModal, $q, kwApi, alertService,
                    configService, kohaDlg, userService, loading, $sce) {

    var patronid = $state.params.borrowernumber;

    $scope.acct = {
        accruing_balance: null,
        balance: null,  // Note this accounts for ExcludeAccruingInTotal
        net_credit: false,
    };

    $scope.txns = {
        show: { pmt: true, fee: 'current' },  // TODO: add param to routing so we can link to history
        view: [],
        fee: {},
        pmt: {},
        accruing: [],
        selection: function(){
            return $scope.txns.view.filter(function(txn){ return txn._selected; });
        },
        selectAll: function(){
            var toggle = !$scope.txns.selection().length;
            angular.forEach($scope.txns.fee, function(fee){
                fee._selected = (fee.outstanding && !fee.noncreditable) ? toggle : false;
            });
        }
    };
    var updateView = function(){

        // TODO: we may wish to prevent user from hiding everything ?
        var merged = [];
        if ($scope.txns.show.fee){
            angular.forEach($scope.txns.fee, function(fee){
                if($scope.txns.show.fee!='current' || fee.outstanding)
                    merged.push(fee);
            });
            merged = merged.concat($scope.txns.accruing);
        }


        function isPmtLinked (pmt, fees){
            return !! fees.filter(function(fee){
                return (fee.credits||[]).filter(function(credit){
                    return credit.payment_id == pmt.id;
                }).length;
            }).length;
        }
        // payments must be date-filtered if not showing history .
        if($scope.txns.show.pmt){
            var pmtsShown = [];

            if($scope.txns.show.fee == 'current'){
                pmtsShown = Object.values($scope.txns.pmt).filter(function(pmt){
                    return !!pmt.unallocated || isPmtLinked(pmt, merged) ||
                            dayjs().isSame(pmt.date, 'day');
                });  // Object.values dne in IE.
            } else {
                pmtsShown = Object.values($scope.txns.pmt);
            }

            merged = merged.concat(pmtsShown);
        }
        $scope.txns.view = merged;
    };
    $scope.$watchCollection('txns.show', updateView);

    var loadAllTxns = function(){
        loading.add('refreshAll');
        $scope.hasRefundsToDo = false;
        var feesPromise = kwApi.Fee.getForPatron({ patron_id: patronid }, function(fees){
            fees.forEach(function(fee){ $scope.txns.fee[fee.id] = fee; });
        }).$promise;
        var paymentsPromise = kwApi.Payment.getForPatron({ patron_id: patronid }, function(pmts){
            pmts.forEach(function(pmt){
                $scope.txns.pmt[pmt.id] = pmt;
                if(pmt.unallocated && !pmt.reallocate) $scope.hasRefundsToDo = true;

            });
        }).$promise;
        $scope.txns.accruing = kwApi.Fee.getForPatron({ patron_id: patronid, type: 'accruing' });
        $q.all( [ $scope.txns.accruing.$promise, feesPromise, paymentsPromise ]).then(function(){
           loading.resolve();
           updateView();
           loading.resolve('refreshAll');
        });
    };

    var updateSummary = function(){
        $http.get('/api/patron/'+patronid+'/account-summary').then(function(rsp){
            $scope.acct.balance = rsp.data.balance; // #includes accruing depending on syspref.
            $scope.acct.accruing_balance = rsp.data.accruing_balance;
            $scope.acct.net_credit = ($scope.acct.balance < 0);
            $scope.acct.patron_alerted = rsp.data.last_fines_alert;
            $scope.acct.fines_alert_amt = rsp.data.fines_alert_threshold || 999999;
            $scope.acct.showPatronAlert =
                ( $scope.acct.balance>0 && userService.can({fees: 'send_alert'})) ||
                $scope.acct.patron_alerted || ( $scope.acct.balance > $scope.acct.fines_alert_amt );
        });
    };

// Redistribute credits on view load.
// Likely not the ideal way to handle this, but (arguably) better than
// all payments on the backend triggering changes to other fees/payments.
    function redistributeCredits(){
        loading.add('redistribute');
        return kwApi.PatronAccount.redistributeCredits({id: patronid}).$promise.then(function(rsp){
            updateSummary();
            loadAllTxns();
            loading.resolve('redistribute');
        }, function(f){
            updateSummary();
            loadAllTxns();
            console.warn(f);
            loading.resolve('redistribute');
        });
    }
    redistributeCredits();

    var credit_action = (userService.can({ fees: 'accept_payment'})) ?
        { PAYMENT: 'Pay' , CREDIT: 'Credit' } : {};

    if(userService.can({fees: 'transfer'})) credit_action.TRANSBUS = "Transfer";

    if(userService.can({fees: 'waive'})){
        credit_action.FORGIVE = 'Forgive';
        credit_action.WRITEOFF = 'Write off';
    }
    $scope.creditActions = ['PAYMENT','CREDIT','TRANSBUS','FORGIVE','WRITEOFF'].filter(function(t){return credit_action[t];});
    $scope.creditTypes = {};

    $scope.user_can_credit = Object.keys(credit_action).length;

    $scope.feeTypes = {};
    $scope.creditTypes = {};
    $scope.acctLabel = { '_ACCRUING': 'Accruing Overdue' };
    kwApi.AccountType.getList({},function(ats){
        ats.forEach(function(accttype){
            if(accttype.class=='fee'||accttype.class=='invoice'){
                $scope.feeTypes[accttype.accounttype] = accttype;
            } else {
                if(credit_action[accttype.accounttype]){
                    accttype.verb = credit_action[accttype.accounttype];
                    $scope.creditTypes[accttype.accounttype] = accttype;
                }
            }
            $scope.acctLabel[accttype.accounttype] = accttype.description;
        });
    });

    var syncFees = function( affectedFees ){
//  we should probably block ui on any updates.

        (affectedFees||[]).forEach(function(fee){
            fee.$get();
        });
    };

    $scope.refreshCurrent = function(){
        // reloads all payments with unallocated credits and unpaid fees.
        // this should be sufficient for most transactions.
        loading.add('refreshCurrent');
        var blocking = [];
        angular.forEach($scope.txns.fee, function(fee){
            if(fee.outstanding)
                blocking.push( fee.$get().$promise );
        });
        angular.forEach($scope.txns.pmt, function(pmt){
            if(pmt.unallocated)
                blocking.push( pmt.$get().$promise );
        });
        $q.all(blocking).then(function(){
            loading.resolve('refreshCurrent');
            updateView();
        });
    };

    $scope.sort = { field: 'timestamp', reverse: true };

    $scope.payOneFee = function(fee, accttype){
        if($scope.txns.selection().length) $scope.txns.selectAll();
        fee._selected = true;
        $scope.openCreditModal(accttype);
    };

    $scope.openCreditModal = function(accttype) {
        var parentScope = $scope;
        if(!accttype) accttype = 'PAYMENT';
        if(!$scope.txns.selection().length && $scope.creditTypes[accttype].class!='payment'){
            // cannot waive without selection.  must explicitly select all first.
            return;
        }
        $uibModal.open({
            title: 'Add Credit',
            templateUrl: '/app/static/partials/staff/patrons/add-credit-modal.html',
            // size: 'sm',
            backdrop: false, // FIXME: Allow user to interact with non-modal elements
                             // so that target fees can be changed/reordered.
            backgroundClick: false,
            controller: ["$scope", "$q", function($scope, $q) {
                // loading.resolve();

                $scope.$watchCollection(parentScope.txns.selection, function(txns){
                    if(!txns) return;
                    $scope.multiTarget = txns.length > 1;
                    $scope.targetFees = txns;
                    if(txns.length){
                        $scope.targetFeeTotal = txns.reduce(function(acc,cur){
                                return cur.outstanding + acc; }, 0);
                    } else {
                        // pay all.
                        $scope.targetFeeTotal = parentScope.acct.balance;
                        if (!configService.ExcludeAccruingInTotal) $scope.targetFeeTotal -= parentScope.acct.accruing_balance;
                    }
                });

                $scope.accttype = accttype || 'PAYMENT';
                $scope.creditVerb = parentScope.creditTypes[$scope.accttype].verb;
                $scope.balance = parentScope.acct.balance;
                if(!configService.ExcludeAccruingInTotal)
                    $scope.assessed_balance = $scope.balance - parentScope.acct.accruing_balance;
                $scope.isPayment = parentScope.creditTypes[accttype].class=='payment';
                $scope.acctLabel = parentScope.acctLabel;
                $scope.credit = {
                    description: '',
                    accounttype: $scope.accttype,
                    amount: undefined,
                    borrowernumber: patronid,
                    branchcode: userService.login_branch
                };

                var updateAffected = function(fees){
                    updateSummary();
                    syncFees( fees || $scope.targetFees );
                };

                $scope.addCredit = function(){
                    if(parentScope.creditTypes[$scope.accttype].class=='payment'){
                        // reload affected fees (or all fees)
                        loading.add('pmt');
                        var credit = angular.copy($scope.credit);
                        credit.target_fees = $scope.targetFees.map(function(fee){ return fee.id; });

                        if(!credit.amount && !credit.target_fees.length){
                            // pay full balance.  Don't pay accruing.
                            var to_credit = $scope.balance;
                            if (!configService.ExcludeAccruingInTotal) to_credit -= parentScope.acct.accruing_balance;
                            if(to_credit > 0){
                                credit.amount = to_credit;
                            } else {
                                return; // should probably alert.
                            }
                        }
                        var pmt = kwApi.Payment.create( credit , function(p){
                            console.log(p);
                            parentScope.refreshCurrent();
                            parentScope.txns.pmt[pmt.id] = pmt;
                            updateSummary();
                            updateView();
            // FIXME: paying fee does not remove it from view if in current fees view.
                            loading.resolve('pmt');
                            $scope.$close();
                        }, function(fail){
                            console.warn(fail);
                            loading.resolve('pmt');
                        });

                    } else {
                        // waive.
                        loading.add('waive');

                        var amt = $scope.credit.amount;
                        // todo: allow per-fee amounts.
                        var promises = [];
                        var fees;
                        if($scope.targetFees.length){
                            fees = $scope.targetFees;
                        } else {
                            // FIXME: Do we really want to waive all fees ?
                            // (No, this is prevented).
                            // fees = parentScope.txns.fee.filter(function(fee){
                            //     return fee.outstanding && !fee.noncreditable;
                            // });
                        }
                        fees.forEach(function(fee){
                            var to_waive = ($scope.credit.amount && (fee.outstanding > $scope.credit.amount) ) ?
                                            $scope.credit.amount : undefined;
                            var credit = angular.extend({}, $scope.credit, { amount: to_waive });
                            credit.notes = credit.description;

                            var req = fee.$waive( credit, function(fee_updated){
                                console.log(fee_updated);
                            } );
                            promises.push(req);
                        });
                        $q.all(promises).then(function(rv){
                            loading.resolve('waive');
                            updateAffected(fees);
                        }, function(err){
                            console.warn(err);
                            loading.resolve('waive');
                        });
                    }
                    $scope.$close();
                };
            }]
        });
    };

    $scope.openInvoiceModal = function() {
        var parentScope = $scope;
        var feeTypes = $scope.feeTypes;
        $uibModal.open({
            title: 'Add Manual Invoice',
            templateUrl: '/app/static/partials/staff/patrons/add-fee-modal.html',
            // size: 'sm',
            backgroundClick: false,

            controller: ["$scope", "$q", function($scope, $q) {
                // loading.resolve();
                $scope.fee = {
                    description: '',
                    accounttype: $scope.accttype,
                    amount: undefined,
                    borrowernumber: patronid,
                    branchcode: userService.login_branch,
                };
                $scope.linked_item = {
                    item: null,
                    barcode: null
                };

                $scope.$watch( 'fee.accounttype', function(accttype){
                    if(accttype && feeTypes[accttype].data.default_amount){
                        $scope.fee.amount = feeTypes[accttype].data.default_amount;
                    }
                });
                $scope.invoiceTypes = Object.keys(feeTypes).reduce(function(filtered, code){
                    if(feeTypes[code].data.invoice)
                        filtered.push( angular.extend(feeTypes[code],
                            { class_desc: (feeTypes[code].class=='fee') ? 'System Fees' : 'Local Invoice Types' })
                        );
                    return filtered;
                }, []).sort(function(a,b){ return (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : -1;});

                $scope.addFee = function(){
                    if(!parseFloat($scope.fee.amount)){
                        return;
                    }
                    loading.add('addFee');
                    if($scope.linked_item.item){
                        $scope.fee.itemnumber = $scope.linked_item.item.itemnumber;
                    }
                    kwApi.Fee.create( $scope.fee , function(f){
                        parentScope.txns.fee[f.id] = f;
                        if(parentScope.acct.unallocated_amount)
                            redistributeCredits();
                        else {
                            updateSummary();
                            updateView();
                        }
                        loading.resolve('addFee');
                        $scope.$close();
                    }, function(fail){
                        console.warn(fail);
                        loading.resolve('addFee');
                        alertService.add({msg: "Add Invoice failed: " + fail.data.replace(/\d\d\d\s*/,''), type: "error"});
                    });
                };
            }]
        });
    };

    $scope.openRefundsModal = function( payment ) {
        var refunds = [];
        if(payment){
            refunds.push(payment);
        } else {
            angular.forEach($scope.txns.pmt, function(pmt){
                if(pmt.unallocated && !pmt.reallocate) refunds.push( pmt );
            });
        }

        var parentScope = $scope;
        var acctLabel = $scope.acctLabel;
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/patrons/refunds-modal.html',
            // size: 'sm',
            // Refunds are limited to lost item fees.
            // Currently [2019-04], they're only triggered if the syspref
            // RefundLostReturnedAmount is set.

            controller: ["$scope", "$q", function($scope, $q) {
                $scope.refunds = refunds;
                $scope.localRefunds = configService.RefundLostReturnedAmount;
                $scope.selected = {};
                refunds.forEach(function(pmt){
                    console.log(pmt);
                    console.log(userService.login_branch);
                    if(pmt.data.fee_id){
                        /// find the item the payment was meant for.
                        // we need its owning branch.
                        pmt.oldFee = parentScope.txns.fee[pmt.data.fee_id];
                        if(pmt.oldFee.itemnumber){
                            $http.get('/api/item/'+pmt.oldFee.itemnumber, {cache: true}).then(function(rsp){
                                var item = rsp.data;
                                if(item.homebranch==userService.login_branch)
                                    $scope.selected[pmt.id] = true;
                            });
                        } else {
                            if(pmt.branchcode==userService.login_branch)
                                $scope.selected[pmt.id] = true;
                        }
                    }
                });
                $scope.selectAll = function(){
                    if(Object.keys($scope.selected).some(function(id){ return $scope.selected[id]; })){
                        refunds.forEach(function(pmt){ $scope.selected[pmt.id] = false; });
                    } else {
                        refunds.forEach(function(pmt){ $scope.selected[pmt.id] = true; });
                    }
                };
                $scope.acctLabel = acctLabel;
                $scope.refundTotal = function(){
                    return refunds.reduce(function(acc,pmt){
                        return ($scope.selected[pmt.id]) ? acc - pmt.unallocated : acc;
                    }, 0);
                };

                // currently reallocate flag determines refundability.
                $scope.setReallocate = function(){
                    // FIXME: Allow Undo?
                    kohaDlg.dialog({
                        // heading: "Confirm",
                        message: "Unlink payment from lost item fees, and apply [ $" + $scope.refundTotal() +
                                 " ] to remaining (or future) patron account fees?"
                    }).result.then(function(ok) {
                        $scope.$close();
                        if(!ok) return;
                        loading.add('setReallocate');
                        var promises = [];
                        refunds.forEach(function(pmt){
                            if(!$scope.selected[pmt.id] || pmt.reallocate)
                                return;
                            pmt.reallocate = true;
                            var p = pmt.$setReallocate();
                            promises.push(p.$promise);
                        });
                        $q.all(promises).then(function(){
                            loading.resolve('setReallocate');
                            redistributeCredits();
                        }, function(err){
                            loading.resolve('setReallocate');
                        });
                    });
                };
                $scope.doRefunds = function(){
                    loading.add('refundPayment');
                    var promises = [];
                    refunds.forEach(function(pmt){
                        if(!$scope.selected[pmt.id] || pmt.reallocate)
                            return;
                        var p = pmt.$reverse({accounttype: 'REFUND'});
                        promises.push(p.$promise);
                    });
                    $q.all(promises).then(function(){
                        loading.resolve('refundPayment');
                        redistributeCredits();
                    }, function(err){
                        loading.resolve('refundPayment');
                    });
                    $scope.$close();
                };
            }]
        });
    };

    $scope.sendAccountingModal = function() {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/patrons/account-receipt-modal.html',
            controller: ["$scope", function($scope) {
                $scope.borrowernumber = patronid;
                kwApi.PatronAccount.getReceipt({id: patronid}).$promise.then(function(rsp){
                    $scope.receipt = $sce.trustAsHtml(rsp.data);
                }, function(fail){
                    console.warn(fail);
                    alertService.addApiError(fail,'Failed to get account receipt');
                });
                $scope.emailReceipt = function(){
                    kwApi.PatronAccount.emailReceipt({id: patronid}).$promise.then(function(rsp){
                        alertService.add({msg: "Account receipt sent", type: "success"});
                    }, function(fail){
                        console.warn(fail);
                        alertService.addApiError(fail,'Message send failed');
                    });
                };
            }]
        });
    };

    $scope.reversePmt =  function(pmt, accttype){

        var amtStr = '$' + pmt.amount.toFixed(2);
        var heading, message;
        var verb = {
            // REFUND: 'Apply Refund',
            REVERSED_PAYMENT: 'Reverse Payment',
            CANCELCREDIT: 'Cancel Credit'
        };
        if(!accttype) accttype = (pmt.accounttype=='CREDIT') ? 'CANCELCREDIT' : 'REVERSED_PAYMENT';

        kohaDlg.dialog({
            heading: verb[accttype] +' ' + amtStr,
            message: verb[accttype] + ' of ' + amtStr + ' to patron account ?',
            buttons: [{val: true, label: verb[accttype], btnClass: 'btn-primary'},
                        {val: false, label: 'No'}],
        }).result.then(function(rv) {
            if (rv) {
                loading.add('reversePmt');
                pmt.$reverse({ accounttype: accttype }, function(){
                    loading.resolve('reversePmt');
                    // really all we need to do is reload fees but...
                    loadAllTxns();
                    updateSummary();
                }, function(e){ loading.resolve('reversePmt');});
            }
        });
    };

    $scope.canReverse = function(pmt){
        return userService.can({fees: 'create'}) && !pmt.reversed;
    };
    $scope.pmtReversedDisplay = {
        CANCELCREDIT: 'Credit Canceled',
        REVERSED_PAYMENT: 'Payment Reversed',
        REFUND: 'Refunded'
    };

    $scope.can_alert = userService.can({fees: 'send_alert'});
    $scope.sendFinesAlert = function(){
        var msg = "Send email fines alert ?  ";
        if($scope.acct.fines_alert_amt){
            msg += "The patron receives this alert automatically when fines exceed " + $scope.acct.fines_alert_amt.toFixed(2);
        }
        kohaDlg.dialog({ message: msg }).result.then(function(rv) {
            if (rv) {
                // send alert...
                // $http.post('/api/patron/'+patronid+'/account-summary', { op: 'send_alert' }).then(
                kwApi.PatronAccount.sendAlert({id: patronid}).$promise.then(
                    function(rsp){
                    updateSummary();
                }, function(fail){
                    console.warn(fail);
                    alertService.addApiError(fail,'Message send failed');
                });
            }
        });
    };
    $scope.creditDetailTmpl = '/app/static/partials/components/credit-detail-popover.html';
}])

.directive('bvAcctTxnDetail', ["kwApi", "$http", "configService", function(kwApi, $http, configService) {
    return {
        scope: {
            txn: "=bvAcctTxnDetail",
            // attrs:
            //   brief (bool) : don't fetch additional bib / issue data.
            //
        },
        templateUrl: '/app/static/partials/staff/patrons/acct-txn-detail.html',
        link: function(scope, el, attrs){
            // Display of FEE:
                // lost item fees
                // accruing fees
                // overdues
                // still to do:  reserve fees, etc

            var brief = 'brief' in attrs;
            if(brief) el.addClass('brief');

            scope.item = {itemnumber: undefined, biblionumber: undefined};
            scope.$watch('txn', function(txn){
                if(!txn) return;

                scope.title = brief ? txn.briefTitle : txn.title;
                if (txn.owningBranch) scope.owningBranch =
                    configService.interpolator('bv-branch').display(txn.owningBranch);
                if (txn.itemnumber) {
                    scope.item.itemnumber = txn.itemnumber;
                    scope.item.biblionumber = txn.itemBib;
                    scope.item.barcode = txn.barcode;
                }
                if(!scope.item.biblionumber && txn.itemnumber){ // accruing fees are missing this datum
                    $http.get('/api/item/' + txn.itemnumber, {cache: true}).then(function(rsp){
                        scope.item = rsp.data;
                        scope.owningBranch = configService.interpolator('bv-branch').display(scope.item.homebranch);
                        if (!scope.title) {
                            if(brief){
                                scope.title = scope.item.bib_title;
                            } else {
                                kwApi.Work.get({id: scope.item.biblionumber}, function(bib){
                                    scope.bib = bib;
                                    scope.title = bib.title_ext;
                                });
                            }
                        }
                    });
                }
                if(txn.duedate){ // i.e. accruingfee.
                    scope.issue = { duedate: txn.duedate };
                }
                if(! brief){
                    if(txn.accounttype=='FINE' && txn.object){
                        scope.issue = kwApi.Issue.get({id: txn.object});
                    }
                }

                // FIXME: Add cached model layer.
            });

        }
    };
}])
.directive('kwItemBarcodeLookup', ["kwApi", "$timeout", function(kwApi, $timeout) {
    return {
        require: 'ngModel',
        scope: {
            item: '=kwItemBarcodeLookup'
        },
        link: function(scope, elm, attrs, ctrl) {
            var nextInput = elm.closest('.form-group').next().find('input, button').first();
            // var jj = elm.wrap('<div class="bclookup">');
            // console.log(jj);

            var lookup = function(bc) {
                kwApi.Item.getByBarcode({ barcode: bc }, function(item){
                    scope.item = item;
                    scope.item.title = '...';
                    kwApi.Work.get({id: item.biblionumber}, function(bib){
                        item.title = bib.title;
                    });
                    ctrl.$setValidity('item', true);
                }, function(err){
                    ctrl.$setValidity('item', false);
                    scope.item = null;
                });
            };
            elm.blur(function(evt){
                if(ctrl.$viewValue){
                    lookup(ctrl.$viewValue);
                } else {
                    $timeout(function(){
                        ctrl.$setValidity('item', true);
                    });
                }
            });
            elm.keypress(function(evt){
                if (evt.which == 13) {
                    console.warn(evt);
                    nextInput.focus();
                    evt.preventDefault();
                } else {
                    // $timeout(function(){
                        scope.item = null;
                    // });

                }
            });
        }
    };
}])

.controller('StaffPatronGroupCtrl', ["$scope", "$state", "kwApi", "alertService", "kohaDlg", "$injector", "$q", "$http", "userService", "configService", function ($scope, $state, kwApi, alertService,
    kohaDlg, $injector, $q, $http, userService, configService) {

    $scope.loading = true;
    var patronid = $state.params.borrowernumber;

    $scope.patron_categories = Object.keys(configService.patroncats)
        .map(function(p) { return { value: p, display: configService.patroncats[p].description }; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });

    var renderMembers = function () {
        kwApi.PatronGroup.getGroup({id: patronid}).$promise.then(function(rv) {
            $scope.members = rv;
            $scope.total_holds = 0;
            $scope.total_issues = 0;
            $scope.total_overdue = 0;
            $scope.total_fines = 0;
            rv.forEach(function(m){
                $scope.total_holds += Number(m.holds_summary.total);
                $scope.total_issues += Number(m.issues_summary.total);
                $scope.total_overdue += Number(m.issues_summary.overdue);
                $scope.total_fines += Number(m.fines_summary.balance);
            });
            $scope.loading = false;
        }, function(er) {
            alertService.addApiError(er, 'Error fetching group members for id "' + patronid + '"');
            $scope.loading = false;
        });
    }

    $scope.addExistingPatron = function(){
        var rel = 'child';
        var bvStaffDlg = $injector.get('bvStaffDlg');
        var getPatron = bvStaffDlg.patronSelect();
        return $q.when(getPatron, function(mid){
            kwApi.PatronGroup.addGroupMember({id: patronid, mid: mid, rel: rel, force: 0}).$promise.then(function(){
                alertService.add({msg: "Added group member", type: 'success'});
                renderMembers();
            }, function(er){
                if(er.status=='409'){
                    kohaDlg.dialog({ message: "Patron is a member of another group. Add this member to this group anyway?" }).result.then(function(rv) {
                        if (rv) {
                            kwApi.PatronGroup.addGroupMember({id: patronid, mid: mid, rel: rel, force: 1}).$promise.then(function(){
                                alertService.add({msg: "Added group member", type: 'success'});
                                renderMembers();
                            }, function(fail){
                                alertService.addApiError(fail,'Adding group member failed');
                            });
                        }
                    });
                } else {
                    alertService.addApiError(er,'Adding group member failed');
                }
            });
        }, function(fail){
            console.warn(fail);
        });

    };

    $scope.removePatron = function(i){
        kohaDlg.dialog({ message: "Confirm patron removal from this group." }).result.then(function(rv) {
            if (rv) {
                kwApi.PatronGroup.rmGroupMember({id: patronid, mid: $scope.members[i].borrowernumber}, function(){
                    $scope.members.splice(i,1);
                }, function(fail){
                    alertService.addApiError(fail,'Removal of group member failed');
                });
            }
        });
    }

    $scope.removeDependentPatron = function(){
        kohaDlg.dialog({ message: "Confirm patron removal from this group." }).result.then(function(rv) {
            if (rv) {
                kwApi.PatronGroup.rmGroupMember({id: $scope.patron.guarantorid, mid: patronid}, function(){
                    $scope.dependentRemoved = true;
                }, function(fail){
                    alertService.addApiError(fail,'Removal of group member failed');
                });
            }
        });
    }

    $scope.addDependentPatron = function(){
        var rel = 'child';
        var bvStaffDlg = $injector.get('bvStaffDlg');
        var getPatron = bvStaffDlg.patronSelect();
        return $q.when(getPatron, function(pid){
            kwApi.PatronGroup.addGroupMember({id: pid, mid: patronid, rel: rel }).$promise.then(function(){
                alertService.add({msg: "Added group member", type: 'success'});
            }, function(er){
                alertService.addApiError(er,'Adding group member failed');
            });
            $scope.dependentRemoved = false;
        }, function(fail){
            console.warn(fail);
        });

    };

    $scope.userCan = {
        createBorrowers: userService.can({borrowers: {'create': '*'}}),
    };

    var once = false;
    $scope.isDependent = function() {
        if ($scope.patron.guarantorid){
            if (!once) {
                $http.get('/api/patron/'+ $scope.patron.guarantorid +'/name').then(function (response) {
                    var firstname = response.data.firstname;
                    var surname = response.data.surname;
                    $scope.guarantorName =  firstname + " " + surname;
                });
            }
            once = true;
            return true;
        }
        else {return false};
    }

    if (!$scope.isDependent()) renderMembers();
}])

.controller('StaffPatronMergeCtrl', ["$scope", "$state", "kwApi", "alertService", "kohaDlg", "$q", "$location", "bvStaffDlg", "$http", function ($scope, $state, kwApi, alertService, kohaDlg, $q, $location, bvStaffDlg, $http) {

    var patronid = $state.params.borrowernumber;
    $scope.showMergeBtn = false;
    $scope.mergePID = undefined;

    $scope.mergePatron = function(){
        kohaDlg.dialog({ message: "WARNING: this action cannot be reversed. Confirm merge with patron " +
            $scope.mergeName + " (" + $scope.mergePID + ")." }).result.then(function(rv) {
            if (rv) {
                var flat_bfields = [];
                Object.keys($scope.bfields).forEach(function(k){
                    if($scope.bfields[k].value === false){
                        flat_bfields.push(k);
                    }
                });
                kwApi.Patron.mergePatron({source_patron_id: patronid, target_patron_id: $scope.mergePID, bfields: flat_bfields}).$promise.then(function(){
                    alertService.add({msg: "Merged patron with borrowernumber " + $scope.mergePID, type: 'success'});
                    $location.url('/app/staff/patron/' + $scope.mergePID + '/details');
                }, function(e){
                    alertService.addApiError(e,'Merging patron failed');
                });
            }
        });
    };

    $scope.cancelMerge = function(){
        $scope.showMergeBtn = false;
        $scope.mergePID = undefined;
        $scope.mergeName = undefined;
    };

    $scope.selectPatron = function(){
        var getPatron = bvStaffDlg.patronSelect();
        return $q.when(getPatron, function(pid){
            if (pid == patronid) {
                alertService.add({msg: "Cannot merge a record with iteself.", type: 'danger'});
                $scope.cancelMerge();
            }
            else {
                $http.get('/api/patron/'+ pid +'/name').then(function (r) {
                    var firstname = r.data.firstname;
                    var surname = r.data.surname;
                    $scope.mergeName =  firstname + " " + surname;
                });
                $scope.mergePID = pid;
                $scope.showMergeBtn = true;
            }
        }, function(fail){
            console.warn(fail);
        });
    };

    $scope.bfields = {
        title: {label: 'Salutation', value: true},
        surname: {label: 'Surname', value: true},
        firstname: {label: 'First name', value: true},
        dateofbirth: {label: 'Date of birth', value: true},
        initials: {label: 'Initials', value: true},
        othernames: {label: 'Other name', value: true},
        sex: {label: 'Sex', value: true},
        guarantorid: {label: 'Guarantor ID (hidden)', value: true},
        contactname: {label: 'Guarantor Surname', value: true},
        contactfirstname: {label: 'Guarantor First name', value: true},
        relationship: {label: 'Guarantor Relationship', value: true},
        address: {label: 'Main Address', value: true},
        address2: {label: 'Main Address 2', value: true},
        city: {label: 'Main Address City', value: true},
        zipcode: {label: 'Main Address Zipcode', value: true},
        country: {label: 'Main Address Country', value: true},
        phone: {label: 'Contact Phone (primary)', value: true},
        phonepro: {label: 'Contact Phone (secondary)', value: true},
        mobile: {label: 'Contact Phone (cell)', value: true},
        smsalertnumber: {label: 'SMS alert (cell)', value: true},
        email: {label: 'Email (primary)', value: true},
        emailpro: {label: 'Email (secondary)', value: true},
        fax: {label: 'Fax', value: true},
        B_address: {label: 'Alternate Address', value: true},
        B_address2: {label: 'Alternate Address 2', value: true},
        B_city: {label: 'Alternate City', value: true},
        B_zipcode: {label: 'Alternate Zipcode', value: true},
        B_country: {label: 'Alternate Country', value: true},
        B_phone: {label: 'Alternate Phone', value: true},
        B_email: {label: 'Alternate Email', value: true},
        contactnote: {label: 'Alternate Contact Note', value: true},
        altcontactsurname: {label: 'Alternate Contact Surname', value: true},
        altcontactfirstname: {label: 'Alternate Contact First name', value: true},
        altcontactaddress1: {label: 'Alternate Contact Address', value: true},
        altcontactaddress2: {label: 'Alternate Contact Address 2', value: true},
        altcontactaddress3: {label: 'Alternate Contact City, State', value: true},
        altcontactzipcode: {label: 'Alternate Contact Zipcode', value: true},
        altcontactcountry: {label: 'Alternate Contact Country', value: true},
        altcontactphone: {label: 'Alternate Contact Phone', value: true},
        cardnumber: {label: 'Card number', value: true},
        branchcode: {label: 'Library', value: true},
        categorycode: {label: 'Category', value: true},
        sort1: {label: 'Sort 1', value: true},
        sort2: {label: 'Sort 2', value: true},
        dateenrolled: {label: 'Registration date', value: true},
        dateexpiry: {label: 'Expiry date', value: true},
        opacnote: {label: 'Discovery Layer note', value: true},
        borrowernotes: {label: 'Circulation note', value: true},
        userid: {label: 'DL Login', value: true},
        password: {label: 'Password', value: true},
        prefs: {label: 'User Preferences', value: true},
        xattr: {label: 'Additional Patron Attributes', value: true}
    };

    $scope.selectBfields = function(){
        Object.keys($scope.bfields).forEach(function(k){
            $scope.bfields[k].value = true;
        }
    )};

    $scope.clearBfields = function(){
        Object.keys($scope.bfields).forEach(function(k){
            $scope.bfields[k].value = false;
        }
    )};

}])

.controller('StaffPatronDeleteCtrl', ["$scope", "$state", "kwApi", "alertService", "kohaDlg", "$injector", "$q", "$location", function ($scope, $state, kwApi, alertService, kohaDlg, $injector, $q, $location) {

    $scope.loading = true;
    var patronid = $state.params.borrowernumber;

    kwApi.Patron.deletePatron({id: patronid, test: true}).$promise.then(function(){
        $scope.cannotDelete = false;
    }, function(e){
        $scope.cannotDeleteError = e.data;
        $scope.cannotDelete = true;
    });

    var renderMembers = function () {
        kwApi.PatronGroup.getGroup({id: patronid}).$promise.then(function(rv) {
            $scope.members = rv;
            $scope.total_holds = 0;
            $scope.total_issues = 0;
            $scope.total_overdue = 0;
            $scope.total_fines = 0;
            rv.forEach(function(m){
                $scope.total_holds += Number(m.holds_summary.total);
                $scope.total_issues += Number(m.issues_summary.total);
                $scope.total_overdue += Number(m.issues_summary.overdue);
                $scope.total_fines += Number(m.fines_summary.balance);
            });
            $scope.loading = false;
        }, function(er) {
            alertService.addApiError(er, 'Error fetching group members for id "' + patronid + '"');
            $scope.loading = false;
        });
    }

    $scope.removePatron = function(i){
        kohaDlg.dialog({ message: "Confirm patron removal from this group." }).result.then(function(rv) {
            if (rv) {
                kwApi.PatronGroup.rmGroupMember({id: patronid, mid: $scope.members[i].borrowernumber}, function(){
                    $scope.members.splice(i,1);
                }, function(fail){
                    alertService.addApiError(fail,'Removal of group member failed');
                });
            }
        });
    }

    $scope.removeDependentPatron = function(){
        kohaDlg.dialog({ message: "Confirm patron removal from this group." }).result.then(function(rv) {
            if (rv) {
                kwApi.PatronGroup.rmGroupMember({id: $scope.patron.guarantorid, mid: patronid}, function(){
                    $scope.dependentRemoved = true;
                }, function(fail){
                    alertService.addApiError(fail,'Removal of group member failed');
                });
            }
        });
    }

    $scope.relinkPatron = function(i){
        var rel = 'child';
        var bvStaffDlg = $injector.get('bvStaffDlg');
        var getPatron = bvStaffDlg.patronSelect();
        return $q.when(getPatron, function(pid){
            kwApi.PatronGroup.addGroupMember({id: pid, mid: $scope.members[i].borrowernumber, rel: rel, force: 1}).$promise.then(function(){
                alertService.add({msg: "Added group member", type: 'success'});
                $scope.members.splice(i,1);
            }, function(e){
                alertService.addApiError(e,'Adding group member failed');
            });
        }, function(fail){
            console.warn(fail);
        });

    };

    $scope.deleteDependentPatron = function(i){
        $scope.deletePatron($scope.members[i].borrowernumber, true, i);
    }

    $scope.deletePatron = function(id, remove, i){
        kohaDlg.dialog({ message: "Confirm patron id " + id + " deletion." }).result.then(function(rv) {
            if (rv) {
                kwApi.Patron.deletePatron({id: id}).$promise.then(function(){
                    alertService.add({msg: "Deleted patron id " + id, type: 'success'});
                    if (remove) {
                        $scope.members.splice(i,1);
                    }
                    else {
                        $location.url('/app/staff/patrons/browse');
                    }
                }, function(e){
                    alertService.addApiError(e,'Deleting patron failed');
                });
            }
        });
    }

    $scope.isDependent = function() {
        if ($scope.patron.guarantorid){return true;}
        else {return false};
    }

    if (!$scope.isDependent()) renderMembers();
}])
.controller('StaffPatronLostItemsCtrl', ["$scope", "$state", "$q", "kwApi", "alertService", "userService", "bibService", "kohaDlg", function ($scope, $state, $q, kwApi, alertService, userService, bibService, kohaDlg) {

    var patronid = $state.params.borrowernumber;

    // this controller should be a child of StaffPatronCtrl ??

    // $scope.patron = kwApi.Patron.get({id: patronid});

    $scope.order = { field: 'date_lost', reverse: false };

    var compose_fee_summary = function(fees){
        var fee_summary = {
            date: (fees.length) ? fees[0].timestamp : null,
            amount: fees.reduce(function(tot,fee){ return tot + fee.amount; }, 0),
            outstanding: fees.reduce(function(tot,fee){ return tot + fee.outstanding; }, 0),
            settled: fees.reduce(
                function(sdate,fee){
                    if(fee.outstanding) return null;
                    else return ( sdate && fee.settlement_date < sdate) ? sdate : fee.settlement_date;
                    }, null),
            creditByDate: []
        };
        fees.forEach(function(fee){
            fee.credits.forEach(function(credit){
                var creditDate = credit.timestamp.substring(0,10);
                var creditByDate = fee_summary.creditByDate.find(function(c){ return c.date == creditDate; });
                if(!creditByDate){
                    creditByDate = {    date: creditDate,
                                        accttype: {}
                                    };
                    fee_summary.creditByDate.push( creditByDate );
                }

                var creditByType = creditByDate.accttype[credit.accounttype];
                if( creditByType ){
                    creditByType.amount -= credit.amount;
                    creditByType.inFull = creditByType.amount == fee_summary.amount;
                } else {
                    creditByDate.accttype[credit.accounttype] = {
                        amount: credit.amount * -1,
                        inFull: credit.amount == fee_summary.amount * -1
                    }
                }
            });
        });
        return fee_summary;
    };

    $scope.acctLabel = {};
    kwApi.AccountType.getList({},function(ats){
        ats.forEach(function(accttype){
            $scope.acctLabel[accttype.accounttype] = accttype.description;
        });
    });

    $scope.linked = {};

    function updateLinkedFees (li){
        kwApi.Fee.getFromLostItem({lostid: li.id}, function(fees){
            $scope.linked[li.id].fees = compose_fee_summary(fees);
        });
    }

    $scope.lostitems = kwApi.LostItem.getforPatron({id: patronid}, function(lostitems){
        lostitems.forEach(function(li){

            $scope.linked[li.id] = {
                _display: { title : li.title }
            };
            $scope.linked[li.id].item = li._embed.item;
            if(!li._embed.item) $scope.linked[li.id].item_deleted = true;
            $scope.linked[li.id].can_remove = userService.can({borrowers: 'delete_lost_items'}) &&
                     ( li.persist || $scope.linked[li.id].item_deleted );
            updateLinkedFees(li);
        });
        $scope.loaded = true;

        $q.all( lostitems.map(
                function(li){ return $scope.linked[li.id].item.$promise; })
                    ).finally(function(){
                        $scope.lostitems.forEach(function(li){
                            bibService.get( li.biblionumber ).then(function(bib){
                                $scope.linked[li.id]._display.title = bib.title_ext;
                            })
                        })
            });
        });

    $scope.claimReturned = function(lostitem, undo){

        var msg = (undo) ? "Undo Claimed Returned ?" :
                "Set Claimed Returned ?";

        kohaDlg.dialog({ message: msg }).result.then(function(rv) {
            if (rv) {
                lostitem.$claimReturned({ undo: undo }, function(li){
                    updateLinkedFees(li);
                });
            }
        });
    };
    $scope.rmLostItem = function(lostitem){
        kohaDlg.dialog({ message: "Delete Lost Item record?  This action cannot be undone." }).result.then(function(rv) {
            if (rv) {
                lostitem.$delete({}, function(ok){
                    var i = $scope.lostitems.indexOf(lostitem);
                    $scope.lostitems.splice(i,1);
                }, function(fail){
                    alertService.addApiError(fail,'Delete failed');
                });
            }
        });
    }

}])


// Serials
.controller('StaffSerialsCtrl', ["$scope", "$state", "userService", function ($scope, $state, userService) {
    var userBranch = userService.login_branch;
    $scope.user = userService;
    $scope.userCanAccessBranch = function(branchCode) {
        return $scope.user.canInBranch({serials: 'base'}, branchCode);
    };

    if ($state.current.name == 'staff.serials') {
        $state.go('staff.serials.index');
    }
}])

.controller('StaffSerialsIndexCtrl', ["$scope", "periodicals", "kwApi", "alertService", "$state", "kohaDlg", "$stateParams", "loading", "configService", function ($scope, periodicals, kwApi, alertService, $state, kohaDlg, $stateParams, loading, configService) {

    $scope.periodicals = periodicals;
    $scope.periodicals.forEach(function(p) {
        p._available_subscribed_branches = p.subscribed_branches.filter(function(b) {
            return $scope.user.canInBranch({serials: 'base'}, b.branch_code);
        });
        p._available_total_subscriptions = p._available_subscribed_branches.length;
    });

    $scope.order = {field: 'work.title', reverse: false};

    $scope.currentPage = 1;
    $scope.totalRows = (periodicals[0] || {})._embed_total_rows;

    $scope.refresh = function() {
        var start = ($scope.currentPage -1) * 20;
        loading.wrap(
            kwApi.Periodical.search({title: $stateParams.title, issn: $stateParams.issn, start: start, count: 20, sort: $scope.order.field, reverse: ($scope.order.reverse ? 1 : 0)}).$promise,
            "Unable to load"
        ).then(function(rv) {
            $scope.periodicals = rv;
            $scope.periodicals.forEach(function(p) {
                p._available_subscribed_branches = p.subscribed_branches.filter(function(b) {
                    return $scope.user.canInBranch({serials: 'base'}, b.branch_code);
                });
                p._available_total_subscriptions = p._available_subscribed_branches.length;
            });
        });
    };

    $scope.pageChanged = function() {
        $scope.refresh();
    };

    $scope.sortChanged = function() {
        $scope.currentPage = 1;
        $scope.refresh();
    };

    $scope.publicationDelete = function(id) {
        var rv;
        if (configService.SerialDeleteItem === 'ask') {
            rv = kohaDlg.dialog({
                heading: 'Confirm; also delete items?',
                message: 'This will remove all associated subscriptions and issues and CANNOT be undone. Do you also wish to remove all items?',
                buttons: [{val: 1, label: 'Remove Items', btnClass: 'btn-warning'}, {val: 0, label: 'Keep Items', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        else {
            rv = kohaDlg.dialog({
                heading: 'Are you sure?',
                message: 'Are you sure you want to delete this publication? This will remove all associated subscriptions and issues and CANNOT be undone.',
                buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        rv.result.then(function(rv) {
            if (rv !== false) {
                kwApi.Periodical.deleteWithItem({id: id, item: rv},{}).$promise.then(function(rv) {
                    alertService.add({msg: "Publication deleted"});
                    $state.go('staff.serials.index', {}, {reload: true});
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };
}])

.controller('StaffSerialsPeriodicalCtrl', ["$scope", "periodical", "subscriptions", "serialEditions", "kwApi", "configService", "loading", "alertService", "bibService", "$uibModal", "$q", "$state", "kohaDlg", "$filter", "$stateParams", function($scope, periodical, subscriptions, serialEditions, kwApi, configService,
    loading, alertService, bibService, $uibModal, $q, $state, kohaDlg, $filter, $stateParams) {
    $scope.periodical = periodical;



    if (!periodical.id) {
        periodical.bib_id = $stateParams.bib_id;
        periodical.work = {};
        periodical.receipt_patterns = [];
        periodical.next_edition = {};
        periodical.auto_create_editions = true;
        periodical.auto_sequence_config = true;
        periodical.sequence_variables = ['x', 'y'];
        periodical.sequence_config = {
            x: {
                add: '1',
                inner_mod: '',
                mod: '',
                linked: 'y',
            },
            y: {
                add: '1',
                inner_mod: '',
                mod: '',
                linked: '',
            },
        };
        periodical.sequence_state = {
            x: {
                count: '1',
                inner_count: '1',
            },
            y: {
                count: '1',
                inner_count: '',
            },
        };
        periodical.chronology_state = dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        $scope.editing = {main: true}
        $scope.expand = {
            chronology: true,
            schedule: true,
            sequence: false,
            predictions: true,
        };
    }
    else {
        $scope.editing = {main: false};
        $scope.expand = {};
        if (!periodical.next_edition) periodical.next_edition = {};
    }

    $scope.subscriptions = subscriptions;
    $scope.serialEditions = serialEditions;

    $scope.subsOrder = {field:'branch_name', reverse: false};
    $scope.editionsOrder = {field: 'publication_date', reverse: true};

    angular.forEach($scope.subscriptions, function(s) {
        s.branch_name = $filter('displayName')(s.branch_code, 'branch');
    });


    $scope.periodical.chronology_state_date = dayjs($scope.periodical.chronology_state).toDate();
    $scope.dpickOpened = false;

    $scope.dirty = false;
    
    $scope.sisterPeriodicals = [];
    $scope.refreshSisterPeriodicals = function() {
        $scope.sisterPeriodicals = [];
        if ($scope.periodical.bib_id) {
            kwApi.Periodical.getForBib({bibid: $scope.periodical.bib_id}).$promise.then(function(rv) {
                rv.forEach(function(p) {
                    if (p.id != $scope.periodical.id) {
                        $scope.sisterPeriodicals.push(p);
                    }
                });
            });
        }
    };
    $scope.$watch('periodical.bib_id', function(newVal, oldVal) {
        if (newVal || oldVal) {
            $scope.refreshSisterPeriodicals();
        }
    });

    $scope.findCatalogRecord = function() {
        if (!$scope.periodical.work && ($scope.periodical.work.title || $scope.periodical.bib_id)) return;
        var query = $scope.periodical.work.title || ('biblionumber:' + $scope.periodical.bib_id);
        var parentScope = $scope;
        loading.add();
        $uibModal.open({
            title: 'Find Catalog Record',
            templateUrl: '/app/static/partials/staff/serials/catalog-select.html',
            size: 'lg',
            backgroundClick: false,
            resolve: {
                records: function() {
                    return kwApi.Catalog.query({query: query, count: 10, fq: 'lost:*'}).$promise.then(function(rv) {
                        rv.hits.forEach(function(hit) {
                            hit.id = hit.work.substr(10);
                        });
                        return rv;
                    });
                },
                title: function() {
                    return $scope.periodical.work.title;
                }
            },
            controller: ["$scope", "records", "title", function($scope, records, title) {
                loading.resolve();
                $scope.currentPage = 1;
                $scope.pageChanged = function() {
                    var start = ($scope.currentPage - 1)*10;
                    loading.add();
                    kwApi.Catalog.query({query: title, start: start, count: 10}).$promise.then(function(rv) {
                        rv.hits.forEach(function(hit) {
                            hit.id = hit.work.substr(10);
                        });
                        $scope.records = rv;
                        loading.resolve();
                    });
                };

                $scope.records = records;
                $scope.select = function(bib) {
                    parentScope.periodical.bib_id = bib.id;
                    parentScope.periodical.work.title = bib.title;
                    $scope.$close();
                };
            }]
        });
    };

    $scope.publicationDelete = function() {
        var rv;
        if (configService.SerialDeleteItem === 'ask') {
            rv = kohaDlg.dialog({
                heading: 'Confirm; also delete items?',
                message: 'This will remove all associated subscriptions and issues and CANNOT be undone. Do you also wish to remove all items?',
                buttons: [{val: 1, label: 'Remove Items', btnClass: 'btn-warning'}, {val: 0, label: 'Keep Items', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        else {
            rv = kohaDlg.dialog({
                heading: 'Are you sure?',
                message: 'Are you sure you want to delete this publication? This will remove all associated subscriptions and issues and CANNOT be undone.',
                buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        rv.result.then(function(rv) {
            if (rv !== false) {
                kwApi.Periodical.deleteWithItem({id: $scope.periodical.id, item: rv},{}).$promise.then(function(rv) {
                    alertService.add({msg: "Publication deleted"});
                    $state.go('staff.serials.index');
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };


    $scope.publicationEdit = function() {
        $scope.orig = angular.copy($scope.periodical);
        $scope.dirty = false;
        $scope.editing.main = true;
        $scope.expand._prev_chronology = $scope.expand.chronology;
        $scope.expand._prev_schedule = $scope.expand.schedule;
        //$scope.expand._prev_sequence = $scope.expand.sequence;
        $scope.expand.chronology = true;
        $scope.expand.schedule = true;
        //$scope.expand.sequence = true;
    };

    $scope.publicationEditCancel = function() {
        $scope.dirty = false;
        $scope.periodical = $scope.orig;
        angular.extend($scope.periodical,$scope.orig);
        $scope.editing.main = false;
        $scope.expand.chronology = $scope.expand._prev_chronology;
        $scope.expand.schedule = $scope.expand._prev_schedule;
        //$scope.expand.sequence = $scope.expand._prev_sequence;
    };

    $scope.publicationEditSave = function() {
        var isNew = false;
        loading.add();
        $scope.periodical.chronology_state =
            dayjs($scope.periodical.chronology_state_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        var promise;
        if ($scope.periodical.id) {
            promise = kwApi.Periodical.put({id: $scope.periodical.id}, $scope.periodical).$promise;
        }
        else {
            promise = kwApi.Periodical.save($scope.periodical).$promise;
            isNew = true;
        }

        promise.then(function(rv) {
            if (isNew) {
                return $q.when(rv);
            }
            else {
                return kwApi.Periodical.get({id: $scope.periodical.id}).$promise;
            }
        }).then(function(obj) {
            if (isNew) {
                $state.go('staff.serials.periodical', {id: obj.id});
            }
            angular.extend($scope.periodical,obj);
            $scope.periodical.chronology_state_date = dayjs($scope.periodical.chronology_state).toDate();
            $scope.editing.main = false;
            $scope.expand.chronology = $scope.expand._prev_chronology;
            $scope.expand.schedule = $scope.expand._prev_schedule;
            $scope.expand.sequence = $scope.expand._prev_sequence;
            $scope.dirty = false;
            loading.resolve();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.resetSequenceConfig = function() {
        $scope.periodical.auto_sequence_config = true;
        $scope.expand.predictions = true;
        $scope.refreshPredictions();
    };


    $scope.refreshPredictions = function() {
        if ($scope.editing.main || $scope.expand.predictions) {
            $scope.refreshingPredictions = true;
            var fmtDate = dayjs($scope.periodical.chronology_state_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
            kwApi.Periodical.predict({infer: ($scope.periodical.auto_sequence_config ? 1 : 0)}, {
                patterns: $scope.periodical.receipt_patterns,
                sequence_config: $scope.periodical.sequence_config,
                chronology_state: fmtDate,
                sequence_state: $scope.periodical.sequence_state,
                pattern_index: $scope.periodical.pattern_index,
            }).$promise.then(function(rv) {
                $scope.predictions = rv;
                if (rv && rv.length > 0) {
                    $scope.predictionWarning = rv[0].prediction_warning;
                    $scope.periodical.next_edition.chronology_vintage = rv[0].chronology;
                    $scope.periodical.next_edition.sequence_vintage = rv[0].sequence;
                    if ($scope.periodical.auto_sequence_config && rv[0].sequence_state) {
                        $scope.periodical.sequence_variables.forEach(function(v) {
                            if (!rv[0].sequence_state[v])
                                rv[0].sequence_state[v] = {};
                            if (!$scope.periodical.sequence_config[v])
                                $scope.periodical.sequence_config[v] = {};
                            ['mod','inner_mod','linked'].forEach(function(param) {
                                $scope.periodical.sequence_config[v][param] = (rv[0].sequence_state[v]||{})[param];
                            });
                        });
                    }
                }
                $scope.refreshingPredictions = false;
                $scope.predictionError = '';
            }, function(err) {
                $scope.refreshingPredictions = false;
                if (err.data) {
                    if (/z is not defined/.test(err.data)) {
                        $scope.predictionError = "Add a 'z' parameter in the Enumeration Counters section";
                    }
                    else if (/y is not defined/.test(err.data)) {
                        $scope.predictionError = "Add a 'y' parameter in the Enumeration Counters section";
                    }
                    else {
                        $scope.predictionError = err.data;
                    }
                }
                else {
                    $scope.predictionError = err;
                }
            });
        }
    };

    // Chronology
    //
    $scope.schedAddPattern = function(i) {
        $scope.dirty = true;
        $scope.periodical.receipt_patterns.splice(i,0,{
            start_date: '01/01',
            end_date: '12/31',
            pattern: undefined,
            pattern_description: 'None',
            chronology_template: '',
            sequence_template: '',
            sequence_increment: '1',
        });
        $scope.schedEditPattern(i);
    };

    $scope.schedSaveTemplate = function() {
        var obj = {
            patterns: JSON.stringify(periodical.receipt_patterns),
            name: $scope.periodical._schedSaveName,
            description: $scope.periodical._schedSaveName,
        };
        kwApi.SerialPatternTemplate.save(obj).$promise.then(function(rv) {
            alertService.add({msg: "Scheduled saved as template " + $scope.periodical._schedSaveName, type: "success"});
        }, function(err) {
            alertService.addApiError(err, 'Save schedule template failed');
        });
    };

    $scope.schedDelPattern = function(i) {
        $scope.dirty = true;
        $scope.periodical.receipt_patterns.splice(i,1);
    };

    $scope.schedEditPattern = function(i) {
        var parentScope = $scope;
        $uibModal.open({
            title: 'Chronology and Sequence Pattern',
            templateUrl: '/app/static/partials/staff/serials/pattern-edit.html',
            resolve: {
                parentReceiptPattern: function() {
                    return parentScope.periodical.receipt_patterns[i];
                },
                pattern: function() {
                    var c = angular.copy(parentScope.periodical.receipt_patterns[i]);
                    if (c.sequence_increment === undefined || c.sequence_increment === null) {
                        c.sequence_increment = '1';
                    }
                    return c;
                },
                serial_pattern_templates: function() {
                    return kwApi.SerialPatternTemplate.query().$promise;
                },
                serial_chronology_templates: function() {
                    return kwApi.SerialChronologyTemplate.query().$promise;
                },
                serial_sequence_templates: function() {
                    return kwApi.SerialSequenceTemplate.query().$promise;
                },
            },
            size: 'lg',
            controller: ["$scope", "pattern", "parentReceiptPattern", "serial_pattern_templates", "serial_chronology_templates", "serial_sequence_templates", "$uibModal", function($scope, pattern, parentReceiptPattern, serial_pattern_templates, serial_chronology_templates, serial_sequence_templates, $uibModal) {
                $scope.pattern = pattern;

                $scope.isMultiMonth = function(p) {
                    if (!p) return;
                    var halves = (p || "").split('*');
                    if (halves.length != 2) return;
                    var lhs = halves[0].split(':');
                    if (lhs.length != 2) return;
                    return (lhs[1] > 1);
                };

                $scope.showHelp = function(s) {
                    $uibModal.open({
                        templateUrl: '/app/static/partials/staff/serials/help-' + s + '.html',
                        size: 'md'
                    });
                };

                $scope.serialPatternTemplates = serial_pattern_templates;

                $scope.serialPatternTemplates.forEach(function(v) {
                    var p = v.patterns;
                    if ((p.length > 1) || (p[0].start_date != '01-01') || (p[0].end_date != '12-31')) {
                        // FIXME this is gross
                        v.template = '#' + v.id;
                        v._is_advanced = true;
                    }
                    else {
                        v.template = v.patterns[0].pattern;
                    }
                });
                $scope.selectedPatternTemplate = $scope.serialPatternTemplates.find(function(v) {
                    return v._is_basic && v.template == $scope.pattern.pattern;
                });
                if (!$scope.selectedPatternTemplate) {
                    $scope.serialPatternTemplates.push({
                        template: $scope.pattern.pattern
                    });
                }

                $scope.serialChronologyTemplates = serial_chronology_templates;
                if ($scope.pattern.chronology_template && $scope.serialChronologyTemplates.find(function(v) {
                    return v.template == $scope.pattern.chronology_template;
                }) === undefined) {
                    $scope.serialChronologyTemplates.push({
                        template: $scope.pattern.chronology_template
                    });
                }

                $scope.serialSequenceTemplates = serial_sequence_templates;
                if ($scope.pattern.sequence_template && $scope.serialSequenceTemplates.find(function(v) {
                    return v.template == $scope.pattern.sequence_template;
                }) === undefined) {
                    $scope.serialSequenceTemplates.push({
                        template: $scope.pattern.sequence_template
                    });
                }

                var bvDateRecur = $filter('bvDateRecur');

                $scope.patternSelectConfig = {
                    maxItems: 1,
                    valueField: 'template',
                    labelField: 'name',
                    searchField: ['name','description'],
                    create: true,
                    render: {
                        item: function(item, escape) {
                            if (!item) return '';
                            var t = '<div>';
                            if (item.name)
                                t = t + escape(item.name);
                            else 
                                t = t + '(unnamed)';
                            t = t + '</div>';
                            return t;
                        },
                        option: function(item, escape) {
                            if (!item) return '';
                            var t = '<div>';
                            if (item.name)
                                t = t + escape(item.name);
                            else 
                                t = t + '(unnamed)';
                            t = t + ' - <i>';
                            if (item._is_advanced)
                                t = t + '[Advanced Pattern]';
                            else
                                t = t + escape(bvDateRecur(item.template));
                            t = t + '</i>';
                            if (item.description)
                                t = t + '<div <small>' + escape(item.description) + '</small></div>';
                            t = t + '</div>';
                            return t;
                        }
                    },
                };


                $scope.chronSelectConfig = $scope.enumSelectConfig = {
                    maxItems: 1,
                    valueField: 'template',
                    labelField: 'name',
                    searchField: ['name','description'],
                    create: true,
                    render: {
                        item: function(item, escape) {
                            if (!item) return '';
                            var t = '<div>';
                            if (item.name)
                                t = t + escape(item.name);
                            else 
                                t = t + '(unnamed)';
                            t = t + ' - ' + escape(item.template) + '</div>';
                            return t;
                        },
                        option: function(item, escape) {
                            if (!item) return '';
                            var t = '<div>';
                            if (item.name)
                                t = t + escape(item.name);
                            else 
                                t = t + '(unnamed)';
                            t = t + ' - ' + escape(item.template) + '</div>';
                            return t;
                        },
                    },
                };

                $scope.pattern.start_month = $scope.pattern.start_date.substr(0,2);
                $scope.pattern.start_day = $scope.pattern.start_date.substr(3,2);

                $scope.pattern.end_month = $scope.pattern.end_date.substr(0,2);
                $scope.pattern.end_day = $scope.pattern.end_date.substr(3,2);

                $scope.$watch('pattern.pattern', function(newVal) {
                    if (newVal) {
                        $scope.selectedPatternTemplate = undefined;
                        if (newVal.substr(0,1) == '#') {
                            var id = newVal.substr(1);
                            $scope.selectedPatternTemplate = $scope.serialPatternTemplates.find(function(v) {
                                return (v.id == id);
                            });
                        }
                        else {
                            $scope.selectedPatternTemplate = $scope.serialPatternTemplates.find(function(v) {
                                return v.template == newVal;
                            });
                        }
                        if ($scope.selectedPatternTemplate) {
                            pattern.pattern_description = $scope.selectedPatternTemplate.name;
                            if (! $scope.selectedPatternTemplate._is_advanced) {
                                var p = $scope.selectedPatternTemplate.patterns[0];
                                if (!$scope.pattern.start_month && p.start_month)
                                    $scope.pattern.start_month = p.start_month;
                                if (!$scope.pattern.end_month && p.end_month)
                                    $scope.pattern.end_month = p.end_month;
                                if (!$scope.pattern.chronology_template && p.chronology_template)
                                    $scope.pattern.chronology_template = p.chronology_template;
                                if (!$scope.pattern.sequence_template && p.sequence_template)
                                    $scope.pattern.sequence_template = p.sequence_template;
                            }
                        }
                    }

                });


                $scope.save = function() {
                    if ($scope.pattern.chronology_template_save_as) {
                        kwApi.SerialChronologyTemplate.save({
                            name: $scope.pattern.chronology_template_save_as,
                            description: $scope.pattern.chronology_template_save_as,
                            template: $scope.pattern.chronology_template,
                        }).$promise.then(function(rv) {
                            alertService.add({msg: "Chronology saved as new template", type: "success"});
                        }, function(err) {
                            alertService.addApiError(err, "Can't save chronology template");
                        });
                    }
                    if ($scope.pattern.sequence_template_save_as) {
                        kwApi.SerialSequenceTemplate.save({
                            name: $scope.pattern.sequence_template_save_as,
                            description: $scope.pattern.sequence_template_save_as,
                            template: $scope.pattern.sequence_template,
                        }).$promise.then(function(rv) {
                            alertService.add({msg: "Enumeration saved as new template", type: "success"});
                        }, function(err) {
                            alertService.addApiError(err, "Can't save enumeration template");
                        });
                    }

                    var p = $scope.selectedPatternTemplate;
                    if (p && p._is_advanced) {
                        parentScope.periodical.receipt_patterns = [];
                        p.patterns.forEach(function(pattern) {
                            parentScope.periodical.receipt_patterns.push(pattern);
                        });
                    }
                    else {
                        $scope.pattern.start_date = $scope.pattern.start_month + '-' + $scope.pattern.start_day;
                        $scope.pattern.end_date = $scope.pattern.end_month + '-' + $scope.pattern.end_day;
                        angular.extend(parentReceiptPattern, $scope.pattern);
                    }

                    parentScope.dirty = true;
                    $scope.$close();
                };
            }]
        });
    };


    $scope.$watch('periodical.receipt_patterns', function(newVal, oldVal) {
        if (newVal) {
            //$scope.expand.predictions = false;
            $scope.refreshPredictions();
            if (oldVal && !angular.equals(newVal,oldVal)) {
                $scope.dirty = true;
            }
        }
    }, true);

    // Sequence

    var knownSequenceVariables = ['x','y','z'];
    var knownSequenceParameters = ['x','y','z'];

    $scope.$watch('periodical.sequence_config', function(newVal, oldVal) {
        $scope.periodical.sequence_variables.forEach(function(k) {
            var linked = newVal[k].linked;
            if (typeof(linked) == 'string' && linked != '' && newVal[linked]) {
                newVal[k].inner_mod = newVal[linked].mod;
            }
        });
        if (newVal) {
            $scope.expand.predictions = false;
            //$scope.refreshPredictions();
            if (oldVal && !angular.equals(newVal,oldVal)) {
                $scope.dirty = true;
            }
        }
    }, true);

    $scope.$watch('periodical.sequence_state', function(newVal, oldVal) {
        $scope.periodical.sequence_variables.forEach(function(k) {
            var linked = $scope.periodical.sequence_config[k].linked;
            if (typeof(linked) == 'string' && linked != '' && newVal[linked]) {
                newVal[k].inner_count = newVal[linked].count;
            }
        });
        if (newVal) {
            $scope.expand.predictions = false;
            //$scope.refreshPredictions();
            if (oldVal && !angular.equals(newVal,oldVal)) {
                $scope.dirty = true;
            }
        }
    }, true);

    $scope.$watch('periodical.chronology_state_date', function(newVal, oldVal) {
        if (newVal) {
            $scope.expand.predictions = false;
            //$scope.refreshPredictions();
            if (oldVal && newVal !== oldVal) {
                $scope.dirty = true;
            }
        }
    });

    $scope.$watch('expand.predictions', function(newVal) {
        if (newVal) {
            $scope.refreshPredictions();
        }
    });

    $scope.seqAddVar = function() {
        var nextVar = knownSequenceVariables[$scope.periodical.sequence_variables.length];
        $scope.periodical.sequence_variables.push(nextVar);

        $scope.periodical.sequence_config[nextVar] = {
            add: '1',
            inner_mod: '',
            mod: '',
            linked: '',
        };
        $scope.periodical.sequence_state[nextVar] = {
            count: '1',
            inner_count: '1',
        };
    };

    $scope.seqDelVar = function() {
        var lastVar = $scope.periodical.sequence_variables[$scope.periodical.sequence_variables.length - 1];
        delete $scope.periodical.sequence_config[lastVar];
        delete $scope.periodical.sequence_state[lastVar];
        $scope.periodical.sequence_variables.pop();
    };

    $scope.subscriptionAdd = function(n) {
        $state.go('staff.serials.subscription', {id: '', parent: $scope.periodical.id});
    };

    $scope.subscriptionDel = function(n) {
        var rv;
        if (configService.SerialDeleteItem === 'ask') {
            rv = kohaDlg.dialog({
                heading: 'Confirm; also delete items?',
                message: 'This will remove all associated issues for this branch and CANNOT be undone. Do you also wish to remove all items?',
                buttons: [{val: 1, label: 'Remove Items', btnClass: 'btn-warning'}, {val: 0, label: 'Keep Items', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        else {
            rv = kohaDlg.dialog({
                heading: 'Are you sure?',
                message: 'Are you sure you want to delete this subscription? This will remove all associated issues for this branch and CANNOT be undone.',
                buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        rv.result.then(function(rv) {
            console.log(rv);
            if (rv !== false) {
                kwApi.Subscription.deleteWithItem({id: n, item: rv},{}).$promise.then(function(rv) {
                    alertService.add({msg: "Subscription deleted"});
                    kwApi.Periodical.getSubscriptions({id: $scope.periodical.id}).$promise.then(function(subs) {
                        $scope.subscriptions = subs;
                        angular.forEach($scope.subscriptions, function(s) {
                            s.branch_name = $filter('displayName')(s.branch_code, 'branch');
                        });
                    });
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };

    $scope.editionEdit = function(n) {
        $state.go('staff.serials.edition', {id: n});
    };

    $scope.editionAdd = function() {
        kwApi.Periodical.generateEdition({id: $scope.periodical.id},{}).$promise.then(function(e) {
            return kwApi.Periodical.getSerialEditions({id: $scope.periodical.id}).$promise;
        }).then(function(rv) {
            $scope.serialEditions = rv;
            return kwApi.Periodical.get({id: $scope.periodical.id}).$promise;
        }).then(function(obj) {
            angular.extend($scope.periodical,obj);
            $scope.periodical.chronology_state_date = dayjs($scope.periodical.chronology_state).toDate();
        }, function(err) {
            alertService.addApiError(err);
        });
    };

    $scope.editionAddSpecial = function() {
        $uibModal.open({
            title: 'New Supplemental Issue',
            templateUrl: '/app/static/partials/staff/serials/issue-supplemental-add.html',
            controller: 'StaffSerialsAddSpecialCtrl',
            resolve: {
                periodical: function() {
                    return $scope.periodical;
                }
            },
        }).result.then(function() {
            kwApi.Periodical.getSerialEditions({id: $scope.periodical.id}).$promise.then(function(rv) {

                $scope.serialEditions = rv;
                return kwApi.Periodical.get({id: $scope.periodical.id}).$promise;
            }).then(function(obj) {
                angular.extend($scope.periodical,obj);
                $scope.periodical.chronology_state_date = dayjs($scope.periodical.chronology_state).toDate();
            }, function(err) {
                alertService.addApiError(err);
            });
        });
    };

    $scope.editionDel = function(n) {
        var rv;
        if (configService.SerialDeleteItem === 'ask') {
            rv = kohaDlg.dialog({
                heading: 'Confirm; also delete items?',
                message: 'This will remove all associated issues for all branches and CANNOT be undone. Do you also wish to remove all items?',
                buttons: [{val: 1, label: 'Remove Items', btnClass: 'btn-warning'}, {val: 0, label: 'Keep Items', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        else {
            rv = kohaDlg.dialog({
                heading: 'Are you sure?',
                message: 'Are you sure you want to delete this issue? This will remove all associated copies for all branches and CANNOT be undone.',
                buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
            });
        }
        rv.result.then(function(rv) {
            if (rv !== false) {
                kwApi.SerialEdition.deleteWithItem({id: n, item: rv},{}).$promise.then(function(rv) {
                    alertService.add({msg: "Issue deleted"});
                    kwApi.Periodical.getSerialEditions({id: $scope.periodical.id}).$promise.then(function(rv) {
                        $scope.serialEditions = rv;
                    });
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };
    $scope.$watch('periodical.bib_id', function(bibid) {
        if (bibid) bibService.get(bibid).then(function(bib){ $scope.bib = bib; });
    });

}])

.controller('StaffSerialsAddSpecialCtrl', ["$scope", "periodical", "kwApi", "alertService", function($scope, periodical, kwApi, alertService) {
    $scope.periodical = periodical;
    console.dir(periodical);
    $scope.edition = {periodical_id: periodical.id, supplement_type: 'supplement'};
    $scope.save = function() {
        $scope.edition.publication_date = $scope.edition._obj_publication_date ?
                dayjs($scope.edition._obj_publication_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
        if ($scope.edition.supplement_type == "")
            $scope.edition.supplement_type = null;

        kwApi.SerialEdition.save($scope.edition).$promise.then(function(rv) {
            $scope.$close();
        }, function(err) {
            alertService.addApiError(err, 'Save failed');
        });
    };
    $scope.expand = { predictions: false };

    $scope.refreshPredictions = function() {
        $scope.refreshingPredictions = true;
        var fmtDate = dayjs($scope.periodical.chronology_state_date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        $scope.edition.publication_date = $scope.edition._obj_publication_date ?
                dayjs($scope.edition._obj_publication_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
        kwApi.Periodical.predict({infer: 0}, {
            patterns: $scope.periodical.receipt_patterns,
            sequence_config: $scope.periodical.sequence_config,
            chronology_state: fmtDate,
            sequence_state: $scope.periodical.sequence_state,
            supplement: $scope.edition,
            pattern_index: $scope.periodical.pattern_index,
        }).$promise.then(function(rv) {
            $scope.predictions = rv;
            $scope.predictions.length = 4;
            if (rv && rv.length > 0) {
                $scope.predictionWarning = rv[0].prediction_warning;
            }
            $scope.refreshingPredictions = false;
            $scope.predictionError = '';
        }, function(err) {
            $scope.refreshingPredictions = false;
            if (err.data) {
                if (/z is not defined/.test(err.data)) {
                    $scope.predictionError = "Add a 'z' parameter in the Enumeration Counters section";
                }
                else if (/y is not defined/.test(err.data)) {
                    $scope.predictionError = "Add a 'y' parameter in the Enumeration Counters section";
                }
                else {
                    $scope.predictionError = err.data;
                }
            }
            else {
                $scope.predictionError = err;
            }
        });
    };

    $scope.$watch('edition', function(newVal, oldVal) {
        if (!newVal) return;
        if (angular.equals(newVal, oldVal)) return;
        if (!$scope.expand.predictions) return;
        $scope.refreshPredictions();
    }, true);

    $scope.$watch('expand', function(newVal, oldVal) {
        if (!$scope.expand.predictions) return;
        $scope.refreshPredictions();
    }, true);

}])


.controller('StaffSerialsSubscriptionCtrl', ["$scope", "subscription", "serialInstances", "branches", "kwApi", "loading", "alertService", "$q", "$state", "configService", "$stateParams", "$filter", function($scope, subscription, serialInstances, branches, kwApi, loading, alertService, $q, $state, configService, $stateParams, $filter) {
    $scope.subscription = subscription;
    $scope.branches = branches.filter(function(b) {
        return $scope.userCanAccessBranch(b.branchcode);
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });
    $scope.editing = {};
    $scope.expand = {};

    $scope.vendor_accounts = [];
    $scope.purchase_orders = [];
    $scope.purchase_order_lines = [];

    $scope.sortableOptions = {};

    $scope.patronSelectConfig = {
        load: function(query, callback) {
            kwApi.Patron.query({view: 'picker', searchValue: query}).$promise.then(function(rv) {
                callback(rv);
            }, function(err) {
                callback();
            });
        },
        maxItems: 1,
        loadThrottle: 600,
        valueField: 'borrowernumber',
        labelField: 'firstname',
        searchField: ['firstname','surname'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' (' + branchName + ')</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' (' + branchName + ')</div>';
            }
        }
    };
    
    $scope.addToRlist = function() {
        kwApi.Patron.get({id: $scope.subscription._rlist_patron_id}).$promise.then(function(rec) {
            if (!$scope.subscription.routing_list)
                $scope.subscription.routing_list = [];
            $scope.subscription.routing_list.push({
                id: rec.borrowernumber,
                firstname: rec.firstname,
                surname: rec.surname,
                branch_code: rec.branchcode,
                branch_name: $filter('displayName')(rec.branchcode, 'branch')
            });
            $scope.subscription._rlist_patron_id = null;
        });
    };

    if (!subscription.id) {
        $scope.editing = {main: true}
        $scope.expand = {item: true};
        $scope._has_start_date = false;
        $scope._has_expiration_date = false;
        subscription.adds_items = false;
        subscription.adds_po_lines = false; 
        subscription.item_defaults = {};
        subscription.periodical_id = $stateParams.parent;
    }
    else {
        $scope.editing = {main: false};
        if ($scope.subscription.start_date) {
            $scope._has_start_date = true;
            $scope.subscription._obj_start_date = dayjs($scope.subscription.start_date).toDate();
        }
        else {
            $scope._has_start_date = false;
        }
        if ($scope.subscription.expiration_date) {
            $scope._has_expiration_date = true;
            $scope.subscription._obj_expiration_date = dayjs($scope.subscription.expiration_date).toDate();
        }
        else {
            $scope._has_expiration_date = false;
        }
    }
    $scope.serialInstances = serialInstances;

    $scope.pairedOrderLines = [];
    $scope.orderLinePairing = {
        mode: 'empty',
    };

    $scope.refreshOrderLines = function() {
        kwApi.Branch.getAcqSubscriptionPOLines({id: $scope.subscription.branch_code, subscription_id: $scope.subscription.id}).$promise.then(function(rv) {
            $scope.pairedOrderLines = rv;
            
            if ($scope.pairedOrderLines.length == 0) {
                if ($scope.subscription.purchase_order_number) {
                    $scope.orderLinePairing.mode = 'unpaired';
                }
                else {
                    $scope.orderLinePairing.mode = 'empty';
                }
            }
            else if ($scope.pairedOrderLines.length > 1) {
                $scope.orderLinePairing.mode = 'multiple';
            }
            else if ($scope.subscription.purchase_order_number == rv[0].purchase_order_number) {
                $scope.orderLinePairing.mode = 'paired';
            }
            else {
                $scope.orderLinePairing.mode = 'override';
                $scope.orderLinePairing.overrideOrder = rv[0].purchase_order_number;
                $scope.orderLinePairing.overrideOrderLineID = rv[0].id;
                $scope.orderLinePairing.overrideOrderLineTitle = rv[0].title;
            }
        });
    };


    $scope.$watch('subscription.branch_code', function(newVal) {
        if (newVal) {
            kwApi.Branch.getAcqVendorAccounts({id: newVal}).$promise.then(function(rv) {
                $scope.vendor_accounts = rv;
                if ($scope.subscription.vendor_account_id) {
                    rv.forEach(function(va) {
                        if (va.id == $scope.subscription.vendor_account_id) {
                            $scope.subscription.vendor_account_name = va.name;
                        }
                    });
                }

            }, function(err) {
                alertService.add({msg: "Unable to fetch vendor accounts for " + newVal + " branch", type: "error"});
            });
            $scope.refreshOrderLines();

        }
    });

    $scope.$watch('subscription.vendor_account_id', function(newVal, oldVal) {
        if (newVal) {
            $scope.vendor_accounts.forEach(function(va) {
                if (va.id == newVal) {
                    $scope.subscription.vendor_account_name = va.name;
                }
            });
            if (1 || newVal !== oldVal) {
                console.log("Loading POs for VAID=" + newVal);
                $scope.subscription.purchase_order_number = null;
                $scope.subscription.purchase_order_line_id = null;
                kwApi.Branch.getAcqPurchaseOrders({
                    id: $scope.subscription.branch_code,
                    view: 'details',
                    vendor_account_id: $scope.subscription.vendor_account_id,
                }).$promise.then(function(rv) {
                    $scope.purchase_orders = rv;
                });
            }
        }
    });

    $scope.$watch('subscription.purchase_order_number', function(newVal, oldVal) {
        if (newVal && (newVal !== oldVal)) {
            $scope.subscription.purchase_order_line_id = null;
            kwApi.Branch.getAcqPurchaseOrderLines({
                id: $scope.subscription.branch_code,
                number: $scope.subscription.purchase_order_number
            }).$promise.then(function(rv) {
                $scope.purchase_order_lines = rv;
            });
        }
    });


    $scope.periodical = kwApi.Periodical.get({id: $scope.subscription.periodical_id});

    $scope.vendorAccountSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'name',
        searchField: ['name', 'vendor_name'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                var t = '<div>' + escape(item.name) + ' (' + escape(item.vendor_name) + ')</div>';
                return t;
            },
            option: function(item, escape) {
                if (!item) return '';
                var t = '<div>' + escape(item.name) + ' (' + escape(item.vendor_name) + ')</div>';
                return t;
            }
        },

    };

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'branchcode',
        labelField: 'branchname',
        searchField: ['branchname','branchcode'],
    };

    $scope.purchaseOrderSelectConfig = {
        maxItems: 1,
        valueField: 'number',
        labelField: 'number',
        searchField: ['number'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                return '<div>' + item.number + ' (' + item.vendor_account_name + ')</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                return '<div>' + item.number + ' (' + item.vendor_account_name + ')</div>';
            }
        }
    };

    $scope.purchaseOrderLineSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'title',
        searchField: ['title'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                return '<div>' + item.title + ' (#' + item.id + ', ' + item.copies_count + ' copies)</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                return '<div>' + item.title + ' (#' + item.id + ', ' + item.copies_count + ' copies)</div>';
            }
        }
    };

    $scope.pairPurchaseOrder = function() {
        kwApi.Branch.pairAcqSubscriptionPOLine({id: $scope.subscription.branch_code, subscription_id: $scope.subscription.id, purchase_order_line_id: $scope.subscription.purchase_order_line_id},{}).$promise.then(function(rv) {
            alertService.add({msg: "Paired successfully", type: "info"});
            $scope.refreshOrderLines();
        }, function(err) {
            alertService.addApiError(err, 'Unable to pair');
        });
    };

    $scope.subscriptionEdit = function() {
        $scope.orig = angular.copy($scope.subscription);
        $scope.dirty = false;
        $scope.editing.main = true;
        $scope.expand._prev_item = $scope.expand.item;
        $scope.expand.item = true;
        $scope.expand._prev_rlist = $scope.expand.rlist;
        $scope.expand.rlist = true;
        if ($scope.orderLinePairing.mode == 'override') {
            $scope.subscription.purchase_order_number = $scope.orderLinePairing.overrideOrder;
            $scope.orderLinePairing.mode = 'paired';
        }
    };

    $scope.subscriptionEditCancel = function() {
        if ($scope.subscription.id) {
            $scope.dirty = false;
            angular.extend($scope.subscription,$scope.orig);
            $scope.editing.main = false;
            $scope.expand.item = $scope.expand._prev_item;
            $scope.expand.rlist = $scope.expand._prev_rlist;
        }
        else {
            $state.go('staff.serials.periodical', {id: $scope.subscription.periodical_id});
        }
    };

    
    $scope.subscriptionEditSave = function() {
        loading.add();

        var isNew = false; 
        $scope.subscription.start_date = $scope.subscription._obj_start_date ?
                dayjs($scope.subscription._obj_start_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
        $scope.subscription.expiration_date = $scope.subscription._obj_expiration_date ?
                dayjs($scope.subscription._obj_expiration_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

        var promise;
        if ($scope.subscription.id) {
            promise = kwApi.Subscription.put({id: $scope.subscription.id}, $scope.subscription).$promise;
        }
        else {
            promise = kwApi.Subscription.save($scope.subscription).$promise;
            isNew = true;
        }

        promise.then(function(rv) {
            if (isNew) {
                return $q.when(rv);
            }
            else {
                return kwApi.Subscription.get({id: $scope.subscription.id}).$promise;
            }
        }).then(function(obj) {
            if (isNew) {
                $state.go('staff.serials.subscription', {id: obj.id});
            }
            angular.extend($scope.subscription,obj);
            $scope.editing.main = false;
            $scope.expand.item = $scope.expand._prev_item;
            $scope.dirty = false;
            loading.resolve();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.$watch('subscription', function(newVal, oldVal) {
        if (newVal && oldVal && !angular.equals(newVal, oldVal)) {
            $scope.dirty = true;
        }
    }, true);

    $scope.defaultFields = [];
    $scope.item = $scope.subscription.item_defaults;

    $scope.itemFields = configService.ItemFields;
    $scope.itemFields.forEach(function(f) {
        if (f.code in subscription.item_defaults) {
            $scope.defaultFields.push(angular.extend({}, f, {
                custom: false,  // just flatten everything, we'll apply accordingly later
            }));
        }
    });
    $scope.availableItemFields = $scope.itemFields;
    $scope.$watch('item', function(newVal) {
        if (!newVal) return;
        $scope.availableItemFields = $scope.itemFields.filter(function(f) {
            return ((f.code in newVal) ? false : true);
        });
    }, true);

    $scope.itemDefaultAdd = function(newField) {
        if (newField) {
            $scope.itemFields.forEach(function(f) {
                if (f.code == newField) {
                    $scope.defaultFields.push(angular.extend({}, f, {
                        custom: false,
                    }));
                    if (f.code == 'wthdrawn' || f.code == 'restricted' || f.code == 'notforloan')
                        $scope.item[f.code] = 0;
                    else
                        $scope.item[f.code] = '';
                }
            });
            $scope.subscription._newItemField = undefined;
        }
    };

    $scope.$watch('subscription._newItemField', function(newVal) {
        if (newVal) {
            $scope.itemDefaultAdd($scope.subscription._newItemField);
        }
    });

    $scope.itemDefaultDel = function(idx) {
        delete $scope.item[$scope.defaultFields[idx].code];
        $scope.defaultFields.splice(idx,1);
    };


}])
.controller('StaffSerialsSubscriptionReceiveCtrl', ["$scope", "subscriptions", "serialInstances", "kwApi", "$filter", function($scope, subscriptions, serialInstances, kwApi, $filter) {
    $scope.subscriptions = subscriptions;
    $scope.subscription = true;

    $scope.editing = {};

    var byId = {};
    angular.forEach(subscriptions, function(s) {
        byId[s.id] = s;
    });

    var instances = [];
    angular.forEach(serialInstances, function(lst) {
        angular.forEach(lst, function(e) {
            e.subscription_name = byId[e.subscription_id].name || ('#'+e.subscription_id);
            if (e.expected_date) e._obj_expected_date = dayjs(e.expected_date).toDate();
            instances.push(e);
        });
    });

    $scope.serialInstances = instances.sort(function(a,b) {
        var aDate = a.expected_date || a.publication_date;
        var bDate = b.expected_date || b.publication_date;
        if (aDate < bDate) return 1;
        else if (aDate > bDate) return -1;
        else return 0;
    });

    $scope.periodical = kwApi.Periodical.get({id: $scope.subscriptions[0].periodical_id});

    var names = [];
    $scope.subscriptions.forEach(function(s) {
        var notes = [];
        if (s.staff_note) {
            notes.push($scope.subscription.staff_note);
        }
        if (s.opac_note) {
            notes.push($scope.subscription.opac_note);
        }

        if (s.adds_items && s.adds_po_lines) {
            notes.push("Receiving creates items and PO lines");
        }
        else if (s.adds_items) {
            notes.push("Receiving creates items but not PO lines");
        }
        else if (s.adds_po_lines) {
            notes.push("Receiving creates PO lines");
        }
        else {
            notes.push("Receiving does not create items or PO lines");
        }
        s.notes = notes.join(' / ');

        names.push(s.name || $filter('displayName')(s.branch_code, 'branch'));
    });

    $scope.names = names.join(', ');
}])

.controller('StaffSerialsEditionCtrl', ["$scope", "serialEdition", "serialInstances", "branches", "kwApi", "loading", "alertService", "$q", "$state", function($scope, serialEdition, serialInstances, branches, kwApi, loading, alertService, $q, $state) {
    $scope.serialEdition = serialEdition;
    $scope.serialInstances = serialInstances;
    $scope.branches = branches.filter(function(b) {
        return $scope.userCanAccessBranch(b.branchcode);
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });

    $scope.periodical = kwApi.Periodical.get({id: $scope.serialEdition.periodical_id});

    $scope.editing = {main: false};
    $scope.expand = {};

    $scope.serialEdition._obj_publication_date = dayjs($scope.serialEdition.publication_date).toDate();

    $scope.serialEditionEdit = function() {
        $scope.orig = angular.copy($scope.serialEdition);
        $scope.dirty = false;
        $scope.editing.main = true;
        $scope.expand._prev_copies = $scope.expand.copies;
        $scope.expand.copies = true;
    };

    $scope.serialEditionEditCancel = function() {
        $scope.dirty = false;
        angular.extend($scope.serialEdition,$scope.orig);
        $scope.editing.main = false;
        $scope.expand.copies = $scope.expand._prev_copies;
    };

    
    $scope.serialEditionEditSave = function() {
        loading.add();

        var isNew = false; 
        $scope.serialEdition.publication_date = $scope.serialEdition._obj_publication_date ?
                dayjs($scope.serialEdition._obj_publication_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

        var promise;
        if ($scope.serialEdition.id) {
            promise = kwApi.SerialEdition.put({id: $scope.serialEdition.id}, $scope.serialEdition).$promise;
        }
        else {
            promise = kwApi.SerialEdition.save($scope.serialEdition).$promise;
            isNew = true;
        }

        promise.then(function(rv) {
            if (isNew) {
                return $q.when(rv);
            }
            else {
                return kwApi.SerialEdition.get({id: $scope.serialEdition.id}).$promise;
            }
        }).then(function(obj) {
            if (isNew) {
                $state.go('staff.serials.edition', {id: obj.id});
            }
            angular.extend($scope.serialEdition,obj);
            $scope.editing.main = false;
            $scope.expand.copies = $scope.expand._prev_copies;
            $scope.dirty = false;
            loading.resolve();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.$watch('serialEdition', function(newVal, oldVal) {
        if (newVal && oldVal && !angular.equals(newVal, oldVal)) {
            $scope.dirty = true;
        }
    }, true);
}])

.controller('StaffSerialsInstancesCtrl', ["$scope", "kwApi", "loading", "alertService", "$uibModal", "bibService", "bvPrintSvc", function($scope, kwApi, loading, alertService, $uibModal, bibService, bvPrintSvc) {
    var disableWatch, enableWatch;

    if ($scope.subscription) {
        $scope.instancesOrder = {field: 'publication_date', reverse: true};
    }
    else {
        $scope.instancesOrder = {field: 'branch_name', reverse: false};
    }

    // FIXME - this could really be a separate controller, or even better, a directive

    $scope.statuses = [
        { id: '1', name: 'Expected' },
        { id: '2', name: 'Arrived' },
        { id: '3', name: 'Late' },
        { id: '4', name: 'Missing' },
        { id: '5', name: 'Unavailable' },
        { id: '6', name: 'Deleted' },
        { id: '7', name: 'Claimed' },
    ];


    $scope.serialInstances.forEach(function(e) {
        if (e.expected_date) e._obj_expected_date = dayjs(e.expected_date).toDate();
        if (e.received_date) e._obj_received_date = dayjs(e.received_date).toDate();
    });

    $scope.serialInstancesEdit = function() {
        $scope.origIssues = angular.copy($scope.serialInstances);
        $scope.copiesDirty = false;
        $scope.editing.copies = true;
    };

    $scope.serialInstancesEditCancel = function() {
        $scope.dirty = false;
        $scope.serialInstances = $scope.origIssues;
        $scope.editing.copies = false;
    };

    $scope.openEditModal = function(holdings,item_id) {
        $uibModal.open({
            backdrop: false,
            templateUrl: '/app/static/partials/staff/item-edit-modal.html',
            controller: 'StaffItemEditCtrl',
            resolve: {
                holdings: function() { return holdings; },
                itemid: function() { return item_id; },
                mfhdid: function() { return null; },
                clone: function() { return null; },
            }
        });
    };

    $scope.serialInstancesPrintRouting = function() {
        var toPrint = $scope.serialInstances.filter(function(e) { return e.received });
        bvPrintSvc.print({
            templateUrl: '/app/static/partials/staff/serials/routing-list-print-all.html',
            controller: function($scope) {
                $scope.issues = toPrint;
            }
        });
    };

    
    $scope.serialInstancesEditSave = function() {
        loading.add();

        $scope.serialInstances.forEach(function(e) {
            e.expected_date = e._obj_expected_date ? dayjs(e._obj_expected_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
            e.received_date = e._obj_received_date ? dayjs(e._obj_received_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
            if (e.received) {
                e.status = 2;
            }

        });

        var promise;

        disableWatch();
        kwApi.SerialInstance.saveAll($scope.serialInstances).$promise.then(function(rv) {
            $scope.serialInstances = rv;
            var newItems = [];
            var bib_id;
            for (var i=0; i<rv.length; i++) {
                var e = $scope.serialInstances[i];
                if (e.expected_date) e._obj_expected_date = dayjs(e.expected_date).toDate();
                if (e.received_date) e._obj_received_date = dayjs(e.received_date).toDate();
                if (e._item_created) {
                    newItems.push(e.item_id);
                    bib_id = e._bib_id;
                }
            }
            loading.resolve();
            $scope.editing.copies = false;
            $scope.copiesDirty = false;
            $scope.serialInstances.forEach(function(e) { e.status = '' + e.status });
            enableWatch();

            if (bib_id && newItems) {
                bibService.holdings(bib_id, {cache: false}).then(function(h) {
                    newItems.forEach(function(item_id) {
                        $scope.openEditModal(h, item_id);
                    });
                });
            }
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Save failed');
            enableWatch();
        });
    };

    enableWatch = function() {
        disableWatch = $scope.$watch('serialInstances', function(newVal, oldVal) {
            if (newVal && oldVal && !angular.equals(newVal, oldVal)) {
                $scope.copiesDirty = true;
                for (var i=0; i<newVal.length; i++) {
                    if (newVal[i].received && !oldVal[i].received) {
                        if (!newVal[i]._obj_received_date) {
                            newVal[i]._obj_received_date = dayjs().toDate();
                        }
                    }
                }
            }
        }, true);
    };
    enableWatch();

    $scope.printRoutingList = function(issue) {
        bvPrintSvc.print({
            templateUrl: '/app/static/partials/staff/serials/routing-list-print.html',
            controller: function($scope) {
                $scope.issue = issue;
            }
        });
    };

    $scope.viewRoutingList = function(issue) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/serials/routing-list-view.html',
            controller: ["$scope", function($scope) {
                $scope.issue = issue;
            }]
        });
    };

}])

.controller('StaffSerialsClaimsCtrl', ["$scope", "branches", "kwApi", "alertService", "bvPrintSvc", function($scope, branches, kwApi, alertService, bvPrintSvc) {
    $scope.branches = branches.filter(function(b) {
        return $scope.userCanAccessBranch(b.branchcode);
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });
    $scope.branch_code = branches[0].branchcode;
    $scope.vendor_accounts = [];
    $scope.vendor_account_id = undefined;
    $scope.instancesOrder = {field: 'publication_date', reverse: false};
    $scope.serialInstances = [];
    $scope.vendor_emails = '';

    $scope.$watch('branch_code', function(newVal) {
        if (newVal) {
            $scope.vendor_account_id = undefined;
            kwApi.Branch.getAcqVendorAccounts({id: newVal}).$promise.then(function(rv) {
                $scope.vendor_accounts = rv;
            }, function(err) {
                alertService.add({msg: "Unable to fetch vendor accounts for " + newVal + " branch", type: "error"});
            });
        }
    });

    $scope.$watch('vendor_account_id', function(newVal) {
        if (newVal && $scope.branch_code) {

            $scope.vendor_accounts.forEach(function(va) {
                if (va.id != newVal) return;

                var bestEmail, altEmail, fallbackEmail;
                var bestAddr, altAddr, fallbackAddr;
                va.addresses.forEach(function(a) {
                    if (a.email) {
                        if (!fallbackEmail)
                            fallbackEmail = a.email;
                        a.use_names.forEach(function(u) {
                            if (u == 'claims') {
                                bestEmail = a.email;
                            }
                            else if (u == 'all') {
                                if (!bestEmail) bestEmail = a.email;
                            }
                            else {
                                if (!altEmail) altEmail = a.email;
                            }
                        });
                    }
                    if (a.line_1) {
                        if (!fallbackAddr)
                            fallbackAddr = a;
                        a.use_names.forEach(function(u) {
                            if (u == 'claims') {
                                bestAddr = a;
                            }
                            else if (u == 'all') {
                                if (!bestAddr) bestAddr = a;
                            }
                            else {
                                if (!altAddr) altAddr = a;
                            }
                        });
                    }

                });
                $scope.vendor_emails = bestEmail || altEmail || fallbackEmail || '';
                $scope.address = bestAddr || altAddr || fallbackAddr || {};
            });

            kwApi.Branch.getSerialInstances({id: $scope.branch_code, scope: 'claimable', vendor_account_id: newVal, include_claimed: ($scope.include_claimed ? 1 : 0)}).$promise.then(function(rv) {
                $scope.serialInstances = rv;
            }, function(err) {
                alertService.add({msg: "Unable to fetch issues for this branch and vendor", type: "error"});
            });
        }
        else {
            $scope.serialInstances = [];
        }
    });

    $scope.$watch('include_claimed', function(newVal) {
        if ($scope.branch_code && $scope.vendor_account_id) {
            kwApi.Branch.getSerialInstances({id: $scope.branch_code, scope: 'claimable', vendor_account_id: $scope.vendor_account_id, include_claimed: ($scope.include_claimed ? 1 : 0)}).$promise.then(function(rv) {
                $scope.serialInstances = rv;
            }, function(err) {
                alertService.add({msg: "Unable to fetch issues for this branch and vendor", type: "error"});
            });
        }
    });

    $scope.vendorAccountSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'name',
        searchField: ['name', 'vendor_name'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                var t = '<div>' + escape(item.name) + ' (' + escape(item.vendor_name) + ')</div>';
                return t;
            },
            option: function(item, escape) {
                if (!item) return '';
                var t = '<div>' + escape(item.name) + ' (' + escape(item.vendor_name) + ')</div>';
                return t;
            }
        },

    };

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'branchcode',
        labelField: 'branchname',
        searchField: ['branchname','branchcode'],
    };

    $scope.submitClaim = function() {
        var claimed = $scope.serialInstances.filter(function(si) {
            return si.selected;
        }).map(function(rv) {
            return { id: rv.id };
        });
        if (!claimed.length) return;

        kwApi.SerialInstance.claim({email: $scope.vendor_emails, mark_claimed: ($scope.mark_claimed ? 1 : 0)},claimed).$promise.then(function(rv) {
            alertService.add({msg: "Claim email sent", type: "success"});
            kwApi.Branch.getSerialInstances({id: $scope.branch_code, scope: 'claimable', vendor_account_id: $scope.vendor_account_id, include_claimed: ($scope.include_claimed ? 1 : 0)}).$promise.then(function(rv) {
                $scope.serialInstances = rv;
            }, function(err) {
                alertService.add({msg: "Unable to fetch issues for this branch and vendor", type: "error"});
            });
        }, function(err) {
            alertService.addApiError(err, 'Unable to submit claim');
        });
    };

    $scope.printClaim = function() {
        var claimed = $scope.serialInstances.filter(function(si) {
            return si.selected;
        }).map(function(rv) {
            return { id: rv.id };
        });
        if (!claimed.length) return;
        var addr = $scope.address;

        kwApi.SerialInstance.claim({print: 1, mark_claimed: ($scope.mark_claimed ? 1 : 0)},claimed).$promise.then(function(rv) {
            bvPrintSvc.print({
                templateUrl: '/app/static/partials/staff/serials/claim-print.html',
                controller: function($scope) {
                    $scope.rendered = rv.rendered;
                    $scope.address = addr;
                }
            });

            kwApi.Branch.getSerialInstances({id: $scope.branch_code, scope: 'claimable', vendor_account_id: $scope.vendor_account_id, include_claimed: ($scope.include_claimed ? 1 : 0)}).$promise.then(function(rv) {
                $scope.serialInstances = rv;
            }, function(err) {
                alertService.add({msg: "Unable to fetch issues for this branch and vendor", type: "error"});
            });
        }, function(err) {
            alertService.addApiError(err, 'Unable to submit claim');
        });
    };

}])


.controller('StaffSerialsExpiresCtrl', ["$scope", "branches", "kwApi", "loading", "$uibModal", function($scope, branches, kwApi, loading, $uibModal) {
    $scope.branches = branches.filter(function(b) {
        return $scope.userCanAccessBranch(b.branchcode);
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });
    $scope.branch_code = branches[0].branchcode;

    $scope.subscriptionsOrder = {field: 'expiration_date', reverse: false};
    $scope.subscriptions = [];
    $scope.since = '1 MONTH';
    $scope.sinceOptions = [
        {label: "1 month", value: "1 MONTH"},
        {label: "3 months", value: "3 MONTH"},
        {label: "6 months", value: "6 MONTH"},
        {label: "1 year", value: "12 MONTH"},
    ];

    $scope.reload = function() {
        loading.wrap(
            kwApi.Branch.getSubscriptions({id: $scope.branch_code, since: $scope.since}).$promise, 
            "Unable to fetch subscriptions for " + $scope.branch_code + " branch"
        ).then(function(rv) {
            $scope.subscriptions = rv;
        });
    };

    $scope.$watch('branch_code', function(newVal) {
        if (newVal) {
            $scope.reload();
        }
    });

    $scope.$watch('since', function(newVal) {
        if (newVal) {
            $scope.reload();
        }
    });

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'branchcode',
        labelField: 'branchname',
        searchField: ['branchname','branchcode'],
    };

    $scope.renew = function(c) {
        var newStart, newEnd;
        if (c.start_date && c.expiration_date) {
            var sdate = dayjs(c.start_date);
            var edate = dayjs(c.expiration_date);
            var daysDiff = edate.diff(sdate, 'days');
            newStart = dayjs(edate);
            newStart.add(1, 'days');
            newEnd = dayjs(newStart);
            newEnd.add(daysDiff, 'days');
        }
        else if (c.expiration_date) {
            newEnd = dayjs(c.expiration_date).add(1, 'years');
        }
        else if (c.start_date) {
            newStart = dayjs(c.start_date).add(1, 'years');
        }

        if (newStart)
            c.new_start_date = newStart.toDate();
        if (newEnd)
            c.new_expiration_date = newEnd.toDate();

        $uibModal.open({
            backdrop: false,
            templateUrl: '/app/static/partials/staff/serials/renew-modal.html',
            controller: ["$scope", "subscription", function($scope, subscription) {
                $scope.startOpened = false;
                $scope.dateFormat = "MM/dd/yyyy";
                $scope.subscription = subscription;
                $scope.save = function() {
                    $scope.subscription.start_date = $scope.subscription.new_start_date ?
                            dayjs($scope.subscription.new_start_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
                    $scope.subscription.expiration_date = $scope.subscription.new_expiration_date ?
                            dayjs($scope.subscription.new_expiration_date).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;


                    loading.wrap(kwApi.Subscription.put({id: $scope.subscription.id}, $scope.subscription).$promise).then(function(rv) {
                        $scope.$close(1);
                    });
                };
            }],
            resolve: {
                subscription: function() { return c; },
            },
        }).result.then(function(rv) {
            if (rv)
                $scope.reload();
        });
    };
        

}])
    
.controller('StaffSerialsScheduleTemplatesCtrl', ["$scope", "templates", "kwApi", "alertService", "kohaDlg", "$uibModal", function($scope, templates, kwApi, alertService, kohaDlg, $uibModal) {
    $scope.templates = templates;
    $scope.templates.forEach(function(t) {
        t._is_basic = (t.patterns.length == 1 && !t.chronology_template && !t.sequence_template);
        t.pattern = (t.patterns[0] || {}).pattern;
    });

    $scope.showHelp = function(s) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/serials/help-' + s + '.html',
            size: 'md'
        });
    };
    $scope.refresh = function() {
        kwApi.SerialPatternTemplate.query().$promise.then(function(rv) {
            $scope.templates = rv;
            $scope.templates.forEach(function(t) {
                t._is_basic = (t.patterns.length == 1 && !t.chronology_template && !t.sequence_template);
                if (!t.patterns) t.patterns = [];
                t.pattern = (t.patterns[0] || {}).pattern;
            });
        });
    };

    $scope.deleteRow = function(n) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you wish to delete this template? This cannot be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.SerialPatternTemplate.delete({id: $scope.templates[n].id}).$promise.then(function(rv) {
                    $scope.templates.splice(n,1);
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };

    $scope.editingAny = false;

    $scope.editRow = function(n) {
        $scope.templates[n].editing = true;
        $scope.editingAny = true;
    };

    $scope.cancelEdit = function(n) {
        $scope.templates[n].editing = false;
        $scope.editingAny = false;
        $scope.refresh();
    };

    $scope.newRow = function() {
        $scope.templates.push({
            editing: true,
            patterns: [{
                pattern: '1*0:0:0:0:0:0',
                start_date: '01-01',
                end_date: '12-31',
                chronology_template: null,
                sequence_template: null
            }],
            name: '',
            description: '',
            pattern: '1*0:0:0:0:0:0',
            _is_basic: true
        });
        $scope.editingAny = true;
    };

    $scope.saveEdit = function(n) {
        var row = $scope.templates[n];
        var promise;
        if (row._is_basic) {
            row.patterns = JSON.stringify([{
                pattern: row.pattern,
                start_date: '01-01',
                end_date: '12-31',
                chronology_template: null,
                sequence_template: null,
            }]);
        }
        if (row.id) {
            promise = kwApi.SerialPatternTemplate.put({id: row.id}, row).$promise;
        }
        else {
            promise = kwApi.SerialPatternTemplate.save(row).$promise;
        }
        promise.then(function(rv) {
            $scope.refresh();
            $scope.editingAny = false;
        }, function(err) {
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.editAdvanced = function(n) {
        $uibModal.open({
            backdrop: false,
            templateUrl: '/app/static/partials/staff/serials/schedule-edit-advanced.html',
            controller: ["$scope", "patterns", function($scope, patterns) {
                $scope.patterns = patterns;
                $scope.patterns.forEach(function(p) {
                    if (!p.chronology_template || p.chronology_template === null) p.chronology_template = '';
                    if (!p.sequence_template || p.sequence_template === null) p.sequence_template = '';
                });
                $scope.saved = angular.copy($scope.patterns);

                $scope.editingAny = false;

                $scope.showHelp = function(s) {
                    $uibModal.open({
                        templateUrl: '/app/static/partials/staff/serials/help-' + s + '.html',
                        size: 'md'
                    });
                };

                $scope.newRow = function(n) {
                    $scope.patterns.push({
                        pattern: '1*0:0:0:0:0:0',
                        start_date: '01-01',
                        end_date: '12-31',
                        chronology_template: null,
                        sequence_template: null,
                        editing: true,
                    });
                    $scope.editingAny = true;
                };
                $scope.editRow = function(n) {
                    $scope.editingAny = true;
                    $scope.patterns[n].editing = true;
                    $scope.saved[n] = angular.copy($scope.patterns[n]);
                };
                $scope.deleteRow = function(n) {
                    $scope.patterns.splice(n,1);
                };
                $scope.saveEdit = function(n) {
                    $scope.patterns[n].editing = false;
                    $scope.editingAny = false;
                };
                $scope.cancelEdit = function(n) {
                    if ($scope.saved[n]) {
                        $scope.patterns[n] = $scope.saved[n];
                        $scope.patterns[n].editing = false;
                    }
                    else {
                        $scope.patterns.pop();
                    }
                    $scope.saved[n] = null;
                    $scope.editingAny = false;
                };
            }],
            size: 'lg',
            resolve: {
                patterns: function() { return $scope.templates[n].patterns; },
            }
        }).result.then(function() {
            $scope.templates[n].patterns.forEach(function(p) {
                if (!p.chronology_template || /^\s*$/.test(p.chronology_template)) p.chronology_template = null;
                if (!p.sequence_template || /^\s*$/.test(p.sequence_template)) p.sequence_template = null;
            });
        });

    };

}])


.controller('StaffSerialsChronologyTemplatesCtrl', ["$scope", "templates", "kwApi", "alertService", "kohaDlg", "$uibModal", function($scope, templates, kwApi, alertService, kohaDlg, $uibModal) {
    $scope.templates = templates;

    $scope.showHelp = function(s) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/serials/help-' + s + '.html',
            size: 'md'
        });
    };
    $scope.refresh = function() {
        kwApi.SerialChronologyTemplate.query().$promise.then(function(rv) {
            $scope.templates = rv;
        });
    };

    $scope.deleteRow = function(n) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you wish to delete this template? This cannot be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.SerialChronologyTemplate.delete({id: $scope.templates[n].id}).$promise.then(function(rv) {
                    $scope.templates.splice(n,1);
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };

    $scope.editingAny = false;

    $scope.editRow = function(n) {
        $scope.editingAny = true;
        $scope.templates[n].editing = true;
    };

    $scope.newRow = function() {
        $scope.editingAny = true;
        $scope.templates.push({editing: true, name: '', description: '', pattern: ''});
    };

    $scope.saveEdit = function(n) {
        var row = $scope.templates[n];
        var promise;
        if (row.id) {
            promise = kwApi.SerialChronologyTemplate.put({id: row.id}, row).$promise;
        }
        else {
            promise = kwApi.SerialChronologyTemplate.save(row).$promise;
        }
        promise.then(function(rv) {
            $scope.refresh();
            $scope.editingAny = false;
        }, function(err) {
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.cancelEdit = function(n) {
        $scope.templates[n].editing = false;
        $scope.editingAny = false;
        $scope.refresh();
    };
}])


.controller('StaffSerialsSequenceTemplatesCtrl', ["$scope", "templates", "kwApi", "alertService", "kohaDlg", "$uibModal", function($scope, templates, kwApi, alertService, kohaDlg, $uibModal) {
    $scope.templates = templates;

    $scope.refresh = function() {
        kwApi.SerialSequenceTemplate.query().$promise.then(function(rv) {
            $scope.templates = rv;
        });
    };
    $scope.showHelp = function(s) {
        $uibModal.open({
            templateUrl: '/app/static/partials/staff/serials/help-' + s + '.html',
            size: 'md'
        });
    };

    $scope.deleteRow = function(n) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you wish to delete this template? This cannot be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.SerialSequenceTemplate.delete({id: $scope.templates[n].id}).$promise.then(function(rv) {
                    $scope.templates.splice(n,1);
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };

    $scope.editingAny = false;

    $scope.editRow = function(n) {
        $scope.templates[n].editing = true;
        $scope.editingAny = true;
    };

    $scope.newRow = function() {
        $scope.templates.push({editing: true, name: '', description: '', pattern: ''});
        $scope.editingAny = true;
    };

    $scope.saveEdit = function(n) {
        var row = $scope.templates[n];
        var promise;
        if (row.id) {
            promise = kwApi.SerialSequenceTemplate.put({id: row.id}, row).$promise;
        }
        else {
            promise = kwApi.SerialSequenceTemplate.save(row).$promise;
        }
        promise.then(function(rv) {
            $scope.refresh();
            $scope.editingAny = false;
        }, function(err) {
            alertService.addApiError(err, 'Delete failed');
        });
    };

    $scope.cancelEdit = function(n) {
        $scope.templates[n].editing = false;
        $scope.editingAny = false;
        $scope.refresh();
    };
}])

.controller('StaffSerialsVendorsCtrl', ["$scope", "kwApi", "loading", "alertService", "branches", "$uibModal", "kohaDlg", function($scope, kwApi, loading, alertService, branches, $uibModal, kohaDlg) {
    $scope.branches = branches.filter(function(b) {
        return $scope.userCanAccessBranch(b.branchcode);
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'branchcode',
        labelField: 'branchname',
        searchField: ['branchname','branchcode'],
    };

    $scope.reloadVendorAccounts = function() {
        loading.add();
        kwApi.Branch.getAcqVendorAccounts({id: $scope.branch_code}).$promise.then(function(vendor_accounts) {
            vendor_accounts.forEach(function(va) {
                va._is_serials_vendor = (va.internal_vendor_account_number == 'CREATED BY SERIALS');

                var bestEmail, altEmail, fallbackEmail;
                var bestAddr, altAddr, fallbackAddr;
                va.addresses.forEach(function(a) {

                    if (a.email) {
                        if (!fallbackEmail)
                            fallbackEmail = a.email;
                        a.use_names.forEach(function(u) {
                            if (u == 'claims') {
                                bestEmail = a.email;
                            }
                            else if (u == 'all') {
                                if (!bestEmail) bestEmail = a.email;
                            }
                            else {
                                if (!altEmail) altEmail = a.email;
                            }
                        });
                    }
                    if (a.line_1) {
                        if (!fallbackAddr)
                            fallbackAddr = a;
                        a.use_names.forEach(function(u) {
                            if (u == 'claims') {
                                bestAddr = a;
                            }
                            else if (u == 'all') {
                                if (!bestAddr) bestAddr = a;
                            }
                            else {
                                if (!altAddr) altAddr = a;
                            }
                        });
                    }
                });
                va.best_email = bestEmail || altEmail || fallbackEmail;
                va.best_address = bestAddr || altAddr || fallbackAddr;
            });

            loading.resolve();
            $scope.vendor_accounts = vendor_accounts;
        }, function(err) {
            loading.resolve();
            alertService.add({msg: "Unable to fetch vendor accounts for " + $scope.branch_code + " branch", type: "error"});
        });
    };

    $scope.$watch('branch_code', function(newVal) {
        if (newVal) {
            $scope.reloadVendorAccounts();
        }
    });

    $scope.editVendor = function(vendor_account) {
        var vendorIdx = {};
        $scope.vendor_accounts.forEach(function(va) {
            vendorIdx[va.vendor_name] = va.vendor_id;
        });
        /*var vendors = [];
        Object.keys(vendorIdx).sort().forEach(function(name) {
            vendors.push({name: name, id: vendorIdx[name]});
        });*/


        $uibModal.open({
            backdrop: true,
            templateUrl: '/app/static/partials/staff/serials/vendor-account-edit.html',
            controller: 'StaffSerialsVendorEditCtrl',
            size: 'lg',
            resolve: {
                vendor_account: function() { return angular.copy(vendor_account); },
                countries: function() { 
                    return kwApi.Branch.getAcqVendorCountries({id: $scope.branch_code }).$promise;
                },
                vendors: function() {
                    return kwApi.Branch.getAcqVendors({id: $scope.branch_code}).$promise;
                },
                branch_code: function() { return $scope.branch_code; },
            }
        }).result.then(function(rv) {
            $scope.reloadVendorAccounts();
        });
    };

    $scope.newVendor = function() {
        $scope.editVendor({
            addresses: [{}],
        });
    };

    $scope.deleteVendor = function(vendor_account) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you wish to delete this vendor account? This cannot be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.Branch.deleteAcqVendorAccount({id: $scope.branch_code, vendor_account_id: vendor_account.id}, {}).$promise.then(function() {
                    $scope.reloadVendorAccounts();
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                });
            }
        });
    };

}])

.controller('StaffSerialsVendorEditCtrl', ["$scope", "kwApi", "vendor_account", "vendors", "countries", "alertService", "branch_code", function ($scope, kwApi, vendor_account, vendors, countries, alertService, branch_code) {
    $scope.vendor_account = vendor_account;
    $scope.is_new = ($scope.vendor_account.id ? false : true);

    $scope.vendors = vendors;
    $scope.countries = countries;
    $scope.branch_code = branch_code;

    if ($scope.is_new) {
        $scope.countries.forEach(function(c) {
            if (c.name === 'United States') {
                $scope.vendor_account.addresses[0].country_id = c.id;
            }
        });
    }
    
    $scope.vendorSelectConfig = {
        create: true,
        maxItems: 1,
        valueField: 'name',
        labelField: 'name',
        searchField: ['name'],
    };

    $scope.countrySelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'name',
        searchField: ['name'],
    };

    $scope.save = function() {
        if ($scope.is_new) {
            $scope.vendors.forEach(function(v) {
                if (v.name === $scope.vendor_account.vendor_name) {
                    $scope.vendor_account.vendor_id = v.id;
                }
            });
        }

        kwApi.Branch.saveAcqVendorAccount({id: $scope.branch_code}, $scope.vendor_account).$promise.then(function() {
            $scope.$close();
        }, function(err) {
            alertService.addApiError(err,'Delete failed');
        });
    };
}])

.controller('StaffOfflineCirculationCtrl', ["$scope", "kwFileUploadSvc", "userService", function ($scope, kwFileUploadSvc, userService) {

    if(userService.can({circulate : 'offline_circ_import'})){
        $scope.canImport = true;
    }

    if(userService.can({circulate : 'offline_circ_export'})){
        $scope.canExport = true;
    }

    $scope.importOfflineCirculation = function() {
        kwFileUploadSvc.upload({
            title: 'Upload Circulation File',
            description: 'Upload .koc file.',
            instructions: false,
            formdata: {},
            inputs: [],
            url: '/bvcgi/offline_circ/process_koc.pl'
        });
    };


}])


// Batch Item Mutator
.controller('StaffBimCtrl', ["$scope", "$state", "$uibModal", "kwApi", "kwFileUploadSvc", "userService", function ($scope, $state, $uibModal, kwApi, kwFileUploadSvc, userService) {
    if ($state.current.name == 'staff.tools.bim') {
        $state.go('staff.tools.bim.index');
    }


    $scope.userCan = {
        modify: userService.can({editcatalogue: 'batch_item_edit'}),
        delete: userService.can({editcatalogue: 'batch_item_delete'}),
    };

    // Common to multiple views
    $scope.getReportSlug = function(i,verbose) {
        if ((i.metadata || {}).report) {
            if (verbose) {
                return ''
                    + i.metadata.report.processed + " Processed, "
                    + i.metadata.report.skipped + " Skipped, "
                    + i.metadata.report.exception + " Errors, "
                    + i.metadata.report.redundant + " Duplicates";
            }
            else {
                return ''
                    + i.metadata.report.processed + "/"
                    + i.metadata.report.skipped + "/"
                    + i.metadata.report.exception + "/"
                    + i.metadata.report.redundant + "";
            }
        }
        else {
            if (verbose) {
                return "New";
            }
            else {
                return '';
            }
        }
    };

    $scope._createFromEntry = function(itemList,keyField) {
        return $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/bim/itemlist-manual-edit-modal.html',
            controller: 'StaffBimManualEditCtrl',
            resolve: {
                entries: function() {
                    if (itemList && keyField) {
                        return kwApi.ItemList.getEntries({id:itemList.id}).$promise.then(function(entries) {
                            return entries.map(function(entry) { return (entry.item||{})[keyField]; });
                        });
                    }
                    else {
                        return [];
                    }
                },
                itemList: function() {
                    return itemList || {name: ""};
                },
                fixedKeyField: function() {
                    return keyField;
                }
            }
        }).result;
    };

    $scope._createFromSearch = function(itemList) {
        return $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/bim/itemlist-attribute-search-modal.html',
            controller: 'StaffBimAttributeSearchCtrl',
            resolve: {
                itemList: function() {
                    return itemList || {name: ""};
                },
            }
        }).result;
    };

    $scope._createFromUpload = function(itemList) {
        var inputs = itemList
            ? [{name: 'id', type: 'hidden', value: itemList.id}]
            : [{name: 'name', type: 'text', label: 'Name', instructions: ""}];

        inputs.push(
            {name: 'key', type: 'select', label: 'Type', 
                instructions: "Select whether the file contains item numbers or barcodes",
                values: [
                    {value: 'id', display: 'Item Numbers'},
                    {value: 'barcode', display: 'Barcodes'},
                ]
            }
        );
        inputs.push(
            {name: 'skipInvalid', type: 'select', label: 'On Invalid', 
                instructions: "What to do when encountering an invalid barcode/item number",
                values: [
                    {value: 1, display: 'Ignore invalid entries'},
                    {value: 0, display: 'Fail and return'},
                ]
            }
        );
        return kwFileUploadSvc.upload({
            title: 'Upload Item List',
            description: 'Upload a text list of item numbers or barcodes',
            instructions: false,
            formdata: {
                id: (itemList ? itemList.id : undefined),
                name: (itemList ? itemList.name : ''),
                key: 'barcode',
                skipInvalid: 1,
            },
            inputs: inputs,
            url: '/api/item-list?op=save-from-upload',
        });
    };

    $scope._mutateItems = function(itemList, op, ids) {
        return $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/bim/itemlist-mutate-items-modal.html',
            controller: 'StaffBimMutateItemsCtrl',
            size: 'lg',
            resolve: {
                itemList: function() {
                    return kwApi.ItemList.get({id: itemList.id}).$promise;
                },
                operation: function() {
                    return op;
                },
                selectedItems: function() {
                    return ids;
                },
                itemStatuses: function() {
                    return kwApi.ItemStatus.query().$promise;
                },
            }
        }).result;
    };
}])

.controller('StaffBimIndexCtrl', ["$scope", "$interval", "itemLists", "kwApi", "alertService", "$state", "kohaDlg", "branches", "userService", "uiGridConstants",
                                  function ($scope, $interval, itemLists, kwApi, alertService, $state, kohaDlg, branches, userService, uiGridConstants) {
    $scope.itemLists = itemLists
        .map(function(e) {e.report_slug = $scope.getReportSlug(e); return e});

    var branchopts = branches
        .map(x => ({value: x.branchcode, label: x.branchname + ' ('+x.branchcode+')'}))
        .sort((a,b) => {return a.label < b.label ? -1 : 1})

    $scope.currentPage = 1;

    $scope.gridOptions = {
        data: "itemLists",
        enableSorting: true,
        enableColumnResizing: true,
        enableColumnReordering: false,
        enableFiltering: true,
        multiSelect: false,
        enableSelectAll: false,
        showGridFooter:true,
        enableFullRowSelection: true,

        saveWidths: true,
        saveOrder: false,
        saveScroll: false,
        saveFocus: false,
        saveVisible: false,
        saveSort: true,
        saveFilter: true,
        savePagination: false,
        savePinning: false,
        saveGrouping: false,
        saveGroupingExpandedStates: false,
        saveTreeView: false,
        saveSelection: false,

        rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.uid" class="ui-grid-cell" ui-grid-cell></div>',
        columnDefs: [
            { field: 'name', displayName: 'Name', enableCellEdit: true,
              cellTemplate: '<div><a href="/app/staff/tools/bim/view/{{row.entity.id}}">{{row.entity.name}}</a></div>'
            },
            { field: 'created_date', displayName: 'Created on', enableCellEdit: false, type: 'date', cellFilter: 'dateFmt', enableFiltering: false },
            { field: 'branch_code', displayName: 'Branch', enableCellEdit: false, sortCellFiltered: false,
              filter: {
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: branchopts
              } },
            { field: 'entries_count', displayName: 'Item count', enableCellEdit: false, enableFiltering: false },
            { field: 'status', enableCellEdit: false,
              filter: {
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: [ {value: 'New', label: 'New'}, {value: 'Processed', label: 'Processed'} ]
              } },
            { field: 'report_slug', displayName: 'OK/Skip/Xcp/Dup', enableCellEdit: false, enableFiltering: false, enableSorting: false },
        ]
    };

    $scope.saveRow = function( rowEntity ) {
        var promise = kwApi.ItemList.setName({id: rowEntity.id, name: rowEntity.name},{}).$promise.then(function(rv) {
            alertService.add({msg: "Item list "+rowEntity.id+" now named \""+rowEntity.name+"\"."});
            $scope.selectedCount = 0;
            $scope.selectedList = null;
            $scope.reload();
        }, function(err) {
            alertService.addApiError(err, 'Rename failed');
            $scope.reload();
        });
        $scope.gridApi.rowEdit.setSavePromise(rowEntity, promise);
    };

    $scope.selectChange = function(e){
        if (e.isSelected) {
            $scope.selectedCount = 1;
            $scope.selectedList = e.entity;
        } else {
            $scope.selectedCount = 0;
            $scope.selectedList = null;
        }
    };

    $scope.saveOpts = function() {
        var state = $scope.gridApi.saveState.save();
        userService.setPref("bim_index_grid_opts", state);
    };

    $scope.restoreOpts = function() {
        $interval(function() {
            var state = userService.getPref("bim_index_grid_opts");
            if (! state) {
                return;
            }
            $scope.gridApi.saveState.restore( $scope, state );
        }, 50, 1);
    };

    $scope.gridOptions.onRegisterApi = function(gridApi){
        $scope.gridApi = gridApi;
        gridApi.rowEdit.on.saveRow($scope, $scope.saveRow);
        gridApi.selection.on.rowSelectionChanged($scope, $scope.selectChange);
        $scope.restoreOpts();
    };

    $scope.selectedCount = 0;

    $scope.rebuildIndex = function() {
        $scope.itemListIndex = {};
        $scope.itemLists.forEach(function(e) {
            $scope.itemListIndex[e.id] = e;
        });
    };
    $scope.rebuildIndex();

    $scope.reload = function() {
        $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL);
        kwApi.ItemList.query({view: 'details', start: 0, sort: null, reverse: null}).$promise.then(function(rv) {
            $scope.itemLists = rv;
            $scope.rebuildIndex();
        });
    };

    $scope.$on('sys.signal.BatchDeleteItems', function(evnt, args) { 
        $scope.reload();
    });
    $scope.$on('sys.signal.BatchModifyItems', function(evnt, args) { 
        $scope.reload();
    });

    $scope.delete = function(itemList) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this item list? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.ItemList.delete({id: itemList.id}).$promise.then(function(rv) {
                    alertService.add({msg: "Item list deleted"});
                    $scope.selectedCount = 0;
                    $scope.selectedList = null;
                    $scope.reload();
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                    $scope.reload();
                });
            }
        });
    };

    $scope.createFromEntry = function(itemList,keyField) {
        $scope._createFromEntry(itemList,keyField).then(function() {
            $scope.reload();
        });
    };

    $scope.createFromSearch = function(itemList) {
        $scope._createFromSearch(itemList).then(function() {
            $scope.reload();
        });
    };

    $scope.createFromUpload = function(itemList) {
        $scope._createFromUpload(itemList).then(function() {
            $scope.reload();
        });
    };

    $scope.mutateItems = function(itemList,op) {
        $scope._mutateItems(itemList,op).then(function(rv) {
            $scope.reload();
        });
    };
}])

.controller('StaffBimDetailsCtrl', ["$scope", "itemList", "itemListEntries", "kwApi", "alertService", "kohaDlg", "loading", function ($scope, itemList, itemListEntries, kwApi, alertService, kohaDlg, loading) {
    $scope.itemList = itemList;
    $scope.itemListEntries = itemListEntries;
    $scope.totalRows = ($scope.itemListEntries[0]||{})._embed_total_rows||0;
    $scope.selected = {};
    $scope.selectedCount = 0;

    $scope.bimItemFieldsTmpl = '/app/static/partials/components/bim-item-fields.html';
    for (var i = 0; i<itemListEntries.length; i++) {
        var entry = itemListEntries[i];
        if (entry.item) 
            entry.item_as_array = Object.keys(entry.item).map(function(key) {return [key, entry.item[key]] });
        else
            entry.item_as_array = [["Status","Deleted"]];
    
    }

    $scope.order = {field: 'title', reverse: false};
    $scope.currentPage = 1;

    $scope.$watch('selected', function(newVal) {
        if (newVal) {
            $scope.selectedCount = 0;
            angular.forEach(newVal, function(val,key) {
                if (val) $scope.selectedCount++;
            });
        }
    }, true);

/*    $scope.rebuildIndex = function() {
        $scope.itemListIndex = {};
        $scope.itemLists.forEach(function(e) {
            $scope.itemListIndex[e.id] = e;
        });
    };
    $scope.rebuildIndex();*/

    $scope.reload = function() {
        var start = ($scope.currentPage -1) * 20;
        kwApi.ItemList.get({id: itemList.id}).$promise.then(function(rv) {
            $scope.itemList = rv;
        });
        kwApi.ItemListEntry.query({item_list_id: itemList.id, view: 'details', start: start, limit: 20, sort: $scope.order.field, reverse: ($scope.order.reverse ? 1 : 0)}).$promise.then(function(rv) {
            $scope.itemListEntries = rv;
        });
        $scope.totalRows = $scope.itemListEntries[0]._embed_total_rows;
        //$scope.rebuildIndex();
    };

    $scope.pageChanged = function() {
        $scope.reload();
    };

    $scope.sortChanged = function() {
        $scope.currentPage = 1;
        $scope.reload();
    };

    $scope.$on('sys.signal.BatchDeleteItems', function(evnt, args) { 
        $scope.reload();
    });
    $scope.$on('sys.signal.BatchModifyItems', function(evnt, args) { 
        $scope.reload();
    });

    $scope.deleteAll = function() {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete these entries? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            var ids = [];
            angular.forEach($scope.selected, function(val, key) {
                if (val) ids.push(key);
            });

            loading.add();
            kwApi.ItemListEntry.forAll({action: 'delete', ids: ids}).$promise.then(function(rv) {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err, 'Delete failed');
                $scope.reload();
            });
        });
    };

    $scope.getItemListEntrySlug = function(i) {
        if (i.metadata) {
            if (i.metadata.state == 'skipped') {
                return 'skip (' + Object.keys(i.metadata.reason).join(',') + ')';
            }
            else if (i.metadata.state == 'exception') {
                return 'error (' + i.metadata.reason + ')';
            }
            else if (i.metadata.state == 'redundant') {
                return 'dup';
            }
            else if (i.metadata.state == 'processed') {
                return i.metadata.reason;
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    };


    $scope.mutateItems = function(itemList,op) {
        var ids = [];
        angular.forEach($scope.selected, function(val, key) {
            if (val) ids.push(key);
        });
        $scope._mutateItems(itemList,op,ids).then(function(rv) {
            $scope.reload();
        });
    };

    $scope.createFromEntry = function(itemList,keyField) {
        $scope._createFromEntry(itemList,keyField).then(function() {
            $scope.reload();
        });
    };

    $scope.createFromSearch = function(itemList) {
        $scope._createFromSearch(itemList).then(function() {
            $scope.reload();
        });
    };

    $scope.createFromUpload = function(itemList) {
        $scope._createFromUpload(itemList).then(function() {
            $scope.reload();
        });
    };
}])

.controller('StaffBimManualEditCtrl', ["$scope", "entries", "itemList", "fixedKeyField", "kwApi", "alertService", function ($scope, entries, itemList, fixedKeyField, kwApi, alertService) {
    $scope.fixedKeyField = fixedKeyField;
    $scope.key = $scope.fixedKeyField || 'barcode';
    $scope.typeName = function() { return ($scope.key == 'barcode' ? 'barcodes' : 'item numbers') };
    $scope.itemList = itemList;
    $scope.entries = entries;
    $scope.entryText = entries.join("\n");
    $scope.skipInvalid = 1;
    $scope.op = 'equals';

    $scope.save = function() {
        $scope.itemList.entries = $scope.entryText.split("\n");
        kwApi.ItemList.saveWithEntries({
            key: $scope.key,
            'skip-invalid': $scope.skipInvalid,
            'append': ($scope.fixedKeyField ? 0 : 1),
        }, $scope.itemList).$promise.then(function(rv) {
            //alertService.add({msg: "Item list deleted"});
            $scope.$close();
        }, function(err) {
            alertService.addApiError(err, 'Save failed');
        });
    };

    $scope.validate = function() {
        $scope.itemList.entries = $scope.entryText.split("\n");
        kwApi.ItemList.validateEntries({key: $scope.key},$scope.itemList.entries).$promise.then(function(rv) {
            $scope.itemList.entries = rv;
            $scope.entryText = rv.join("\n");
        }, function(err) {
            alertService.addApiError(err, 'Validate failed');
        });
    };
}])

.controller('StaffBimAttributeSearchCtrl', ["$scope", "itemList", "kwApi", "configService", "alertService", function ($scope, itemList, kwApi, configService, alertService) {
    $scope.itemFields = configService.ItemFields.sort(function(a,b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0) });
    $scope.itemList = itemList;
    $scope.op = 'equals';
    $scope.item = {};       // Dummy item for koha-item-data-input
    $scope.field = {};

    $scope.save = function() {
        console.dir($scope);
        $scope.itemList._search = {field: $scope.field.code, op: $scope.op};
        if ($scope.allowArbitrary)
            $scope.itemList._search.value = $scope.value;
        else if ($scope.field.custom)
            $scope.itemList._search.value = $scope.item.fields[$scope.field.code];
        else
            $scope.itemList._search.value = $scope.item[$scope.field.code];

        kwApi.ItemList.saveFromSearch({},$scope.itemList).$promise.then(function(rv) {
            $scope.$close();
        }, function(err) {
            alertService.addApiError(err, 'Search failed');
        });
    };
}])


.controller('StaffBimMutateItemsCtrl', ["$scope", "operation", "selectedItems", "itemList", "kwApi", "configService", "alertService", "itemStatuses", function ($scope, operation, selectedItems, itemList, kwApi, configService, alertService, itemStatuses) {
    $scope.itemFields = angular.copy(configService.ItemFields.sort(function(a,b) { return (a.label < b.label ? -1 : a.label > b.label ? 1 : 0) })).filter(function(x) {
        if (x.code === 'biblionumber') return false;
        if (x.code === 'biblioitemnumber') return false;
        if (x.code === 'itemnumber') return false;
        if (x.code === 'datelastseen') return false;
        if (x.code === 'barcode') return false;
        if (x.code === 'uuid') return false;
        if (x.code === '_availability') return false;
        if (x.code === 'cn_sort') return false;
        return true;
    });
    $scope.itemFields.unshift({code: '_itemstatus', label: 'Custom Item Status'});

    $scope.itemStatuses = itemStatuses;

    $scope.itemList = itemList;
    $scope.item = {};
    $scope.async = 1;
    $scope.modItemFields = [];
    $scope.constraints = {};
    $scope.operation = operation;
    if (selectedItems && selectedItems.length) {
        $scope.itemCount = '' + selectedItems.length + ' Selected';
        $scope.selectedItems = selectedItems;
    }
    else {
        $scope.itemCount = 'All';
    }

    var itemFieldIndex = {};
    $scope.itemFields.forEach(function(r) {
        itemFieldIndex[r.code] = r;
    });


    if (itemList.metadata) {
        var settings = itemList.metadata[$scope.operation] || {};

        $scope.constraints = settings.constraints || {};
        $scope.delete_title = settings.delete_title || '';
        $scope.item = {};
        $scope.modItemFields = settings.mod_item || [];
        $scope.modItemFields.forEach(function(f) {
            if (f._action == 'set' || f._action == 'default') {
                $scope.item[f.code] = f.value;
            }
        });
    }

    $scope.itemFieldAdd = function(newField) {
        if (newField) {
            $scope.itemFields.forEach(function(f) {
                if (f.code == newField) {
                    $scope.modItemFields.push(angular.extend({}, f, {
                        custom: false, 
                        _action: (f.code == '_itemstatus' ? 'addstatus' : 'set'),
                        _itemstatus: (f.code == '_itemstatus'),
                    }));
                    $scope.item[f.code] = '';
                }
            });
            $scope.newItemField = undefined;
        }
    };

    $scope.$watch('newItemField', function(newVal) {
        if (newVal) {
            $scope.itemFieldAdd($scope.newItemField);
        }
    });

    $scope.itemFieldDel = function(idx) {
        delete $scope.item[$scope.modItemFields[idx].code]; 
        $scope.modItemFields.splice(idx,1);
    };

    $scope.process = function() {
        $scope.modItemFields.forEach(function(mf) {
            if (mf._action == 'set' || mf._action == 'default') { 
                mf.value = $scope.item[mf.code];
            }
        });

        var method = (operation == 'delete' ? 'deleteItems' : 'modifyItems');
        kwApi.ItemList[method]({
            id: itemList.id,
            async: $scope.async,
        },{
            mod_item: $scope.modItemFields,
            constraints: $scope.constraints,
            delete_title: $scope.delete_title,
            selected_items: $scope.selectedItems,
        }).$promise.then(function() {
            alertService.add({msg: ($scope.async ? "Process started, you will be notified when it is finished" : "Process completed")});
            $scope.$close();
        }, function(err) {
            alertService.addApiError(err, ($scope.async ? "Unable to start process" : "Unable to process items"));
        });
    };

    $scope.save = function() {
        $scope.modItemFields.forEach(function(mf) {
            if (mf._action == 'set' || mf._action == 'default') { 
                mf.value = $scope.item[mf.code];
            }
        });
        kwApi.ItemList.saveMetadata({
            id: itemList.id,
            key: $scope.operation,
        },{
            mod_item: $scope.modItemFields,
            constraints: $scope.constraints,
            delete_title: $scope.delete_title,
        }).$promise.then(function() {
            alertService.add({msg: "Settings saved"});
        }, function(err) {
            alertService.addApiError(err, 'Unable to save');
        });
    };
}])


// Message Template Editor
.controller('StaffMteCtrl', ["$scope", "$state", "userService", function ($scope, $state, userService) {
    if ($state.current.name == 'staff.tools.mte') {
        $state.go('staff.tools.mte.index');
    }

    $scope.userCan = {
        templates: userService.can({tools: {edit_notices: 'update'}}),
        branches: userService.can({tools: {edit_notices: 'branch_assign'}}, '*'),
    };

}])

.controller('StaffMteIndexCtrl', ["$scope", "$state", "$stateParams", "templates", "branches", "userService", "$uibModal", "alertService", "kwApi", "configService", "loading", "kohaDlg", function ($scope, $state, $stateParams, templates, branches, userService, $uibModal, alertService, kwApi, configService, loading, kohaDlg) {
    var filteredBranches = $scope.branches = branches.filter(function(branch) {
        return userService.can({tools: {edit_notices: 'branch_assign'}}, "branch=" + branch.branchcode) ||
            (branch.branchcode == userService.login_branch && userService.can({tools: {edit_notices: 'branch_assign'}}, 'branch=my_own_branch'));
    }).sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });


    var hasBranch = {};
    filteredBranches.forEach(function(b) {
        hasBranch[b.branchcode] = true;
    });

    $scope.mode = $stateParams.mode || ($scope.userCan.templates ? 'templates' : 'branch');
    $scope.branch = $stateParams.branch || userService.login_branch;
    $scope.order = {};

    $scope.endpointTypes = [
        {code: 'popup', name: 'Popup'},
        {code: 'list', name: 'List'},
        {code: 'rss', name: 'RSS'},
        {code: 'email', name: 'Email'},
    ];

    if (configService.TalkingTechText) {
        $scope.endpointTypes.push({code: 'tttxt', name: 'TTtext'});
        $scope.TalkingTech = true;
    }

    if (configService.TalkingTechVoice) {
        $scope.endpointTypes.push({code: 'ttvox', name: 'TTvoice'});
        $scope.TalkingTech = true;
    }

    $scope.collapsed = {};
    $scope.flat = false;

    $scope.setMode = function(mode) {
        if (mode == 'own-branch') {
            $state.go('staff.tools.mte.index',{mode:'branch', branch:userService.login_branch});
        }
        else if (mode == 'any-branch') {
            $uibModal.open({
                backdrop: true,
                size: 'sm',
                templateUrl: '/app/static/partials/staff/tools/mte/branch-select-modal.html',
                controller: ["$scope", function($scope) {
                    $scope.branches = filteredBranches;
                    $scope.$watch('sel', function(newVal) {
                        if (newVal) {
                            $scope.$close(newVal);
                        }
                    });
                }],
            }).result.then(function(v) {
                if (v) {
                    $state.go('staff.tools.mte.index',{mode:'branch', branch:v});
                }
            });
        }
        else {
            $state.go('staff.tools.mte.index',{mode:mode});
        }
    };

    $scope.collapseAll = function() {
        $scope.flat = false;
        $scope.displayTemplates = $scope.templates;
        $scope.templates.forEach(function(tmpl) {
            $scope.collapsed[tmpl.category] = true;
        });
    };

    $scope.expandAll = function() {
        $scope.flat = false;
        $scope.displayTemplates = $scope.templates;
        $scope.templates.forEach(function(tmpl) {
            $scope.collapsed[tmpl.category] = false;
        });
    };

    $scope.viewFlat = function() {
        $scope.flat = true;
        $scope.displayTemplates = $scope.uncategorizedTemplates;
    };



    $scope.totalCols = 7 + $scope.endpointTypes.length;

    $scope.processTemplates = function(tmpl) {
        $scope.branchTemplates = {};
        $scope.availableTemplates = {};
        $scope.typeIndex = {};
        $scope.codeIndex = {};
        $scope.allTemplates = [];

        var allFlatTemplates = [];
        var semiFlat = [];
        var totalCategories = 0;
        tmpl.forEach(function(c) {
            if (!userService.can({tools: {edit_notices: 'access'}}, "msgcat=" + c.category)) return;
            totalCategories++;

            var flatTemplates = [];
            c.types.forEach(function(mt) {
                mt.id = mt.type.id;
                var branchTemplates = {};
                var availableTemplates = [];
                var templateCodeBranches = {};

                mt.branch_message_templates.forEach(function(bmt) {
                    branchTemplates[bmt.branch_code] = bmt.template_code;
                    if (!templateCodeBranches[bmt.template_code])
                        templateCodeBranches[bmt.template_code] = [];
                    templateCodeBranches[bmt.template_code].push(bmt.branch_code);
                });

                var msgType = mt.type;
                $scope.typeIndex[mt.id] = mt;
                mt.template_codes.forEach(function(templateSet) {
                    var canEdit = $scope.userCan.templates || (templateSet.owning_branch && hasBranch[templateSet.owning_branch]);
                    if (templateSet.owning_branch && !canEdit)
                        return;

                    var branchesList = (templateCodeBranches[templateSet.code] || [])
                        .filter(function(b) { return hasBranch[b] })
                        .join(', ');

                    $scope.allTemplates.push(templateSet);
                    availableTemplates.push({code: templateSet.code, name: templateSet.code});
                    var row = angular.extend({}, mt.type, {
                        templateCode: templateSet.code,
                        templateSet: templateSet.templates,
                        branches: branchesList,
                        canEdit: canEdit,
                        owningBranch: templateSet.owning_branch,
                        //branchTemplates: branchTemplates,
                        //availableTemplates: availableTemplates,
                        isPrimary: (!templateSet.code || (mt.type.code == templateSet.code)),
                    });
                    flatTemplates.push(row);
                    allFlatTemplates.push(row);
                    $scope.codeIndex[templateSet.code] = row;
                });

                $scope.branchTemplates[mt.type.id] = branchTemplates || {};
                if ($scope.userCan.branches) {
                    availableTemplates.push({code: '', name: 'New...'});
                }
                $scope.availableTemplates[mt.type.id] = availableTemplates;
            });
            flatTemplates = flatTemplates.sort(function(a,b) {
                if (a.code < b.code) return -1;
                if (a.code > b.code) return 1;
                if (a.isPrimary && !b.isPrimary) return -1;
                if (!a.isPrimary && b.isPrimary) return 1;
                if (a.templateCode < b.templateCode) return -1;
                if (a.templateCode > b.templateCode) return 1;
            });
            semiFlat.push({category: c.category, templates: flatTemplates});
            if (!$scope.order[c.category]) {
                $scope.order[c.category] = {};
            }
        });
        allFlatTemplates = allFlatTemplates.sort(function(a,b) {
            if (a.category < b.category) return -1;
            if (a.category > b.category) return 1;
            if (a.code < b.code) return -1;
            if (a.code > b.code) return 1;
            if (a.isPrimary && !b.isPrimary) return -1;
            if (!a.isPrimary && b.isPrimary) return 1;
            if (a.templateCode < b.templateCode) return -1;
            if (a.templateCode > b.templateCode) return 1;
        });

        $scope.templates = semiFlat;
        $scope.uncategorizedTemplates = [{category: 'All Templates', templates: allFlatTemplates}];
        if (!$scope.order['All Templates']) {
            $scope.order['All Templates'] = {};
        }
        $scope.displayTemplates = ($scope.flat ? $scope.uncategorizedTemplates : $scope.templates);
        if (totalCategories > 1) {
            $scope.userCan.multicat = true;
        }
    };

    $scope.processTemplates(templates);

    $scope.$watch('branchTemplates', function(newVal, oldVal) {
        if (!newVal && oldVal) return;
        angular.forEach(newVal, function(branches, typeId) {
            angular.forEach(branches, function(templateCode, branchCode) {
                if (oldVal[typeId][branchCode] != newVal[typeId][branchCode]) {
                    if (newVal[typeId][branchCode] === '') {
                        $scope.addTemplate($scope.typeIndex[typeId].type,branchCode);
                    }
                    else {
                        loading.add();
                        kwApi.MessageType.setBranchTemplate({id: typeId},{branch_code: branchCode, template_code: newVal[typeId][branchCode]}).$promise.then(function(rv) {
                            loading.resolve();
                        }, function(err) {
                            loading.resolve();
                            alertService.addApiError(err, 'Unable to save');
                            $state.go('staff.tools.mte.index', {mode:$scope.mode, branch: $scope.branch}, {reload: true});
                        });
                    }
                }
            });
        });
    }, true);

    $scope.currentPage = 1;

    $scope.addTemplate = function(mt, applyToBranch) {
        var cloned = angular.copy(mt);
        delete cloned['templateSet'];
        $scope.editTemplate(cloned, applyToBranch).then(function(newTemplateSet) {
            var templateCode;
            angular.forEach(newTemplateSet, function(val, key) {
                if (val.template_code)
                    templateCode = val.template_code;
            });

            if (applyToBranch && templateCode) {
                loading.add();
                kwApi.MessageType.setBranchTemplate({id: mt.id},{branch_code: applyToBranch, template_code: templateCode}).$promise.then(function(rv) {
                    return kwApi.MessageTemplate.tree().$promise;
                }).then(function(rv) {
                    $scope.processTemplates(rv);
                    loading.resolve();
                }, function(err) {
                    loading.resolve();
                    alertService.addApiError(err, 'Unable to save');
                    $state.go('staff.tools.mte.index', {mode:$scope.mode, branch: $scope.branch}, {reload: true});
                });
            }
            else {
                $state.go('staff.tools.mte.index', {mode:$scope.mode, branch: $scope.branch}, {reload: true});
            }
        });
    };

    $scope.editTemplate = function(mt, applyToBranch) {
        return $uibModal.open({
            backdrop: 'static',
            size: 'lg',
            templateUrl: '/app/static/partials/staff/tools/mte/edit-template-modal.html',
            controller: 'StaffMteEditTemplateCtrl',
            resolve: {
                allTemplates: function() {
                    return $scope.allTemplates;
                },
                branch: function() {
                    return applyToBranch;
                },
                branches: function() {
                    return $scope.branches;
                },
                messageType: function() {
                    return mt;
                },
                templates: function() {
                    return mt.templateSet;
                },
                endpointTypes: function() {
                    return $scope.endpointTypes;
                },
                typeDef: function() {
                    return kwApi.MessageType.getWithParameters({id: mt.id}).$promise;
                },
                templateCodes: function() {
                    return kwApi.MessageTemplate.getTemplateCodes().$promise;
                },

            },
        }).result;
    };

    $scope.deleteTemplate = function(mt) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete these templates? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;

            var ids = [];
            angular.forEach(mt.templateSet, function(val, key) {
                if (val) {
                    ids.push(val.id);
                }
            });

            loading.add();
            kwApi.MessageTemplate.forAll({action: 'delete', ids: ids}).$promise.then(function(rv) {
                return kwApi.MessageTemplate.tree().$promise;
            }).then(function(rv) {
                $scope.processTemplates(rv);
                loading.resolve();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err, 'Delete failed');
            });
        });
    };
}])

.controller('StaffMteEditTemplateCtrl', ["$scope", "branch", "branches", "messageType", "templates", "endpointTypes", "allTemplates", "templateCodes", "typeDef", "alertService", "kwApi", "loading", "userService", function ($scope, branch, branches, messageType, templates, endpointTypes, allTemplates, templateCodes, typeDef, alertService, kwApi, loading, userService) {
    $scope.applyToBranch = branch;
    $scope.branches = branches.sort(function(a,b) {
        return (a.branchname < b.branchname ? -1 : a.branchname > b.branchname ? 1 : 0)
    });
    $scope.allTemplates = allTemplates;
    $scope.parameters = typeDef.parameters;
    $scope.endpointTypes = endpointTypes;
    $scope.messageType = messageType;
    if (templates) {
        $scope.isNew = false;
        $scope.template_code = '';
        angular.forEach(templates, function(val,key) {
            if (val.template_code)
                $scope.template_code = val.template_code;
        });
        $scope.owning_branch = messageType.owningBranch;
    }
    else {
        $scope.isNew = true;
        $scope.template_code = (branch ? (messageType.code + '.' + branch) : '');
        $scope.owning_branch = branch || userService.login_branch;
    }
    $scope.template = templates || {};
    $scope.d = {type: 'popup'};

    $scope.existingTemplateCode = {};
    templateCodes.forEach(function(t) {
        $scope.existingTemplateCode[t] = true;
    });

    $scope.endpointTypes.forEach(function(type) {
        if (!$scope.template[type.code]) $scope.template[type.code] = {};
        if (!$scope.template[type.code].content) $scope.template[type.code].content = '';
        if (!$scope.template[type.code].name) $scope.template[type.code].name = '';
    });

    $scope.validType = {};

    $scope.typeClass = function(type) {
        if (!$scope.template[type]) {
            $scope.validType[type] = true;
            return 'btn-outline-secondary';
        }
        else if ($scope.template[type].name && $scope.template[type].content) {
            $scope.validType[type] = true;
            return 'btn-outline-primary';
        }
        else if ($scope.template[type].name || $scope.template[type].content) {
            $scope.validType[type] = false;
            return 'btn-outline-danger';
        }
        else {
            $scope.validType[type] = true;
            return 'btn-outline-secondary';
        }
    };

    $scope.formValid = function() {
        if (!$scope.template_code) return false;
        if ($scope.isNew && $scope.existingTemplateCode[$scope.template_code]) return false;

        var rv = true;
        angular.forEach($scope.validType, function(val) {
            if (val === false) {
                rv = false;
            }
        });
        return rv;
    };

    $scope.clear = function(type) {
        $scope.template[type].name = '';
        $scope.template[type].title = '';
        $scope.template[type].content = '';
    };

    $scope.copyFrom = function(p) {
        if (!$scope.template[p])
            return;
        if (!$scope.template[$scope.d.type])
            $scope.template[$scope.d.type] = {};
        $scope.template[$scope.d.type].name = $scope.template[p].name;
        $scope.template[$scope.d.type].title = $scope.template[p].title;
        $scope.template[$scope.d.type].content = $scope.template[p].content;
    };

    $scope.copyFromTemplate = function(p) {
        $scope.template = p.templates;
    };

    $scope.addParameter = function() {

        if (!$scope.parameter.length) return;
        var selections = $scope.parameter.map(function(p) { return '[% ' + p + ' %]'; });

        var form = document.form;
        if (!form) {
            $scope.template[$scope.d.type].content += selections.join(', ');
        }
        else {
            var content = form.content;
            if (content.selectionStart || content.selectionStart == "0") {
                $scope.template[$scope.d.type].content = content.value.substring(0,content.selectionStart)
                    + selections.join(', ')
                    + content.value.substring(content.selectionEnd, content.value.length);
            }
            else {
                $scope.template[$scope.d.type].content += selections.join(', ');
            }
        }
    };

    $scope.save = function() {
        Object.keys($scope.template).forEach(function(key) {
            if (!$scope.template[key].name) {
                delete $scope.template[key];
            }
            else {
                $scope.template[key].message_endpoint_type = key;
                $scope.template[key].template_code = $scope.template_code;
                $scope.template[key].owning_branch = $scope.owning_branch;
            }
        });

        loading.add();
        kwApi.MessageType.replaceTemplateSet({id: $scope.messageType.id}, $scope.template).$promise.then(function(rv) {
            loading.resolve();
            $scope.$close($scope.template);
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err, 'Unable to save');
        });
    };

}])


.controller('StaffCronCtrl', ["$scope", "$state", "cronScripts", function ($scope, $state, cronScripts) {
    $scope.cronScripts = cronScripts;
    if ($state.current.name == 'staff.admin.cron') {
        $state.go('staff.admin.cron.index');
    }


}])

.controller('StaffCronIndexCtrl', ["$scope", "cronJobs", "alertService", "kwApi", "loading", "kohaDlg", function ($scope, cronJobs, alertService, kwApi, loading, kohaDlg) {
    $scope.cronJobs = cronJobs;

    $scope.reload = function() {
        loading.add();
        kwApi.CronJob.query().$promise.then(function(rv) {
            loading.resolve();
            $scope.cronJobs = rv;
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t reload');
        });
    };

    $scope.delete = function(n) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this cron script entry? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            kwApi.CronJob.delete({id: n}).$promise.then(function(rv) {
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Can\'t delete');
            });
        });
    };

    $scope.order = {};

}])

.controller('StaffCronDetailsCtrl', ["$scope", "$state", "$stateParams", "cronJob", "cronJobRuns", "alertService", "kwApi", "loading", function ($scope, $state, $stateParams, cronJob, cronJobRuns, alertService, kwApi, loading) {
    $scope.cronJob = cronJob;
    $scope.runs = cronJobRuns;
    $scope.order = {field: 'created_time', reverse: true};
    $scope.isActive = (cronJob.schedule ? true : false);
    $scope.saveStdout = (cronJob.save_stdout>0 ? true : false);
    $scope.saveStderr = (cronJob.save_stderr>0 ? true : false);
    $scope.saveStderrLevel = cronJob.save_stderr_level || 'warning';
    $scope.notifyStderrLevel = cronJob.notify_stderr_level || 'off';
    $scope.maxRuntime = cronJob.max_runtime || 0;
    if ($scope.cronJob.purge_after === null)
        $scope.cronJob.purge_after = '';

    if (!$scope.cronJob.id) {
        $scope.$watch('cronJob.cron_script_id', function(newVal) {
            if (!newVal) return;
            $scope.cronScripts.forEach(function(s) {
                if (s.id != newVal) return;
                $scope.cronJob.args = s.default_args;
                $scope.cronJob.schedule = s.default_schedule;
                $scope.cronJob.description = s.description;
            });
        });
    }

    $scope.$watch('isActive', function(newVal) {
        if (newVal && $scope.cronJob.schedule === null)
            if ($scope.cronJob.old_schedule)
                $scope.cronJob.schedule = $scope.cronJob.old_schedule;
            else
                $scope.cronJob.schedule = '0:0:0:1*0:0:0';
    });


    $scope.reload = function() {
        loading.add();
        kwApi.CronJob.get({id: $scope.cronJob.id}).$promise.then(function(rv) {
            $scope.cronJob = rv;
            $scope.isActive = ($scope.cronJob.schedule ? true : false);
            $scope.saveStdout = ($scope.cronJob.save_stdout>0 ? true : false);
            $scope.saveStderr = ($scope.cronJob.save_stderr>0 ? true : false);
            $scope.saveStderrLevel = $scope.cronJob.save_stderr_level || 'warning';
            $scope.notifyStderrLevel = $scope.cronJob.notify_stderr_level || 'off';
            $scope.maxRuntime = $scope.cronJob.max_runtime || 0;
            if ($scope.cronJob.purge_after === null)
                $scope.cronJob.purge_after = '';
            loading.resolve();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t reload');
        });

        loading.add();
        kwApi.CronJob.getRuns({id: $stateParams.id}).$promise.then(function(rv) {
            loading.resolve();
            $scope.runs = rv;
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t reload');
        });
    }

    $scope.save = function() {
        if (!$scope.isActive) {
            if ($scope.cronJob.schedule)
                $scope.cronJob.old_schedule = $scope.cronJob.schedule;
            $scope.cronJob.schedule = null;
        }
        $scope.cronJob.save_stdout = ($scope.saveStdout ? 1 : 0);
        $scope.cronJob.save_stderr = ($scope.saveStderr ? 1 : 0);
        $scope.cronJob.save_stderr_level = $scope.saveStderrLevel;
        $scope.cronJob.notify_stderr_level = $scope.notifyStderrLevel;
        $scope.cronJob.max_runtime = $scope.maxRuntime;
        if (!($scope.cronJob.purge_after === '0' || (1*$scope.cronJob.purge_after) > 0))
            $scope.cronJob.purge_after = null;

        loading.add();
        if ($scope.cronJob.id) {
            kwApi.CronJob.put({id: $scope.cronJob.id}, $scope.cronJob).$promise.then(function() {
                loading.resolve();
                $scope.reload();
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Can\'t save');
            });
        }
        else {
            kwApi.CronJob.save($scope.cronJob).$promise.then(function(rv) {
                loading.resolve();
                $state.go('staff.admin.cron.view', {id: rv.id});
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Can\'t save');
            });
        }
    };


    $scope.run = function() {
        loading.add();
        kwApi.CronJob.put({id: $scope.cronJob.id}, $scope.cronJob).$promise.then(function() {
            return kwApi.CronJob.execute({id: $scope.cronJob.id},{}).$promise;
        }).then(function(rv) {
            loading.resolve();
            alertService.add({msg: "Job started; you will be notified when complete", type: "info"});
            $scope.reload();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Can\'t run');
        });
    };

    $scope.remove_run = function(n) {
        loading.add();
        kwApi.CronJob.deleteRun({id: $scope.cronJob.id, rid: n.id}).$promise.then(function() {
            loading.resolve();
            $scope.reload();
        }, function(err) {
            loading.resolve();
            alertService.addApiError(err,'Remove failed');
        });
    };

    $scope.isValid = function() {
        $scope.err = '';
        if (!$scope.cronJob.cron_script_id) {
            $scope.err = 'Script is required';
            return false;
        }
        if ($scope.isActive && !$scope.cronJob.schedule) {
            $scope.err = 'Schedule is required';
            return false;
        }

        if ($scope.cronJob.args.filter(function(s) { return s === '' }).length != 0) {
            $scope.err = 'All arguments must have values';
            return false;
        }

        return true;
    };

    $scope.scriptSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'script',
        searchField: ['script','description'],
        create: false,
        render: {
            item: function(rec, escape) {
                if (!rec) return '';
                return '<div>' + escape(rec.script) + ' - <small>' + escape(rec.description) + '</small></div>';
            },
            option: function(rec, escape) {
                if (!rec) return '';
                return '<div>' + escape(rec.script) + ' - <small>' + escape(rec.description) + '</small></div>';
            },
        },
    };

    $scope.canRemove = function(n) {
        if (n.status == 'started')
            return false;
        else if (n.cron_spec && n.status == 'pending')
            return false;
        else
            return true;
    };


    $scope.$on('sys.signal.CronJob', function(evnt, args) { 
        if (args.cron_job_id == cronJob.id) {
            loading.add();
            kwApi.CronJob.getRuns({id: $stateParams.id}).$promise.then(function(rv) {
                loading.resolve();
                $scope.runs = rv;
            }, function(err) {
                loading.resolve();
                alertService.addApiError(err,'Can\'t reload');
            });
        }
    });

}])

.controller('StaffCronRunDetailsCtrl', ["$scope", "cronJob", "run", function ($scope, cronJob, run) {
    $scope.cronJob = cronJob;
    $scope.run = run;
    $scope.isActive = (cronJob.schedule ? true : false);
    $scope.saveStdout = (cronJob.save_stdout>0 ? true : false);
    $scope.saveStderr = (cronJob.save_stderr>0 ? true : false);

}])

.controller('StaffTraceCtrl', ["$scope", "traceOptions", "$http", "$rootScope", function($scope, traceOptions, $http, $rootScope) {
    $scope.traceOptions = traceOptions;
    $scope.enabledTraceOptions = {};

    $scope.readHeader = function() {
    };

    $scope.setOptions = function(opts) {
        $scope.selectedOptions = '';
        $scope.enabledTraceOptions = {};
        $scope.enabledTraceOptionsList = [];
        if (opts && opts.length) {
            opts.forEach(function(o) {
                $scope.enabledTraceOptions[o] = true;
            });

            $scope.traceOptions.forEach(function(group) {
                group.items.forEach(function(item) {
                    if ($scope.enabledTraceOptions[item.value]) {
                        $scope.enabledTraceOptionsList.push(item.value);
                    }
                });
            });
            $scope.selectedOptions = $scope.enabledTraceOptionsList.join(', ');
        }
    };

    $scope.processTrace = function(t) {
        t.patron = (t.last_name || '?') + ', ' + (t.first_name || '?') + ' (' + t.userid + ')';
        if (t.headers) {
            t.headers = t.headers.split(/[\n\r]+/);
        }
        if (t.data && t.data.exception_reason) {
            t.title = t.data.exception_reason;
        }
        else if (t.data && t.data.stack_brief && t.data.stack_brief[0]) {
            t.title = t.data.stack_brief[0].func;
        }
        else {
            t.title = '';
        }
        return t;
    };

    var opts = $http.defaults.headers.common['X-KohaOX-Trace'];
    if (opts) {
        $scope.setOptions(opts.split(/,\s*/));
    }
    else {
        $scope.setOptions(false);
    }

    $scope.$on('$destroy', $rootScope.$on('setApiTracing', function(evnt, opts) {
        $scope.setOptions(opts);
    }));
}])

.controller('StaffTraceIndexCtrl', ["$scope", "$uibModal", "traces", "kwApi", "userService", "$http", "$rootScope", "$timeout", "alertService", function($scope, $uibModal, traces, kwApi, userService, $http, $rootScope, $timeout, alertService) {
    $scope.order = {field: 'stamp', reverse: true};

    $scope.pager = new KOHA.Pager({numResults: traces.length, offset: 0, numPerPage: 20});
    $scope.page = {start: 0, count: 20};

    $scope.displayTraces = [];

    $scope.refresh = function() {
        // filter by tags
        var displayList = $scope.traces;
        $scope.totalFiltered = displayList.length;
        
        displayList.sort(function(a,b) {
            var ordf = $scope.order.field;
            var n = ($scope.order.reverse ? -1 : 1);
            if (typeof a[ordf] !== 'string') {
                return -n;
            } else if (typeof b[ordf] !== 'string') {
                return n;
            }
            if (a[ordf].toUpperCase() < b[ordf].toUpperCase()) {
                return -n;
            }
            else if (a[ordf].toUpperCase() > b[ordf].toUpperCase()) {
                return n;
            }
            else {
                return 0;
            }
        });
        displayList = displayList.slice($scope.page.start, $scope.page.start + (1*$scope.page.count));
        $scope.displayTraces.replaceWith(displayList);
    };

    $scope.toPage = function(page) {
        $scope.page.start = (page-1) * $scope.page.count;
        $scope.refresh();
    };


    $scope.sortChanged = function() {
        $scope.page.start = 0;
        $scope.pager.page = 1;
        $scope.refresh();
    };

    $scope.$watch('order', function(newVal) {
        if (!newVal) return;
        $scope.sortChanged();
    }, true);


    $scope.processTraces = function(tt) {
        $scope.traces = [];
        tt.forEach(function(t) {
            $scope.traces.push($scope.processTrace(t));
        });
        $scope.pager.numResults = $scope.traces.length;
    };
    $scope.processTraces(traces);

    $scope.canAllMembers = userService.can({tools: {trace: 'other'}});
    $scope.canView = userService.can({tools: {trace: 'other'}}) || userService.can({tools: {trace: 'self'}});
    $scope.canCreate = userService.can({tools: {trace: 'create'}});

    $scope.reload = function() {
        var promise = $scope.allMembers
            ? kwApi.SessionApiMetadata.query().$promise
            : kwApi.SessionApiMetadata.query({member_id: userService.id}).$promise;
        promise.then(function(rv) {
            $scope.processTraces(rv);
            $scope.refresh();
        });
    };

    $scope.setAllMembers = function(n) {
        $scope.allMembers = n ? true : false;
        $scope.reload();
    };

    $scope.genError = function(type,code) {
        if (type.substr(0,8) == 'Frontend') {
            var prevOpts = $rootScope.tracingOptions;
            $rootScope.setApiTracing(['FrontendLog','FrontendWarn','FrontendError']);
            if (type == 'FrontendLog') {
                console.log('This is a test log');
            }
            else if (type == 'FrontendWarn') {
                console.warn('This is a test warning');
            }
            else if (type == 'FrontendError') {
                console.error('This is a test error');
            }
            $rootScope.setApiTracing(prevOpts);
            $timeout(function() { $scope.reload() }, 0);
        }
        else {
            $http.post('/api/session-api-metadata?op=throw&type='+type+'&code='+code, {}, {headers:{'X-KohaOX-Trace':'all'}}).then(function() {
                $scope.reload();
            }, function() {
                $scope.reload();
            });
        }
    };
            
    $scope.toggleOptions = function() {
        if ($scope.enabledTraceOptionsList.length > 0) {
            $rootScope.setApiTracing(false);
        }
        else {
            $uibModal.open({
                backdrop: 'static',
                templateUrl: '/app/static/partials/staff/tools/trace/edit-options-modal.html',
                controller: 'StaffTraceEditOptionsCtrl',
                resolve: {
                    enabledTraceOptions: function() {
                        return $scope.enabledTraceOptions;
                    },
                    traceOptions: function() {
                        return $scope.traceOptions;
                    },
                    forUser: function() {
                        return false;
                    },
                },
            }).result.then(function(s) {
                if (s) {
                    var opts = [];
                    angular.forEach(s.options, function(val, key) {
                        if (val) opts.push(key);
                    });
                    $rootScope.setApiTracing(opts,s.duration);
                }
            });
        }
    };

    $scope.traceUser = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/tools/trace/edit-options-modal.html',
            controller: 'StaffTraceEditOptionsCtrl',
            resolve: {
                enabledTraceOptions: function() {
                    return $scope.enabledTraceOptions;
                },
                traceOptions: function() {
                    return $scope.traceOptions;
                },
                forUser: function() {
                    return true;
                },
            },
        }).result.then(function(s) {
            if (s) {
                var opts = [];
                angular.forEach(s.options, function(val, key) {
                    if (val) opts.push(key);
                });
                if (opts.length == 0)
                    opts = null;
                kwApi.SessionApiMetadata.traceUser({},{
                    user_id: s.user_id,
                    opts: opts,
                    duration: s.duration,
                }).$promise.then(function(rv) {
                    alertService.add({msg: "Tracing will " + (opts ? "start" : "stop") + " on user's next message query", type: "info"});
                }, function(err) {
                    alertService.addApiError(err,'Trace failed');
                });
            }
        });
    };
}])

.controller('StaffTraceEditOptionsCtrl', ["$scope", "enabledTraceOptions", "$filter", "traceOptions", "forUser", "kwApi", function($scope, enabledTraceOptions, $filter, traceOptions, forUser, kwApi) {
    $scope.forUser = forUser;
    $scope.enabledTraceOptions = (forUser ? {} : angular.copy(enabledTraceOptions));
    $scope.traceOptions = traceOptions;
    $scope.duration = '300';

    $scope.patronSelectConfig = {
        load: function(query, callback) {
            kwApi.Patron.query({view: 'picker', searchValue: query}).$promise.then(function(rv) {
                callback(rv);
            }, function(err) {
                callback();
            });
        },
        maxItems: 1,
        loadThrottle: 600,
        valueField: 'borrowernumber',
        labelField: 'firstname',
        searchField: ['firstname','surname'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' (' + branchName + ')</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' (' + branchName + ')</div>';
            }
        }
    };

    $scope.$watch('enabledTraceOptions', function(newVal) {
        if (!newVal) return;
        $scope.disabled = {};
        $scope.allDisabledExcept = '';
        $scope.traceOptions.forEach(function(group) {
            group.items.forEach(function(item) {
                if (!newVal[item.value]) return;
                if (!item.implies) return;
                item.implies.forEach(function(v) {
                    if (v == '*') {
                        $scope.allDisabledExcept = item.value;
                    }
                    else {
                        $scope.disabled[v] = true;
                    }
                });
            });
        });
    }, true);

}])

.controller('StaffTraceViewCtrl', ["$scope", "trace", function($scope, trace) {
    $scope.trace = $scope.processTrace(trace);
    $scope.showData = true;
    $scope.order = {field: 'tot_time', reverse: true};
}])

.controller('StaffAdminCircBaseCtrl', ["$scope", "$state", "userService", "configService", function($scope, $state, userService, configService) {
    $scope.hdr = {
        title: 'Circ Admin',
        save: function() { ; },
        dirty: false,
        groupPolicies: ((configService.BranchGroupPolicies && (configService.BranchGroupPolicies !== null)) ? true : false),
    };

    $scope.setOptions = function() {
        $scope.hdr.state = $state.$current.name.split('.').pop();
    };

    $scope.setOptions();

    $scope.$on('$stateChangeSuccess', function(evnt, toState) {
        $scope.setOptions();
    });

    $scope.isClass = function(s) {
        return ($scope.hdr.state == s) ? 'btn-primary' : 'btn-outline-secondary';
    };

    $scope.permit = {
        all: false,
        branches: [],
        groups: [],
        any: false,
    };

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'display', 
        searchField: ['display'],
        create: false,
    };

    $scope.hdr.branch = userService.login_branch;

    $scope.getPermissions = function() {
        $scope.permit.all = userService.can({parameters: 'circ_policy'});

        userService.getAccessibleBranchesAndGroups('parameters.circ_policy').then(function(b) {
            $scope.permit.branches = b.branches;
            $scope.permit.groups = b.groups;
            $scope.permit.any = (b.branches.length>0);

            $scope.hdr.branches = [];
            Array.prototype.push.apply(
                $scope.hdr.branches,
                $scope.permit.branches.map(function(b) {
                    return { id: b.branchcode, display: b.branchname }
                }).sort(function(a,b) {
                    return (a.display < b.display) ? -1 :
                        (a.display > b.display) ? 1 : 0;
                })
            );
            if ($scope.hdr.groupPolicies) {
                Array.prototype.push.apply(
                    $scope.hdr.branches,
                    $scope.permit.groups.map(function(c) {
                        return {
                            id: ('[BranchGroup]'+c.categorycode),
                            display: ('[Group] ' + c.categoryname)
                        }
                    }).sort(function(a,b) {
                    return (a.display < b.display) ? -1 :
                        (a.display > b.display) ? 1 : 0;
                })
                );
            }

            if ($scope.permit.all) {
                $scope.hdr.branches.push({id: '==DEFAULT==', display: 'Default'});
                $scope.hdr.branches.push({id: '', display: 'All'});
            }
        });
    };

    $scope.getPermissions();
    $scope.$on('userSetBranch', $scope.getPermissions);

    $scope.hdr.getSearchArgs = function(baseArgs) {
        var args = angular.extend({}, (baseArgs || {}));
        if ($scope.hdr.branch === '==DEFAULT==') {
            args.default = 1;
        }
        else if (!$scope.hdr.branch || ($scope.hdr.branch == '')) {
            ;
        }
        else if ($scope.hdr.branch.substr(0,13) == "[BranchGroup]") {
            args.branch_group = $scope.hdr.branch.substr(13);
        }
        else {
            args.branch_code = $scope.hdr.branch;
        }
        return args;
    };

    $scope.hdr.getBranchCode = function() {
        if ($scope.hdr.branch === '==DEFAULT==') {
            return null;
        }
        else if (!$scope.hdr.branch || ($scope.hdr.branch == '')) {
            return null;
        }
        else if ($scope.hdr.branch.substr(0,13) == "[BranchGroup]") {
            return null;
        }
        else {
            return $scope.hdr.branch;
        }
    };

    $scope.hdr.getBranchGroup = function() {
        if ($scope.hdr.branch === '==DEFAULT==') {
            return null;
        }
        else if (!$scope.hdr.branch || ($scope.hdr.branch == '')) {
            return null;
        }
        else if ($scope.hdr.branch.substr(0,13) == "[BranchGroup]") {
            return $scope.hdr.branch.substr(13);
        }
        else {
            return null;
        }
    };

    
}])

.controller('StaffAdminCircConfigCtrl', ["$scope", "circScopes", "circControl", "kwApi", "loading", "$q", function($scope, circScopes, circControl, kwApi, loading, $q) {
    $scope.circScopes = circScopes;

    $scope.precedenceSelectConfig = {
        valueField: 'value',
        labelField: 'display', 
        searchField: ['display'],
        create: false,
    };

    $scope.hdr.branchSelect = false;
    $scope.hdr.test = false;

    $scope.circControl = [];

    var deregister;
    var register = function() {
        deregister = $scope.$watch('circControl', function(newVal, oldVal) {
            if (!oldVal || angular.equals(newVal,oldVal))
                return;

            for (var i=0; i<newVal.length; i++) {
                if (!angular.equals(newVal[i].precedences, oldVal[i].precedences)
                   || (newVal[i].specified !== oldVal[i].specified)) {
                    newVal[i].dirty = true;
                    $scope.hdr.dirty = true;
                }
            }
        }, true);
    };


    $scope.refresh = function(c) {
        var ccIdx = {};

        var d = angular.copy($scope.circScopes);

        (c||[]).forEach(function(x) {
            x.precedences = x.precedence.split(' ');
            x.specified = true;
            ccIdx[x.scope] = x;
        });

        d.forEach(function(x) {
            x.dirty = false;
            x.required = (x.required===1 || x.required==='1') ? 1 : 0;
            x.precedenceOptions = x.options.map(function(e) {
                return {
                    value: e,
                    display: e.replace(/(.)([A-Z])/g, function(s,a,b) { return a + ' ' + b }),
                }
            });

            if (ccIdx[x.scope]) {
                angular.extend(x, ccIdx[x.scope]);
            }
            else {
                angular.extend(x, {
                    specified: false,
                    precedences: [],
                });
                ccIdx[x.scope] = x;
            }
            if (x.required == 1)
                x.specified = true;
        });

        d.forEach(function(x) {
            if (x.rule_fallback) {
                var r = x.rule_fallback;
                var f = [];

                while (r) {
                    f.push(r);
                    r = ccIdx[r].rule_fallback;
                }
                x.defaults_to = f.join(' then ');
            }
        });

        $scope.circControl.replaceWith(d);
    };

    $scope.reload = function() {
        console.log("Reload");
        deregister();
        kwApi.CircControl.query().$promise.then(function(rv) {
            $scope.refresh(rv);
        });
        register();

    };

    $scope.refresh(circControl);
    register();

    $scope.hdr.save = function() {
        var promises = [];
        $scope.circControl.forEach(function(c) {
            if (c.dirty && (c.id || c.specified)) {
                c.precedence = c.precedences.join(' ') || '';
                promises.push(
                    !c.specified ? kwApi.CircControl.delete({id: c.id}).$promise :
                    c.id ? kwApi.CircControl.put({id: c.id}, c).$promise :
                    kwApi.CircControl.save(c).$promise
                );
            }
        });
        loading.wrap($q.all(promises), "Unable to save circ config").then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };
   
}])

.controller('StaffAdminCircPoliciesCtrl', ["$scope", "$state", "loading", "kwApi", "kohaDlg", function($scope, $state, loading, kwApi, kohaDlg) {

    $scope.policies = [];
    $scope.hdr.branchSelect = true;
    $scope.hdr.branchSelectDisabled = false;
    $scope.hdr.test = false;
    $scope.hdr.save = false;

    $scope.reload = function() {
        var args = $scope.hdr.getSearchArgs();;
        loading.wrap(
            kwApi.CircPolicy.query(args).$promise,
            "Unable to load circ policies"
        ).then(function(rv) {
            rv.forEach(function(rec) {
                try {
                    rec.args = JSON.parse(rec.args);
                }
                catch (e) {
                    ;
                }

                rec.fmt = {};
                if ('maxissueqty' in rec.args) {
                    if (rec.args.maxissueqty === null | rec.args.maxissueqty === undefined) {
                        rec.fmt.maxissueqty = 'Unlimited';
                    }
                    else {
                        rec.fmt.maxissueqty = rec.args.maxissueqty;
                        if (rec.args.maxissueqty_scope) {
                            rec.fmt.maxissueqty = rec.fmt.maxissueqty + ' per ' +
                                (rec.args.maxissueqty_scope||'').split(' ')
                                    .map(function(s) { return s.replace(/_/g,' '); })
                                    .join(',');
                        }
                    }
                }
                if ('issue_length' in rec.args) {
                    rec.fmt.issue_length = rec.args.issue_length + ' ' + rec.args.issue_length_unit;
                }

                if ('recall_interval' in rec.args) {
                    rec.fmt.recall_interval = rec.args.recall_interval + ' ' + rec.args.recall_interval_unit;
                }

                if ('maxrenewals' in rec.args) {
                    if (rec.args.maxrenewals === null || rec.args.maxrenewals === undefined) {
                        rec.fmt.maxrenewals = 'Unlimited';
                    }
                    else {
                        rec.fmt.maxrenewals = rec.args.maxrenewals;
                    }
                }

                if (('overdue_fine' in rec.args) || ('fine_period' in rec.args)) {
                    var p = ['',''];
                    if ('overdue_fine' in rec.args) {
                        p[0] = rec.args.overdue_fine;
                    }
                    if ('fine_period' in rec.args) {
                        p[1] = rec.args.fine_period + ' ' + rec.args.fine_period_unit;
                    }
                    if (!rec.args.skip_fine_grace) {
                        p.push('No grace');
                    }
                    else if ('grace_period' in rec.args) {
                        p.push(rec.args.grace_period + ' ' + rec.args.grace_period_unit + ' ' + 'grace');
                    }

                    rec.fmt.overdue_fine = p.join(' / ');
                }

                if (('rentalcharge' in rec.args) || ('replacement_fee' in rec.args) || ('maxfine' in rec.args)) {
                    var p = [];
                    if ('rentalcharge' in rec.args) {
                        p.push('C/O ' + rec.args.rentalcharge);
                    }
                    if ('replacement_fee' in rec.args) {
                        p.push('Repl ' + rec.args.replacement_fee);
                    }
                    if ('maxfine' in rec.args) {
                        p.push('Max ' + rec.args.maxfine);
                    }
                    rec.fmt.other_fees = p.join(' / ');
                }
                if (rec.args.loan_type == 'hourly') {
                    if (('hourly_incr' in rec.args) || ('allow_overnight' in rec.args) || ('allow_over_closed' in rec.args) || ('overnight_due' in rec.args) || ('overnight_window' in rec.args)) {
                        var p = [];
                        if ('hourly_incr' in rec.args) {
                            p.push('Interval: ' + rec.args.hourly_incr + ' min');
                        }
                        if ('overnight_window' in rec.args) {
                            p.push('Window: ' + rec.args.overnight_window + ' min');
                        }
                        if ('overnight_due' in rec.args) {
                            p.push('Due: ' + rec.args.overnight_due + ' min after open');
                        }
                        if ('allow_overnight' in rec.args) {
                            p.push('Allow overnight');
                        }
                        if ('allow_over_closed' in rec.args) {
                            p.push('Allow over closed dates');
                        }
                        rec.fmt.hourly = p.join(' / ');
                    }
                }

                if ('holdallowed' in rec.args) {
                    if (rec.args.holdallowed === 2 || rec.args.holdallowed === '2') {
                        rec.fmt.holds = 'In branch group';
                    }
                    else if (rec.args.holdallowed === 1 || rec.args.holdallowed === '1') {
                        rec.fmt.holds = 'Branch only';
                    }
                    else {
                        rec.fmt.holds = 'Not allowed';
                    }
                }

                if (('allow_callslip' in rec.args) || ('allow_doc_del' in rec.args) || ('max_callslip' in rec.args) || ('max_doc_del' in rec.args)) {
                    var p = [];
                    if ('allow_callslip' in rec.args) {
                        if (rec.args.allow_callslip === 1 || rec.args.allow_callslip === '1') {
                            if ('max_callslip' in rec.args) {
                                p.push('Callslips allowed (max ' + rec.args.max_callslip + ')');
                            }
                            else {
                                p.push('Callslips allowed');
                            }
                        }
                        else {
                            p.push('No callslips');
                        }
                    }

                    if ('allow_doc_del' in rec.args) {
                        if (rec.args.allow_doc_del === 1 || rec.args.allow_doc_del === '1') {
                            if ('max_doc_del' in rec.args) {
                                p.push('Doc delivery allowed (max ' + rec.args.max_doc_del + ')');
                            }
                            else {
                                p.push('Doc delivery allowed');
                            }
                        }
                        else {
                            p.push('No doc delivery');
                        }
                    }
                    rec.fmt.callslip = p.join(' / ');
                }
            });
            $scope.policies = rv;
        }, function(err) {
            ;
        });
    };

    $scope.reload();

    $scope.$watch('hdr.branch', $scope.reload);

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this policy? This will also delete any circ or recall rules which use it, and CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.CircPolicy.delete({id: p.id}).$promise,
                "Can't delete circ policy"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };

    $scope.edit = function(p) {
        $state.go('staff.admin.circ.policy', {id: p.id});
    };

    $scope.new = function(p) {
        $state.go('staff.admin.circ.policy', {id: 0});
    };
}])

.controller('StaffAdminCircPolicyCtrl', ["$scope", "$state", "policy", "loading", "kwApi", function($scope, $state, policy, loading, kwApi) {
    var ifHourly = function() {
        return (($scope.parameter.loan_type||{}).value === 'hourly');
    };

    $scope.booleanOptions = [
        {display: 'Yes', value: 1},
        {display: 'No', value: 0}
    ];

    $scope.hdr.test = false;

    $scope.parameters = [{
        section: 'Circulation parameters'
    }, {
        description: 'Concurrent checkouts',
        unlimitedIfNull: true,
        variable: 'maxissueqty',
        type: 'number',
        scopeOptions: [
            {value: 'NONE', display: 'Automatic (by rule)'},
            {value: '', display: 'Globally'},
            {value: 'item_type', display: 'Per item type'},
            {value: 'branch_code', display: 'Per branch'},
            {value: 'branch_group', display: 'Per branch group'},
            {value: 'item_type branch_code', display: 'Per item type and branch'},
            {value: 'item_type branch_group', display: 'Per item type and group'},
        ],
        defaultScope: 'NONE',
        class: 'sm',
        scopeClass: 'xl'
    }, {
        description: 'Loan type',
        type: 'select',
        variable: 'loan_type',
        default: 'daily',
        options: [
            {value: 'daily', display: 'Daily'},
            {value: 'hourly', display: 'Hourly'},
        ],
        defaultUnit: 'daily',
        class: 'sm',
    }, {
        description: 'Loan length',
        type: 'number',
        variable: 'issue_length',
        unitOptions: [
            {value: 'days', display: 'days'},
            {value: 'hours', display: 'hours'},
            {value: 'minutes', display: 'minutes'},
        ],
        defaultUnit: 'days',
        class: 'sm',
        unitClass: 'md',
    }, {
        description: 'Recall interval',
        variable: 'recall_interval',
        type: 'number',
        unitOptions: [
            {value: 'days', display: 'days'},
            {value: 'hours', display: 'hours'},
            {value: 'minutes', display: 'minutes'},
        ],
        defaultUnit: 'days',
        class: 'sm',
        unitClass: 'md',
    }, {
        description: 'Max renewals',
        unlimitedIfNull: true,
        variable: 'maxrenewals',
        type: 'number',
        class: 'sm',
    }, {
        description: 'Hold policy',
        variable: 'holdallowed',
        integer: true,
        type: 'select',
        options: [
            {value: 0, display: 'No holds allowed'},
            {value: 1, display: 'From owning library'},
            {value: 2, display: 'From any library in group'},
        ],
        default: 0,
        class: 'lg',
    }, {

        section: 'Fines and charges'
    }, {
        description: 'Overdue fine',
        variable: 'overdue_fine',
        type: 'money',
        class: 'sm',
    }, {
        description: 'Fine charging interval',
        variable: 'fine_period',
        type: 'number',
        unitOptions: [
            {value: 'days', display: 'days'},
            {value: 'hours', display: 'hours'},
            {value: 'minutes', display: 'minutes'},
        ],
        defaultUnit: 'days',
        default: 1,
        class: 'sm',
        unitClass: 'md',
    }, {
        description: 'Fine grace period',
        variable: 'grace_period',
        type: 'number',
        unitOptions: [
            {value: 'days', display: 'days'},
            {value: 'hours', display: 'hours'},
            {value: 'minutes', display: 'minutes'},
        ],
        defaultUnit: 'days',
        class: 'sm',
        unitClass: 'md',
    }, {
        description: 'Skip grace period when calculating overdue fine?',
        variable: 'skip_fine_grace',
        ifFunc: function() { return ($scope.parameter.grace_period||{}).enabled; },
        type: 'select',
        integer: true,
        options: [
            {value: 1, display: 'Yes'},
            {value: 0, display: 'No'}
        ],
        default: 0,
        class: 'sm',
    }, {
        description: 'Checkout fee',
        variable: 'rentalcharge',
        type: 'money',
        class: 'sm',
    }, {
        description: 'Replacement surcharge',
        variable: 'replacement_fee',
        type: 'money',
        class: 'sm',
    }, {
        description: 'Maximum overdue fine',
        variable: 'maxfine',
        type: 'money',
        class: 'sm',
    }, {

        section: 'Callslips'
    }, {
        description: 'Allow call slip requests',
        variable: 'allow_callslip',
        type: 'checkbox',
        class: 'sm',
    }, {
        description: 'Max call slip requests',
        ifFunc: function() { return (('max_callslip' in $scope.policy.args) || (($scope.parameter.allow_callslip||{}).value === 1)); },
        variable: 'max_callslip',
        type: 'number',
        class: 'sm',
    }, {
        description: 'Allow document delivery requests',
        variable: 'allow_doc_del',
        type: 'checkbox',
        class: 'sm',
    }, {
        description: 'Max document delivery requests',
        ifFunc: function() { return (('max_doc_del' in $scope.policy.args) || (($scope.parameter.allow_doc_del||{}).value === 1)); },
        variable: 'max_doc_del',
        type: 'number',
        class: 'sm',
    }, {

        section: 'Hourly loan policies',
        ifFunc: ifHourly,
    }, {
        description: 'Hourly loan interval',
        ifFunc: ifHourly,
        variable: 'hourly_incr',
        type: 'select',
        integer: true,
        options: [
            {display: '10', value: 10},
            {display: '15', value: 15},
            {display: '20', value: 20},
            {display: '30', value: 30},
            {display: '60', value: 60},
            {display: '120', value: 120},
        ],
        default: 60,
        class: 'sm',
    }, {
        description: 'Allow overnight loan',
        ifFunc: ifHourly,
        variable: 'allow_overnight',
        type: 'checkbox',
        class: 'sm',
    }, {
        description: 'Allow over closed days',
        ifFunc: ifHourly,
        variable: 'allow_over_closed',
        type: 'checkbox',
        class: 'sm',
    }, {
        description: 'Time due (minutes after open)',
        ifFunc: ifHourly,
        variable: 'overnight_due',
        type: 'number',
        class: 'sm',
    }, {
        description: 'Overnight window (minutes before close)',
        ifFunc: ifHourly,
        variable: 'overnight_window',
        type: 'number',
        class: 'sm',
    }];

    $scope.parameter = {};
    $scope.parameters.forEach(function(p) {
        $scope.parameter[p.variable] = p;
    });


    $scope.refresh = function(p) {
        $scope.policy = p;
        try {
            $scope.policy.args = JSON.parse($scope.policy.args);
        }
        catch (e) {
            ;
        }

        $scope.parameters.forEach(function(p) {
            p.enabled = false;
        });

        angular.forEach($scope.policy.args, function(val, key) {
            var p = $scope.parameter[key];
            if (!p) return;

            p.enabled = true;
            p.value = val;

            if (p.type === 'checkbox') {
                p.value = (val === 1 || val === '1' || val === true) ? 1 : 0;
            }
            else if (p.integer) {
                p.value = parseInt(val);
            }
            else {
                p.value = val;
            }

            if (p.unlimitedIfNull) {
                p.isLimited = (val === '' || val === null || val === undefined) ? '0' : '1';
            }

            if ($scope.policy.args[key + '_unit']) {
                p.units = $scope.policy.args[key + '_unit'];
            }
            if ($scope.policy.args[key + '_scope']) {
                p.scope = $scope.policy.args[key + '_scope'];
            }
        });
        $scope.parameters.forEach(function(p) {
            if (p.scopeOptions && !p.scope)
                p.scope = 'NONE';
        });
    };

    $scope.reload = function() {
        loading.wrap(
            kwApi.CircPolicy.get({id: $scope.policy.id}).$promise,
            "Unable to load circ policy"
        ).then(function(rv) {
            $scope.refresh(rv);
        }, function(err) {
            ;
        });
    };

    $scope.refresh(policy);

    $scope.hdr.branchSelect = true;
    if ($scope.policy.id) {
        $scope.hdr.branchSelectDisabled = true;
        if ($scope.policy.branch_code) {
            $scope.hdr.branch = $scope.policy.branch_code;
        }
        else if ($scope.policy.branch_group) {
            $scope.hdr.branch = '[BranchGroup]' + $scope.policy.branch_group;
        }
        else {
            $scope.hdr.branch = '==DEFAULT==';
        }
    }
    else {
        $scope.hdr.branchSelectDisabled = false;
    }

    $scope.hdr.branchSelectDisabled = ($scope.policy.id ? true : false);

    $scope.$watch('parameters', function(newVal, oldVal) {
        if (!newVal || angular.equals(newVal, oldVal)) return;

        for (var i=0; i<newVal.length; i++) {
            var p = newVal[i];

            if (oldVal[i]) {
                var a = angular.copy(p);
                var b = angular.copy(oldVal[i]);
                delete a.$$hashKey;
                delete a.dirty;
                delete b.$$hashKey;
                delete b.dirty;
                if (!angular.equals(a, b)) {
                    p.dirty = true;
                    $scope.hdr.dirty = true;
                }
            }

            if (!p.enabled) continue;

            if (p.value === undefined) {
                if ('default' in p) {
                    p.value = p.default;
                }
                else if (p.type === 'checkbox') {
                    p.value = 0;
                }
                else if (p.type === 'number' && p.unlimitedIfNull) {
                    if (p.isLimited === undefined || p.isLimited === null) {
                        p.isLimited = '0';
                        p.value = '';
                    }
                    else {
                        p.value = 0;
                    }
                }
                else if (p.type === 'number') {
                    p.value = 0;
                }
                else if (p.type === 'money') {
                    p.value = '0.00';
                }
            }
            if (p.unitOptions && (p.units === undefined || p.units === null)) {
                p.units = p.defaultUnit;
            }
            if (p.scopeOptions && (p.scope === undefined || p.scope === null)) {
                p.scope = p.defaultScope;
            }

            if ((p.variable === 'maxissueqty' || p.variable === 'maxrenewals') && p.isLimited === '0' && p.value === '') {
                p.value = 0;
            }

        }
    }, true);

    $scope.hdr.save = function() {
        var newPolicy = {};

        if (!($scope.policy.id > 0)) {
            if ($scope.hdr.branch === '==DEFAULT==') {
                $scope.policy.branch_code = null;
                $scope.policy.branch_group = null;
            }
            else if ($scope.hdr.branch.substr(0,13) == '[BranchGroup]') {
                $scope.policy.branch_code = null;
                $scope.policy.branch_group = $scope.hdr.branch.substr(13);
            }
            else if ($scope.hdr.branch) {
                $scope.policy.branch_code = $scope.hdr.branch;
                $scope.policy.branch_group = null;
            }
            else {
                $scope.policy.branch_code = null;
                $scope.policy.branch_group = null;
            }
        }


        $scope.parameters.forEach(function(p) {
            if (!p.enabled) return;

            if (p.unlimitedIfNull && (p.isLimited === '0')) {
                newPolicy[p.variable] = null;
            }
            else {
                newPolicy[p.variable] = p.value;
            }

            if (p.scopeOptions && (p.scope !== 'NONE')) {
                newPolicy[p.variable + '_scope'] = p.scope;
            }

            if (p.unitOptions) {
                newPolicy[p.variable + '_unit'] = p.units;
            }
        });
        $scope.policy.args = newPolicy;
        if ($scope.policy.id > 0) {
            loading.wrap(
                kwApi.CircPolicy.put({id: $scope.policy.id},$scope.policy).$promise,
                "Unable to save circ policy"
            ).then(function(rv) {
                $scope.reload();
                $scope.hdr.dirty = false;
                $scope.parameters.forEach(function(p) { p.dirty = false; });
            }, function(err) {
                $scope.reload();
            });
        }
        else {
            loading.wrap(
                kwApi.CircPolicy.save($scope.policy).$promise,
                "Unable to save circ policy"
            ).then(function(rv) {
                $scope.hdr.dirty = false;
                $scope.parameters.forEach(function(p) { p.dirty = false; });
                $state.go('staff.admin.circ.policy',{id: rv.id},{reload: true});
            }, function(err) {
                ;
            }); 
        }
    };
}])

.controller('StaffAdminCircRulesCtrl', ["$scope", "loading", "kwApi", "kohaDlg", "$uibModal", function($scope, loading, kwApi, kohaDlg, $uibModal) {

    $scope.rules = [];
    $scope.hdr.branchSelect = true;
    $scope.hdr.branchSelectDisabled = false;
    $scope.reload = function() {
        var args = $scope.hdr.getSearchArgs();
        loading.wrap(
            kwApi.CircRule.query(args).$promise,
            "Unable to load circ rules"
        ).then(function(rv) {
            rv.forEach(function(rec) {
                try {
                    rec.policy_args = JSON.parse(rec.policy_args);
                }
                catch (e) {
                    ;
                }
            });
            $scope.rules = rv;
        }, function(err) {
            ;
        });
    };

    $scope.reload();

    $scope.$watch('hdr.branch', $scope.reload);

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this rule? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.CircRule.delete({id: p.id}).$promise,
                "Can't delete circ rule"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };

    $scope.edit = function(p) {
        var baseArgs = {default: 1};
        if (p.branch_code)
            baseArgs.branch_code = p.branch_code;
        else if (p.branch_group)
            baseArgs.branch_group = p.branch_group;
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/rule-modal.html',
            controller: 'StaffAdminCircRuleCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return p;
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return kwApi.CircPolicy.query($scope.hdr.getSearchArgs(baseArgs)).$promise;
                },
                termsets: function() {
                    return kwApi.CircTermset.query($scope.hdr.getSearchArgs(baseArgs)).$promise;
                },
                test: function() {
                    return false;
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.new = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/rule-modal.html',
            controller: 'StaffAdminCircRuleCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return {
                        branch_code: $scope.hdr.getBranchCode(),
                        branch_group: $scope.hdr.getBranchGroup(), 
                        item_type: null,
                        patron_category_code: null,
                    };
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return kwApi.CircPolicy.query($scope.hdr.getSearchArgs({default: 1})).$promise;
                },
                termsets: function() {
                    return kwApi.CircTermset.query($scope.hdr.getSearchArgs({default: 1})).$promise;
                },
                test: function() {
                    return false;
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.hdr.test = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/rule-test-modal.html',
            controller: 'StaffAdminCircRuleCtrl',
            size: 'lg',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return {
                        branch_code: $scope.hdr.getBranchCode(),
                        branch_group: $scope.hdr.getBranchGroup(), 
                        item_type: null,
                        patron_category_code: null,
                    };
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return [];
                },
                termsets: function() {
                    return [];
                },
                test: function() {
                    return true;
                },
            },
        });
    };
}])

.controller('StaffAdminCircRuleCtrl', ["$scope", "permit", "test", "rule", "policies", "termsets", "kwApi", "loading", "configService", "hdr", function($scope, permit, test, rule, policies, termsets, kwApi, loading, configService, hdr) {
    $scope.rule = rule;
    $scope.test = test;
    $scope.hdr = hdr;
    if (test) $scope.rule.branch_group = false;

    $scope.policies = policies;
    $scope.termsets = termsets;

    $scope.selectConfig = {
        maxItems: 1,
        valueField: 'value',
        labelField: 'display', 
        searchField: ['display'],
        create: false,
    };

    $scope.policySelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'description', 
        searchField: ['description'],
        create: false,
    };

    $scope.onBranchChange = function() {
        var args = {};
        if ($scope.rule.branch_code) {
            args = {branch_code: $scope.rule.branch_code, default: 1};
        }
        else if ($scope.rule.branch_group) {
            args = {branch_group: $scope.rule.branch_group, default: 1};
        }
        else {
            args = {default: 1};
        }

        loading.wrap(
            kwApi.CircPolicy.query(args).$promise,
            "Unable to load circ policies"
        ).then(function(rv) {
            $scope.policies = rv;
        });

        loading.wrap(
            kwApi.CircTermset.query(args).$promise,
            "Unable to load circ termsets"
        ).then(function(rv) {
            $scope.termsets = rv;
        });
    };

    $scope.$watch('rule', function(newVal, oldVal) {
        if (!(newVal && !angular.equals(newVal,oldVal))) return;

        if (!$scope.test) {
            if (newVal.branch_code !== '' && newVal.branch_group !== '') {
                if (oldVal.branch_code === '' && newVal.branch_code !== '') {
                    newVal.branch_group = '';
                }
                else if (oldVal.branch_group === '' && newVal.branch_group !== '') {
                    newVal.branch_code= '';
                }
                else {
                    newVal.branch_group = '';
                }
            }

            if ((newVal.branch_code !== oldVal.branch_code) || (newVal.branch_group !== oldVal.branch_group)) {
                $scope.onBranchChange();
            }
        }
        else {
            if (newVal.branch_code && newVal.item_type && newVal.patron_category_code) {
                loading.wrap(
                    kwApi.CircRule.testTuple(newVal).$promise,
                    "Unable to test"
                ).then(function(rv) {
                    var cascaded = {};
                    for (var i=0; i<rv.length; i++) {
                        var thisPol = angular.copy(rv[i][3] || {});
                        angular.extend(thisPol, cascaded);
                        cascaded = rv[i][4] = angular.copy(thisPol);
                    }
                    $scope.testResults = rv;
                });
            }
        }
    }, true);


    ['branch_code','branch_group','item_type','patron_category_code'].forEach(function(p) {
        if ($scope.rule[p] === null || $scope.rule[p] === undefined)
            $scope.rule[p] = '';
    });

    $scope.branches = permit.branches
        .map(function(s) { return {value: s.branchcode, display: s.branchname}; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.branches.unshift({value: '', display: '(Any)'});

    $scope.branch_groups = permit.groups
        .map(function(s) { return {value: s.categorycode, display: s.categoryname}; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.branch_groups.unshift({value: '', display: '(Any)'});

    $scope.item_types = Object.keys(configService.itemtypes)
        .map(function(p) { return { value: p, display: configService.itemtypes[p].description }; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.item_types.unshift({value: '', display: '(Any)'});

    $scope.patron_categories = Object.keys(configService.patroncats)
        .map(function(p) { return { value: p, display: configService.patroncats[p].description }; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.patron_categories.unshift({value: '', display: '(Any)'});

    $scope.save = function() {
        if ($scope.test) return;

        var newRule = angular.copy($scope.rule);
        ['branch_code','branch_group','item_type','patron_category_code','circ_policy_id','circ_termset_id'].forEach(function(p) {
            if (newRule[p] === '')
                newRule[p] = null;
        });

        if (newRule.id) {
            loading.wrap(
                kwApi.CircRule.put({id: newRule.id},newRule).$promise,
                "Unable to save rule"
            ).then(function(rv) {
                $scope.$close();
            });
        }
        else {
            loading.wrap(
                kwApi.CircRule.save(newRule).$promise,
                "Unable to save rule"
            ).then(function(rv) {
                $scope.$close();
            });
        }
    };
}])


.controller('StaffAdminCircRecallRulesCtrl', ["$scope", "loading", "kwApi", "kohaDlg", "$uibModal", function($scope, loading, kwApi, kohaDlg, $uibModal) {

    $scope.rules = [];
    $scope.hdr.branchSelect = true;
    $scope.hdr.branchSelectDisabled = false;
    $scope.reload = function() {
        var args = $scope.hdr.getSearchArgs();
        loading.wrap(
            kwApi.RecallRule.query(args).$promise,
            "Unable to load circ rules"
        ).then(function(rv) {
            rv.forEach(function(rec) {
                try {
                    rec.policy_args = JSON.parse(rec.policy_args);
                }
                catch (e) {
                    ;
                }
            });
            $scope.rules = rv;
        }, function(err) {
            ;
        });
    };

    $scope.reload();

    $scope.$watch('hdr.branch', $scope.reload);

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this rule? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.RecallRule.delete({id: p.id}).$promise,
                "Can't delete circ rule"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };

    $scope.edit = function(p) {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/recall-rule-modal.html',
            controller: 'StaffAdminCircRecallRuleCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return p;
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return kwApi.CircPolicy.query($scope.hdr.getSearchArgs({default: 1})).$promise;
                },
                test: function() {
                    return false;
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.new = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/recall-rule-modal.html',
            controller: 'StaffAdminCircRecallRuleCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return {
                        branch_code: $scope.hdr.getBranchCode(),
                        branch_group: $scope.hdr.getBranchGroup(), 
                        item_type: null,
                        patron_category_code: null,
                    };
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return kwApi.CircPolicy.query($scope.hdr.getSearchArgs({default: 1})).$promise;
                },
                test: function() {
                    return false;
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.hdr.test = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/recall-rule-test-modal.html',
            controller: 'StaffAdminCircRecallRuleCtrl',
            size: 'lg',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                rule: function() {
                    return {
                        branch_code: $scope.hdr.getBranchCode(),
                        branch_group: $scope.hdr.getBranchGroup(), 
                        item_type: null,
                        patron_category_code: null,
                    };
                },
                permit: function() {
                    return $scope.permit;
                },
                policies: function() {
                    return [];
                },
                test: function() {
                    return true;
                },
            },
        });
    };
}])

.controller('StaffAdminCircRecallRuleCtrl', ["$scope", "permit", "test", "rule", "policies", "kwApi", "loading", "configService", "hdr", function($scope, permit, test, rule, policies, kwApi, loading, configService, hdr) {
    $scope.rule = rule;
    $scope.test = test;
    $scope.hdr = hdr;
    if (test) $scope.rule.branch_group = false;

    $scope.policies = policies;

    $scope.selectConfig = {
        maxItems: 1,
        valueField: 'value',
        labelField: 'display', 
        searchField: ['display'],
        create: false,
    };

    $scope.policySelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'description', 
        searchField: ['description'],
        create: false,
    };

    $scope.onBranchChange = function() {
        var args = {};
        if ($scope.rule.branch_code) {
            args = {branch_code: $scope.rule.branch_code, default: 1};
        }
        else if ($scope.rule.branch_group) {
            args = {branch_group: $scope.rule.branch_group, default: 1};
        }
        else {
            args = {default: 1};
        }

        loading.wrap(
            kwApi.CircPolicy.query(args).$promise,
            "Unable to load circ policies"
        ).then(function(rv) {
            $scope.policies = rv;
        });
    };

    $scope.$watch('rule', function(newVal, oldVal) {
        if (!(newVal && !angular.equals(newVal,oldVal))) return;

        if (!$scope.test) {
            if (newVal.branch_code !== '' && newVal.branch_group !== '') {
                if (oldVal.branch_code === '' && newVal.branch_code !== '') {
                    newVal.branch_group = '';
                }
                else if (oldVal.branch_group === '' && newVal.branch_group !== '') {
                    newVal.branch_code= '';
                }
                else {
                    newVal.branch_group = '';
                }
            }

            if ((newVal.branch_code !== oldVal.branch_code) || (newVal.branch_group !== oldVal.branch_group)) {
                $scope.onBranchChange();
            }
        }
        else {
            if (newVal.branch_code && newVal.item_type && newVal.patron_category_code) {
                loading.wrap(
                    kwApi.RecallRule.testTuple(newVal).$promise,
                    "Unable to test"
                ).then(function(rv) {
                    for (var i=0; i<rv.length; i++) {
                        if (("" + $scope.rule.from_opac) == '1' && rv[i][3] && ("" + rv[i][3].from_opac) == '0')
                            rv[i][3] = null;
                    }
                    $scope.testResults = rv;
                });
            }
        }
    }, true);


    ['branch_code','branch_group','item_type','patron_category_code'].forEach(function(p) {
        if ($scope.rule[p] === null || $scope.rule[p] === undefined)
            $scope.rule[p] = '';
    });

    $scope.branches = permit.branches
        .map(function(s) { return {value: s.branchcode, display: s.branchname}; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.branches.unshift({value: '', display: '(Any)'});

    $scope.branch_groups = permit.groups
        .map(function(s) { return {value: s.categorycode, display: s.categoryname}; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.branch_groups.unshift({value: '', display: '(Any)'});

    $scope.item_types = Object.keys(configService.itemtypes)
        .map(function(p) { return { value: p, display: configService.itemtypes[p].description }; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.item_types.unshift({value: '', display: '(Any)'});

    $scope.from_opac = [{ value: 0, display: 'No'}, { value: 1, display: 'Yes' }];

    $scope.patron_categories = Object.keys(configService.patroncats)
        .map(function(p) { return { value: p, display: configService.patroncats[p].description }; })
        .sort(function(a,b) { return (a.display < b.display ? -1 : a.display > b.display ? 1 : 0) });
    if (!$scope.test)
        $scope.patron_categories.unshift({value: '', display: '(Any)'});

    $scope.save = function() {
        if ($scope.test) return;

        var newRule = angular.copy($scope.rule);
        ['branch_code','branch_group','item_type','patron_category_code','circ_policy_id'].forEach(function(p) {
            if (newRule[p] === '')
                newRule[p] = null;
        });

        if (newRule.id) {
            loading.wrap(
                kwApi.RecallRule.put({id: newRule.id},newRule).$promise,
                "Unable to save rule"
            ).then(function(rv) {
                $scope.$close();
            });
        }
        else {
            loading.wrap(
                kwApi.RecallRule.save(newRule).$promise,
                "Unable to save rule"
            ).then(function(rv) {
                $scope.$close();
            });
        }
    };
}])


.controller('StaffAdminCircTermsetsCtrl', ["$scope", "$state", "loading", "kwApi", "kohaDlg", function($scope, $state, loading, kwApi, kohaDlg) {

    $scope.termsets = [];
    $scope.hdr.branchSelect = true;
    $scope.hdr.branchSelectDisabled = false;
    $scope.hdr.test = false;
    $scope.hdr.save = false;

    $scope.reload = function() {
        var args = $scope.hdr.getSearchArgs();;
        loading.wrap(
            kwApi.CircTermset.query(args).$promise,
            "Unable to load circ termsets"
        ).then(function(rv) {
            $scope.termsets = rv;
        }, function(err) {
            ;
        });
    };

    $scope.reload();

    $scope.$watch('hdr.branch', $scope.reload);

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this term? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.CircTermset.delete({id: p.id}).$promise,
                "Can't delete circ policy"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };

    $scope.edit = function(p) {
        $state.go('staff.admin.circ.termset', {id: p.id});
    };

    $scope.new = function(p) {
        $state.go('staff.admin.circ.termset', {id: 0});
    };
}])

.controller('StaffAdminCircTermsetCtrl', ["$scope", "$q", "$state", "termset", "termDates", "loading", "kwApi", "kohaDlg", "$uibModal", function($scope, $q, $state, termset, termDates, loading, kwApi, kohaDlg, $uibModal) {
    $scope.hdr.test = false;

    $scope.refresh = function(p) {
        $scope.termset = p[0];
        $scope.termDates = p[1];
    };

    $scope.reload = function() {
        loading.wrap(
            $q.all([
                kwApi.CircTermset.get({id: $scope.termset.id}).$promise,
                kwApi.CircTermset.getDates({id: $scope.termset.id}).$promise,
            ]),
            "Unable to load term set"
        ).then(function(rv) {
            $scope.refresh(rv);
        }, function(err) {
            ;
        });
    };
    $scope.refresh([termset,termDates]);


    $scope.hdr.branchSelect = true;
    if ($scope.termset.id) {
        $scope.hdr.branchSelectDisabled = true;
        if ($scope.termset.branch_code) {
            $scope.hdr.branch = $scope.termset.branch_code;
        }
        else if ($scope.termset.branch_group) {
            $scope.hdr.branch = '[BranchGroup]' + $scope.termset.branch_group;
        }
        else {
            $scope.hdr.branch = '==DEFAULT==';
        }
    }
    else {
        $scope.hdr.branchSelectDisabled = false;
    }

    $scope.$watch('termset.description', function(newVal, oldVal) {
        if (newVal && oldVal && newVal !== oldVal) {
            $scope.hdr.dirty = true;
        }
    });

    $scope.hdr.save = function() {
        if (!$scope.hdr.dirty) return;
        if ($scope.termset.id) {
            loading.wrap(
                kwApi.CircTermset.put({id: $scope.termset.id}, $scope.termset).$promise,
                "Unable to save circ termset"
            ).then(function(rv) {
                $scope.hdr.dirty = false;
                $scope.reload();
            }, function(rv) {
                $scope.reload();
            });
        }
        else {
            loading.wrap(
                kwApi.CircTermset.save($scope.termset).$promise,
                "Unable to save circ termset"
            ).then(function(rv) {
                $scope.hdr.dirty = false;
                $state.go('staff.admin.circ.termset', {id: rv.id}, {reload: true});
            }, function(rv) {
                $scope.reload();
            });
        }
    };

    $scope.edit = function(p) {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/term-date-modal.html',
            controller: 'StaffAdminCircTermDateCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                termDate: function() {
                    return p;
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.new = function() {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/admin/circ/term-date-modal.html',
            controller: 'StaffAdminCircTermDateCtrl',
            resolve: {
                hdr: function() {
                    return $scope.hdr;
                },
                termDate: function() {
                    return {circ_termset_id: $scope.termset.id};
                },
            },
        }).result.then(function(s) {
            $scope.reload();
        }, function(err) {
            $scope.reload();
        });
    };

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this date? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.CircTermDate.delete({id: p.id}).$promise,
                "Can't delete date"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };
}])

.controller('StaffAdminCircTermDateCtrl', ["$scope", "termDate", "loading", "kwApi", "hdr", function($scope, termDate, loading, kwApi, hdr) {
    $scope.hdr = hdr;
    $scope.date = angular.copy(termDate);
    if ($scope.date.id) {
        $scope.date.startdate = dayjs($scope.date.startdate).toDate();
        $scope.date.enddate = dayjs($scope.date.enddate).toDate();
        $scope.date.duedate = dayjs($scope.date.duedate).toDate();
    }

    $scope.$watch('date', function(newVal) {
        if (!newVal) return;
        var err = [];
        if (newVal.startdate > newVal.enddate) {
            err.push("Start date is after end date");
        }
        if (newVal.enddate > newVal.duedate) {
            err.push("End date is after due date");
        }
        $scope.valid = (err.length == 0) ? true : false;
        $scope.errors = err.join(', ');
        console.dir($scope.valid);
        console.dir($scope.errors);
    }, true);


    $scope.save = function() {
        loading.wrap(
            $scope.date.id
                ? kwApi.CircTermDate.put({id: $scope.date.id}, $scope.date).$promise
                : kwApi.CircTermDate.save($scope.date).$promise,
            "Unable to save date"
        ).then(function(rv) {
            $scope.$close();
        });
    };
}])


.controller('StaffAdminNcipBaseCtrl', [ "$scope", "$state", "userService", function($scope, $state, userService) {
    $scope.hdr = {
        title: 'NCIP Admin',
        save: function() { ; },
        dirty: false,
    };

    $scope.setOptions = function() {
        $scope.hdr.state = $state.$current.name.split('.').pop();
    };

    $scope.setOptions();

    $scope.$on('$stateChangeSuccess', function(evnt, toState) {
        $scope.setOptions();
    });

    $scope.isClass = function(s) {
        return ($scope.hdr.state == s) ? 'btn-primary' : 'btn-default';
    };

    $scope.permit = {
        all: false,
        branches: [],
        groups: [],
        any: false,
    };

    $scope.branchSelectConfig = {
        maxItems: 1,
        valueField: 'id',
        labelField: 'display', 
        searchField: ['display'],
        create: false,
    };

    $scope.hdr.branch = userService.login_branch;

    $scope.getPermissions = function() {
        $scope.permit.all = userService.can({parameters: 'netsvc'});

        userService.getAccessibleBranchesAndGroups('parameters.netsvc').then(function(b) {
            $scope.permit.branches = b.branches;
            $scope.permit.groups = b.groups;
            $scope.permit.any = (b.branches.length>0);

            $scope.hdr.branches = [];
            Array.prototype.push.apply(
                $scope.hdr.branches,
                $scope.permit.branches.map(function(b) {
                    return { id: b.branchcode, display: b.branchname }
                }).sort(function(a,b) {
                    return (a.display < b.display) ? -1 :
                        (a.display > b.display) ? 1 : 0;
                })
            );
        });
    };

    $scope.getPermissions();
    $scope.$on('userSetBranch', $scope.getPermissions);
}])


.controller('StaffAdminNcipAgenciesCtrl', [  "$scope", "$state", "loading", "kwApi", "kohaDlg", 
            function($scope, $state, loading, kwApi, kohaDlg) {

    $scope.ncipAgencies = [];
    $scope.hdr.branchSelect = true;
    $scope.hdr.branchSelectDisabled = false;
    $scope.hdr.save = false;

    $scope.reload = function() {
        loading.wrap(
            kwApi.Branch.getNcipAgencies({id: $scope.hdr.branch}).$promise,
            "Unable to load NCIP agencies"
        ).then(function(rv) {
            $scope.ncipAgencies = rv;
        }, function(err) {
            ;
        });
    };

    $scope.reload();

    $scope.$watch('hdr.branch', $scope.reload);

    $scope.delete = function(p) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this NCIP agency? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (!rv) return;
            loading.wrap(
                kwApi.NcipAgency.delete({id: p.id}).$promise,
                "Can't delete NCIP agency"
            ).then(function(rv) {
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        });
    };

    $scope.edit = function(p) {
        $state.go('staff.admin.ncip.agency', {id: p.id});
    };

    $scope.new = function(p) {
        $state.go('staff.admin.ncip.agency', {id: 0});
    };
}])

.controller('StaffAdminNcipAgencyCtrl', [  "$scope", "$state", "ncipAgency", "loading", "kwApi", function( $scope, $state, ncipAgency, loading, kwApi ) {

    $scope.knownServices = [
        'LookupVersion',
        'LookupAgency',
        'LookupUser',
        'CheckOutItem',
        'CheckInItem',
        'RenewItem',
        'RequestItem',
        'CancelRequestItem',
    ];

    $scope.hasService = {};

    $scope.refresh = function(p) {
        $scope.ncipAgency = p;
        $scope.ncipAgency.services ||= '';
        $scope.knownServices.forEach(function(s) {
            $scope.hasService[s] = false;
        });
        $scope.ncipAgency.services.split(' ').forEach(function(s) {
            if (s) {
                $scope.hasService[s] = true;
            }
        });
    };

    $scope.reload = function() {
        loading.wrap(
            kwApi.NcipAgency.get({id: $scope.ncipAgency.id}).$promise
        ).then(function(rv) {
            $scope.refresh(rv);
        }, function(err) {
            ;
        });
    };
    $scope.refresh(ncipAgency);

    $scope.hdr.branchSelect = true;
    if ($scope.ncipAgency.id) {
        $scope.hdr.branchSelectDisabled = true;
        if ($scope.ncipAgency.branch_code) {
            $scope.hdr.branch = $scope.ncipAgency.branch_code;
        }
    }
    else {
        $scope.hdr.branchSelectDisabled = false;
    }

    ['description','agency_id','system_id','agency_auth','system_auth'].forEach(function(p) {
        $scope.$watch('ncipAgency.'+p, function(newVal, oldVal) {
            if (newVal && oldVal && newVal !== oldVal) {
                $scope.hdr.dirty = true;
            }
        });
    });

    $scope.$watch('hasService', function(newVal, oldVal) {
        if (newVal && oldVal && !angular.equals(oldVal,newVal)) {
            $scope.hdr.dirty = true;

            $scope.ncipAgency.services = 
                Object.keys($scope.hasService)
                    .filter(function(f) { return $scope.hasService[f] })
                    .sort(function(a,b) { return(a<b ? -1 : a>b ? 1 : 0) })
                    .join(' ');
        }
    }, true);

    $scope.hdr.save = function() {
        if (!$scope.hdr.dirty) return;
        if ($scope.ncipAgency.id) {
            loading.wrap(
                kwApi.NcipAgency.put({id: $scope.ncipAgency.id}, $scope.ncipAgency).$promise,
                "Unable to save NCIP agency"
            ).then(function(rv) {
                $scope.hdr.dirty = false;
                $scope.reload();
                $state.go('staff.admin.ncip.agencies');
            }, function(rv) {
                $scope.reload();
            });
        }
        else {
            $scope.ncipAgency.branch_code = $scope.hdr.branch;
            loading.wrap(
                kwApi.NcipAgency.save($scope.ncipAgency).$promise,
                "Unable to save NCIP agency"
            ).then(function(rv) {
                $scope.hdr.dirty = false;
                $state.go('staff.admin.ncip.agencies');
            }, function(rv) {
                $scope.reload();
            });
        }
    };
}])


// Marc Validation Rulesets
.controller('StaffMvrCtrl', ["$scope", "$state", "userService", function ($scope, $state, userService) {
    if ($state.current.name == 'staff.tools.mvr') {
        $state.go('staff.tools.mvr.index');
    }

    $scope.userCan = {
        modify: userService.can({editcatalogue: {mvr: 'update'}}),
        delete: userService.can({editcatalogue: {mvr: 'update'}}),
    };
}])

.controller('StaffMvrIndexCtrl', ["$scope", "rulesets", "kwApi", "alertService", "kohaDlg", function ($scope, rulesets, kwApi, alertService, kohaDlg) {
    $scope.rulesets = rulesets;

    $scope.order = {field: 'title', reverse: false};

    $scope.currentPage = 1;
    $scope.totalRows = (rulesets[0]||{})._embed_total_rows||0;

    $scope.reload = function() {
        var start = ($scope.currentPage -1) * 20;
        kwApi.MarcValidationRuleset.query({view: 'brief', start: start, limit: 20, sort: $scope.order.field, reverse: ($scope.order.reverse ? 1 : 0)}).$promise.then(function(rv) {
            $scope.rulesets = rv;
        });
    };

    $scope.pageChanged = function() {
        $scope.reload();
    };

    $scope.sortChanged = function() {
        $scope.currentPage = 1;
        $scope.reload();
    };

    $scope.delete = function(r) {
        kohaDlg.dialog({
            heading: 'Are you sure?',
            message: 'Are you sure you want to delete this ruleset? This CANNOT be undone.',
            buttons: [{val: true, label: 'Yes', btnClass: 'btn-primary'}, {val: false, label: 'Cancel'}],
        }).result.then(function(rv) {
            if (rv) {
                kwApi.MarcValidationRuleset.delete({id: r.id}).$promise.then(function(rv) {
                    alertService.add({msg: "Ruleset deleted"});
                    $scope.reload();
                }, function(err) {
                    alertService.addApiError(err, 'Delete failed');
                    $scope.reload();
                });
            }
        });
    };
}])

.controller('StaffMvrDetailsCtrl', ["$scope", "ruleset", "kwApi", "$state", "loading", "$uibModal", function ($scope, ruleset, kwApi, $state, loading, $uibModal) {
    $scope.ruleset = ruleset;
    $scope.editing = ($scope.ruleset.id ? false : true);
    $scope.editingBlock = false;
    $scope.title = ruleset.title;
    $scope.syntaxClass = 'warning';
    $scope.blockIndex = 0;
    $scope.blockRef = {};

    $scope.refresh = function() {
        $scope.title = ruleset.title;
        $scope.syntaxClass = 'success';
        $scope.ruleset.blocks.forEach(function(b) {
            if (!b._id) {
                b._id = ++$scope.blockIndex;
            }
            if (b._syntaxClass == 'danger')
                $scope.syntaxClass = 'danger';
            else if (b._syntaxClass == 'warning' && $scope.syntaxClass == 'success')
                $scope.syntaxClass = 'warning';

        });
    };

    $scope.reload = function() {
        if ($scope.ruleset.id) {
            kwApi.MarcValidationRuleset.get({id: $scope.ruleset.id}).$promise.then(function(rv) {
                $scope.ruleset = rv;
                $scope.refresh();
            });
        }
    };

    if ($scope.ruleset.id) {
        $scope.refresh();
    }
    else {
        $scope.ruleset = {blocks: [{code: ''}]};
    }

    $scope.edit = function() {
        if ($scope.userCan.modify) {
            $scope.editing = true;
        }
    };

    $scope.editCancel = function() {
        if ($scope.ruleset.id) {
            $scope.editing = false;
            $scope.reload();
        }
        else {
            $state.go('staff.tools.mvr.index');
        }
    };

    $scope.editBlock = function(n) {
        $scope.editingBlock = n;
        var b = $scope.ruleset.blocks[n];
        b._edit = true;
        b._old = b.code;
    };

    $scope.saveBlock = function(n) {
        $scope.editingBlock = false;
        var b = $scope.ruleset.blocks[n];
        if (b.code !== b._old) {
            b.meta.dirty = true;
            b._syntaxClass = 'warning';
            $scope.syntaxClass = 'warning';
            $scope.anyDirty = true;
        }
        b._edit = false;
    };

    $scope.deleteBlock = function(n) {
        $scope.ruleset.blocks.splice(n,1);
    };

    $scope.newBlock = function() {
        $scope.ruleset.blocks.push({code: '', _syntaxClass: 'warning', _id: ++$scope.blockIndex});
    };

    $scope.checkBlock = function(n) {
        var b = $scope.ruleset.blocks[n];
        kwApi.MarcValidationRuleset.checkSyntax({}, {code: b.code}).$promise
        .then(function(rv) {
            if (rv.error) {
                b._error = rv.error
                b._syntaxClass = 'danger';
            }
            else {
                b._error = null;
                b._syntaxClass = 'success';
            }
        });
    };

    $scope.buildBlock = function(n) {
        $uibModal.open({
            backdrop: 'static',
            templateUrl: '/app/static/partials/staff/tools/mvr/builder-modal.html',
            controller: 'StaffMvrBuilderCtrl',
            size: 'lg',
            resolve: {
                block: function() {
                    return $scope.ruleset.blocks[n];
                },
            },
        }).result.then(function(s) {
            if (s) {
                $scope.anyDirty = true;
                $scope.ruleset.blocks[n] = s;
            }
        });
    };

    $scope.checkAll = function() {
        kwApi.MarcValidationRuleset.checkAllSyntax({id: $scope.ruleset.id},{}).$promise.then(function(rv) {
            $scope.syntaxClass = 'success';
            var i;
            for (i=0; i<rv.length; i++) {
                var b = $scope.ruleset.blocks[i];
                if (rv[i] && (typeof(rv[i]) === 'string')) {
                    b._error = rv[i];
                    b._syntaxClass = 'danger';
                    $scope.syntaxClass = 'danger';
                }
                else {
                    b._syntaxClass = 'success';
                }
            }
            while (i < $scope.ruleset.blocks.length) {
                $scope.ruleset.blocks[i]._syntaxClass = 'success';
                delete $scope.ruleset.blocks[i]._error;
                i++;
            }
            console.dir($scope.ruleset);
        });
    };



    $scope.save = function() {
        if (!$scope.userCan.modify) 
            return;
        var promise;
        if (!$scope.ruleset.id) {
            loading.wrap(
                kwApi.MarcValidationRuleset.save($scope.ruleset).$promise, 
                "Unable to save ruleset"
            ).then(function(rv) {
                $scope.editing = false;
                $state.go('staff.tools.mvr.view', {id: rv.id});
            }, function(err) {
                $scope.reload();
            });
        }
        else {
            loading.wrap(
                kwApi.MarcValidationRuleset.put({id: $scope.ruleset.id}, $scope.ruleset).$promise, 
                "Unable to save ruleset"
            ).then(function(rv) {
                $scope.editing = false;
                $scope.reload();
            }, function(err) {
                $scope.reload();
            });
        }
    };

}])

.controller('StaffMvrBuilderCtrl', ["$scope", "block", "loading", "$timeout", function ($scope, block, loading, $timeout) {
    $scope.block = angular.copy(block);
    if (!$scope.block.meta) $scope.block.meta = {};
    if (!$scope.block.meta.rule) $scope.block.meta.rule = {};
    if (!$scope.block.meta._buildMode) $scope.block.meta._buildMode = 'assert';

    var sanitizeInput; sanitizeInput = function(r) {
        if (r instanceof Array) {
            r.forEach(function(e) {
                sanitizeInput(e);
            });
        }
        else {
            Object.keys(r).forEach(function(k) {
                var v = r[k];

                if (k === '_dsl' || k === '_valid')
                    delete r[k];
                else if ((typeof(v) === 'object') && (v !== null))
                    sanitizeInput(v);
            });
        }
    };
    //sanitizeInput($scope.block.meta.rule);

    loading.add();
    $timeout(function() {
        $scope.$broadcast('mvr-register');
        loading.resolve();
    }, 100);

    $scope.save = function() {
        var code = "# Automatically generated by rule buider\n"
            + "# If manually edited, the rule builder cannot be used to maintain it\n"
            + $scope.block.meta.rule._dsl + ";\n";

        $scope.block.code = code;
        $scope.$close($scope.block);
    };
}])

.controller('StaffSendMessageCtrl', ["$scope", "kwApi", "$filter", "loading", "alertService", function($scope, kwApi, $filter, loading, alertService) {
    $scope.recipientTypes = [{
        value: 'users', display: 'Specific user(s)'
    }, {
        value: 'email', display: 'Email address'
    }];

    $scope.m = {
        type: 'gen.letter',
        async: "1",
        recipientType: 'users',
        email: '',
        endpoint: 'default',
        immediate: false,
        priority: false,
        title: '',
        content: '',
        users: [],
        new_user_id: null,
    };

    $scope.isValid = function() {
        if ($scope.m.type === 'gen.letter') {
            if ($scope.m.recipientType !== 'users' && $scope.m.recipientType !== 'email') return false;
        }
        else {
            if ($scope.m.recipientType !== 'users' && $scope.m.recipientType !== 'multicast') return false;
        }

        if ($scope.m.recipientType === 'users' && $scope.m.new_user_id === null && $scope.m.users.length == 0) return false;

        if ($scope.m.recipientType === 'email' && !$scope.m.email) return false;

        if ($scope.m.type === 'sys.shutdown') {
            if (!$scope.m.when) return false;
        }
        else {
            if (!$scope.m.content) return false;
        }
        return true;
    };

    $scope.$watch('m.type', function(newVal) {
        if (newVal == 'gen.letter') {
            $scope.recipientTypes = [{
                value: 'users', display: 'Specific user(s)'
            }, {
                value: 'email', display: 'Email address'
            }];
            $scope.m.recipientType = 'users';
        }
        else if (newVal == 'gen.misc') {
            $scope.recipientTypes = [{
                value: 'users', display: 'Specific user(s)'
            }, {
                value: 'multicast', display: 'All subscribed users'
            }];
            $scope.m.recipientType = 'multicast';
        }
        else if (newVal == 'sys.shutdown') {
            $scope.recipientTypes = [{
                value: 'multicast', display: 'All subscribed users'
            }];
            $scope.m.recipientType = 'multicast';
        }
    });

    $scope.$watch('m.recipientType', function(newVal) {
        if (newVal == "multicast")
            $scope.m.async = "1";
    });

    $scope.patronSelectConfig = {
        load: function(query, callback) {
            kwApi.Patron.query({view: 'picker', searchValue: query}).$promise.then(function(rv) {
                callback(rv);
            }, function(err) {
                callback();
            });
        },
        maxItems: 1,
        loadThrottle: 600,
        valueField: 'borrowernumber',
        labelField: 'firstname',
        searchField: ['firstname','surname','card_number'],
        render: {
            item: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' [' + item.card_number + '] (' + branchName + ')</div>';
            },
            option: function(item, escape) {
                if (!item) return '';
                var branchName = $filter('displayName')(item.branch_code, 'branch');
                return '<div>' + item.firstname + ' ' + item.surname + ' [' + item.card_number + '] (' + branchName + ')</div>';
            }
        }
    };

    $scope.addUser = function() {
        kwApi.Patron.get({id: $scope.m.new_user_id}).$promise.then(function(rv) {
            var branchName = $filter('displayName')(rv.branchcode, 'branch');
            $scope.m.users.push({
                id: rv.borrowernumber,
                description: (rv.firstname + ' ' + rv.surname + ' (' + branchName + ') [' + rv.cardnumber + ']'),
            });
            $scope.m.new_user_id = null;
        });
    };

    $scope.delUser = function(n) {
        $scope.m.users.splice(n,1);
    };

    $scope.send = function() {
        var message = {};
        var opts = {};
        var async = false;

        if (!$scope.m.type) return;
        message.code = $scope.m.type;

        var users = $scope.m.users.map(function(e) { return e.id; });
        if ($scope.m.new_user_id !== null)
            users.push($scope.m.new_user_id);

        if (($scope.m.recipientType !== 'multicast') && !users.length && !$scope.m.email) return;

        if ($scope.m.type === 'gen.letter') {
            message.parameters = {
                title: $scope.m.title,
                content: $scope.m.content,
            };

            if ($scope.m.recipientType === 'users') {
                if (users.length > 1)
                    message.user_ids = users;
                else if (users.length == 1)
                    message.user_id = users[0];
                else
                    return;
            }
            else if ($scope.m.recipientType === 'email') {
                if (!$scope.m.email) return;
                message.recipient = $scope.m.email;
            }
            else {
                opts.multicast = 1;
            }

            if (!opts.force_endpoint && ($scope.m.endpoint !== 'default'))
                opts.force_endpoint = $scope.m.endpoint;
            else if ($scope.m.recipientType === 'email')
                opts.force_endoint = 'email';

            if ($scope.m.immediate)
                opts.deliver = 1;

            if ($scope.m.priority)
                message.level = 2;

            if ($scope.m.async === 1 || $scope.m.async === "1")
                async = true;
        }
        else if ($scope.m.type === 'gen.misc') {
            message.parameters = $scope.m.content;

            if ($scope.m.recipientType === 'users')  {
                if (users.length > 1)
                    message.user_ids = users;
                else if (users.length == 1)
                    message.user_id = users[0];
                else
                    return;
            }
            else {
                opts.multicast = 1;
            }

            if (!opts.force_endpoint && ($scope.m.endpoint !== 'default'))
                opts.force_endpoint = $scope.m.endpoint;

            if ($scope.m.immediate)
                opts.deliver = 1;

            if ($scope.m.priority)
                message.level = 2;

            if ($scope.m.async === 1 || $scope.m.async === "1")
                async = true;
        }
        else {
            message.parameters = {
                shutdown: { when: $scope.m.when }
            };

            opts.multicast = 1;
            opts.force_endpoint = 'popup';
            message.level = 2;

            if ($scope.m.async === 1 || $scope.m.async === "1")
                async = true;
        }

        var reqParams = {};
        if (async)
            reqParams.async = 1;

        loading.wrap(
            kwApi.Message.send(reqParams, {message: message, opts: opts}).$promise,
            "Unable to send message"
        ).then(function(rv) {
            if (async)
                alertService.add({msg: "Message enqueued for asynchronous delivery", type: 'info'});
            else
                alertService.add({msg: "Sent " + rv.messages + " messages to " + rv.endpoints + " endpoints", type: 'info'});
        });
    };
}])

})();
